/* eslint-disable react/no-danger */
import React from 'react';
import {
  addGlossaries,
  formatList,
  removeHtmlClassFromString,
} from '../../utils/helpers';
import { glossaries } from '../../utils/reactiveVars';

const BioModule = ({ text, title, image }) => {
  const glossariesArr = glossaries();
  let formattedText = formatList(removeHtmlClassFromString(text));
  formattedText = addGlossaries(formattedText, glossariesArr);

  return (
    <div className="row">
      <div className="col-md-5">
        <img src={image} className="mb-3 img-fluid" alt={title} />
      </div>
      <div className="col-md-6">
        {title && <div className="d-block text__black text__fs-20 mb-2 font-weight-bold">{title}</div>}
        <p
          className="summary__text-sm text__black"
          dangerouslySetInnerHTML={{ __html: formattedText }}
        />
      </div>
    </div>
  );
};

export default BioModule;
