import React from 'react';

const Header = () => (
  <div className="main__container bg__green-meadow mt-0">
    <div className="singsubject__header schoolroutine__edition">
      <h2 className="singsubject__header__title">School Routines & Signals</h2>
      <p className="singsubject__header__text">
        Songs to influence mood, focus attention, signal a change of activity
        and make life in your classroom a little easier!
      </p>
    </div>
  </div>
);

export default Header;
