import { gql } from '@apollo/client';
import { TagFieldsFragment } from '../tag/fragments';

export const SearchResultFieldsFragments = gql`
  fragment SearchResultFields on SearchResult {
    id
    type
    category
    title
    description
    media
    minAge
    maxAge
    slug
    tags {
      ...TagFields
    }
    link
  }
  ${TagFieldsFragment}
`;

export const SearchResultResponseFieldsFragments = gql`
  fragment SearchResultResponseFields on SearchResource {
    results {
      ...SearchResultFields
    }
    count {
      song
      esongbook
      assembly
      playlist
      collection
      musicActivity
      musicCurriculumActivity
      blogOrGuide
      sendResource
      curriculumLinksAndMore
    }
  }
  ${SearchResultFieldsFragments}
`;
