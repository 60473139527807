/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useReactiveVar } from '@apollo/client';

import { MediaButton } from './Icon';

import useStores from '../hooks/useStores';
import {
  isMobileFullscreen,
  isPlayerFullscreen,
} from '../../utils/reactiveVars';
import Spacer from './SpacerNew';
import GridContainer from './Styles/GridContainer';

type Props = {
  gridPosition: number;
  getSongRequest: (
    id: number | string,
    songShareId?: null | undefined,
    ootaShareUUID?: string
  ) => number | string;
};

export const SongControls = ({
  gridPosition,
  getSongRequest,
}: Props): JSX.Element => {
  const { mediaPlayerStore } = useStores();

  const mobileFullscreenStatus = useReactiveVar(isMobileFullscreen);
  const currentSongId = mediaPlayerStore.playlist.findIndex(
    (song) => song.id === mediaPlayerStore.song.id
  );

  const nextSongId = mediaPlayerStore.playlist[currentSongId + 1]?.id;
  const previousSongId = mediaPlayerStore.playlist?.[currentSongId - 1]?.id;

  const playButtonShouldPulsate =
    mediaPlayerStore.ready &&
    !mediaPlayerStore.playing &&
    !(mediaPlayerStore.progress.playedSeconds > 0) &&
    !isPlayerFullscreen();

  return useObserver(() => (
    <GridContainer
      gridPosition={gridPosition}
      mobileFullscreen={mobileFullscreenStatus}
    >
      <MediaButton
        icon="ChangeSong"
        mobileDimensions={16}
        tabletDimensions={14}
        mobileFullscreenDimensions={22}
        onClick={() => {
          // setPosition(position - 1);
          // mediaPlayerStore.setSong(mediaPlayerStore.song.src[position - 1]);
          if (mediaPlayerStore.progress.playedSeconds < 1) {
            mediaPlayerStore.setPlaying(false);
            // setSongId(previousSongId);
            previousSongId && getSongRequest(previousSongId);
            setTimeout(() => {
              mediaPlayerStore.switchSong(true);
            }, 400);
            return;
          }
          mediaPlayerStore.switchSong(true);
        }}
      />
      <Spacer />
      <MediaButton
        icon={mediaPlayerStore.playing ? 'Pause' : 'Play'}
        onClick={() => {
          mediaPlayerStore.setPlaying(!mediaPlayerStore.playing);
          mediaPlayerStore.resetJumpTo();
        }}
        mobileDimensions={30}
        tabletDimensions={30}
        mobileFullscreenDimensions={60}
        pulsate={playButtonShouldPulsate}
      />
      <Spacer />
      <MediaButton
        icon="ChangeSong"
        onClick={() => {
          // mediaPlayerStore.setSong(mediaPlayerStore.song.src[position + 1]);]
          mediaPlayerStore.setPlaying(false);
          getSongRequest(nextSongId);
          setTimeout(() => {
            mediaPlayerStore.switchSong(false);
          }, 1000);
        }}
        rotate={180}
        tabletDimensions={14}
        hideOnMobile
        showOnMobileFs
        mobileFullscreenDimensions={22}
        disabled={!nextSongId}
      />
    </GridContainer>
  ));
};
