import { useMutation } from '@apollo/client';
import { ADD_LESSON_PLAN_BOOKMARK } from '../../graphql/mutations/curriculum';
import {
  dismissErrorMessage,
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { GET_MUSIC_CURRICULUM } from '../../graphql/queries/musicCurriculum/musicCurriculum';

const useAddLessonPlanBookmark = (organisationRef) => {
  const [addLessonPlanBookmark, { loading }] = useMutation(
    ADD_LESSON_PLAN_BOOKMARK,
    {
      refetchQueries: [
        { query: GET_MUSIC_CURRICULUM, variables: { organisationRef } },
      ],
      onCompleted: () => {
        displaySuccessMessage('Bookmark added!');
        dismissErrorMessage();
        dismissLoadingMessage();
      },
      onError: () => displayErrorMessage('Failed to add bookmark'),
      notifyOnNetworkStatusChange: true,
    }
  );

  loading && displayLoadingMessage();

  return { addLessonPlanBookmark, loading };
};

export default useAddLessonPlanBookmark;
