import { css, styled } from 'twin.macro';
import 'styled-components/macro';

type BackgroundProps = {
  scale: number;
  containerDimensions: { width: number; height: number };
  plainTextView: boolean;
  background: string;
};
const Background = styled('div')<BackgroundProps>(
  ({ plainTextView, background, scale, containerDimensions }) => [
    css`
      min-height: 100%;
      height: ${(containerDimensions.height / (622 * scale)) * 100}%;
      margin-top: 0px;
      display: block;
      position: absolute;
      top: 0px;
      width: 150%;
      left: -25%;
      background-repeat: no-repeat;
      background-position: center center;
      z-index: 100;
      background-size: 100% auto;
      background-color: #fff;
    `,
    plainTextView
      ? css`
          background-color: rgb(255, 255, 183);
        `
      : css`
          background-image: url(${background});
        `,
  ]
);

export default Background;
