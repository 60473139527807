import React from 'react';

const Header = ({ title, backgroundColor }) => (
  <div
    className="card__plain__header resource-header mb-3"
    style={{ background: backgroundColor }}
  >
    <p className="card__plain__heading mb-0">{title}</p>
  </div>
);

export default Header;
