import { gql } from '@apollo/client';
import { PlaylistFieldsFragment } from '../playlist/fragments';
import { EsongbookFieldsFragments } from '../songbook/fragments';

export const CategoryPageFieldsFragment = gql`
  fragment CategoryPageFields on CategoryPage {
    id
    name
    videos
    playlists {
      ...PlaylistFields
    }
    esongbooks {
      ...EsongbookFields
    }
  }
  ${PlaylistFieldsFragment}
  ${EsongbookFieldsFragments}
`;
