import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { organisationLoggedInAs } from '../../../../../utils/reactiveVars';
import Organisation from './Organisation';

const Organisations = ({ organisations, userAvatar }) => {
  if (!organisations || organisations?.length < 1) return null;

  const selectedOrganisation = useReactiveVar(organisationLoggedInAs);
  const availableOrganisations = [...organisations].filter(
    (organisation) => organisation.id !== selectedOrganisation?.id
  );

  return (
    <>
      <hr />
      <div className="dropdown-item">
        <p className="text__fs-12">Log in as</p>
      </div>
      {availableOrganisations?.map(({ id, organisation }) => (
        <Organisation
          key={id}
          name={organisation.displayName || organisation.detailName}
          logo={organisation.avatar}
          organisation={organisation}
        />
      ))}
      {selectedOrganisation && (
        <Organisation
          name="Private license user"
          logo={userAvatar}
          privateUser
        />
      )}
    </>
  );
};

export default Organisations;
