import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_USER_NOTIFICATIONS } from '../../graphql/queries/notification/notification';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useGetUserNotifications = () => {
  const organisationRef = useReactiveVar(organisationLoggedInAs)?.id || null;

  const { data, loading, error } = useQuery(GET_USER_NOTIFICATIONS, {
    variables: { organisationRef },
    pollInterval: 180000
  });

  return { data, loading, error };
};

export default useGetUserNotifications;
