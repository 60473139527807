import { useQuery } from '@apollo/client';
import { GET_SHARE_LINK } from '../../graphql/queries/share/share';
import { displayErrorMessage } from '../../utils/helpers';

const useGetShareLink = (songId, organisationRef) => {
  const { data, loading } = useQuery(GET_SHARE_LINK, {
    variables: {
      song_id: songId,
      organisation_ref: organisationRef,
    },
    onError: () => displayErrorMessage(),
  });

  return { data, loading };
};

export default useGetShareLink;
