export type Progress = {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
};

export const ProgressDefaultState = {
  played: 0,
  playedSeconds: 0,
  loaded: 0,
  loadedSeconds: 0,
};

export const songSrcOne = [
  `${process.env.PUBLIC_URL}/audio/track_1/3891.mp3`,
  `${process.env.PUBLIC_URL}/audio/track_1/3894.mp3`,
  `${process.env.PUBLIC_URL}/audio/track_1/3899.mp3`,
  `${process.env.PUBLIC_URL}/audio/track_1/3900.mp3`,
];

export const songSrcTwo = [
  `${process.env.PUBLIC_URL}/audio/track_2/3892.mp3`,
  `${process.env.PUBLIC_URL}/audio/track_2/3895.mp3`,
];

export interface Files {
  mp3: string;
  ogg: string;
}

export const FilesDefaultState: Files = {
  mp3: '',
  ogg: '',
};

export interface FileSizes {
  mp3: number;
  ogg: number;
}

export const FileSizesDefaultState: FileSizes = {
  mp3: 0,
  ogg: 0,
};

export interface Audio {
  id: number;
  isDefault: boolean;
  isExtractable: boolean;
  isVisible: boolean;
  type: string;
  tempo: number;
  infoName: string;
  infoNameExtractable: string;
  infoArtist: string;
  infoCopyright: string;
  infoISRC: string;
  millisecondsLength: number;
  files: Files;
  file_md5s: Files;
  file_sizes: FileSizes;
}

export const AudioDefaultState: Audio = {
  id: 0,
  isDefault: false,
  isExtractable: false,
  isVisible: false,
  type: '',
  tempo: 0,
  infoName: '',
  infoNameExtractable: '',
  infoArtist: '',
  infoCopyright: '',
  infoISRC: '',
  millisecondsLength: 0,
  files: FilesDefaultState,
  file_md5s: FilesDefaultState,
  file_sizes: FileSizesDefaultState,
};

export interface Cloud {
  id: number;
  filename: string;
  filename_md5: string;
  filename_size: string;
}

export const CloudDefaultState: Cloud = {
  id: 0,
  filename: '',
  filename_md5: '',
  filename_size: '',
};

export type Phrase = {
  id: number;
  content: string;
  milliseconds: number;
  hide: number;
  isClickable: boolean;
  isHighlightable: boolean;
  italics?: boolean;
  subtitle?: boolean;
};

export const PhraseDefault: Phrase = {
  id: -1,
  content: '',
  milliseconds: 0,
  hide: 0,
  isClickable: false,
  isHighlightable: false,
  italics: false,
  subtitle: false,
};

export type Line = {
  id: number;
  splitLineNumber: number;
  phrases: Phrase[];
};

export const LineDefaultState: Line = {
  id: 0,
  splitLineNumber: 0,
  phrases: [PhraseDefault],
};

export type Group = {
  id: number;
  infoName: string;
  styleGroupIndex: number;
  styleGroupCol: string;
  styleColIndex: number;
  stylePadding: number;
  splitLineNumber: number;
  lines: Line[];
};

export const GroupDefaultState: Group = {
  id: 0,
  infoName: '',
  styleGroupIndex: 0,
  styleGroupCol: '',
  styleColIndex: 0,
  stylePadding: 0,
  splitLineNumber: 0,
  lines: [LineDefaultState],
};

export interface Page {
  id: number;
  infoHeader: string;
  infoHeaderBasicOverride: string;
  infoFooter1: string;
  infoFooter2: string;
  showPageHeader: boolean;
  showPageFooter: boolean;
  millisecondsStart: number;
  millisecondsStop: number;
  styleBodyLeading: number;
  styleBodySize: number;
  clouds: Cloud[];
  cloudPosX: number;
  cloudPosY: number;
  groups: Group[];
}

export const PageDefaultState: Page = {
  id: 0,
  infoHeader: '',
  infoHeaderBasicOverride: '',
  infoFooter1: '',
  infoFooter2: '',
  showPageHeader: false,
  showPageFooter: false,
  millisecondsStart: 0,
  millisecondsStop: 0,
  styleBodyLeading: 0,
  styleBodySize: 0,
  clouds: [CloudDefaultState],
  cloudPosX: -1,
  cloudPosY: -1,
  groups: [GroupDefaultState],
};

export const EsongbookDefaultState: Esongbook = {
  id: 0,
  infoName: '',
  infoAuthor: '',
  imageFilename: '',
  imageFileOriginal: '',
  imageFileLarge: '',
  imageFileMedium: '',
  albumArt: '',
};
export const EsbTrackDefaultState: EsbTrack = {
  id: 0,
  esongbook: EsongbookDefaultState,
};
export interface Esongbook {
  id: number;
  infoName: string;
  infoAuthor: string;
  imageFilename: string;
  imageFileOriginal: string;
  imageFileLarge: string;
  imageFileMedium: string;
  albumArt: string;
}

export interface EsbTrack {
  id: number;
  esongbook: Esongbook;
}

export interface Song {
  id: number;
  tempo: number;
  infoName: string;
  infoNameExtractable: string;
  infoAuthor: string;
  infoCopyright: string;
  infoCopyrightFormatted: string;
  infoCCLI: string;
  infoAge: string;
  infoCategory: string;
  keywords: string;
  overrideButtonInfo: string;
  overrideButtonLyrics: string;
  overrideButtonScore: string;
  showButtonExtract: boolean;
  showButtonInfo: boolean;
  showButtonLyrics: boolean;
  showButtonScore: boolean;
  styleBodyFont: string;
  styleBodyLeading: number;
  styleBodySize: number;
  styleTitleCol: string;
  styleTitleFont: string;
  styleTitleSize: number;
  styleCol1Highlighted: string;
  styleCol1Unhighlighted: string;
  styleCol2Highlighted: string;
  styleCol2Unhighlighted: string;
  styleCol3Highlighted: string;
  styleCol3Unhighlighted: string;
  styleCol4Highlighted: string;
  styleCol4Unhighlighted: string;
  styleCol5Highlighted: string;
  styleCol5Unhighlighted: string;
  styleMarginLeft: number;
  styleMarginRight: number;
  styleMarginTop: number;
  styleMarginBottom: number;
  fileBackground: string;
  fileBackgroundMD5: string;
  fileBackgroundSize: number;
  fileLarge: string;
  fileLargeMD5: string;
  fileLargeSize: number;
  fileInfo: string;
  fileInfoMD5: string;
  fileInfoSize: number;
  fileLyrics: string;
  fileLyricsMD5: string;
  fileLyricsSize: number;
  fileScore: string;
  fileScoreMD5: string;
  fileScoreSize: number;
  isFavourited: boolean;
  albumArt?: string | null;
  audios: Audio[];
  clouds: Cloud[];
  pages: Page[];
  esbTrack: EsbTrack | null;
}

export const SongDefaultState: Song = {
  id: 0,
  tempo: 0,
  infoName: '',
  infoNameExtractable: '',
  infoAuthor: '',
  infoCopyright: '',
  infoCopyrightFormatted: '',
  infoCCLI: '',
  infoAge: '',
  infoCategory: '',
  keywords: '',
  overrideButtonInfo: '',
  overrideButtonLyrics: '',
  overrideButtonScore: '',
  showButtonExtract: false,
  showButtonInfo: false,
  showButtonLyrics: false,
  showButtonScore: false,
  styleBodyFont: '',
  styleBodyLeading: 0,
  styleBodySize: 0,
  styleTitleCol: '',
  styleTitleFont: '',
  styleTitleSize: 0,
  styleCol1Highlighted: '',
  styleCol1Unhighlighted: '',
  styleCol2Highlighted: '',
  styleCol2Unhighlighted: '',
  styleCol3Highlighted: '',
  styleCol3Unhighlighted: '',
  styleCol4Highlighted: '',
  styleCol4Unhighlighted: '',
  styleCol5Highlighted: '',
  styleCol5Unhighlighted: '',
  styleMarginLeft: 0,
  styleMarginRight: 0,
  styleMarginTop: 0,
  styleMarginBottom: 0,
  fileBackground: '',
  fileBackgroundMD5: '',
  fileBackgroundSize: 0,
  fileLarge: '',
  fileLargeMD5: '',
  fileLargeSize: 0,
  fileInfo: '',
  fileInfoMD5: '',
  fileInfoSize: 0,
  fileLyrics: '',
  fileLyricsMD5: '',
  fileLyricsSize: 0,
  fileScore: '',
  fileScoreMD5: '',
  fileScoreSize: 0,
  isFavourited: false,
  audios: [AudioDefaultState],
  clouds: [CloudDefaultState],
  pages: [PageDefaultState],
  esbTrack: EsbTrackDefaultState,
};

export interface CustomSong extends Song {
  src: string[];
}

export const CustomSongDefaultState: CustomSong = {
  ...SongDefaultState,
  src: songSrcOne,
};

export interface Album {
  albumName: string;
  songCount: number;
  songs: CustomSong[];
}

export const AlbumDefaultState: Album = {
  albumName: '',
  songCount: 0,
  songs: [CustomSongDefaultState],
};

export interface RootObject {
  songs: Song[];
}

export const JumpToDefault = {
  phraseStarts: -1,
  phraseEnds: -1,
  setAt: new Date(),
  complete: true,
  playSinglePhrase: false,
  newPhraseID: 0,
};
export interface JumpTo {
  phraseStarts: number;
  phraseEnds: number;
  setAt: Date;
  complete: boolean;
  playSinglePhrase: boolean;
  newPhraseID: number;
}

export type PlayerHit = {
  sessionUUID: string;
  songId: number;
  hitUUID: string;
  audioId: number | undefined;
  updateExpected: boolean;
  updateSequence: number;
  playheadPositionStartMs: number;
  playheadPositionEndMs?: number | null;
  shareId?: number | null;
  esongbookId?: number | null;
  organisationRef?: number;
  id?: number | null;
};

export const PlayerHitDefault: PlayerHit = {
  sessionUUID: '',
  songId: 0,
  hitUUID: '',
  audioId: 0,
  updateExpected: true,
  updateSequence: 0,
  playheadPositionStartMs: 0,
  playheadPositionEndMs: 0,
  shareId: null,
  esongbookId: null,
  organisationRef: 0,
  id: null,
};
