import React from 'react';

const AllDoneButton = ({ handleSubmit }) => (
  <div className="d-flex justify-content-center my-5">
    <button
      className="btn button__green-meadow"
      data-target="#editAccountModal"
      data-toggle="modal"
      onClick={handleSubmit}
    >
      All done, thank you!
    </button>
  </div>
);

export default AllDoneButton;
