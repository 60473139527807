/* eslint-disable func-names */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  REMOVE_ASSEMBLY_FROM_FAVOURITES,
  REMOVE_RESOURCE_FROM_FAVOURITES,
  REMOVE_SONG_FROM_FAVOURITES,
} from '../../graphql/mutations/favourite';
import { GET_FAVOURITE_ASSEMBLIES } from '../../graphql/queries/assembly/assembly';
import { GET_FAVOURITE_RESOURCES } from '../../graphql/queries/resource/resource';
import { GET_FAVOURITE_SONGS } from '../../graphql/queries/song/song';
import {
  userFavouriteAssemblies,
  userFavouriteResources,
  userFavouriteSongs,
} from '../../utils/reactiveVars';

const useRemoveFromFavourites = (type, id) => {
  const [removeSongFromFavourites, { loading, data }] = useMutation(
    REMOVE_SONG_FROM_FAVOURITES,
    {
      refetchQueries: [
        {
          query: GET_FAVOURITE_SONGS,
        },
      ],
    }
  );

  const [removeResourceFromFavourites, { data: resources }] = useMutation(
    REMOVE_RESOURCE_FROM_FAVOURITES,
    {
      refetchQueries: [
        {
          query: GET_FAVOURITE_RESOURCES,
        },
      ],
    }
  );

  const [removeAssemblyFromFavourites, { data: assemmblies }] = useMutation(
    REMOVE_ASSEMBLY_FROM_FAVOURITES,
    {
      refetchQueries: [
        {
          query: GET_FAVOURITE_ASSEMBLIES,
        },
      ],
    }
  );

  const removeFromFavouritesRequest = {
    send: function () {
      type === 'SONG' && this.removeSong();
      type === 'RESOURCE' && this.removeResource();
      type === 'ASSEMBLY' && this.removeAssembly();
    },
    removeSong: () => {
      removeSongFromFavourites({
        variables: {
          input: {
            type,
            item_id: parseInt(id, 10),
          },
        },
      })
        .then((res) => {
          userFavouriteSongs(res.data.removeSongFromFavourite);
        })
        .catch((e) => toast.error('Operation failed!', { toastId: 'error' }));
    },
    removeResource: () => {
      removeResourceFromFavourites({
        variables: {
          input: {
            type,
            item_id: parseInt(id, 10),
          },
        },
      })
        .then((res) => {
          userFavouriteResources(res.data.removeResourceFromFavourite);
        })
        .catch((e) => toast.error('Operation failed!', { toastId: 'error' }));
    },
    removeAssembly: () => {
      removeAssemblyFromFavourites({
        variables: {
          input: {
            type,
            item_id: parseInt(id, 10),
          },
        },
      })
        .then((res) => {
          userFavouriteAssemblies(res.data.removeAssembliesFromFavourites);
        })
        .catch((e) => toast.error('Operation failed!', { toastId: 'error' }));
    },
  };

  return { removeFromFavouritesRequest, data, loading };
};

export default useRemoveFromFavourites;
