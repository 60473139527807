import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { albumArtDefault } from '../../utils/helpers';
import FeaturedAlbumArt from '../Shared/FeaturedAlbumArt';

const CardCollection = ({
  title,
  id,
  songs,
  history,
  isShowSorting,
  image,
}) => (
  <div className="card__plain-lg">
    <Tappable
      component="a"
      onTap={() => {
        history.push(`/collection/${id}`);
      }}
    >
      <div className="bg-white d-flex">
        <div className="collection__card-album-art">
          {image ? (
            <img className="d-inline w-100 h-100" src={image} alt="" />
          ) : (
            <>
              {songs?.length === 0 ? (
                <div className="w-100 h-100">
                  <img
                    className="d-inline w-100 h-100"
                    src={albumArtDefault}
                    alt=""
                  />
                </div>
              ) : (
                <FeaturedAlbumArt songs={songs} />
              )}
            </>
          )}
        </div>
        <div className="text__fs-18 text-black w-50 align-self-center">
          {title}
        </div>
        {isShowSorting && (
          <div className="ml-auto d-flex">
            <img
              src="/img/order.svg"
              alt="order"
              className="pr-4 cursor-pointer"
              width="48"
            />
          </div>
        )}
      </div>
    </Tappable>
  </div>
);

export default withRouter(CardCollection);
