import { useLazyQuery } from '@apollo/client';
import {
  GET_PURCHASED_MUSICALS,
  GET_PURCHASED_NATIVITIES,
  GET_PURCHASED_SONGBOOKS,
} from '../../graphql/queries/nativitiesAndMusicals/nativitiesAndMusicals';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
} from '../../utils/helpers';

const useGetPurchasedSongbooks = (type) => {
  const queryMap = {
    Purchased: GET_PURCHASED_SONGBOOKS,
    'Purchased Nativities': GET_PURCHASED_NATIVITIES,
    'Purchased Musicals': GET_PURCHASED_MUSICALS,
  };

  const [getPurchasedItems, { data, loading, error }] = useLazyQuery(
    type !== undefined ? queryMap[type] : queryMap.Purchased,
    {
      onCompleted: () => dismissLoadingMessage(),
      onError: () => {
        dismissLoadingMessage();
        displayErrorMessage();
      },
    }
  );

  const getPurchasedItemsRequest = {
    send: () => {
      getPurchasedItems();
    },
  };

  loading && displayLoadingMessage();

  return { getPurchasedItemsRequest, data, loading, error };
};

export default useGetPurchasedSongbooks;
