/* eslint-disable operator-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
import { useReactiveVar } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FilterSection from '../../components/Shared/Resources/FilterSection';
import ResourcesList from '../../components/RecommendedResources/Sections/MainSection/ResourcesList';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useGetResources from '../../hooks/Resource/useGetResources';
import { returnHasTags } from '../../utils/helpers';
import { filterTypeMap } from '../../utils/objects';
import { resourceList } from '../../utils/reactiveVars';

const SeeAll = () => {
  const mapTitle = {
    Assembly: 'Assembly',
    Curriculum: 'Sing In Every Subject',
    'Collective Worship': 'Collective Worship',
    'Concerts/Performance': 'Choirs and Concerts',
    'Just for Fun': 'Sing for Fun',
    'Related Guides and Resources': 'Related Guides and Resources',
    'Routines & Signals': 'School Routines and Signals',
    Wellbeing: 'Wellbeing',
    'New Sparks': 'New Sparks',
  };

  const getTitle = (input) => mapTitle[input];

  const [resources, setResources] = useState([]);
  const {
    path,
    title,
    type = '',
    query = null,
    resources: existingResources,
    actions,
    showTags,
  } = useReactiveVar(resourceList);
  let resourceQuery = returnHasTags('hasTags', type, title);

  if (query) {
    resourceQuery = query;
  }

  const { data = {}, loading } = useGetResources(2000, resourceQuery);

  useEffect(() => {
    if (existingResources) {
      return setResources(existingResources);
    }
    if ('resources' in data) {
      setResources(data.resources.data);
    }
  }, [data, existingResources]);

  const handleFilter = (resourceType) => {
    const originalResourceList =
      existingResources || data?.resources?.data || [];
    const resourceResultListCopy = [...originalResourceList];

    const filteredResources = resourceResultListCopy.filter(
      ({ category: { name } }) => {
        if (filterTypeMap[resourceType]) {
          return filterTypeMap[resourceType](name);
        }
        return name === resourceType;
      }
    );

    setResources(filteredResources);

    // const resourceParams = buildResourceParams(params);

    // fetchMoreResources({
    //   variables: { ...resourceParams },
    //   ...updateQuery,
    // });
  };

  return (
    <>
      <section className="main__container">
        <Link to={path} className="cp__header__breadcrumb">
          {`${getTitle(title) || ''} > Resources`}
        </Link>
      </section>
      <FilterSection
        title={title}
        handleFilter={handleFilter}
        loading={loading}
      />
      <section className="container__small mx-auto ml-lg-40 mt-5">
        <ResourcesList
          resources={resources}
          loading={loading}
          actions={actions}
          showTags={showTags}
        />
      </section>
    </>
  );
};

export default withPageWrapper({})(SeeAll);
