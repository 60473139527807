/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Copyright from '../../components/Shared/Copyright';
import MainSection from '../../components/Resources/Sections/MainSection/Index';
// import RelatedGuidesAndResources from '../components/Shared/RelatedGuidesAndResources/Index';
import RelatedSongs from '../../components/Shared/SongCards/Index';
import ResourcesHeader from '../../components/Resources/Sections/ResourcesHeader/Index';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useGetResource from '../../hooks/Resource/useGetResource';
import { currentResource } from '../../utils/reactiveVars';
import LoadJquery from '../../components/Shared/LoadJquery';
import { displayLoadingMessage } from '../../utils/helpers';

const Resource = () => {
  const { slug } = useParams();
  const { data, loading } = useGetResource(slug);

  data?.resource && currentResource(data.resource);
  loading && displayLoadingMessage('Loading resource...');

  useEffect(() => {
    document.title = `${data?.resource?.title || ''} | Collection | Sparkyard`;
  }, [data]);

  if (data?.resource === null) return <Redirect to="/404" />;

  const isBlogOrGuide = () => {
    const category = data?.resource?.category?.name;
    return category === 'Blog' || category === 'Guide';
  };

  return (
    <>
      {data?.resource && (
        <>
          <ResourcesHeader />
          <LoadJquery />
          <section className="main__container">
            <div className="mt-5 p-3 mt-md-0 p-md-0" />

            {!isBlogOrGuide() && (
              <RelatedSongs
                title="Song choice"
                songs={data?.resource?.songs}
                pageTitle={data?.resource?.title}
              />
            )}

            <MainSection />

            {isBlogOrGuide() && (
              <RelatedSongs
                title="Song choice"
                songs={data?.resource?.songs}
                pageTitle={data?.resource?.title}
              />
            )}
            {/* <RelatedGuidesAndResources /> */}
            <Copyright />
          </section>
        </>
      )}
    </>
  );
};

export default withPageWrapper({})(Resource);
