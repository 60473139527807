/* eslint-disable array-callback-return */
import React from 'react';
import LinkedAssemblies from './Sections/LinkedAssemblies/Index';
import SongSummaryTeachingTips from './Sections/SongSummaryTeachingTips/Index';
import VideoModule from './Modules/VideoModule';
import { currentSong } from '../../../../utils/reactiveVars';
import CategoryGroup from './Sections/CategoryGroup/Index';
import useRenderSparkles from '../../../../hooks/useRenderSparkles';

const SongsPageContent = ({ categories }) => {
  const { sparkles, assemblyPlans } = currentSong();
  const categoryArr = [...categories];
  const sortedCategories = categoryArr.sort((a, b) => a.position - b.position);
  const hiddenCategories = ['Music Curriculum Activity', 'Blog', 'Guide'];

  return (
    <div className="songpage__container">
      <VideoModule />
      <SongSummaryTeachingTips />
      <LinkedAssemblies assemblyPlans={assemblyPlans} />

      {sortedCategories.map((category) => {
        if (!hiddenCategories.includes(category.name)) {
          return (
            <CategoryGroup
              title={category.name}
              key={category.id}
              backgroundColor={category.color}
            />
          );
        }
      })}
      {sparkles?.map((sparkle) => (
        <React.Fragment key={sparkle.id}>
          {useRenderSparkles(sparkle)}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SongsPageContent;
