/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { groupBy } from '../../../../utils/helpers';
import { assemblyThemesObj } from '../../../../utils/objects';
import CardGroup from './CardGroup';

const MainContent = ({ data }) => {
  const groupedData = groupBy(data.featuredAssemblies, ({ theme }) => theme);

  return (
    <section className="main__container mt-40">
      <div className="assembly__grid">
        {groupedData.map((assembly) => (
          <CardGroup
            key={assembly[0].id}
            title={assemblyThemesObj[assembly[0].theme]}
            content={assembly}
            type={assembly[0].theme.toLowerCase()}
          />
        ))}
      </div>
    </section>
  );
};

export default MainContent;
