/* eslint-disable implicit-arrow-linebreak */
import { useObserver } from 'mobx-react-lite';
import React from 'react';
import useStores from '../../hooks/useStores';
import { MediaButton } from '../Icon';
import { ButtonShadow, PagesTab } from '../SlideSwitcher';

type Props = {
  currentSlide: number;
  totalSlides: number;
};
const SlideActions = ({ currentSlide, totalSlides }: Props): JSX.Element => {
  const { mediaPlayerStore } = useStores();

  return useObserver(() => (
    <div className="d-flex justify-content-between flex-2">
      <div className="d-inline-flex align-items-start text__fs-10">
        <ButtonShadow leftButton isMobile>
          <MediaButton
            icon="ChangeSlide"
            rotate={-135}
            disabled={currentSlide === 0}
            onClick={() => {
              mediaPlayerStore.switchSlide(-1, currentSlide);
            }}
          >
            {'<'}
          </MediaButton>
        </ButtonShadow>
        <ButtonShadow>
          <PagesTab>
            {currentSlide + 1}/{totalSlides}
          </PagesTab>
        </ButtonShadow>
        <ButtonShadow rightButton navigation isMobile>
          <MediaButton
            icon="ChangeSlide"
            rotate={45}
            disabled={currentSlide === totalSlides - 1}
            onClick={() => {
              mediaPlayerStore.switchSlide(1, currentSlide);
            }}
          >
            {'>'}
          </MediaButton>
        </ButtonShadow>
      </div>

      <div>
        <ButtonShadow
          active={!mediaPlayerStore.plainTextView}
          leftButton
          isMobile
        >
          <MediaButton
            type="button"
            icon="Fancytext"
            onClick={mediaPlayerStore.enableFancyTextView}
          />
        </ButtonShadow>
        <ButtonShadow
          active={mediaPlayerStore.plainTextView}
          rightButton
          isMobile
        >
          <MediaButton
            type="button"
            icon="Plaintext"
            onClick={mediaPlayerStore.enablePlainTextView}
          />
        </ButtonShadow>
      </div>
    </div>
  ));
};

export default SlideActions;
