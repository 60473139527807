import React from 'react';

export const SonglistBanner = () => (
  <div className="main__container px-md-5 song-calendar__header__container songslist__header__banner">
    <span className="songslist__header__description">
      The complete Sparkyard song library in one place. Use the filters to find
      the perfect song for your needs.
    </span>
    <img
      src="/img/songs-banner.png"
      className="img-fluid songslist__header__banner__img"
      alt="banner"
    />
  </div>
);

const SonglistHeader = () => (
  <>
    <div className="songslist__header main__container mb-0 mb-xl-4">
      <img
        src="img/audio-icon-grey.svg"
        className="songslist__header__icon"
        alt=""
      />
      <h2 className="songslist__header__title d-md-block d-none">
        Songs and Playlists &nbsp;{' '}
        <span className="text__white">All Songs</span>
      </h2>
      <h2 className="songslist__header__title d-md-none">
        Music &nbsp; <span className="text__white">All Songs</span>
      </h2>
    </div>
    <SonglistBanner />
  </>
);

export default SonglistHeader;
