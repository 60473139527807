/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import useAcceptOrDeclineInvite from '../../../../hooks/Organisation/useAcceptOrDeclineInvite';

const Invite = ({ organisationName, inviteRef, organisationRef }) => {
  const { acceptOrDeclineInviteRequest, loading } = useAcceptOrDeclineInvite();

  return (
    <>
      <h6 className="text__fs-18 text__fw-200 text__white mt-20  mb-5 text__center">
        You have been invited to join {organisationName}
      </h6>

      <button
        className="button button__md button__green-meadow mx-auto mt-6"
        onClick={() =>
          acceptOrDeclineInviteRequest({
            invite_ref: parseInt(inviteRef, 10),
            action: 'ACCEPT',
            organisation_ref: +organisationRef,
          })
        }
        disabled={loading}
      >
        Accept
      </button>
      <button
        className="button button__md button__cerise-dark mx-auto mt-6"
        onClick={() =>
          acceptOrDeclineInviteRequest({
            invite_ref: parseInt(inviteRef, 10),
            action: 'DECLINE',
          })
        }
        disabled={loading}
      >
        Decline
      </button>
    </>
  );
};

export default Invite;
