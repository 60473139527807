import { gql } from '@apollo/client';

export const GENERATE_URL_HASH = gql`
  mutation GenerateUrlHash($url: String!) {
    generateUrlHash(url: $url)
  }
`;

export const CREATE_FILE_STAT = gql`
  mutation CreateFileStat($input: CreateFileStatInput!) {
    createFileStat(input: $input) {
      id
    }
  }
`;
