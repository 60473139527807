/* eslint-disable operator-linebreak */
import { styled } from 'twin.macro';
import 'styled-components/macro';
import { MediaButton } from '../Icon';

export const GridMediaButton = styled(MediaButton)<{
  gridPosition: number;
  hideOnMobile?: boolean;
  gridPositionMobile?: number;
}>(({ gridPosition, hideOnMobile, gridPositionMobile }) => [
  gridPosition && `-ms-grid-column:  ${gridPosition}`,
  gridPosition && `grid-column:  ${gridPosition}`,
  hideOnMobile &&
    ` 
    @media (max-width: 767px),screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
      display: none;
    }`,
  gridPositionMobile &&
    `
    @media (max-width: 767px),screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
      grid-column: ${gridPositionMobile};
    }`,
]);
