import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { availableRoles } from '../../../../../utils/objects';
import { user } from '../../../../../utils/reactiveVars';
import SectionHeader from '../SectionHeader';
import Checkbox from '../Checkbox';

const WhatIsYourRole = ({ handleShowSection, handleDataChange }) => {
  const { roles } = useReactiveVar(user);
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    setSelectedRoles(roles);
  }, [roles]);

  const isChecked = (role) => !!selectedRoles.find((item) => item === role);
  const handleChange = (role, checked) => {
    const newRoles = checked
      ? [...selectedRoles, role]
      : selectedRoles.filter((existingRole) => existingRole !== role);

    setSelectedRoles(newRoles);
    handleDataChange({ roles: newRoles });
  };

  return (
    <>
      <SectionHeader text="What is your role?" />
      <div className="row text__fs-13" style={{ wordBreak: 'break-word' }}>
        {availableRoles?.map((role) => (
          <Checkbox
            key={role}
            role={role}
            checked={isChecked(role)}
            handleChange={handleChange}
            onCheck={() => handleShowSection('whichAgeGroupDoYouTeach')}
          />
        ))}
      </div>
    </>
  );
};

export default WhatIsYourRole;
