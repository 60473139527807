import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_RESOURCE } from '../../graphql/queries/resource/resource';

const useGetResource = (slug) => {
  const { data, loading, error } = useQuery(GET_RESOURCE, {
    variables: {
      slug,
    },
    onCompleted: () => toast.dismiss('loading'),
    onError: () => {
      toast.dismiss('loading');
      toast.error('Could not load resource');
    },
  });

  return { data, loading, error };
};

export default useGetResource;
