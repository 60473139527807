/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import useGetMemberships from '../../../../../hooks/Organisation/useGetMemberships';
import useHandleSubscriptionAndTrials from '../../../../../hooks/Organisation/useHandleSubscriptionAndTrials';
import {
  defaultAvatarMid,
  defaultOrganisationLogo,
} from '../../../../../utils/objects';
import {
  currentOrganisationHasActiveSub,
  loggedInAsPrivateUser,
  organisationLoggedInAs,
  user,
  userHasActiveSubOrTrial,
} from '../../../../../utils/reactiveVars';
import {
  getOrganisationFromLocalStorage,
  persistOrganisationLoggedInAs,
} from '../../../../../utils/user';
import Organisation from './Organisation';
import Organisations from './Organisations';

const OrganisationsDropdown = () => {
  const { avatar, activeTrial, sysubs } = useReactiveVar(user);
  const hasActiveSubOrTrial = sysubs?.[0]?.isActive || activeTrial;

  const userAvatar = avatar || defaultAvatarMid;

  const { data, loading } = useGetMemberships();
  const { handleOrganisationSub, handleUserSubOrTrial } =
    useHandleSubscriptionAndTrials();

  const organisationCount = data?.memberships?.length || 0;
  const currentlyLoggedInAsOrganisation = useReactiveVar(
    organisationLoggedInAs
  );
  const isPrivateUser = useReactiveVar(loggedInAsPrivateUser);

  const getFirstOrganisationWithActiveSubscription = (memberships) => {
    const organisationWithActiveSub = memberships?.find(
      (membership) => membership.organisation.sysubs?.[0]?.isActive
    );

    return organisationWithActiveSub?.organisation;
  };

  const validateOrganisationId = (id, memberships) => {
    const validOrganisation = memberships?.find(
      (membership) => membership.organisation.id === id
    );
    return validOrganisation;
  };

  // attempt to log user in based on what they last logged in as
  // default to first organisation with an active subscription
  // if no organisation has an active sub check if user does and default to user pov
  // if user does not have an active sub default to first organisation in list
  // if no organisation exists default to user pov
  useEffect(() => {
    if (
      organisationCount > 0 &&
      !isPrivateUser &&
      !currentlyLoggedInAsOrganisation
    ) {
      if (localStorage.getItem('loggedInAsPrivateUser') === 'true') {
        loggedInAsPrivateUser(true);
        handleUserSubOrTrial();
        return;
      }

      const savedOrganisation = getOrganisationFromLocalStorage();
      if (savedOrganisation) {
        const validOrganisation = validateOrganisationId(
          savedOrganisation,
          data?.memberships
        );

        if (validOrganisation) {
          organisationLoggedInAs(validOrganisation.organisation);
          handleOrganisationSub(validOrganisation.organisation);
          persistOrganisationLoggedInAs(validOrganisation.id);
          return;
        }
      }

      const organisationWithSub = getFirstOrganisationWithActiveSubscription(
        data?.memberships
      );

      if (organisationWithSub) {
        organisationLoggedInAs(organisationWithSub);
        handleOrganisationSub(organisationWithSub);
      } else if (hasActiveSubOrTrial) {
        loggedInAsPrivateUser(true);
        handleUserSubOrTrial();
      } else {
        const firstOrganisation = data?.memberships?.[0]?.organisation;
        organisationLoggedInAs(firstOrganisation);
        handleOrganisationSub(firstOrganisation);
      }
    }

    if (data && organisationCount < 1) {
      loggedInAsPrivateUser(true);
      handleUserSubOrTrial();
      localStorage.setItem('loggedInAsPrivateUser', 'true');
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (organisationLoggedInAs() && !currentOrganisationHasActiveSub()) {
        handleOrganisationSub(organisationLoggedInAs);
      }
      if (loggedInAsPrivateUser() && !userHasActiveSubOrTrial()) {
        handleUserSubOrTrial();
      }
    }
  }, [data]);

  if (loading) return null;

  const photoToDisplay = isPrivateUser
    ? userAvatar
    : currentlyLoggedInAsOrganisation?.avatar || defaultOrganisationLogo;

  const addBorderToAvatar = isPrivateUser ? 'user-avatar-organisations' : '';

  const hideSwitch = organisationCount < 1 ? 'd-none' : '';

  const organisationName =
    currentlyLoggedInAsOrganisation?.displayName ||
    currentlyLoggedInAsOrganisation?.detailName;

  return (
    <div className={`main__nav__info__avatar dropdown ${hideSwitch}`}>
      <a
        href="#"
        className="dropdown-toggle"
        role="button"
        id="dropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img src={photoToDisplay} alt="" className={addBorderToAvatar} />
        <span className="d-inline text__fs-11 d-xl-none text-white">
          {organisationName || 'Private license user'}
        </span>
      </a>
      <div
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuLink"
      >
        <div className="dropdown-item d-none d-xl-flex">
          <p className="text__fs-12">Logged in as</p>
        </div>
        <div className="d-none d-xl-flex">
          {organisationCount > 0 && currentlyLoggedInAsOrganisation ? (
            <Organisation
              name={organisationName}
              logo={currentlyLoggedInAsOrganisation?.avatar}
              disableClick
            />
          ) : (
            <Organisation
              name="Private license user"
              logo={userAvatar}
              disableClick
            />
          )}
        </div>

        <Organisations
          organisations={data?.memberships}
          userAvatar={userAvatar}
        />
      </div>
    </div>
  );
};

export default OrganisationsDropdown;
