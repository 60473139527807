import React from 'react';
import Tag from './Tag';

const SelectedTags = ({ selectedTags, handleChange, questionRef }) => (
  <div className="form-control role__tags-area">
    {selectedTags?.length < 1 && (
      <div className="pt-4 text__grey">Select one or more options below</div>
    )}
    {selectedTags.map((answer) => (
      <Tag
        key={answer}
        answer={answer}
        handleChange={handleChange}
        questionRef={questionRef}
      />
    ))}
  </div>
);

export default SelectedTags;
