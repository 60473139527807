/* eslint-disable operator-linebreak */
export const getUserFromLocalStorage = () => {
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user);
  return null;
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem('user');
};

export const persistOrganisationLoggedInAs = (id) => {
  localStorage.setItem('organisationLoggedInAs', id);
  localStorage.setItem('loggedInAsPrivateUser', 'false');
};

export const getOrganisationFromLocalStorage = () => {
  const organisationId = localStorage.getItem('organisationLoggedInAs');
  if (
    localStorage.getItem('loggedInAsPrivateUser') === 'true' ||
    !organisationId
  ) {
    return false;
  }

  return organisationId;
};

export const clearLoginPreferences = () => {
  localStorage.removeItem('organisationLoggedInAs');
  localStorage.removeItem('loggedInAsPrivateUser');
};
