/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import FilterSection from '../../components/CategoryPages/SingInEverySubject/Sections/FilterSection';
import Header from '../../components/CategoryPages/SingInEverySubject/Sections/Header';
import MainSection from '../../components/CategoryPages/SingInEverySubject/Sections/MainSection/Index';
import useGetResources from '../../hooks/Resource/useGetResources';
import useGetSongs from '../../hooks/Song/useGetSongs';
import { displayLoadingMessage, returnHasTags } from '../../utils/helpers';
import { resourceQuery, updateQuery } from '../../utils/objects';
import { buildResourceParams } from '../../utils/resource';
import useGetCategoryPage from '../../hooks/useGetCategoryPage';
import useGetSongbooks from '../../hooks/Songbook/useGetSongbooks';
import useGetPlaylists from '../../hooks/Playlist/useGetPlaylists';

const SingInEverySubject = () => {
  const cookies = new Cookies();
  const filterCookie = cookies.get('filterSingInEverySubject');
  const [tagsChanged, setTagsChanged] = useState(false);
  const [displaySongs, setDisplaySongs] = useState(true);
  const [displayResources, setDisplayResources] = useState(true);
  const [displayPlaylists, setDisplayPlaylists] = useState(true);
  const [displaySongbooks, setDisplaySongbooks] = useState(true);
  const [resourceFilterParams, setResourceFilterParams] = useState({});

  const { data, loading: loadingCategoryPage } = useGetCategoryPage(
    'sing in every subject'
  );

  loadingCategoryPage && displayLoadingMessage();

  const { data: playlists, fetchMore: fetchMorePlaylists } = useGetPlaylists(
    'sy',
    {
      hasCategoryPages: { column: 'NAME', value: 'sing in every subject' },
    }
  );

  const {
    data: songs,
    fetchMore: fetchMoreSongs,
    loading: loadingSongs,
  } = useGetSongs(6, { available: true }, true);

  const {
    data: mathsSongs,
    fetchMore: fetchMoreMathsSongs,
    loading: loadingMathsSongs,
  } = useGetSongs(
    6,
    returnHasTags('hasSongTags', 'Curriculum Subject Links', 'Maths', true),
    true
  );

  const {
    data: scienceSongs,
    fetchMore: fetchMoreScienceSongs,
    loading: loadingScienceSongs,
  } = useGetSongs(
    6,
    returnHasTags('hasSongTags', 'Curriculum Subject Links', 'Science', true),
    true
  );

  const {
    data: englishSongs,
    fetchMore: fetchMoreEnglishSongs,
    loading: loadingEnglishSongs,
  } = useGetSongs(
    6,
    {
      hasSongTags: {
        AND: [
          { column: 'TYPE', value: 'Curriculum Subject Links' },
          { column: 'NAME', value: 'English' },
        ],
        OR: [
          { column: 'TYPE', value: 'Curriculum Subject Links' },
          { column: 'NAME', value: 'Literacy' },
        ],
      },
      available: true,
      randomise: true,
    },
    true
  );

  const { data: classAssembly, fetchMore: fetchMoreClassAssemblies } =
    useGetSongbooks({
      limit: 20,
      classAssembly: true,
    });

  const {
    data: resources,
    fetchMore: fetchMoreResources,
    loading: loadingResources,
  } = useGetResources(2, resourceQuery);

  const filterElements = (params, tagsHaveChanged = false) => {
    const containsSubjectFilter = params?.filtered?.find(
      (param) => param.type === 'Curriculum Subject Links'
    );

    if (containsSubjectFilter) {
      fetchMorePlaylists({
        variables: {
          hasPlaylistTags: {
            AND: [
              { column: 'TYPE', value: 'Curriculum Subject Links' },
              { column: 'NAME', value: containsSubjectFilter.formatted_name },
            ],
          },
        },
        ...updateQuery,
      });
    }
    if (Object.keys(params).length === 0) {
      fetchMorePlaylists({
        variables: {},
        ...updateQuery,
      });
    }

    fetchMoreClassAssemblies({
      variables: {
        filtered: params.filtered,
      },
      ...updateQuery,
    });

    setTagsChanged(tagsHaveChanged);

    if (tagsHaveChanged) {
      fetchMoreSongs({
        variables: { first: 2000, ...params },
        ...updateQuery,
      });
    }

    const resourceParams = buildResourceParams(params);
    setResourceFilterParams(resourceParams);

    const fetchMoreKeys = {
      variables: {
        ...params,
      },
      ...updateQuery,
    };
    fetchMoreMathsSongs(fetchMoreKeys);
    fetchMoreScienceSongs(fetchMoreKeys);
    fetchMoreEnglishSongs(fetchMoreKeys);
    fetchMoreResources({
      variables: {
        ...resourceParams,
      },
      ...updateQuery,
    });
    fetchMorePlaylists(fetchMoreKeys);
    setDisplaySongs(true);
    setDisplayResources(true);
    setDisplayPlaylists(true);
    setDisplaySongbooks(true);
  };

  const loading =
    loadingScienceSongs ||
    loadingEnglishSongs ||
    loadingMathsSongs ||
    loadingResources ||
    loadingSongs ||
    loadingCategoryPage;

  useEffect(() => {
    if (filterCookie) {
      filterElements(filterCookie, true);
    }
  }, []);

  return (
    <>
      <Header />
      <FilterSection handleFilter={filterElements} loading={loading} />
      <MainSection
        scienceSongs={scienceSongs?.songs?.data}
        mathsSongs={mathsSongs?.songs?.data}
        englishSongs={englishSongs?.songs?.data}
        resources={resources?.resources?.data}
        tagsChanged={!!tagsChanged}
        filterResults={songs?.songs?.data}
        displayResources={displayResources}
        displaySongs={displaySongs}
        displayPlaylists={displayPlaylists}
        displaySongbooks={displaySongbooks}
        categoryPage={data?.categoryPage}
        playlists={playlists?.playlists}
        resourceFilterParams={resourceFilterParams}
        classAssembly={classAssembly?.esongbooks?.data}
      />
    </>
  );
};

export default withPageWrapper({})(SingInEverySubject);
