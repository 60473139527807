/* eslint-disable operator-linebreak */
/* eslint-disable react/no-danger */
import { format, getDate } from 'date-fns';
import React from 'react';
import DownloadsAndAboutDropdown from '../../../Resources/DownloadsAndAboutDropdown';

const Header = ({ resource, title, description, startDate, endDate, id }) => {
  const downloadablesAttached = JSON.parse(resource.downloadables)?.length > 0;
  const startDay = getDate(new Date(startDate.split(' ')[0]));
  const startMonth = format(new Date(startDate.split(' ')[0]), 'MMMM');
  const endDay = endDate ? getDate(new Date(endDate.split(' ')[0])) : null;
  const endMonth = endDate
    ? format(new Date(endDate.split(' ')[0]), 'MMMM')
    : null;

  const stat = {
    calendar_event_id: id,
    event_type: 'OpenedCalendarEventPDF',
  };

  return (
    <div className="main__container bg__arsenic mt-0">
      <div className="songpage__header eventpage__header">
        <div className="resource__header__grid">
          <div className="resource__header__info">
            <div className="d-flex align-items-center">
              <img
                src="/img/calendar_white.svg"
                alt="calendar icon"
                className="song-calendar__header__icon d-inline-block mr-3"
                width="40px"
              />
              <h2 className="resource__header__title eventpage__header__title d-inline-block mb-0">
                {title}
              </h2>
            </div>

            <p className="resource__header__subtitle mb-3">
              {startDay} {(!endDate || startMonth !== endMonth) && startMonth}
              {endDate && ` - ${endDay} ${endMonth}`}
            </p>
            <p
              className="eventpage__header__desc"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          {downloadablesAttached && (
            <DownloadsAndAboutDropdown
              resource={resource}
              buttonText="Downloads"
              stat={stat}
              eventPage
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
