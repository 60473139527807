import React from 'react';
import { currentResource } from '../../../../utils/reactiveVars';
import LikeAndAddIcons from '../../../Shared/LikeAddIcons';
import DownloadsAndAboutDropdown from '../../DownloadsAndAboutDropdown';
import ResourcesHeaderInfo from './Sections/ResourcesHeaderInfo';

const ResourcesHeader = () => {
  const { id } = currentResource();

  const stat = {
    sy_resource_id: id,
    event_type: 'OpenedSyResourcePDF',
  };

  return (
    <div className="main__container bg__cello mt-0">
      <div className="resource__header">
        <div className="resource__header__grid">
          <ResourcesHeaderInfo />
          <LikeAndAddIcons
            headerText="Resource options"
            modalId="resourceHeaderActions"
            addToCollection
            favourite
            id={id}
            itemType="RESOURCE"
          />
          <DownloadsAndAboutDropdown stat={stat} />
        </div>
      </div>
    </div>
  );
};

export default ResourcesHeader;
