import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_HOMEPAGE } from '../graphql/queries/homepage/homepage';
import { dismissLoadingMessage, displayErrorMessage } from '../utils/helpers';

const useGetHomePage = (name) => {
  const { data, loading, error } = useQuery(GET_HOMEPAGE, {
    variables: {
      name,
    },
    onCompleted: () => dismissLoadingMessage(),
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage('Could not load home page');
    },
  });

  const features = [
    {
      image: data?.getHomepage.songImage || '/img/song-of-the-week.png',
      title: data?.getHomepage.songTitle,
      description: data?.getHomepage.songDescription,
      link: `/song/${data?.getHomepage.songId}`,
      type: 'SONG',
      id: data?.getHomepage.songId,
    },
    {
      image: data?.getHomepage.songbookImage || '/img/featured-song-book.png',
      title: data?.getHomepage.songbookTitle,
      description: data?.getHomepage.songbookDescription,
      link: `/songbook/${data?.getHomepage.songbookId}`,
      type: 'SONGBOOK',
    },
    {
      image:
        data?.getHomepage.collectionImage || '/img/featured-collection.png',
      title: data?.getHomepage.collectionTitle,
      description: data?.getHomepage.collectionDescription,
      link: `/collection/${data?.getHomepage.collectionId}`,
      type: 'COLLECTION',
    },
  ];

  if (data?.getHomepage.featuredSyResourceTypeId) {
    // resource data should be here
    features.push({
      image: data?.getHomepage.blogThumbnail || '/img/why-choose-us.png',
      title: data?.getHomepage.blogTitle,
      description: data?.getHomepage.blogDescription,
      link: data?.getHomepage.blogUrl,
      linkTarget: data?.getHomepage.openBlogLinkInNewTab ? '_blank' : '',
      type: '',
    });
  } else {
    features.push({
      image: data?.getHomepage.blogThumbnail || '/img/why-choose-us.png',
      title: data?.getHomepage.blogTitle,
      description: data?.getHomepage.blogDescription,
      link: data?.getHomepage.blogUrl,
      linkTarget: data?.getHomepage.openBlogLinkInNewTab ? '_blank' : '',
      type: '',
    });
  }

  const [content, events] = useMemo(
    () => [
      data?.getHomepage.mainContent
        ? JSON.parse(data?.getHomepage.mainContent)
        : [],
      data?.getHomepage.eventsContent
        ? JSON.parse(data?.getHomepage.eventsContent)
        : [],
    ],
    [data]
  );

  return { data, loading, error, features, content, events };
};

export default useGetHomePage;
