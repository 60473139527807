import { useQuery } from '@apollo/client';
import { GET_RESOURCES } from '../../graphql/queries/resource/resource';
import {
  dismissLoadingMessage,
  displayErrorMessage,
} from '../../utils/helpers';

const useGetResources = (first, variables) => {
  const { data, loading, error, fetchMore } = useQuery(GET_RESOURCES, {
    variables: {
      first,
      ...variables,
    },
    onCompleted: () => dismissLoadingMessage(),
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage('Could not load resources');
    },
    notifyOnNetworkStatusChange: true,
  });

  return { data, loading, error, fetchMore };
};

export default useGetResources;
