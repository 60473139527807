/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable implicit-arrow-linebreak */
import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Tappable from 'react-tappable';
import useAddToFavourites from '../../../../hooks/Favourite/useAddToFavourites';
import useRemoveFromFavourites from '../../../../hooks/Favourite/useRemoveFromFavourites';
import {
  userFavouriteAssemblies,
  userFavouriteResources,
  userFavouriteSongs,
} from '../../../../utils/reactiveVars';

const Favourite = ({ itemType, id, show }) => {
  if (!show) return null;

  const { addToFavouriteRequest } = useAddToFavourites(itemType, id);
  const { removeFromFavouritesRequest } = useRemoveFromFavourites(itemType, id);

  const [iconState, setIconState] = useState('inactive');

  const favSongs = useReactiveVar(userFavouriteSongs);
  const favResources = useReactiveVar(userFavouriteResources);
  const favAssemblies = useReactiveVar(userFavouriteAssemblies);

  const fnMap = {
    SONG: favSongs,
    RESOURCE: favResources,
    ASSEMBLY: favAssemblies,
  };

  useEffect(() => {
    const isAlreadyFavourited = () =>
      fnMap[itemType]?.some((item) => item.id === id);

    return isAlreadyFavourited()
      ? setIconState('active')
      : setIconState('inactive');
  }, [favSongs, id, favResources, favAssemblies]);

  return (
    <div className="mb-20 text__black text__fs-16">
      <Tappable
        component="a"
        onTap={() => {
          if (iconState !== 'active') {
            addToFavouriteRequest.send();
            return setIconState('active');
          }
          removeFromFavouritesRequest.send();
          return setIconState('inactive');
        }}
        data-dismiss="modal"
      >
        <i
          className={`${
            iconState === 'active' ? 'fa' : 'far'
          } fa-heart text__turquoise-medium text__fs-20 mr-4`}
        />{' '}
        <span className="text-black">
          {iconState === 'active' ? 'Remove from favourites' : 'Favourite'}
        </span>
      </Tappable>
    </div>
  );
};

export default Favourite;
