/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useMutation } from '@apollo/client';
import { ADD_TO_COLLECTION } from '../../graphql/mutations/collection';
import { GET_USER_COLLECTIONS } from '../../graphql/queries/collection/collection';
import hideModal from '../../styles/js/modals';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useAddToCollection = (type, itemId, modalId) => {
  const [addToCollection, { loading, data }] = useMutation(ADD_TO_COLLECTION, {
    refetchQueries: [
      {
        query: GET_USER_COLLECTIONS,
      },
    ],
  });

  const addToCollectionRequest = {
    send: (attachIds, detachIds) => {
      addToCollection({
        variables: {
          input: {
            type,
            item_id: parseInt(itemId, 10),
            attach: attachIds,
            detach: detachIds,
          },
        },
      })
        .then((res) => {
          displaySuccessMessage('Success!');
          dismissLoadingMessage();
          hideModal(modalId);
        })
        .catch((e) => {
          dismissLoadingMessage();
          displayErrorMessage('Operation failed');
        });
    },
  };

  return { loading, data, addToCollectionRequest };
};

export default useAddToCollection;
