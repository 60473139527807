import React from 'react';

const TagSuggestions = ({ tags, handleChange, questionRef }) => (
  <div className="my-3">
    {tags.map((tag) => (
      <a
        href=""
        className="role__suggestion"
        key={tag}
        onClick={(e) => handleChange(e, questionRef, tag, 'ADD')}
      >
        {tag}
      </a>
    ))}
  </div>
);

export default TagSuggestions;
