import { gql } from '@apollo/client';
import {
  EsongbookFieldsFragments,
  MinEsongbookFieldsFragments,
} from './fragments';

export const GET_SONGBOOK = gql`
  query Esongbook($id: ID!) {
    esongbook(id: $id) {
      ...EsongbookFields
    }
  }
  ${EsongbookFieldsFragments}
`;

export const GET_SONGBOOKS = gql`
  query Esongbooks(
    $limit: Int
    $where: QueryEsongbooksWhereWhereConditions
    $orderBy: [QueryEsongbooksOrderByOrderByClause!]
    $classAssembly: Boolean
    $filtered: [TagInput]
    $excludeNativitiesMusicals: Boolean
  ) {
    esongbooks(
      limit: $limit
      where: $where
      orderBy: $orderBy
      classAssembly: $classAssembly
      filtered: $filtered
      excludeNativitiesMusicals: $excludeNativitiesMusicals
    ) {
      ...MinEsongbookFields
    }
  }
  ${MinEsongbookFieldsFragments}
`;
