import { gql } from '@apollo/client';
import { EsongbookFieldsFragments } from '../songbook/fragments';
import { TagFieldsFragment } from '../tag/fragments';

export const CategoryFieldsFragments = gql`
  fragment CategoryFields on Category {
    id
    name
    color
  }
`;

export const SparklesFieldsFragment = gql`
  fragment SparklesFields on Sparkle {
    id
    title
    text
    imageUrl
    backgroundColor
  }
`;

export const ResourceFieldsFragments = gql`
  fragment ResourceFields on Resource {
    id
    slug
    title
    privateTitle
    content
    active
    summary
    ageRange
    thumbnail
    thumbnailText
    downloadables
    href
    resourceTags {
      ...TagFields
    }
    category {
      ...CategoryFields
    }
    sparkles {
      ...SparklesFields
    }
    songs {
      id
      title
      isFavourited
      albumArt
      duration
    }
  }
  ${TagFieldsFragment}
  ${CategoryFieldsFragments}
  ${SparklesFieldsFragment}
`;

export const CloudFieldsFragment = gql`
  fragment CloudFields on Cloud {
    id
    filename
    filename_md5
    filename_size
  }
`;

export const PhraseFieldsFragment = gql`
  fragment PhraseFields on Phrase {
    id
    content
    milliseconds
    hide
    isClickable
    isHighlightable
    splitLineNumber
  }
`;

export const LineFieldsFragment = gql`
  fragment LineFields on Line {
    id
    splitLineNumber
    phrases {
      ...PhraseFields
    }
  }
  ${PhraseFieldsFragment}
`;

export const GroupFieldsFragment = gql`
  fragment GroupFields on Group {
    id
    infoName
    styleGroupIndex
    styleGroupCol
    styleColIndex
    stylePadding
    lines {
      ...LineFields
    }
  }
  ${LineFieldsFragment}
`;

export const PageFieldsFragment = gql`
  fragment PageFields on Page {
    id
    infoHeader
    infoHeaderBasicOverride
    infoFooter1
    infoFooter2
    showPageHeader
    showPageFooter
    millisecondsStart
    millisecondsStop
    styleBodyLeading
    styleBodySize
    cloudPosX
    cloudPosY
    clouds {
      ...CloudFields
    }
    groups {
      ...GroupFields
    }
  }
  ${CloudFieldsFragment}
  ${GroupFieldsFragment}
`;

export const AudioFileFieldsFragment = gql`
  fragment AudioFileFields on AudioFile {
    mp3
    ogg
  }
`;

export const AudioFieldsFragment = gql`
  fragment AudioFields on Audio {
    id
    metadata
    duration
    id
    isDefault
    isExtractable
    type
    tempo
    infoName
    infoNameExtractable
    infoArtist
    infoCopyright
    infoISRC
    millisecondsLength
    files {
      ...AudioFileFields
    }
    file_md5s {
      ...AudioFileFields
    }
    file_sizes {
      ...AudioFileFields
    }
  }
  ${AudioFileFieldsFragment}
`;

export const SongFieldsFragments = gql`
  fragment SongFields on Song {
    albumArt
    id
    tempo
    infoName
    infoNameExtractable
    infoAuthor
    infoCopyright
    infoCopyrightFormatted
    infoCCLI
    infoAge
    infoCategory
    keywords
    overrideButtonInfo
    overrideButtonLyrics
    overrideButtonScore
    showButtonExtract
    showButtonInfo
    showButtonLyrics
    showButtonScore
    styleBodyFont
    styleBodyLeading
    styleBodySize
    styleTitleCol
    styleTitleFont
    styleTitleSize
    styleCol1Highlighted
    styleCol1Unhighlighted
    styleCol2Highlighted
    styleCol2Unhighlighted
    styleCol3Highlighted
    styleCol3Unhighlighted
    styleCol4Highlighted
    styleCol4Unhighlighted
    styleCol5Highlighted
    styleCol5Unhighlighted
    styleMarginLeft
    styleMarginRight
    styleMarginTop
    styleMarginBottom
    fileBackground
    fileBackgroundMD5
    fileBackgroundSize
    fileLarge
    fileLargeMD5
    fileLargeSize
    fileInfo
    fileInfoMD5
    fileInfoSize
    fileLyrics
    fileLyricsMD5
    fileLyricsSize
    fileScore
    fileScoreMD5
    fileScoreSize
    title
    author
    lyrics
    score
    age
    videoUrl
    videoTitle
    summary
    teachingTips
    instrumentalResource
    songSummaryMedia
    teachingTipsMedia
    assemblyPlans {
      id
      name
      slug
      assembly {
        id
        title
        description
      }
    }
    userCollections {
      id
    }
    clouds {
      ...CloudFields
    }
    pages {
      ...PageFields
    }
    audios {
      ...AudioFields
    }
    songTags {
      ...TagFields
    }
    resources {
      ...ResourceFields
    }
    sparkles {
      ...SparklesFields
    }
    recommendedResources {
      ...ResourceFields
    }
    esbTrack {
      id
      esongbook {
        ...EsongbookFields
      }
    }
    esongbooks {
      ...EsongbookFields
    }
  }
  ${ResourceFieldsFragments}
  ${SparklesFieldsFragment}
  ${TagFieldsFragment}
  ${CloudFieldsFragment}
  ${PageFieldsFragment}
  ${AudioFieldsFragment}
  ${EsongbookFieldsFragments}
`;

export const MinSongFieldsFragment = gql`
  fragment MinSongFields on Song {
    id
    title
    author
    age
    isFavourited
    albumArt
    duration
    ordering
  }
`;

export const MidSongFieldsFragment = gql`
  fragment MidSongFields on Song {
    age
    albumArt
    author
    fileBackground
    fileBackgroundMD5
    fileBackgroundSize
    fileInfo
    fileInfoMD5
    fileInfoSize
    fileLarge
    fileLargeMD5
    fileLargeSize
    fileLyrics
    fileLyricsMD5
    fileLyricsSize
    fileScore
    fileScoreMD5
    fileScoreSize
    id
    infoAge
    infoAuthor
    infoCategory
    infoCCLI
    infoCopyright
    infoCopyrightFormatted
    infoName
    infoNameExtractable
    keywords
    lyrics
    overrideButtonInfo
    overrideButtonLyrics
    overrideButtonScore
    score
    showButtonExtract
    showButtonInfo
    showButtonLyrics
    showButtonScore
    styleBodyFont
    styleBodyLeading
    styleBodySize
    styleCol1Highlighted
    styleCol1Unhighlighted
    styleCol2Highlighted
    styleCol2Unhighlighted
    styleCol3Highlighted
    styleCol3Unhighlighted
    styleCol4Highlighted
    styleCol4Unhighlighted
    styleCol5Highlighted
    styleCol5Unhighlighted
    styleMarginBottom
    styleMarginLeft
    styleMarginRight
    styleMarginTop
    styleTitleCol
    styleTitleFont
    styleTitleSize
    tempo
    title
    clouds {
      ...CloudFields
    }
    pages {
      ...PageFields
    }
    audios {
      ...AudioFields
    }
    esbTrack {
      id
      esongbook {
        ...EsongbookFields
      }
    }
  }
  ${CloudFieldsFragment}
  ${PageFieldsFragment}
  ${AudioFieldsFragment}
  ${EsongbookFieldsFragments}
`;
