import React from 'react';

const AllSongbookSeries = ({ songbookSeries }) => (
  <>
    <div>
      <span className="text__fs-18 font-weight-bold">
        Songbook series to explore
      </span>
    </div>
    <div className="songbook__series mt-5">
      {songbookSeries?.map(({ imageFileOriginal, infoName, id }) => (
        <a href={`#${infoName}`} key={id}>
          <img
            src={imageFileOriginal}
            alt={infoName}
            className="songbook__series__item"
          />
        </a>
      ))}
    </div>
  </>
);

export default AllSongbookSeries;
