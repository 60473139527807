/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useGetTag from '../../../hooks/useGetTag';
import useRefetchTags from '../../../hooks/useRefetchTags';
import { buildFilterParams } from '../../../utils/helpers';
import AgeRangeSelect from '../CategoryPages/AgeRangeSelect';
import SelectSection from '../CategoryPages/SelectSection';
import SubmitSection from '../CategoryPages/SubmitSection';

const FilterSection = ({ handleFilter, loading, title }) => {
  const { data: values, fetchMore: fetchMoreValues } = useGetTag(
    'Values (PSHE, SMSC)'
  );
  const { data: topicOrTheme, fetchMore: fetchMoreTopicsOrThemes } = useGetTag(
    'Topic/Theme'
  );
  const { data: season, fetchMore: fetchMoreSeasons } = useGetTag('Seasonal');

  const { register, handleSubmit, reset } = useForm();

  const allTagTypesRefetchMapDefault = {
    values: fetchMoreValues,
    topic_or_theme: fetchMoreTopicsOrThemes,
    seasonal: fetchMoreSeasons,
  };

  const [
    handleClearFilters,
    handleTagChange,
    handleAgeRangeChange,
  ] = useRefetchTags(allTagTypesRefetchMapDefault);

  // eslint-disable-next-line arrow-body-style
  const handleFilterParams = (data) => {
    return handleFilter(data.content_type);
    // const contentType = data.content_type;
    // delete data.content_type;
    // const filterParameters = buildFilterParams(data, contentType);
    // return handleFilter(filterParameters);
  };

  const [showAgeRange, setShowAgeRange] = useState(false);
  const [showValues, setShowValues] = useState(false);
  const [showTopic, setShowTopic] = useState(false);
  const [showContentType, setShowContentType] = useState(false);
  const [className, setClassName] = useState({});
  const [contentTypeTags, setContentTypeTags] = useState([
    { formattedName: 'Assembly', id: 'assembly' },
    { formattedName: 'Music Activity', id: 'music-activity' },
    {
      formattedName: 'Curriculum links and more',
      id: 'curriculum-links-and-more',
    },
    { formattedName: 'Blog or Guide', id: 'blog-guide' },
    { formattedName: 'SEND Resource', id: 'send-resource' },
  ]);

  useEffect(() => {
    const categoryPagesAndGuides = [
      'Assembly',
      'Collective Worship',
      'Community/Charity',
      'Concerts/Performance',
      'Curriculum',
      'Just for Fun',
      'Routines & Signals',
      'Wellbeing',
      'Related Guides and Resources',
    ];
    if (categoryPagesAndGuides.includes(title)) {
      setShowContentType(true);
      setClassName('see-all-filter');

      if (['Assembly', 'Related Guides and Resources'].includes(title)) {
        setContentTypeTags([
          { formattedName: 'Blog', key: 'blog' },
          { formattedName: 'Guide', key: 'guide' },
        ]);
      }
    }
  }, [title]);

  const valuesObjs = values?.tags
    ?.slice()
    .sort((a, b) =>
      a.formattedName.toLowerCase().localeCompare(b.formattedName.toLowerCase())
    );

  const topicOrThemeObjs = topicOrTheme?.tags
    ?.slice()
    .sort((a, b) =>
      a.formattedName.toLowerCase().localeCompare(b.formattedName.toLowerCase())
    );

  return (
    <div className="main__container bg__blue-nile mt-0">
      <div className="assembly__songfinder">
        <h2 className="assembly__songfinder__title">Find resources</h2>

        <div className={`assembly__filter ${className}`}>
          {showAgeRange && (
            <div className="assembly__songfinder__range">
              <label htmlFor="">Age range</label>
              <div className="assembly__songfinder__range__selectors">
                <AgeRangeSelect
                  ref={register}
                  name="min_age"
                  handleAgeRangeChange={handleAgeRangeChange}
                />
                <p>to</p>
                <AgeRangeSelect
                  ref={register}
                  name="max_age"
                  handleAgeRangeChange={handleAgeRangeChange}
                />
              </div>
            </div>
          )}

          {showValues && (
            <SelectSection
              label="Values"
              ref={register}
              tags={valuesObjs}
              name="values"
              handleTagChange={handleTagChange}
            />
          )}

          {showTopic && (
            <SelectSection
              label="Topic / Theme"
              ref={register}
              tags={topicOrThemeObjs}
              name="topic_or_theme"
              handleTagChange={handleTagChange}
            />
          )}

          {showContentType && (
            <SelectSection
              label="Content Type"
              ref={register}
              tags={contentTypeTags}
              name="content_type"
              handleTagChange={handleTagChange}
            />
          )}

          <SubmitSection
            ref={register}
            onClick={handleSubmit(handleFilterParams)}
            disabled={loading}
            reset={reset}
            handleClearFilters={() => {
              handleClearFilters();
              handleSubmit(handleFilter({}));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
