import { gql } from '@apollo/client';
import {
  CollectionFieldsFragment,
  CollectionsFieldsFragment,
  CollectionShareLinkFieldsFragment,
} from './fragments';

export const GET_USER_COLLECTIONS = gql`
  query UserCollections($organisation_ref: ID) {
    userCollections(organisation_ref: $organisation_ref) {
      ...CollectionsFields
    }
  }
  ${CollectionsFieldsFragment}
`;

export const GET_SY_COLLECTIONS = gql`
  query SparkyardCollections {
    sparkyardCollections {
      ...CollectionsFields
    }
  }
  ${CollectionsFieldsFragment}
`;

export const GET_COLLECTION = gql`
  query Collection($id: ID!, $organisation_ref: ID) {
    collection(id: $id) {
      ...CollectionFields
      hasSharedWithOrganisation(organisation_ref: $organisation_ref)
      shareId(organisation_ref: $organisation_ref)
      wasSharedWithUser(organisation_ref: $organisation_ref)
    }
  }
  ${CollectionFieldsFragment}
`;

export const GET_COLLECTIONS_SHARED_WITH_ORGANISATION = gql`
  query CollectionsSharedWithOrganisation($organisation_ref: ID!) {
    collectionsSharedWithOrganisation(organisation_ref: $organisation_ref) {
      ...CollectionsFields
    }
  }
  ${CollectionsFieldsFragment}
`;

export const GET_COLLECTION_SHARE_LINK = gql`
  query GetCollectionShareLink($collection_id: ID!) {
    getCollectionShareLink(collection_id: $collection_id) {
      ...CollectionShareLinkFields
    }
  }
  ${CollectionShareLinkFieldsFragment}
`;
