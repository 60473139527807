import React from 'react';

const Header = () => (
  <div className="main__container bg__cerise-dark mt-0">
    <div className="assembly__header choirs__edition">
      <h2 className="assembly__header__title">Choirs and Concerts</h2>
      <p className="assembly__header__text">
        Songs and resources to add a sparkle to your concert and put a zing in
        your sing!
      </p>
    </div>
  </div>
);

export default Header;
