import React from 'react';

const SearchHeader = () => (
  <>
    <div className="songslist__header main__container mb-0 mb-xl-4">
      <img
        src="img/search-icon-white.png"
        className="songslist__header__icon d-none d-md-block"
        alt=""
      />
      <h2 className="songslist__header__title">
        <span className="text__white">Search Results</span>
      </h2>
    </div>
  </>
);

export default SearchHeader;
