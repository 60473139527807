/* eslint-disable eqeqeq */
import React from 'react';
import { Link } from 'react-router-dom';
import { user } from '../../utils/reactiveVars';

const Reception = () => (
  <>
    <span className="d-none d-md-inline">Reception</span>
    <span className="d-inline d-md-none">R</span>
  </>
);

const Tabs = ({ musicCurriculum, selectedYear, handleSelectedYear }) => {
  const musicCurriculumCopy = [...musicCurriculum];
  const sortedCurriculum = musicCurriculumCopy.sort((a, b) => a.year - b.year);

  const shouldShow = (adminOnly) => !adminOnly || (adminOnly && user().isAdmin);

  return (
    <ul className="nav nav-tabs" id="mcTab" role="tablist">
      {sortedCurriculum?.map((item) => (
        <>
          {shouldShow(item.adminOnly) && (
            <li className="nav-item" key={item.id}>
              <Link
                to={`/music-curriculum/${item.year}`}
                className={`nav-link ${
                  item.id == selectedYear?.id
                    ? 'active text-dark'
                    : 'text-light'
                }`}
                id="eyfs-tab"
                data-toggle="tab"
                href="#eyfs"
                role="tab"
                aria-controls="eyfs"
                aria-selected="true"
                onClick={() => handleSelectedYear(item.year)}
              >
                {item.year === '0' ? <Reception /> : item.year}
              </Link>
            </li>
          )}
        </>
      ))}
    </ul>
  );
};

export default Tabs;
