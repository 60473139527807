import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DELETE_COLLECTION } from '../../graphql/mutations/collection';
import { GET_USER_COLLECTIONS } from '../../graphql/queries/collection/collection';
import hideModal from '../../styles/js/modals';
import {
  dismissLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { toastErrorObj } from '../../utils/objects';

const useDeleteCollection = (id) => {
  const history = useHistory();
  const [deleteCollection] = useMutation(DELETE_COLLECTION, {
    refetchQueries: [
      {
        query: GET_USER_COLLECTIONS,
      },
    ],
  });

  const deleteCollectionRequest = {
    send: () => {
      deleteCollection({ variables: { id } })
        .then(() => {
          displaySuccessMessage('Collection deleted!');
          dismissLoadingMessage();
          hideModal();
          history.push('/collections');
        })
        .catch(() => {
          toast.dismiss('loading');
          toast.error('Could not delete collection', toastErrorObj);
        });
    },
  };

  return { deleteCollectionRequest };
};

export default useDeleteCollection;
