/* eslint-disable operator-linebreak */
import { useMutation } from '@apollo/client';
import {
  UPDATE_COLLECTION,
  UPDATE_COLLECTION_PLAYLIST_ORDERING,
  UPDATE_COLLECTION_SONG_ORDERING,
} from '../../graphql/mutations/collection';
import { GET_COLLECTION } from '../../graphql/queries/collection/collection';
import { displayErrorMessage } from '../../utils/helpers';

const useUpdateCollection = (id, key) => {
  const queryMap = {
    resources: UPDATE_COLLECTION,
    songs: UPDATE_COLLECTION_SONG_ORDERING,
    playlists: UPDATE_COLLECTION_PLAYLIST_ORDERING,
  };
  const query = queryMap[key];

  const [updateCollection] = useMutation(query, {
    refetchQueries: [
      {
        query: GET_COLLECTION,
        variables: { id },
      },
    ],
  });

  const updateCollectionRequest = {
    send: (resources) => {
      updateCollection({
        variables: {
          id,
          input: { [key]: { syncWithoutDetaching: resources } },
        },
      }).catch(() => displayErrorMessage());
    },
  };

  return { updateCollectionRequest };
};

export default useUpdateCollection;
