import { useMutation } from '@apollo/client';
import { SEND_INVITE } from '../../graphql/mutations/organisation';
import { GET_ORGANISATION } from '../../graphql/queries/organisation/organisation';
import {
  displayLoadingMessage,
} from '../../utils/helpers';

const useSendInvite = (organisationId) => {
  const [sendInvite, { loading }] = useMutation(SEND_INVITE, {
    refetchQueries: [
      {
        query: GET_ORGANISATION,
        variables: {
          id: organisationId,
        },
      },
    ],
  });

  const sendInviteRequest = (input) => {
    return new Promise((resolve, reject) => {
      sendInvite({
        variables: { input },
      })
        .then(() => {
          resolve('Invite sent!');
        })
        .catch((response) => {
          reject(response.message);
        });
    });
  };

  loading && displayLoadingMessage();

  return { sendInviteRequest, loading };
};

export default useSendInvite;
