import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { shortenLongDescription } from '../../../../utils/helpers';
import {
  getResourceDescription,
  getResourceThumbnail,
} from '../../../../utils/resource';
import LikeAndAddIcons from '../../../Shared/LikeAddIcons';
import Tag from '../../../ResourcesFavourite/Tag';

const Card = ({ resource, actions, history, showTags }) => {
  const {
    id,
    title,
    thumbnail,
    slug,
    href,
    category: { name },
    resourceTags,
  } = resource;
  const description = getResourceDescription(resource);

  return (
    <div className="card__favorite">
      <div className="card__favorite__body bg__white border__radius-5">
        <div className="card__favorite__img">
          <img src={getResourceThumbnail(thumbnail, name)} alt={title} />
        </div>
        <div className="card__favorite__info">
          <p className="card__favorite__title">
            <Tappable
              component="a"
              className="card__favorite__title"
              onTap={() => {
                history.push(href || `/resources/${slug}`);
              }}
            >
              {title}
            </Tappable>
          </p>
          <p className="card__favorite__subtext">
            {shortenLongDescription(description, 115)}
          </p>
        </div>
        {showTags && (
          <div className="tags-wrapper">
            {resourceTags?.map((tag) => (
              <Tag
                type={tag.type}
                formattedName={tag.formattedName}
                key={tag.name}
              />
            ))}
          </div>
        )}
        <div className="card__favorite__ctas">
          <LikeAndAddIcons
            headerText="Song options"
            modalId="songHeaderActions"
            favourite
            addToCollection
            darkColor
            itemType="RESOURCE"
            id={id}
            type={actions}
          />
        </div>
      </div>
    </div>
  );
};
export default withRouter(Card);
