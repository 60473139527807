/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-linebreak */
import { useEffect, useState } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { GET_USER } from '../../graphql/queries/user/user';
import { showModal } from '../../styles/js/modals';
import { sparkyardLoginUrl } from '../../utils/objects';
import { user } from '../../utils/reactiveVars';

const useGetUser = (options, disableRedirect) => {
  const [fetchTime, setFetchTime] = useState(6000);
  const queryOptions = {
    // pollInterval: 7000,
    fetchPolicy: 'cache-first',
    ...options,
  };

  const history = useHistory();

  useEffect(() => {
    const timeout = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      refetch();
    }, fetchTime - 300000);

    return () => clearTimeout(timeout);
  }, [fetchTime]);

  const { data, refetch } = useQuery(GET_USER, {
    ...queryOptions,
    onCompleted: (res) => {
      if (disableRedirect) {
        return;
      }
      if (res?.me === null) {
        window.location.href = sparkyardLoginUrl;
        return;
      }

      localStorage.setItem('user', JSON.stringify(res.me));
      user(res.me);

      const shouldRequireValidation =
        !res.me?.isValidated && res.me?.validationRequired;
      if (shouldRequireValidation) {
        history.push('/restricted');
      }

      const shouldShowInvitesModal =
        res.me.invites?.length > 0 && !shouldRequireValidation;
      if (shouldShowInvitesModal) {
        history.push('/manage-account');
        return showModal('invitesModal', true);
      }

      const timeNow = new Date();
      const timeForNextRefetch = differenceInMilliseconds(
        new Date(res.me.tokenExpires),
        new Date(
          timeNow.getUTCFullYear(),
          timeNow.getUTCMonth(),
          timeNow.getUTCDate(),
          timeNow.getUTCHours(),
          timeNow.getUTCMinutes(),
          timeNow.getUTCSeconds()
        )
      );

      setFetchTime(timeForNextRefetch);
    },
    notifyOnNetworkStatusChange: true,
    context: {
      headers: {
        forceRefresh: true,
      },
    },
  });

  return { data };
};

export default useGetUser;
