import React from 'react';
import { currentResource } from '../../../../../utils/reactiveVars';

const ResourcesHeaderInfo = () => {
  const {
    title,
    category: { name },
  } = currentResource();

  return (
    <div className="resource__header__info">
      <h2 className="resource__header__title">{title}</h2>
      <p className="resource__header__subtitle mb-40">{name}</p>
    </div>
  );
};
export default ResourcesHeaderInfo;
