/* eslint-disable arrow-body-style */
import React from 'react';
import CurriculumResources from './CurriculumResources';
import MusicCurriculumBanner from './MusicCurriculumBanner';
import SongCards from '../../../../Shared/SongCards/Index';
import PlaylistCards from '../../../../Shared/PlaylistCards';
import Songbooks from '../../../../Shared/Songbook/Index';
import { resourceQuery } from '../../../../../utils/objects';

const MainSection = ({
  scienceSongs,
  englishSongs,
  mathsSongs,
  resources,
  tagsChanged,
  filterResults,
  displayResources = true,
  displaySongs = true,
  displayPlaylists = true,
  displaySongbooks = true,
  categoryPage,
  playlists,
  resourceFilterParams,
  classAssembly,
}) => {
  const query = { ...resourceQuery, ...resourceFilterParams };

  return (
    <section className="main__container">
      <div className="px__sm-3">
        {displaySongs && (
          <>
            {!tagsChanged ? (
              <>
                <SongCards
                  title="Maths songs"
                  songs={mathsSongs}
                  pageTitle="Maths songs"
                  name="Maths"
                  type="Curriculum Subject Links"
                />
                <SongCards
                  title="English songs"
                  songs={englishSongs}
                  pageTitle="English songs"
                  name="English"
                  type="Curriculum Subject Links"
                />
                <SongCards
                  title="Science songs"
                  songs={scienceSongs}
                  pageTitle="Science songs"
                  name="Science"
                  type="Curriculum Subject Links"
                />
              </>
            ) : (
              <SongCards
                title="Songs"
                songs={filterResults}
                pageTitle="Songs"
              />
            )}
          </>
        )}
        {displaySongbooks && (
          <Songbooks songbooks={classAssembly} title="Class Assembly Series" />
        )}
        {displayResources && (
          <CurriculumResources
            resources={resources}
            title="Curriculum"
            type="OOTA Singing School"
            query={query}
          />
        )}
        {displayPlaylists && (
          <PlaylistCards
            playlists={playlists}
            title="playlists"
            disableDeletePlaylist
          />
        )}
        {displaySongbooks && <Songbooks songbooks={categoryPage?.esongbooks} />}
        <MusicCurriculumBanner />
      </div>
    </section>
  );
};

export default MainSection;
