/* eslint-disable jsx-a11y/anchor-is-valid */
import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { openTabType } from '../../../../../../utils/reactiveVars';

const MyFavourites = ({ location, history }) => {
  const [isActive, setIsActive] = useState(false);
  const openTab = useReactiveVar(openTabType);

  useEffect(() => {
    setIsActive(openTab === 'favourite');
  }, [openTab]);

  return (
    <li
      className={`sidebar__list__item has-sublist  ${isActive ? 'active' : ''}`}
    >
      <Tappable
        component="a"
        className="sidebar__list__item__link"
        onTap={() => {
          if (!isActive) {
            openTabType('favourite');
          } else {
            openTabType('');
          }
        }}
      >
        <img src="/img/favourite-cyan.svg" alt="favourite" />
        <p>My Favourites</p>
      </Tappable>
      <ul className="sidebar__list__item__sublist">
        <li className="sidebar__list__item__sublist__item">
          <Tappable
            component="a"
            className={
              'sidebar__list__item__sublist__item__link' +
              (location.pathname === '/favourite-songs'
                ? ' font-weight-bold'
                : '')
            }
            onTap={() => {
              history.push('/favourite-songs');
            }}
          >
            Favourite Songs
          </Tappable>
        </li>
        <li className="sidebar__list__item__sublist__item">
          <Tappable
            component="a"
            className={
              'sidebar__list__item__sublist__item__link' +
              (location.pathname === '/favourite-resources'
                ? ' font-weight-bold'
                : '')
            }
            onTap={() => {
              history.push('/favourite-resources');
            }}
          >
            Favourite Resources
          </Tappable>
        </li>
      </ul>
    </li>
  );
};

export default withRouter(MyFavourites);
