import { gql } from '@apollo/client';

export const MinResourceFragmentFields = gql`
  fragment MinResourceFields on Resource {
    id
    slug
    thumbnail
    title
    thumbnailText
    ordering
    minAge
    maxAge
    summary
    content
    href
    calendarEvents {
      id
      slug
    }
    category {
      id
      name
    }
    resourceTags {
      id
      formattedName
      type
    }
  }
`;
