import React, { useState } from 'react';
import FilterSection from '../../components/CategoryPages/ChoirsAndConcerts/Sections/FilterSection';
import Header from '../../components/CategoryPages/ChoirsAndConcerts/Sections/Header';
import MainSection from '../../components/CategoryPages/ChoirsAndConcerts/Sections/MainSection/Index';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useGetResources from '../../hooks/Resource/useGetResources';
import useGetSongs from '../../hooks/Song/useGetSongs';
import { returnHasTags } from '../../utils/helpers';
import { updateQuery } from '../../utils/objects';
import { buildResourceParams } from '../../utils/resource';

const ChoirsAndConcerts = () => {
  const [displaySongs, setDisplaySongs] = useState(true);
  const [displayResources, setDisplayResources] = useState(true);
  const [displayPlaylists, setDisplayPlaylists] = useState(true);
  const [filterParams, setFilterParams] = useState({});

  const {
    data: songs,
    loading,
    fetchMore: fetchMoreSongs,
  } = useGetSongs(
    6,
    returnHasTags(
      'hasSongTags',
      'OOTA Singing School',
      'Concerts/Performance',
      true
    ),
    true
  );

  const { data: resources, fetchMore: fetchMoreResources } = useGetResources(
    2,
    returnHasTags('hasTags', 'OOTA Singing School', 'Concerts/Performance')
  );

  const handleFilter = (params) => {
    const resourceParams = buildResourceParams(params);

    fetchMoreSongs({
      variables: { ...params },
      ...updateQuery,
    });
    fetchMoreResources({
      variables: {
        ...resourceParams,
      },
      ...updateQuery,
    });
    setDisplaySongs(true);
    setDisplayResources(true);
    setDisplayPlaylists(true);
    setFilterParams(params);
  };

  return (
    <>
      <Header />
      <FilterSection loading={loading} handleFilter={handleFilter} />
      <MainSection
        songs={songs?.songs?.data}
        resources={resources?.resources?.data}
        displayResources={displayResources}
        displaySongs={displaySongs}
        displayPlaylists={displayPlaylists}
        filterParams={filterParams}
      />
    </>
  );
};

export default withPageWrapper({})(ChoirsAndConcerts);
