/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from 'react';
import useUpdateOrganisationLogo from '../../../../../hooks/Organisation/useUpdateOrganisationLogo';
import useUploadImage from '../../../../../hooks/Others/useUploadmage';
import { defaultOrganisationLogo } from '../../../../../utils/objects';
import FormError from '../../../FormError';
import Loading from '../../../Loading';

const Logo = ({ organisationRef, avatar }) => {
  const avatarRef = useRef(null);
  const defaultImage = avatar || defaultOrganisationLogo;
  const { onChange, img } = useUploadImage(defaultImage);

  const avatarHasChanged =
    defaultImage !== img.url && img.url !== defaultOrganisationLogo;

  const { updateOrganisationLogoRequest, loading, error } =
    useUpdateOrganisationLogo(organisationRef);

  useEffect(() => {
    if (avatarHasChanged) {
      updateOrganisationLogoRequest({
        organisation_ref: organisationRef,
        avatar: img.file,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img]);

  const disableElement = loading ? 'disable-element' : '';

  return (
    <>
      <div
        className={`col-lg-3 text-center text__fs-14 pt-5 ${disableElement}`}
        onClick={() => avatarRef.current.click()}
        style={{ cursor: 'pointer' }}
      >
        <img src={img.url} alt="" className="manage-account__avatar" />
        <br />
        Change logo
        {loading && <Loading text="Please wait" />}
        {error && (
          <FormError text="Could not update logo, please try again later. " />
        )}
      </div>
      <input
        type="file"
        id="file"
        ref={avatarRef}
        style={{ display: 'none' }}
        onChange={(e) => onChange.getImageFile(e)}
      />
    </>
  );
};

export default Logo;
