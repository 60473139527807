import { gql } from '@apollo/client';

export const AddressFieldsFragment = gql`
  fragment AddressFields on Address {
    id
    addressRef
    detailFirstName
    detailSurname
    detailCounty
    detailCompany
    detailLine1
    detailLine2
    detailTown
    detailPostcode
    detailCountry
    detailPhone
    detailFax
    isDefaultBilling
  }
`;

export const SysubFieldsFragment = gql`
  fragment SysubFields on Sysub {
    id
    sysubRef
    dateCreated
    dateUpdated
    dateRenewal
    dateUpdated
    isActive
    willRenew
    syprodDescription
    syprodPupilsMax
    syprodReadablePrice
    subscriptionType
    manualRationale
    stripeCollectionMethod
    scheduledDeactivation
  }
`;

export const MemberFieldsFragment = gql`
  fragment MemberFields on Member {
    id
    memberUserRef
    isAdmin
    sendEmails
  }
`;

export const InviteFieldsFragment = gql`
  fragment InviteFields on Invite {
    id
    email
    inviteRef
    firstname
    surname
  }
`;

export const OrganisationFieldsFragment = gql`
  fragment OrganisationFields on Organisation {
    id
    syId
    displayName
    detailHeadCount
    organisationRef
    detailName
    detailCategory
    detailHeadCount
    detailBillingEmail
    readOnly
    effectiveUserCount
    effectiveUserLimit
    avatar
    playsPast30Days
    playsThisMonth
    addresses {
      ...AddressFields
    }
    sysubs {
      ...SysubFields
    }
    users {
      id
      email
      firstname
      surname
      ootaId
    }
    members {
      ...MemberFields
    }
    invites {
      ...InviteFields
    }
    joinRequests {
      id
      email
      firstname
      surname
    }
  }
  ${AddressFieldsFragment}
  ${SysubFieldsFragment}
  ${MemberFieldsFragment}
  ${InviteFieldsFragment}
`;

export const MembershipFieldsFragment = gql`
  fragment MembershipFields on Membership {
    id
    isAdmin
    sendEmails
    organisation {
      ...OrganisationFields
    }
  }
  ${OrganisationFieldsFragment}
`;
