import React from 'react';
import useFileStats from '../../hooks/Others/useFileStats';

const Downloadable = ({ link, text, stat, pdfId }) => {
  const { createFileStatRequest } = useFileStats();

  return (
    <>
      <div>
        <p className="text__fs-12 text__fw-600 mb-0">{text}</p>
      </div>
      <div>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="font-weight-light text__white d-flex align-items-center"
          onClick={() => createFileStatRequest({ ...stat, sy_pdf_id: pdfId })}
        >
          <i className="fas fa-cloud-download-alt mr-3" />
          <u className="text__fs-11 mt-1">Download PDF</u>
        </a>
      </div>
    </>
  );
};
export default Downloadable;
