import React from 'react';

const EditCollection = ({ onClick }) => (
  <div className="mb-26">
    <a
      href=""
      data-target="#editCollectionModal"
      data-toggle="modal"
      className="text__black"
      onClick={onClick}
    >
      <i className="fas fa-pencil-alt mr-3 text__fs-20 text__turquoise-medium" />{' '}
      Edit name/description
    </a>
  </div>
);

export default EditCollection;
