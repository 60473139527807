import React from 'react';
import { user } from '../../../../utils/reactiveVars';
import Invite from './Invite';

const InvitesModal = () => {
  const { invites } = user();
  if (invites?.length < 1) return null;

  return (
    <div
      className="modal fade"
      id="invitesModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="invitesModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="custom__modal__content bg__cello px-sm-32 px-74">
            {invites?.map(({ id, organisationName, organisationRef }) => (
              <Invite
                key={id}
                inviteRef={id}
                organisationName={organisationName}
                organisationRef={organisationRef}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitesModal;
