/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

const ModalHeader = ({ shareLink, hasReachedSharingLimit }) => (
  <>
    <div className="custom__modal__header">
      <h5 className="custom__modal__title">Share link</h5>
      <a href="" className="custom__modal__close" data-dismiss="modal">
        <img src="/img/plus-icon.svg" alt="" />
      </a>
    </div>
    {shareLink ? (
      <p className="text-dark text__fs-12">
        Copy the link below to share with students at home. The link will expire
        after 14 days and can be managed and renewed in your account area.
      </p>
    ) : (
      <p className="text-dark text__fs-12 mb-1">
        {hasReachedSharingLimit ? (
          <>
            You have reached your limit and can no longer generate share links.
            Visit the Manage Account area to manage your student links.
          </>
        ) : (
          <>Generate a link to share this song with students at home.</>
        )}
      </p>
    )}
    <p className="text-dark text__fs-12">
      You are sharing this resource, subject to our agreed{' '}
      <a
        href="https://www.sparkyard.com/terms-and-conditions"
        target="_blank"
        rel="noreferrer"
      >
        Terms & Conditions &nbsp;
      </a>
      for its use.
    </p>
  </>
);

export default ModalHeader;
