/* eslint-disable operator-linebreak */
import React from 'react';
import useMarkUserNotificationsAsRead from '../../hooks/Notification/useMarkUserNotificationsAsRead';

const MarkAsRead = () => {
  const { markUserNotificationsAsReadRequest, loading } =
    useMarkUserNotificationsAsRead();

  const onClick = (e) => {
    e.preventDefault();
    markUserNotificationsAsReadRequest();
  };

  return (
    <a
      href=""
      onClick={onClick}
      className={`float-right text__fs-11 ${
        loading ? 'disable-element' : ''
      } ml-auto mr-0`}
    >
      Mark all as read
    </a>
  );
};

export default MarkAsRead;
