import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { calculateDaysLeftInTrial } from '../../../../utils/helpers';
import {
  currentlySelectedSubscription,
  user,
} from '../../../../utils/reactiveVars';

const Countdown = () => {
  const loggedInUser = useReactiveVar(user);
  const currentSub = useReactiveVar(currentlySelectedSubscription);

  let daysLeft = null;
  let text = '';
  let clockClassName = 'd-inline-block mr-2';
  let shouldDisplayCountdown = true;

  if (loggedInUser.activeTrial || loggedInUser.activeSysub) {
    const state = loggedInUser.activeTrial ? 'trial' : 'sub';

    const stateMap = {
      trial: {
        fnParam: loggedInUser.trialDateUntil,
        text: 'days left of your free trial',
      },
      sub: {
        fnParam: currentSub?.dateRenewal || Math.floor(Date.now() / 1000),
        text: currentSub?.willRenew
          ? 'days left until renewal'
          : 'days left in your subscription',
      },
    };

    daysLeft = calculateDaysLeftInTrial(stateMap[state].fnParam);

    text = stateMap[state].text;
    if (state === 'sub' && daysLeft > 30) {
      shouldDisplayCountdown = false;
    }
    if (currentSub.subscriptionType === 'MANUAL' && !currentSub.dateRenewal) {
      shouldDisplayCountdown = false;
    }

    if (daysLeft < 6) {
      clockClassName += ' ring';
    }
  }

  if ((!daysLeft && daysLeft !== 0) || !shouldDisplayCountdown) return null;

  let textClassName = 'text__fs-13';
  if (daysLeft > 15) {
    textClassName += ' text__moderate-green';
  } else if (daysLeft < 16 && daysLeft > 5) {
    textClassName += ' text__pumpkin';
  } else if (daysLeft < 6) {
    textClassName += ' text__cerise-light';
  }

  return (
    <div className="d-flex align-items-center ml-24">
      <img
        src={`/img/countdown/days-left-reminder-${daysLeft}.png`}
        alt={`${daysLeft} days left`}
        className={clockClassName}
      />
      <span className={textClassName}>{text}</span>
    </div>
  );
};

export default Countdown;
