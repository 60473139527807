/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isMobileSidebarOpen, user } from '../../../utils/reactiveVars';
import BottomSpacer from '../BottomSpacer';
import Header from '../Header/Index';
import Sidebar from '../Sidebar/Index';
import LoadJquery from '../LoadJquery';
import { getUserFromLocalStorage } from '../../../utils/user';
import { sparkyardLoginUrl } from '../../../utils/objects';
import useGetUser from '../../../hooks/User/useGetUser';
import GetCommonData from '../Others/GetCommonData';
import { hideToolTips } from '../../../styles/js/app';
import InvitesModal from '../Modals/InvitesModal/InvitesModal';
import NoSubscriptionModal from '../Modals/NoSubscriptionModal';
import TrialEndedModal from '../Modals/TrialEndedModal';
import { showModal } from '../../../styles/js/modals';
import OnboardingQuestions from '../Modals/OnboardingQuestions/Index';

const withPageWrapper =
  ({ pageTitle }) =>
  (Wrapped) =>
  () => {
    useEffect(() => {
      isMobileSidebarOpen(false);
      document.body.style.overflow = 'auto';
      toast.dismiss('error');
      hideToolTips();
      document.title = pageTitle || 'SPARKYARD';

      // fix bug with pages not being scrollable on mobile sometimes
      document.body.style.overflow = 'visible';
    }, []);

    const location = useLocation();

    const currentURL = location.pathname;
    const isSharedPageURL =
      currentURL.includes('/shared/collection') ||
      currentURL.includes('/shared/playlist');

    const whitelistedFromAuthRedirect =
      currentURL.includes('login') || isSharedPageURL;

    const { data } = useGetUser({}, isSharedPageURL);

    if (!getUserFromLocalStorage() && !whitelistedFromAuthRedirect) {
      window.location.href = sparkyardLoginUrl;
      return null;
    }

    if (isSharedPageURL && (!getUserFromLocalStorage() || data?.me === null)) {
      const type = currentURL.includes('/collection')
        ? 'collection'
        : 'playlist';

      window.location.href = `/guest/${type}`;
      return null;
    }

    user(getUserFromLocalStorage());

    return (
      <div className="d-flex">
        {data?.me && (
          <>
            <Sidebar />
            <main role="main" className="main">
              <GetCommonData />
              <Header />
              <Wrapped />
              <BottomSpacer />
              <InvitesModal />
              <NoSubscriptionModal />
              <TrialEndedModal />
              <OnboardingQuestions />
            </main>
          </>
        )}
        <LoadJquery />
      </div>
    );
  };

export default withPageWrapper;
