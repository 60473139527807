import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { videoList } from '../../../utils/reactiveVars';
import Video from './Video';

const VideoCards = ({ videos, title, history }) => {
  if (videos?.length < 1) return null;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <p className="heading__section">{title || 'Videos'}</p>
        <Tappable
          component="a"
          className="text__white text__fs-10"
          onClick={() => {
            videoList(videos);
            history.push('/see-all/videos');
          }}
        >
          SEE ALL
        </Tappable>
      </div>

      <div className="grid__vid mb-40">
        {videos.slice(0, 2).map((video) => (
          <div className="grid__vid__item" key={video.key}>
            <div className="grid__vid__thumb">
              <Video url={video.attributes.url} mini />
            </div>
            <p className="grid__vid__title">{video.attributes.title}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default withRouter(VideoCards);
