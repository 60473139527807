import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { useForm } from 'react-hook-form';
import useCreateCollection from '../../../../hooks/Collection/useCreateCollection';
import { addToCollectionItemObj } from '../../../../utils/reactiveVars';
import FormError from '../../FormError';

const CreateCollectionModal = () => {
  const { register, handleSubmit, errors, reset } = useForm();

  const { id: itemId, itemType } = useReactiveVar(addToCollectionItemObj);

  const { createCollectionRequest } = useCreateCollection(
    reset,
    null,
    itemType,
    itemId
  );

  const handleCreateCollection = ({ title, description }) => {
    createCollectionRequest.send(title, description);
  };

  return (
    <div
      className="modal fade"
      id="createCollectionModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="createCollectionModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered custom__modal__filter"
        role="document"
      >
        <div className="modal-content">
          <div className="custom__modal__content">
            <div className="custom__modal__header">
              <h5 className="custom__modal__title">Create collection</h5>
              <a href="" className="custom__modal__close" data-dismiss="modal">
                <img src="/img/plus-icon.svg" alt="" />
              </a>
            </div>
            <h6 className="text__fs-14 text__black mb-5">
              Enter a name for your new collection below
            </h6>
            <div className="input__control mb-20">
              <input
                name="title"
                type="text"
                placeholder="Collection name"
                className="text-dark"
                ref={register({ required: true })}
              />
              {errors.title && <FormError text="required" />}
            </div>
            <div className="input__control mb-5">
              <textarea
                className="text-dark"
                name="description"
                placeholder="Collection description"
                rows="5"
                ref={register({ required: true })}
              />
              {errors.description && <FormError text="required" />}
            </div>
            <button
              href=""
              className="button button__turquoise"
              onClick={handleSubmit(handleCreateCollection)}
              disabled={!!errors.title || !!errors.description}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateCollectionModal;
