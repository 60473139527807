import React from 'react';
import useCopyPlaylist from '../../../../hooks/Playlist/useCopyPlaylist';

const CopyPlaylist = ({ id, show }) => {
  if (!show) return null;

  const { copyPlaylistRequest } = useCopyPlaylist();

  return (
    <div className="mb-20 text__black text__fs-16">
      <a
        href=""
        className="text__black"
        id="addTo"
        onClick={(e) => {
          e.preventDefault();
          copyPlaylistRequest(id);
        }}
      >
        <i className="fa fa-copy text__turquoise-medium text__fs-20 mr-4" />{' '}
        Copy Playlist
      </a>
    </div>
  );
};

export default CopyPlaylist;
