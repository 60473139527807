/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { openTabType } from '../../../../../../utils/reactiveVars';

const SongsAndPlaylist = ({ history }) => {
  const [isActive, setIsActive] = useState(false);
  const openTab = useReactiveVar(openTabType);

  useEffect(() => {
    setIsActive(openTab === 'songs');
  }, [openTab]);

  return (
    <li
      className={`sidebar__list__item has-sublist ${isActive ? 'active' : ''}`}
    >
      <Tappable
        component="a"
        className="sidebar__list__item__link"
        onTap={() => {
          if (!isActive) {
            openTabType('songs');
          } else {
            openTabType('');
          }
        }}
      >
        <img src="/img/audio-icon.svg" alt="" />
        <p>Songs and Playlists</p>
      </Tappable>
      <ul className="sidebar__list__item__sublist">
        <li className="sidebar__list__item__sublist__item">
          <Tappable
            component="a"
            className={
              'sidebar__list__item__sublist__item__link' +
              (location.pathname === '/songs'
                ? ' font-weight-bold'
                : '')
            }
            onTap={() => {
              history.push('/songs');
            }}
          >
            All Songs
          </Tappable>
        </li>
        <li className="sidebar__list__item__sublist__item">
          <Tappable
            component="a"
            className={
              'sidebar__list__item__sublist__item__link' +
              (location.pathname === '/songs/playlists'
                ? ' font-weight-bold'
                : '')
            }
            onTap={() => {
              history.push('/songs/playlists');
            }}
          >
            All Playlists
          </Tappable>
        </li>
        <li className="sidebar__list__item__sublist__item">
          <Tappable
            component="a"
            className={
              'sidebar__list__item__sublist__item__link' +
              (location.pathname === '/songs/all-songbooks'
                ? ' font-weight-bold'
                : '')
            }
            onTap={() => {
              history.push('/songs/all-songbooks');
            }}
          >
            All eSongbooks
          </Tappable>
        </li>
        <li className="sidebar__list__item__sublist__item">
          <Tappable
            component="a"
            className={
              'sidebar__list__item__sublist__item__link' +
              (location.pathname === '/songs/nativities-and-musicals'
                ? ' font-weight-bold'
                : '')
            }
            onTap={() => {
              history.push('/songs/nativities-and-musicals');
            }}
          >
            Purchased Nativities and Musicals
          </Tappable>
        </li>
      </ul>
    </li>
  );
};

export default withRouter(SongsAndPlaylist);
