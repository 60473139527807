import React, { useState } from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import LeftIcon from './LeftIcon';
import LeftShadow from './LeftShadow';
import RightIcon from './RightIcon';
import RightShadow from './RightShadow';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

const categories = [
  {
    link: '/assemblies',
    title: 'ASSEMBLIES',
    image: '/img/category-assemblies.png',
    color: '#c43bb7',
    imgHeight: '55px',
  },
  {
    link: '/sing-in-every-subject',
    title: 'Sing in Every Subject',
    image: '/img/category-sing-in-every-subject.png',
    color: '#f27021',
    imgHeight: '55px',
  },
  {
    link: '/school-routines-and-signals',
    title: 'School Routines & signals',
    image: '/img/category-school-routines.png',
    color: '#15a881',
    imgHeight: '62px',
  },
  {
    link: '/sing-for-fun',
    title: 'Sing for fun',
    image: '/img/category-sing-for-fun.png',
    color: '#0a7bc1',
    imgHeight: '57px',
  },
  {
    link: '/choirs-and-concerts',
    title: 'Choirs & Concerts',
    image: '/img/category-choirs.png',
    color: '#de2962',
    imgHeight: '45px',
  },
  {
    link: '/sing-in-the-community',
    title: 'Sing in the community',
    image: '/img/category-sing-in-the-community.png',
    color: '#9670c7',
    imgHeight: '57px',
  },
  {
    link: '/wellbeing',
    title: 'Wellbeing',
    image: '/img/category-wellbeing.png',
    color: '#52788c',
    imgHeight: '60px',
  },
  {
    link: '/collective-worship',
    title: 'Collective Worship',
    image: '/img/category-collective-worship.png',
    color: '#65a22f',
    imgHeight: '54px',
  },
];

const SwiperButton = ({ onPrev, onNext, hidePrev, hideNext }) => {
  return (
    <>
      {!hidePrev && (
        <div
          className={classNames(
            'home-categories__btn home-categories__btn-prev'
          )}
        >
          <div className="home-categories__btn-shadow">
            <LeftShadow />
          </div>
          <button onClick={onPrev}>
            <LeftIcon />
          </button>
        </div>
      )}

      {!hideNext && (
        <div
          className={classNames(
            'home-categories__btn home-categories__btn-next'
          )}
        >
          <div className="home-categories__btn-shadow">
            <RightShadow />
          </div>
          <button onClick={onNext}>
            <RightIcon />
          </button>
        </div>
      )}
    </>
  );
};

const Categories = ({ history }) => {
  const [reachBeginning, setReachBeginning] = useState(true);
  const [reachEnd, setReachEnd] = useState(false);
  const [swiperCategories, setSwiperCategories] = useState(null);

  return (
    <div className="px-xl-0 px-3 home-categories">
      <div>
        <Swiper
          spaceBetween={10}
          slidesPerView="auto"
          onInit={(swiper) => {
            setSwiperCategories(swiper);
          }}
          allowTouchMove={false}
          onReachBeginning={() => {
            setReachBeginning(true);
          }}
          onReachEnd={() => {
            setReachEnd(true);
          }}
          onSlidePrevTransitionStart={() => {
            setReachEnd(false);
          }}
          onSlideNextTransitionStart={() => {
            setReachBeginning(false);
          }}
          modules={[Navigation]}
        >
          {categories.map((category, index) => (
            <SwiperSlide
              key={index}
              style={{ width: 'auto' }}
              className="home-categories__item"
            >
              <div
                className="home-categories__item-inner"
                style={{ backgroundColor: category.color }}
              >
                <Tappable
                  component="a"
                  onTap={() => history.push(category.link)}
                >
                  <img
                    src={category.image}
                    alt=""
                    className="category__thumbnail__img"
                    style={{
                      height: category.imgHeight,
                    }}
                  />
                  <h3>{category.title}</h3>
                </Tappable>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <SwiperButton
          onPrev={() => swiperCategories.slidePrev()}
          onNext={() => swiperCategories.slideNext()}
          hidePrev={reachBeginning}
          hideNext={reachEnd}
        />
      </div>
    </div>
  );
};

export default withRouter(Categories);
