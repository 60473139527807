import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SelectSection from '../../Shared/CategoryPages/SelectSection';
import SubmitSection from '../../Shared/CategoryPages/SubmitSection';

const Header = ({ filter, reset, collectionId, title }) => {
  const contentTypeTags = [
    { formattedName: 'Assembly', id: 'assembly' },
    { formattedName: 'Blog or Guide', id: 'blog-guide' },
    {
      formattedName: 'Curriculum links and more',
      id: 'curriculum-links-and-more',
    },
    { formattedName: 'Music Activity', id: 'music-activity' },
    { formattedName: 'SEND Resource', id: 'send-resource' },
  ];

  const [filterData, setFilterData] = useState({});

  useEffect(() => {}, [filterData]);

  return (
    <>
      <section className="main__container">
        <div className="assembly__song__header__links">
          <Link to={`/collection/${collectionId}`} className="mr-3 active">
            {title}
          </Link>
          {` > `}
          <span className="pl-3">All resources</span>
        </div>
      </section>
      <div className="main__container bg__blue-nile mt-0">
        <div className="assembly__songfinder">
          <h2 className="assembly__songfinder__title">Find resources</h2>

          <div
            className="assembly__filter"
            style={{ gridTemplateColumns: 'auto 10%' }}
          >
            <SelectSection
              label="Content Type"
              tags={contentTypeTags}
              name="content_type"
              handleTagChange={(value) => {
                setFilterData({ resource_type: value });
              }}
            />

            <SubmitSection
              onClick={() => {
                filter(filterData);
              }}
              reset={reset}
              handleClearFilters={reset}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
