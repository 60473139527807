import { gql } from '@apollo/client';
import { MinAssemblyFieldsFragment } from '../assembly/fragments';
import { PlaylistFieldsFragment } from '../playlist/fragments';
import { MinResourceFragmentFields } from '../resource/fragments';
import {
  MinSongFieldsFragment,
  SparklesFieldsFragment,
} from '../song/fragments';
import { EsongbookFieldsFragments } from '../songbook/fragments';

export const CollectionFieldsFragment = gql`
  fragment CollectionFields on Collection {
    id
    title
    pdfs
    videos
    description
    isUserCollection
    image
    songs {
      ...MinSongFields
    }
    sparkles {
      ...SparklesFields
    }
    resources {
      ...MinResourceFields
    }
    playlists {
      ...PlaylistFields
    }
    esongbooks {
      ...EsongbookFields
    }
    assemblies {
      ...MinAssemblyFields
    }
    # assemblyPlans {
    #   ...AssemblyPlanFields
    # }
  }
  ${SparklesFieldsFragment}
  ${EsongbookFieldsFragments}
  ${MinSongFieldsFragment}
  ${MinResourceFragmentFields}
  ${PlaylistFieldsFragment}
  ${MinAssemblyFieldsFragment}
`;

export const CollectionsFieldsFragment = gql`
  fragment CollectionsFields on Collection {
    id
    title
    pdfs
    isUserCollection
    image
    songs {
      ...MinSongFields
    }
    resources {
      id
    }
    assemblies {
      id
    }
    esongbooks {
      id
    }
    playlists {
      id
    }
  }
  ${MinSongFieldsFragment}
`;

export const CollectionShareLinkFieldsFragment = gql`
  fragment CollectionShareLinkFields on CollectionShareLink {
    id
    uuid
    userId
    collectionId
  }
`;
