import React from 'react';
import { Link } from 'react-router-dom';

const AssemblyPlans = () => {
  const Button = ({ text }) => (
    <a href="#" className="button__md button__white">
      {text}
    </a>
  );

  return (
    <div className="assembly__banner bg__fuchsia">
      <h2 className="assembly__banner__title">Assembly plans</h2>
      <p className="assembly__banner__text">
        A selection of engaging assembly plans for the whole school year, with
        songs, playlists and resources.
      </p>
      <div className="assembly__banner__buttons">
        <Button text="Seasonal" />
        <Button text="Harvest" />
        <Button text="Christmas" />
        <Button text="Easter" />
        <Button text="Collective worship" />
      </div>
      <Link to="/assembly-plans" className="button__md button__white-outline">
        View all assembly plans
      </Link>
    </div>
  );
};

export default AssemblyPlans;
