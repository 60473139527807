import { gql } from '@apollo/client';
import { NotificationFieldsFragment } from './fragments';

export const GET_USER_NOTIFICATIONS = gql`
  query GetUserNotifications($organisationRef: ID) {
    getUserNotifications(organisationRef: $organisationRef) {
      ...NotificationFields
    }
  }
  ${NotificationFieldsFragment}
`;
