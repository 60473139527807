import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { shortenLongDescription } from '../../utils/helpers';
import LikeAndAddIcons from './LikeAddIcons';

const Card = ({
  title,
  description,
  id,
  themePage,
  thumbnail,
  resource,
  slug,
  type,
  history,
}) => {
  const link = resource ? `/resources/${slug}` : `/assembly/${id}`;

  return (
    <div className={`assembly__card ${themePage ? 'theme-page' : ''}`}>
      <a href="" className="assembly__card__thumbnail">
        <img
          src={thumbnail || '/img/assembly-thumbnail.png'}
          alt=""
          style={{ objectFit: 'cover' }}
        />
      </a>
      <div>
        <Tappable
          component="a"
          className="assembly__card__title"
          onTap={() => {
            history.push(link);
          }}
        >
          {title}
        </Tappable>
        <Tappable
          component="a"
          className="assembly__card__text"
          onTap={() => {
            history.push(link);
          }}
        >
          {shortenLongDescription(description, 115)}
        </Tappable>
      </div>
      <div className="assembly__card__cta">
        <LikeAndAddIcons
          headerText="Options"
          darkColor
          modalId="resourceActions"
          type={type}
          favourite
          addToCollection
          itemType={resource ? 'RESOURCE' : 'ASSEMBLY'}
          id={id}
        />
      </div>
    </div>
  );
};

export default withRouter(Card);
