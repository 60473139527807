import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_ANIMATED_ASSEMBLY_MEDIA } from '../../graphql/queries/assembly/assembly';

const useGetAnimatedAssemblyMedia = () => {
  const { data, loading, error } = useQuery(GET_ANIMATED_ASSEMBLY_MEDIA, {
    onCompleted: () => toast.dismiss('loading'),
    onError: () => {
      toast.dismiss('loading');
      toast.error('Could not load media', { toastId: 'mediaError' });
    },
  });

  return { data, loading, error };
};

export default useGetAnimatedAssemblyMedia;
