import { useMutation } from '@apollo/client';
import { UPDATE_PLAYLIST_SONG_ORDERING } from '../../graphql/mutations/playlist';
import { GET_PLAYLIST } from '../../graphql/queries/playlist/playlist';
import { displayErrorMessage } from '../../utils/helpers';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useUpdatePlaylistSongOrdering = (id) => {
  const [updatePlaylistSongOrdering] = useMutation(
    UPDATE_PLAYLIST_SONG_ORDERING,
    {
      refetchQueries: [
        {
          query: GET_PLAYLIST,
          variables: {
            id,
            organisation_ref: organisationLoggedInAs()?.id || null,
          },
        },
      ],
    }
  );

  const updatePlaylistSongOrderingRequest = (input) => {
    updatePlaylistSongOrdering({
      variables: {
        id,
        input: {
          songs: {
            syncWithoutDetaching: input,
          },
        },
      },
    }).catch(() => displayErrorMessage());
  };

  return { updatePlaylistSongOrderingRequest };
};

export default useUpdatePlaylistSongOrdering;
