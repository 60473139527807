import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_RECENNTLY_PLAYED } from '../../graphql/queries/player/player';
import {
  dismissLoadingMessage,
  displayErrorMessage,
} from '../../utils/helpers';

const useGetRecentlyPlayed = (
  number,
  variables = null,
  notifyOnNetworkStatusChange = false
) => {
  const { data, loading, error, fetchMore } = useQuery(GET_RECENNTLY_PLAYED, {
    variables: {
      first: number,
      ...variables,
    },
    onCompleted: () => toast.dismiss('loading'),
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage('Could not load songs');
    },
    notifyOnNetworkStatusChange,
  });

  return { data, loading, error, fetchMore };
};

export default useGetRecentlyPlayed;
