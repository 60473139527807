/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React from 'react';
import { useObserver } from 'mobx-react-lite';
import ReactPlayer from 'react-player';
import { isMobileFullscreen } from '../../../utils/reactiveVars';
import { SongControls } from '../SongControls';

import SlideActions from './SlideActions';
import SongActions from './SongActions';
import Progress from './Progress';
import Footer from './Footer';

type Props = {
  currentSlide: number;
  totalSlides: number;
  player: React.MutableRefObject<ReactPlayer>;
  setIconType: React.Dispatch<React.SetStateAction<'Fullscreen' | 'Minimize'>>;
  isGuest: boolean;
};

const MobileFullscreenDetails = ({
  currentSlide,
  totalSlides,
  player,
  setIconType,
  isGuest,
}: Props): JSX.Element | null => {
  if (!isMobileFullscreen()) return null;

  return useObserver(() => (
    <div className="mobile-fullscreen align-content-between">
      <SlideActions currentSlide={currentSlide} totalSlides={totalSlides} />

      <div className="flex-1">
        <SongActions isGuest={isGuest} />
        <Progress player={player} />
      </div>

      <div className="d-flex justify-content-center flex-1 mt-5">
        <SongControls gridPosition={1} />
      </div>

      <Footer setIconType={setIconType} isGuest={isGuest} />
    </div>
  ));
};

export default MobileFullscreenDetails;
