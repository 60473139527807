import { useQuery } from '@apollo/client';
import { GET_COLLECTION } from '../../graphql/queries/collection/collection';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
} from '../../utils/helpers';

const useGetCollection = (id, organisationRef = null, options = {}) => {
  const { data, loading, error } = useQuery(GET_COLLECTION, {
    variables: {
      id,
      organisation_ref: organisationRef,
    },
    onCompleted: () => dismissLoadingMessage(),
    onError: () => displayErrorMessage(),
  });

  if (loading && !options.loadSilently) {
    displayLoadingMessage('Loading Collection...');
  }

  return { data, loading, error };
};

export default useGetCollection;
