import { gql } from '@apollo/client';
import { ResourceFieldsFragments } from '../song/fragments';
import { MinResourceFragmentFields } from './fragments';

export const GET_RESOURCE = gql`
  query Resource($slug: String!) {
    resource(slug: $slug) {
      ...ResourceFields
    }
  }
  ${ResourceFieldsFragments}
`;

export const GET_RECOMMENDED_RESOURCES = gql`
  query RecommendedResources($first: Int!, $page: Int) {
    recommendedResources(first: $first, page: $page) {
      data {
        ...MinResourceFields
      }
      paginatorInfo {
        currentPage
        lastPage
        count
        hasMorePages
      }
    }
  }
  ${MinResourceFragmentFields}
`;

export const GET_RESOURCES = gql`
  query Resources(
    $first: Int!
    $page: Int
    $where: QueryResourcesWhereWhereConditions
    $hasTags: QueryResourcesHasTagsWhereHasConditions
    $hasCategory: QueryResourcesHasCategoryWhereHasConditions
    $filtered: [TagInput]
  ) {
    resources(
      first: $first
      page: $page
      where: $where
      hasTags: $hasTags
      hasCategory: $hasCategory
      filtered: $filtered
    ) {
      data {
        ...ResourceFields
      }
      paginatorInfo {
        currentPage
        lastPage
        count
        hasMorePages
      }
    }
  }
  ${ResourceFieldsFragments}
`;

export const GET_FAVOURITE_RESOURCES = gql`
  query UserFavouriteResources($organisation_ref: ID) {
    userFavouriteResources(organisation_ref: $organisation_ref) {
      ...MinResourceFields
    }
  }
  ${MinResourceFragmentFields}
`;

export const GET_NEW_SPARKS = gql`
  query NewSparks {
    newSparks {
      id
      resources {
        ...MinResourceFields
      }
    }
  }
  ${MinResourceFragmentFields}
`;
