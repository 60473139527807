/* eslint-disable react/no-danger */
/* eslint-disable react/require-default-props */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import 'styled-components/macro';
import {
  albumArtDefault,
  shortenLongDescription,
  stripHtml,
} from '../../utils/helpers';
import { mediaPlayerSelectedSongState } from '../../utils/reactiveVars';
import useMediaQuery from '../../hooks/Others/useMediaQuery';

const Container = styled.div<{
  gridPosition: number;
  gridPositionMobile?: number;
}>`
  ${tw`flex items-center`}
  ${({ gridPosition }) => gridPosition && `-ms-grid-column:  ${gridPosition}`};
  ${({ gridPosition }) => gridPosition && `grid-column:  ${gridPosition}`};
  ${({ gridPositionMobile }) =>
    gridPositionMobile &&
    `
    @media (max-width: 767px),screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
      grid-column: ${gridPositionMobile};
  }`};
`;

const AlbumCover = styled.img`
  height: 38px;
  width: 38px;
  @media (max-width: 767px),
    screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    display: none;
  }
`;

export const AlbumInfo = styled.div`
  ${tw`h-full flex flex-col justify-around text-white py-4 pl-6`}
  font-size: 12px;
  // width: 60rem;
  @media (max-width: 767px) {
    max-width: 184px;
  }
  @media (max-width: 810px) {
    position: relative;
    width: 100%;
  }
  @media (max-width: 767px),
    screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    max-width: 184px;
  }
`;

type Props = {
  gridPosition: number;
  gridPositionMobile?: number;
  isGuest: boolean;
  history: any;
};

const Song = ({
  gridPosition,
  gridPositionMobile,
  isGuest,
  history,
}: Props) => {
  const songState = useReactiveVar(mediaPlayerSelectedSongState);

  const isNotDesktop = useMediaQuery('(max-width: 810px)');
  const isVerySmallScreen = useMediaQuery('(max-width: 374px)');

  const shouldScrollSongName =
    songState?.data?.song?.infoName.length > 35 && isNotDesktop;

  const textLimit = isVerySmallScreen ? 25 : 35;

  let songName = songState?.data?.song?.infoName;
  songName = shouldScrollSongName
    ? shortenLongDescription(stripHtml(songName), textLimit)
    : songName;

  const SongLink = () => (
    <Tappable
      component="a"
      className="text-white"
      onTap={() => {
        history.push(`/song/${songState.data.song?.id}`);
      }}
    >
      <span
        className="font-weight-bold text__fs-m-8"
        id="songName"
        dangerouslySetInnerHTML={{
          __html: songName,
        }}
      />
    </Tappable>
  );

  const SongDetails = () => {
    // const albumName = shortenLongDescription(
    //   JSON.parse(songState.data.song?.audios[0].metadata || '{}')?.album || '',
    //   30
    // );

    const songbook = shortenLongDescription(
      (songState.data.song as any)?.esongbooks[0]?.infoName || '',
      30
    );

    return (
      <>
        {isGuest ? (
          <>
            <span
              className="font-weight-bold text__fs-m-8 text-white"
              dangerouslySetInnerHTML={{
                __html: songName,
              }}
            />
            <span className="text__fs-m-8 text-white">{songbook}</span>
          </>
        ) : (
          <>
            <SongLink />
            <Tappable
              component="a"
              onTap={() => {
                history.push(
                  `/songbook/${songState.data.song?.esbTrack?.esongbook.id}`
                );
              }}
            >
              <span className="text__fs-m-8 text-white text__underline-hover">
                {songbook}
              </span>
            </Tappable>
          </>
        )}
      </>
    );
  };

  const albumArt = songState.data.song?.albumArt;

  return (
    <Container
      gridPosition={gridPosition}
      gridPositionMobile={gridPositionMobile}
    >
      {isGuest ? (
        <AlbumCover src={albumArt || albumArtDefault} />
      ) : (
        <Tappable
          component="a"
          onTap={() => {
            history.push(
              `/songbook/${songState.data.song?.esbTrack?.esongbook.id}`
            );
          }}
        >
          <AlbumCover src={albumArt || albumArtDefault} />
        </Tappable>
      )}

      <AlbumInfo id="albumInfo">
        {songState.loading ? (
          <span className="text__fs-m-8 my-auto">Loading song...</span>
        ) : (
          <SongDetails />
        )}
      </AlbumInfo>
    </Container>
  );
};

export default withRouter(Song);
