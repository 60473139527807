/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import useRenderResources from '../../../../../../hooks/Resource/useRenderResources';
import { currentSong } from '../../../../../../utils/reactiveVars';
import { formatResourceContent, getCategoryResources, getResourceTags } from '../../../../../../utils/resource';
import CategoryHeader from '../../../../../Shared/CategoryHeader';
import ResourceHeader from '../../../../../Shared/ResourceHeader';
import TagsModule from '../MusicActivity/TagsModule';

const CategoryGroup = ({ title, backgroundColor }) => {
  const { resources } = currentSong();
  const songResources = getCategoryResources(resources, title);

  if (songResources.length < 1) return null;

  return (
    <div className="card__plain mb-4">
      <CategoryHeader title={title} backgroundColor={backgroundColor} />
      <div
        className="card__plain__body plus-padding bg__white"
        style={{ padding: '40px 32px' }}
      >
        {songResources.map((resource, index) => (
          <React.Fragment key={resource.id}>
            <ResourceHeader
              title={resource.title}
              tags={resource.resourceTags}
              id={resource.id}
              categoryName={resource.category?.name}
            />
            {formatResourceContent(resource.content)?.map((item) => (
              <React.Fragment key={item.key}>
                {useRenderResources(item)}
              </React.Fragment>
            ))}
            <TagsModule
              title="Inter-related Dimensions of Music"
              tags={getResourceTags(resource.resourceTags, 'Music Checklist')}
            />
            <TagsModule
              title="National Curriculum coverage for Music"
              tags={getResourceTags(
                resource.resourceTags,
                'National Music Curriculum descriptor'
              )}
            />
            {index + 1 !== songResources.length && (
              <hr className="hr__lime my-4" />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CategoryGroup;
