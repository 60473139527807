import React from 'react';

const Tag = ({ answer, handleChange, questionRef }) => (
  <div className="role__tag">
    <span>{answer}</span>
    <a
      className="role__clear"
      href=""
      onClick={(e) => handleChange(e, questionRef, answer[0], 'REMOVE')}
    >
      <img src="/img/bin-tag.png" alt="remove" />
    </a>
  </div>
);

export default Tag;
