/* eslint-disable operator-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-empty */
import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useAddToCollection from '../../../hooks/Collection/useAddToCollection';
import useGetCollections from '../../../hooks/Collection/useGetCollections';
import useAddToPlaylist from '../../../hooks/Playlist/useAddToPlaylist';
import useGetPlaylists from '../../../hooks/Playlist/useGetPlaylists';
import { modelMap } from '../../../utils/objects';
import {
  addToCollectionItemObj,
  addToPlaylistSongId,
  organisationLoggedInAs,
} from '../../../utils/reactiveVars';
import Loading from '../Loading';
import CreateCollectionModal from './Collection/CreateCollectionModal';
import CreatePlaylistModal from './Playlist/CreatePlaylistModal';
import hideModal, { showModal } from '../../../styles/js/modals';

const AddToPlaylistAndCollectionModal = ({ show, modalId, itemType, id }) => {
  if (!id) return null;
  const organisationRef = useReactiveVar(organisationLoggedInAs)?.id || null;
  const { data: userCollections, loading: loadingUserCollections } =
    useGetCollections('user', organisationRef);

  const { data: playlists, loading: loadingPlaylists } =
    useGetPlaylists('user');

  const { addToCollectionRequest, loading } = useAddToCollection(
    itemType,
    id,
    modalId
  );
  const { addToPlaylistRequest } = useAddToPlaylist(id, modalId);

  const [collectionCheckedList, setCollectionCheckedList] = useState({});
  const [playlistCheckedList, setPlaylistCheckedList] = useState({});

  const stateMap = {
    collection: setCollectionCheckedList,
    playlist: setPlaylistCheckedList,
  };

  const findModelAndUpdateCheckedList = (items, type) =>
    stateMap[type]((prevState) => ({
      ...prevState,
      [items.id]: items[modelMap[itemType]]?.some((item) => item.id === id),
    }));

  useEffect(() => {
    if (userCollections?.userCollections) {
      const collections = [...userCollections?.userCollections];
      setCollectionCheckedList({});
      collections.forEach((collection) =>
        findModelAndUpdateCheckedList(collection, 'collection')
      );
    }
  }, [itemType, id, userCollections]);

  useEffect(() => {
    if (playlists?.playlists) {
      const userPlaylists = [...playlists?.playlists];
      setPlaylistCheckedList({});
      userPlaylists.forEach((playlist) =>
        findModelAndUpdateCheckedList(playlist, 'playlist')
      );
    }
  }, [itemType, id, playlists]);

  loading && toast.info('Please wait...', { toastId: 'loading' });

  const handleAddToCollection = (e) => {
    e.preventDefault();
    const collectionAttachIds = [];
    const collectionDetachIds = [];
    const playlistAttachIds = [];
    const playlistDetachIds = [];

    for (const [key, value] of Object.entries(collectionCheckedList)) {
      value && collectionAttachIds.push(parseInt(key, 10));
      !value && collectionDetachIds.push(parseInt(key, 10));
    }

    for (const [key, value] of Object.entries(playlistCheckedList)) {
      value && playlistAttachIds.push(parseInt(key, 10));
      !value && playlistDetachIds.push(parseInt(key, 10));
    }

    addToCollectionRequest.send(collectionAttachIds, collectionDetachIds);
    if (itemType === 'SONG') {
      addToPlaylistRequest.send(playlistAttachIds, playlistDetachIds);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addToPlaylistAndCollection"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered custom__modal__filter-new"
          role="document"
        >
          <div className="modal-content modal-dialog-scrollable">
            <div className="custom__modal__content">
              <div className="custom__modal__header">
                <h5 className="custom__modal__title">Add to...</h5>
                <a
                  href=""
                  className="custom__modal__close"
                  data-dismiss="modal"
                >
                  <img src="/img/plus-icon.svg" alt="" />
                </a>
              </div>
              {(show === 'both' || show === 'playlist') && (
                <>
                  <h6 className="text__fs-14 text__fw-600 text__black">
                    Playlists
                  </h6>
                  {loadingPlaylists && <Loading />}
                  {playlists?.playlists?.map(({ id: playlistId, name }) => (
                    <div className="checkbox__white mb-12" key={playlistId}>
                      <input
                        key={playlistId}
                        type="checkbox"
                        name={`playlist_${playlistId}`}
                        id={`playlist_${playlistId}`}
                        checked={playlistCheckedList[playlistId]}
                        onChange={(e) => {
                          setPlaylistCheckedList({
                            ...playlistCheckedList,
                            [playlistId]: e.target.checked,
                          });
                        }}
                      />
                      <label htmlFor={`playlist_${playlistId}`}>{name}</label>
                    </div>
                  ))}

                  <a
                    href=""
                    className="text__fs-14 text__blue-curious text__fw-600 mb-5 d-block"
                    onClick={(e) => {
                      e.preventDefault();
                      hideModal(modalId);
                      showModal('createPlaylistModal');
                      addToPlaylistSongId(id);
                    }}
                  >
                    Create New Playlist...
                  </a>
                </>
              )}
              {(show === 'both' || show === 'collection') && (
                <>
                  <h6 className="text__fs-14 text__fw-600 text__black">
                    Collections
                  </h6>
                  {loadingUserCollections && <Loading />}
                  {userCollections?.userCollections?.map(
                    ({ id: collectionId, title }) => (
                      <div className="checkbox__white mb-12" key={collectionId}>
                        <input
                          key={collectionId}
                          type="checkbox"
                          name={`collection_${collectionId}`}
                          id={`collection_${collectionId}`}
                          checked={collectionCheckedList[collectionId]}
                          onChange={(e) => {
                            setCollectionCheckedList({
                              ...collectionCheckedList,
                              [collectionId]: e.target.checked,
                            });
                          }}
                        />
                        <label htmlFor={`collection_${collectionId}`}>
                          {title}
                        </label>
                      </div>
                    )
                  )}

                  <a
                    href=""
                    className="text__fs-14 text__blue-curious text__fw-600 mb-4 d-block"
                    onClick={(e) => {
                      e.preventDefault();
                      hideModal(modalId);
                      showModal('createCollectionModal');
                      addToCollectionItemObj({ id, itemType });
                    }}
                  >
                    Create new collection…
                  </a>
                  <a
                    href=""
                    // data-dismiss="modal"
                    className="button button__turquoise"
                    onClick={(e) => handleAddToCollection(e)}
                  >
                    Add
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <CreateCollectionModal />
      <CreatePlaylistModal />
    </>
  );
};
export default AddToPlaylistAndCollectionModal;
