/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { GET_FAVOURITE_SONGS } from '../../graphql/queries/song/song';
import { dismissLoadingMessage } from '../../utils/helpers';
import { userFavouriteSongs } from '../../utils/reactiveVars';

const useGetFavouriteSongs = () => {
  const [data, setData] = useState([]);
  const { loading, error, fetchMore } = useQuery(GET_FAVOURITE_SONGS, {
    onCompleted: (res) => {
      dismissLoadingMessage();
      const sortedSongs = [...res.userFavouriteSongs].sort((a, b) =>
        a.title.toLowerCase().localeCompare(b.title.toLowerCase(), 'en', {
          sensitivity: 'base',
        })
      );
      userFavouriteSongs(sortedSongs);
      setData({ userFavouriteSongs: sortedSongs });
    },
    onError: () => {},
  });

  return { data, loading, error, fetchMore };
};

export default useGetFavouriteSongs;
