/* eslint-disable operator-linebreak */
// import React from 'react';
import tw, { css, styled } from 'twin.macro';
import 'styled-components/macro';
import { isMobileFullscreen } from '../../utils/reactiveVars';
import { getMapValue } from '../utils';

export interface IButton {
  variant?: string;
  icon?:
    | 'Fullscreen'
    | 'ChangeSong'
    | 'Favourite'
    | 'Favourited'
    | 'Hotspot'
    | 'Pause'
    | 'Play'
    | 'ChangeSlide'
    | 'Volume'
    | 'Minimize'
    | 'Text'
    | 'Vocal'
    | 'Instrumental'
    | 'Fancytext'
    | 'MusicalNote'
    | 'Plaintext';
  onClick?: () => void;
  disabled?: boolean;
  content?: string;
  rotate?: number;
  type?: 'submit' | 'reset' | 'button';
  gridPosition?: number;
  children?: JSX.Element | string;
  bordered?: boolean;
  id?: number;
  active?: boolean;
  hideOnMobile?: boolean;
  showOnMobileFs?: boolean;
  gridPositionMobile?: number;
  mobileDimensions?: number;
  tabletDimensions?: number;
  mobileFullscreenDimensions?: number;
  audioBg?: boolean;
  pulsate?: boolean;
}

const defaultProps = {
  variant: 'default',
  type: 'button',
  rotate: 0,
  content: '',
};

const buttonStyleMap = {
  primary: tw`bg-green-400`,
  secondary: tw`bg-white`,
  default: tw`h-12 w-12 bg-blue-400`,
  media: tw`text-white`,
};

const ButtonBase = styled.button.attrs(({ type = 'button' }: IButton) => ({
  type,
}))(({ disabled }) => [
  tw`text-black`,
  disabled && tw`hover:cursor-default opacity-25`,
]);

export const Button = styled(ButtonBase)<IButton>(
  ({
    variant = defaultProps.variant,
    rotate,
    gridPosition,
    gridPositionMobile,
    bordered,
    active,
    hideOnMobile,
    showOnMobileFs,
    audioBg,
  }) => [
    getMapValue(variant, buttonStyleMap),
    rotate &&
      css`
        transform: rotate(${rotate}deg);
      `,
    gridPosition &&
      css`
        grid-column: ${gridPosition};
        -ms-grid-column: ${gridPosition};
      `,
    css`
      font-size: 13px;
    `,
    bordered &&
      css`
        padding: 4px 11px 5px;
        border-radius: 5px;
        font-family: Arial Regular, Arial, sans-serif;
      `,

    hideOnMobile &&
      css`
        @media (max-width: 767px),
          screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
          display: none;
        }
      `,
    gridPositionMobile &&
      `@media (max-width: 767px),screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
      grid-column: ${gridPositionMobile};
    }`,
    audioBg &&
      css`
        background: #212121;
      `,
    active &&
      css`
        background-color: #40c0c0;
      `,
    showOnMobileFs &&
      isMobileFullscreen() &&
      css`
        @media (max-width: 429px),
          screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
          display: inherit;
        }
      `,
  ]
);

export default Button;
