/* eslint-disable implicit-arrow-linebreak */
import React, { useState } from 'react';
import useUpdateUser from '../../../../hooks/User/useUpdateUser';
import { user } from '../../../../utils/reactiveVars';
import AllDoneButton from './AllDoneButton';
import AreasYoureInterestedIn from './AreasYoureInterestedIn/Index';
import MarketingConsent from './MarketingConsent/Index';
import ModalHeader from './ModalHeader';
import SingWithYourChildren from './SingWithYourChildren/Index';
import WhatIsYourRole from './WhatIsYourRole/Index';
import WhichAgeGroupDoYouTeach from './WhichAgeGroupDoYouTeach/Index';

const OnboardingQuestionsModal = () => {
  const { updateUserRequest } = useUpdateUser();

  const { email, firstname, surname } = user();

  const [showSections, setShowSections] = useState({
    whatIsYourRole: true,
    whichAgeGroupDoYouTeach: false,
    areasYoureInterestedIn: false,
    singWithYourChildren: false,
    allDoneButton: false,
  });

  const [data, setData] = useState({
    roles: [],
    min_age: 0,
    max_age: 0,
    subjects: [],
    categories: [],
    email,
    firstname,
    surname,
    marketing_consent: 1,
  });

  const {
    whatIsYourRole,
    whichAgeGroupDoYouTeach,
    areasYoureInterestedIn,
    singWithYourChildren,
    allDoneButton,
  } = showSections;

  const handleShowSection = (section) =>
    setShowSections({ ...showSections, [section]: true });

  const handleDataChange = (values) => setData({ ...data, ...values });

  const handleSubmit = () => updateUserRequest.send(data);

  return (
    <div
      className="modal fade"
      id="onboardingQuestionsModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="hidePCModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered custom__modal__onboarding-questions"
        role="document"
      >
        <div className="modal-content">
          <div className="custom__modal__content bg__cello px-sm-32 px-32 border-10">
            <ModalHeader />
            {whatIsYourRole && (
              <WhatIsYourRole
                handleShowSection={handleShowSection}
                handleDataChange={handleDataChange}
              />
            )}
            {whichAgeGroupDoYouTeach && (
              <WhichAgeGroupDoYouTeach
                handleShowSection={handleShowSection}
                handleDataChange={handleDataChange}
              />
            )}
            {areasYoureInterestedIn && (
              <AreasYoureInterestedIn
                handleShowSection={handleShowSection}
                handleDataChange={handleDataChange}
              />
            )}
            {singWithYourChildren && (
              <SingWithYourChildren
                handleShowSection={handleShowSection}
                handleDataChange={handleDataChange}
              />
            )}
            <MarketingConsent handleDataChange={handleDataChange} />
            {allDoneButton && <AllDoneButton handleSubmit={handleSubmit} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingQuestionsModal;
