import React from 'react';
import { user } from '../../../../../utils/reactiveVars';

const ModalHeader = ({ sharedLinksCount }) => {
  const { activeSysub } = user();

  return (
    <>
      <div className="custom__modal__header">
        <div className="w-100 pt-2 text-center">
          <h5 className="custom__modal__edit-account__content__title ml-auto">
            MANAGE STUDENT LINKS
          </h5>
          <p className="text__fs-14 mt-3 mr-4 mr-md-0">
            {activeSysub ? (
              <>
                You are sharing {sharedLinksCount} out of 35 available song
                links
              </>
            ) : (
              <>You only have 5 shares available during free trial.</>
            )}
          </p>
        </div>
        <a href="" className="custom__modal__close" data-dismiss="modal">
          <img src="/img/plus-icon.svg" alt="" />
        </a>
      </div>
      <div className="text-left text__fs-14 my-3">
        <p className="text__fw-500 mb-5">
          You can share songs with the students in your class, choir or group
          for them to practise at home.
        </p>
        <p>
          To create a link, find a song and tap the{' '}
          <i className="fa fa-share-alt text__turquoise-medium text__fs-20 mx-3" />
          icon to generate a link.
        </p>
      </div>
      <h5 className="text__fs-18 my-5">Shared song list</h5>
    </>
  );
};

export default ModalHeader;
