import { useMutation } from '@apollo/client';
import { UPDATE_ORGANISATION_LOGO } from '../../graphql/mutations/organisation';
import { GET_ORGANISATION } from '../../graphql/queries/organisation/organisation';
import {
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useUpdateOrganisationLogo = (organisationRef) => {
  const [updateOrganisationLogo, { loading, data, error }] = useMutation(
    UPDATE_ORGANISATION_LOGO,
    {
      refetchQueries: [
        {
          query: GET_ORGANISATION,
          variables: {
            id: organisationRef,
          },
        },
      ],
    }
  );

  const updateOrganisationLogoRequest = (input) => {
    updateOrganisationLogo({
      variables: { input },
    })
      .then((res) => {
        displaySuccessMessage('Logo updated!');
        organisationLoggedInAs({
          ...organisationLoggedInAs(),
          avatar: res.data?.updateOrganisationLogo,
        });
      })
      .catch(() => displayErrorMessage());
  };

  return { updateOrganisationLogoRequest, loading, data, error };
};

export default useUpdateOrganisationLogo;
