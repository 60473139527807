/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import getVideoId from 'get-video-id';
import { currentSong } from '../../../../../utils/reactiveVars';

const VideoModule = () => {
  const { videoUrl, videoTitle } = currentSong();
  if (!videoUrl) return null;

  const embedUrl = getVideoId(videoUrl);
  if (!embedUrl) return null;
  const { service, id } = embedUrl;

  return (
    <div className="songpage__vid">
      <div className="songpage__vid__thumbnail">
        {service === 'youtube' && (
          <iframe
            height="370px"
            src={`https://youtube.com/embed/${id}`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            width="100%"
          />
        )}
        {service === 'vimeo' && (
          <>
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                src={`https://player.vimeo.com/video/${id}`}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            </div>
            <script src="https://player.vimeo.com/api/player.js" />
          </>
        )}
      </div>
      {videoTitle && (
        <div
          className={`songpage__vid__title ${
            service === 'youtube' ? 'mt-n2' : ''
          }`}
        >
          <p>
            <span className="text__fw-600">Video </span> {videoTitle}
          </p>
        </div>
      )}
    </div>
  );
};

export default VideoModule;
