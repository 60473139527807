/* eslint-disable react/no-danger */
import React from 'react';

const TextModule = ({ text }) => (
  <p
    className="summary__text text__black mb-4"
    dangerouslySetInnerHTML={{ __html: text }}
  />
);

export default TextModule;
