/* eslint-disable operator-linebreak */
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { format } from 'date-fns';
import { countries } from 'countries-list';
import {
  loggedInAsPrivateUser,
  subscriptionDetails,
  user,
} from '../../../../../../utils/reactiveVars';
import SubscriptionModalContent from '../../../../../SubscriptionModalContent/SubscriptionModalContent';

const SubscriptionDetails = () => {
  const { sysubs, addresses, hasMultiplePaidSubs } =
    useReactiveVar(subscriptionDetails);

  if (!sysubs?.[0]) {
    if (loggedInAsPrivateUser() && user().activeTrial) {
      return (
        <SubscriptionModalContent message="You are currently on a free trial" />
      );
    }

    return <SubscriptionModalContent message="No subscription found." />;
  }

  if (hasMultiplePaidSubs) {
    return (
      <SubscriptionModalContent
        message="It looks like you have created more than one active subscription for your organisation. Please contact  customer services at info@sparkyard.com so that we can straighten things out for you.
  "
      />
    );
  }

  const firstActiveSub = sysubs?.find((sub) => sub.isActive);

  const {
    isActive,
    dateCreated,
    dateRenewal,
    willRenew,
    scheduledDeactivation,
  } = firstActiveSub || sysubs[0];

  const subscriptionStatus = isActive ? 'Active' : 'Inactive';

  const formatDate = (date) => {
    if (!date) return 'N/A';
    return format(new Date(date * 1000), 'dd/MM/yyyy');
  };

  const Row = ({ title, text }) => (
    <div className="manage-account__subscription-details-row">
      <span className="text__fw-600 pr-3">{title}</span>
      <span className="ml-3 ml-md-0">{text}</span>
    </div>
  );

  const AddressDetails = ({ address }) => {
    const {
      detailCompany,
      detailFirstName,
      detailSurname,
      detailLine1,
      detailTown,
      detailPostcode,
      detailCountry,
    } = address;

    const name = `${detailFirstName} ${detailSurname}`;
    const addressName = `${detailLine1}, ${detailTown} ${detailPostcode}`;
    const country = countries[detailCountry]?.name;

    return (
      <>
        <Row title="Subscription Holder" text={detailCompany} />
        <Row title="Name" text={name} />
        <Row title="Address" text={addressName} />
        <Row title="Country" text={country} />
      </>
    );
  };

  return (
    <div className="col-12 col-md-8 pr-5">
      <div className="text__fs-18 mb-5">
        <span>Current Subscription Status: </span>
        <span className="text__orange">{subscriptionStatus}</span>
      </div>

      <div>
        <Row title="Subscription Started" text={formatDate(dateCreated)} />
        {isActive && willRenew && (
          <Row
            title="Subscription Renewal Date"
            text={formatDate(dateRenewal)}
          />
        )}
        {isActive && !willRenew && (
          <Row
            title="Your subscription will expire on"
            text={formatDate(dateRenewal || scheduledDeactivation)}
          />
        )}
        {addresses[0] && <AddressDetails address={addresses[0]} />}
      </div>
    </div>
  );
};

export default SubscriptionDetails;
