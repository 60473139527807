import React from 'react';

const Header = () => (
  <div className="manage-account__header">
    <img
      src="/img/settings-icon-cyan.svg"
      alt="manage account icon"
      className="song-calendar__header__icon"
    />
    <img
      src="/img/settings-icon-white.svg"
      alt="manage account icon"
      className="mb-2 d-inline-block d-md-none"
    />
    <span className="manage-account__header__text">Manage your account</span>
  </div>
);

export default Header;
