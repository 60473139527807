import React from 'react';
import Loading from '../../Shared/Loading';
import Collection from '../CardCollection';

const SparkyardCollections = ({ collections, isLoading }) => (
  <>
    {isLoading && <Loading />}
    {collections?.map(({ id, title, songs, image }) => (
      <Collection key={id} id={id} title={title} songs={songs} image={image} />
    ))}
  </>
);

export default SparkyardCollections;
