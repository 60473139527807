import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { organisationLoggedInAs } from '../../../utils/reactiveVars';
import LikeAndAddIcons from '../../Shared/LikeAddIcons';

const Subheader = ({ playlist }) => {
  const {
    name,
    id,
    isUserPlaylist,
    type,
    hasSharedWithOrganisation,
    shareId,
    isSharedWithUser,
  } = playlist;

  const sharePlaylist = { hasSharedWithOrganisation, shareId };

  return (
    <>
      <div className="songslist__subheader">
        <h2 className="songslist__subheader__title">{name}</h2>
        <LikeAndAddIcons
          headerText="Playlist options"
          modalId="playlistActions"
          addToCollection
          deletePlaylist={isUserPlaylist}
          editPlaylist={isUserPlaylist}
          id={id}
          itemType="PLAYLIST"
          playlistName={name}
          type={type}
          sharePlaylist={
            isUserPlaylist && useReactiveVar(organisationLoggedInAs)
              ? sharePlaylist
              : null
          }
          canCopyPlaylist={isSharedWithUser}
          hidePC={isSharedWithUser ? 'playlist' : null}
        />
      </div>
    </>
  );
};

export default Subheader;
