import React from 'react';
import Loading from '../../Shared/Loading';
import PlaylistList from '../PlaylistList';
import useUpdatePlaylistsOrdering from '../../../hooks/Playlist/useUpdatePlaylistsOrdering';

const UserPlaylists = ({ playlists, isLoading, showDragIcon }) => {
  const { updatePlaylistsOrderingRequest } = useUpdatePlaylistsOrdering();

  return (
    <>
      {isLoading && <Loading />}
      <PlaylistList
        playlists={playlists}
        orderingFunction={showDragIcon ? updatePlaylistsOrderingRequest : null}
      />
    </>
  );
};

export default UserPlaylists;
