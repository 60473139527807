import React from 'react';

const Header = () => (
  <div className="main__container bg__pumpkin mt-0">
    <div className="singsubject__header">
      <h2 className="singsubject__header__title">Sing in every subject</h2>
      <p className="singsubject__header__text">
        Songs and resources to support the whole-school curriculum and bring
        learning to life.
      </p>
      <div className="singsubject__header__buttons">
        <a href="" className="button__md button__white w-100">
          Music Curriculum
        </a>
      </div>
    </div>
  </div>
);

export default Header;
