/* eslint-disable func-names */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from '@apollo/client';
import {
  ADD_ASSEMBLY_TO_FAVOURITES,
  ADD_RESOURCE_TO_FAVOURITES,
  ADD_SONG_TO_FAVOURITES,
} from '../../graphql/mutations/favourite';
import { GET_FAVOURITE_ASSEMBLIES } from '../../graphql/queries/assembly/assembly';
import { GET_FAVOURITE_RESOURCES } from '../../graphql/queries/resource/resource';
import { GET_FAVOURITE_SONGS } from '../../graphql/queries/song/song';
import { displayErrorMessage } from '../../utils/helpers';
import {
  organisationLoggedInAs,
  userFavouriteAssemblies,
  userFavouriteResources,
  userFavouriteSongs,
} from '../../utils/reactiveVars';

const useAddToFavourites = (type, id) => {
  const organisationRef = organisationLoggedInAs()?.id || null;
  const [addToFavourites, { loading, data }] = useMutation(
    ADD_SONG_TO_FAVOURITES,
    {
      refetchQueries: [
        {
          query: GET_FAVOURITE_SONGS,
        },
      ],
    }
  );

  const [addResourceToFavourites, { data: resources }] = useMutation(
    ADD_RESOURCE_TO_FAVOURITES,
    {
      refetchQueries: [
        {
          query: GET_FAVOURITE_RESOURCES,
        },
      ],
    }
  );

  const [addAssemblyToFavourites, { data: assemmblies }] = useMutation(
    ADD_ASSEMBLY_TO_FAVOURITES,
    {
      refetchQueries: [
        {
          query: GET_FAVOURITE_ASSEMBLIES,
        },
      ],
    }
  );

  const addToFavouriteRequest = {
    send: function () {
      type === 'SONG' && this.addSong();
      type === 'RESOURCE' && this.addResource();
      type === 'ASSEMBLY' && this.addAssembly();
    },
    addSong: () => {
      addToFavourites({
        variables: {
          input: {
            type,
            item_id: parseInt(id, 10),
            organisation_ref: organisationRef,
          },
        },
      })
        .then((res) => {
          userFavouriteSongs(res.data.addSongToFavourite);
        })
        .catch(() => displayErrorMessage());
    },
    addResource: () => {
      addResourceToFavourites({
        variables: {
          input: {
            type,
            item_id: parseInt(id, 10),
            organisation_ref: organisationRef,
          },
        },
      })
        .then((res) => {
          userFavouriteResources(res.data.addResourceToFavourite);
        })
        .catch(() => displayErrorMessage());
    },
    addAssembly: () => {
      addAssemblyToFavourites({
        variables: {
          input: {
            type,
            item_id: parseInt(id, 10),
            organisation_ref: organisationRef,
          },
        },
      })
        .then((res) => {
          userFavouriteAssemblies(res.data.userFavouriteAssemblies);
        })
        .catch(() => displayErrorMessage());
    },
  };

  return { addToFavouriteRequest, data, loading };
};

export default useAddToFavourites;
