/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Select from 'react-select';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import useStores from '../../hooks/useStores';
import Vocal from '../../images/vocal.png';
import Instrumental from '../../images/instrumental.png';
import MusicalNote from '../../images/musical-note.png';
import minimize from '../../images/minimize.png';
import {
  isMobileFullscreen,
  isPlayerFullscreen,
} from '../../../utils/reactiveVars';
import useMediaQuery from '../../../hooks/Others/useMediaQuery';

const Footer = ({
  setIconType,
  isGuest,
  history,
}: {
  setIconType: React.Dispatch<React.SetStateAction<'Fullscreen' | 'Minimize'>>;
  isGuest: boolean;
}): JSX.Element => {
  const { mediaPlayerStore } = useStores();
  const isMobile = useMediaQuery('(max-width: 428px)');

  const getIcon = (infoName: string) => {
    if (infoName === 'Vocal') return Vocal;
    if (infoName === 'Instrumental') return Instrumental;
    return MusicalNote;
  };

  const options = mediaPlayerStore.song.audios.map(({ infoName }, idx) => ({
    label: (
      <div>
        <img
          src={getIcon(infoName)}
          alt={infoName}
          width={22}
          height={22}
          className="d-inline mr-2"
        />
        {infoName}
      </div>
    ),
    value: idx,
  }));

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleChange = (option: { label: JSX.Element; value: number }) => {
    setSelectedOption(option);
    mediaPlayerStore.switchTrack(
      option.value,
      mediaPlayerStore.progress.playedSeconds
    );
  };

  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      background: '#40c0c0',
      color: '#fff',
      border: 'none',
      paddingTop: '6px',
      paddingBottom: '6px',
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        borderColor: state.isFocused ? 'red' : 'blue',
      },
      width: '170px',
      fontSize: '10px',
    }),
    singleValue: () => ({
      color: '#fff',
    }),
    menu: (base: any) => ({
      ...base,
      marginTop: 0,
      paddingTop: 0,
      width: '170px',
      background: '#333',
      opacity: 1,
      zIndex: 200,
      marginBottom: '3px',
    }),
    option: (provided: any) => ({
      ...provided,
      background: 'transparent',
      borderBottom: '1px solid #222',
      padding: 20,
      fontSize: '10px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };

  return (
    <div className="d-flex justify-content-between align-items-end flex-2">
      <Select
        options={options}
        styles={customStyles}
        value={selectedOption}
        onChange={handleChange}
        isSearchable={false}
        menuPlacement="top"
        className="d-inline-block"
      />

      <Tappable
        component="a"
        className={`song-resources align-self-end ${
          isGuest ? 'disable-element' : ''
        }`}
        onClick={() => {
          setIconType('Fullscreen');
          isPlayerFullscreen(false);
          mediaPlayerStore.setShowSlide(false);
          if (isMobile) {
            isMobileFullscreen(false);
          }
          history.push(`/song/${mediaPlayerStore.song.id}`);
        }}
      >
        Song resources
      </Tappable>

      <a
        href=""
        className={`${isGuest ? 'disable-element' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          setIconType('Fullscreen');
          mediaPlayerStore.setShowSlide(!mediaPlayerStore.showSlide);
          isPlayerFullscreen(!isPlayerFullscreen());
          isMobileFullscreen(false);
        }}
      >
        <img src={minimize} alt="minimize" className="minimize" />
      </a>
    </div>
  );
};
export default withRouter(Footer);
