import { gql } from '@apollo/client';

export const SEND_INVITE = gql`
  mutation SendInvite($input: InviteInput!) {
    sendInvite(input: $input)
  }
`;

export const CANCEL_INVITE = gql`
  mutation CancelInvite($input: CancelInviteInput!) {
    cancelInvite(input: $input)
  }
`;

export const EDIT_MEMBER = gql`
  mutation EditMember($input: EditMemberInput!) {
    editMember(input: $input)
  }
`;

export const REMOVE_MEMBER = gql`
  mutation RemoveMember($input: RemoveMemberInput!) {
    removeMember(input: $input)
  }
`;

export const ADD_ADDRESS = gql`
  mutation AddAddress($input: AddAddressInput!) {
    addAddress(input: $input)
  }
`;

export const EDIT_ADDRESS = gql`
  mutation EditAddress($input: EditAddressInput!) {
    editAddress(input: $input)
  }
`;

export const UPDATE_ORGANISATION_LOGO = gql`
  mutation UpdateOrganisationLogo($input: UpdateOrganisationLogoInput!) {
    updateOrganisationLogo(input: $input)
  }
`;

export const ACCEPT_OR_DECLINE_INVITE = gql`
  mutation AcceptOrDeclineInvite($input: AcceptOrDeclineInviteInput!) {
    acceptOrDeclineInvite(input: $input)
  }
`;

export const UPSERT_ORGANISATION_NAME = gql`
  mutation UpsertOrganisationName(
    $syId: ID
    $input: UpdateOrganisationNameInput!
  ) {
    upsertOrganisationName(syId: $syId, input: $input)
  }
`;

export const ACCEPT_OR_DECLINE_JOIN_REQUEST = gql`
  mutation AcceptOrDeclineJoinRequest($input: AcceptOrDeclineJoinRequestInput!) {
    acceptOrDeclineJoinRequest(input: $input)
  }
`;
