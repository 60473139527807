/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { REMOVE_FROM_PLAYLIST } from '../../graphql/mutations/playlist';
import { GET_PLAYLIST } from '../../graphql/queries/playlist/playlist';
import hideModal from '../../styles/js/modals';
import {
  dismissLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { toastErrorObj, toastSuccessObj } from '../../utils/objects';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useRemoveFromPlaylist = (songId, playlistId) => {
  const [removeFromPlaylist, { data }] = useMutation(REMOVE_FROM_PLAYLIST, {
    refetchQueries: [
      {
        query: GET_PLAYLIST,
        variables: {
          id: playlistId,
          organisation_ref: organisationLoggedInAs()?.id || null,
        },
      },
    ],
  });

  const removeFromPlaylistRequest = {
    send: () => {
      removeFromPlaylist({
        variables: {
          input: {
            song_id: parseInt(songId, 10),
            playlist_id: parseInt(playlistId, 10),
          },
        },
      })
        .then((res) => {
          displaySuccessMessage();
          dismissLoadingMessage();
          hideModal();
        })
        .catch((e) => toast.error('Operation failed!', toastErrorObj));
    },
  };

  return { data, removeFromPlaylistRequest };
};

export default useRemoveFromPlaylist;
