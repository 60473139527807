import { useQuery, useReactiveVar } from '@apollo/client';
import {
  GET_PLAYLISTS,
  GET_PLAYLISTS_SHARED_WITH_ORGANISATION,
} from '../../graphql/queries/playlist/playlist';
import {
  dismissLoadingMessage,
  displayErrorMessage,
} from '../../utils/helpers';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useGetPlaylists = (createdBy, variables) => {
  const organisationRef = useReactiveVar(organisationLoggedInAs)?.id || null;

  const includeOrganisationRefForTypes = ['shared', 'user'];
  const isSharedPlaylists = createdBy === 'shared';
  const query = isSharedPlaylists
    ? GET_PLAYLISTS_SHARED_WITH_ORGANISATION
    : GET_PLAYLISTS;

  const { data, loading, error, refetch, fetchMore } = useQuery(query, {
    variables: {
      ...(!isSharedPlaylists && { createdBy }),
      ...(includeOrganisationRefForTypes.includes(createdBy) && {
        organisation_ref: organisationRef,
      }),
      ...variables,
    },
    onCompleted: () => dismissLoadingMessage(),
    onError: () => {
      dismissLoadingMessage();
      // suppress error for shared playlists as organisation ref
      // may not be available on page load yet
      if (!isSharedPlaylists) {
        displayErrorMessage('Could not load playlists');
      }
    },
  });

  return { data, loading, error, refetch, fetchMore };
};

export default useGetPlaylists;
