import React from 'react';
import { withRouter } from 'react-router-dom';
import SimpleCard from '../../Shared/SimpleCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import useMediaQuery from '../../../hooks/Others/useMediaQuery';

const Features = ({ features }) => {
  const isMobile = useMediaQuery('(max-width: 926px)');

  return (
    <div className="px-xl-0 px-3">
      {isMobile ? (
        <div className="home-features-carousel">
          <Swiper spaceBetween={24} slidesPerView={1.4}>
            {features.map((feature, index) => (
              <SwiperSlide key={index} style={{ height: 'auto' }}>
                <SimpleCard
                  image={feature.image}
                  title={feature.title}
                  description={feature.description}
                  link={feature.link}
                  linkTarget={feature.linkTarget}
                  type={feature.type}
                  id={feature.id}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="home-features">
          {features.map((feature, index) => (
            <div key={`feature-${index}`} className="home-features__item">
              <SimpleCard
                image={feature.image}
                title={feature.title}
                description={feature.description}
                link={feature.link}
                linkTarget={feature.linkTarget}
                type={feature.type}
                id={feature.id}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default withRouter(Features);
