import { useMutation } from '@apollo/client';
import { EDIT_MEMBER } from '../../graphql/mutations/organisation';
import { GET_ORGANISATION } from '../../graphql/queries/organisation/organisation';
import {
  dismissErrorMessage,
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';

const useEditMember = (organisationRef) => {
  const [editMember, { loading }] = useMutation(EDIT_MEMBER, {
    refetchQueries: [
      {
        query: GET_ORGANISATION,
        variables: {
          id: organisationRef,
        },
      },
    ],
  });

  const sendEditMemberRequest = (input) => {
    dismissErrorMessage();

    editMember({
      variables: {
        input,
      },
    })
      .then(() => {
        dismissLoadingMessage();
        displaySuccessMessage();
      })
      .catch(() => {
        dismissLoadingMessage();
        displayErrorMessage();
      });
  };

  loading && displayLoadingMessage('Please wait...');

  return { sendEditMemberRequest, loading };
};

export default useEditMember;
