/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import { constantCase, sentenceCase } from 'change-case';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import FilterSection from '../../components/Search/Sections/FilterSection';
import SearchHeader from '../../components/Search/Sections/SearchHeader';
import SearchResultList from '../../components/Search/Sections/SearchResultList';
// import SortSongs from '../../components/Songs/Sections/SortSongs';
import { SEARCH } from '../../graphql/mutations/search';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  matchesAgeRange,
} from '../../utils/helpers';
import { searchTypeTexts } from '../../constants/global';

const Search = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, { data: { search: { count = {} } = {} } = {}, loading }] =
    useMutation(SEARCH);
  const [originalList, setOriginalList] = useState({});
  const [searchResultList, setSearchResultList] = useState({});
  const location = useLocation();
  const queryStrings = queryString.parse(location.search);
  const { text } = queryStrings;

  const history = useHistory();

  loading && displayLoadingMessage();

  const handleFilter = async (filterData) => {
    const searchTypes =
      filterData.resource_types?.map((resource_type) =>
        encodeURIComponent(constantCase(resource_type))
      ) || [];

    const queryParams = [
      `text=${encodeURIComponent(text)}`,
      `minAge=${filterData.min_age}`,
      `maxAge=${filterData.max_age}`,
    ];

    searchTypes.forEach((element) => {
      queryParams.push(`types[]=${element}`);
    });

    history.push(`/search?${queryParams.join('&')}`);
  };

  const doSearch = {
    send: async (variables) => {
      await search({
        variables,
      })
        .then((res) => {
          let { __typename, ...datas } = res.data.search;

          setOriginalList(datas);

          dismissLoadingMessage();
        })
        .catch(() => {
          dismissLoadingMessage();
          displayErrorMessage('We are unable to search at this time.');
        });
    },
  };

  const filterResults = useCallback(
    (minAge, maxAge, types) => {
      let datas = { ...originalList };

      // filter by age
      if (minAge) {
        const keys = Object.keys(datas);

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (datas[key]) {
            datas[key] = {
              ...datas[key],
              results: datas[key].results.filter((resource) =>
                matchesAgeRange(
                  minAge,
                  maxAge,
                  resource.minAge,
                  resource.maxAge
                )
              ),
            };
          }
        }
      }

      // filter by resource types
      if (Array.isArray(types) && types.length >= 1) {
        const keys = Object.keys(searchTypeTexts);

        types.sort(function (a, b) {
          return keys.indexOf(a) - keys.indexOf(b);
        });

        const results = {};

        types.forEach((element) => {
          results[element] = datas[element];
        });

        datas = results;
      }

      setSearchResultList(datas);
    },
    [originalList]
  );

  useEffect(() => {
    if (text === '') {
      displayErrorMessage('You must provide at least one word!');
    } else {
      doSearch.send({
        text,
      });
    }
  }, [text]);

  useEffect(() => {
    const queryStrings = queryString.parse(location.search);
    const { minAge, maxAge } = queryStrings;

    filterResults(
      minAge,
      maxAge,
      queryStrings['types[]'] && Array.isArray(queryStrings['types[]'])
        ? queryStrings['types[]']
        : queryStrings['types[]']
        ? [queryStrings['types[]']]
        : null
    );
  }, [filterResults, location]);

  const resultComponents = useMemo(() => {
    const localResultComponents = [];
    const keys = Object.keys(searchResultList);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (searchResultList[key] && searchResultList[key].results.length >= 1) {
        localResultComponents.push(
          <div key={i} className="mb-5">
            <h3 className="text-uppercase mb-3">{searchTypeTexts[key]}</h3>
            <SearchResultList
              searchResults={searchResultList[key].results}
              searchText={text}
            />
          </div>
        );
      }
    }
    return localResultComponents;
  }, [searchResultList]);

  return (
    <>
      <SearchHeader />

      <FilterSection
        handleFilter={handleFilter}
        searchText={text}
        tags={Object.keys(originalList)
          .filter((key) => originalList[key]?.results.length >= 1)
          .map((key) => {
            return {
              value: key,
              label: searchTypeTexts[key],
            };
          })}
      />

      <section className="main__container">
        {/* <SortSongs sortSongs={sort} /> */}
        {loading ? (
          <div className="text-center px-2 py-2" key={0}>
            Loading ...
          </div>
        ) : (
          <div>
            {resultComponents.length >= 1 ? (
              resultComponents
            ) : (
              <div>No results available</div>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default withPageWrapper({})(Search);
