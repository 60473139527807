/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-assign */
import { useLocalStore } from 'mobx-react-lite';
import {
  Album,
  AlbumDefaultState,
  CustomSong,
  Progress,
  ProgressDefaultState,
  Page,
  Phrase,
  PageDefaultState,
  PhraseDefault,
  JumpTo,
  JumpToDefault,
  songSrcOne,
  PlayerHit,
  PlayerHitDefault,
} from '../types';
import songData from '../temp/songs.json';
import { currentSongTypeId, playerActive } from '../../utils/reactiveVars';
import Decimal from 'decimal.js';

export type MediaPlayerStoreType = {
  album: Album;
  playing: boolean;
  duration: number;
  volume: number;
  currentSrc: string;
  seeking: boolean;
  jumpTo: JumpTo;
  showSlide: boolean;
  plainTextView: boolean;
  page: Page;
  phrase: Phrase;
  progress: Progress;
  playlist: CustomSong[];
  song: CustomSong;
  ready: boolean;
  shareId: number | null;
  playerHit: PlayerHit;
  shouldRecordHit: boolean;
  shouldUpdateHit: boolean;
  error: boolean;
  lastPlayheadPositionMs: number;
  currentPlayheadPositionMs: number;
  togglePlainTextView: () => void;
  enablePlainTextView: () => void;
  enableFancyTextView: () => void;
  switchSong: (backwards: boolean) => void;
  setShowSlide: (toggle: boolean) => void;
  resetJumpTo: () => void;
  setJumpTo: ({
    phraseStarts,
    phraseEnds,
    playSinglePhrase,
    newPhraseID,
  }: {
    phraseStarts: number;
    phraseEnds?: number;
    playSinglePhrase: boolean;
    newPhraseID?: number;
  }) => void;
  setPlaying: (playing: boolean) => void;
  setDuration: (duration: number) => void;
  setVolume: (volume: number) => void;
  setSong: (song: string) => void;
  setProgress: (progress: Progress) => void;
  setSeeking: (isSeeking: boolean) => void;
  setPage: (page: Page) => void;
  switchTrack: (idx: number, playedSeconds: number) => void;
  switchSlide: (offset: number, currentSlide: number) => void;
  setPlaylist: (songs: CustomSong[]) => void;
  setReady: (ready: boolean) => void;
  setShareId: (shareId: number | null) => void;
  setPlayerHit: (hit: PlayerHit) => void;
  setShouldRecordHit: (value: boolean) => void;
  setShouldUpdateHit: (value: boolean) => void;
  setError: (value: boolean) => void;
  setLastPlayheadPositionMs: (value: number) => void;
  setCurrentPlayheadPositionMs: (value: number) => void;
};

export const MediaPlayerStore = (): MediaPlayerStoreType => {
  const store: MediaPlayerStoreType = useLocalStore<MediaPlayerStoreType>(
    () => ({
      album: AlbumDefaultState,
      playing: false,
      duration: 0,
      volume: 0.5,
      currentSrc: '',
      seeking: false,
      jumpTo: JumpToDefault,
      page: PageDefaultState,
      phrase: PhraseDefault,
      progress: ProgressDefaultState,
      playlist: AlbumDefaultState.songs,
      showSlide: false,
      song: { ...songData.songs[0], src: songSrcOne },
      plainTextView: false,
      ready: false,
      shareId: null,
      playerHit: PlayerHitDefault,
      shouldRecordHit: true,
      shouldUpdateHit: true,
      error: false,
      lastPlayheadPositionMs: 0,
      currentPlayheadPositionMs: 0,
      togglePlainTextView: () => (store.plainTextView = !store.plainTextView),
      enablePlainTextView: () => (store.plainTextView = true),
      enableFancyTextView: () => (store.plainTextView = false),
      switchSong: (backwards: boolean) => {
        // NOTE: this is all done very directly,
        // will be reducing down the repeat in the storage of songs
        // as store.playlist isn't currently in use etc.
        if (backwards && store.progress.playedSeconds > 0.25) {
          const previousPlayingState = store.playing;
          store.setLastPlayheadPositionMs(
            Math.round(store.progress.playedSeconds * 1000)
          );
          store.setJumpTo({ phraseStarts: 1, playSinglePhrase: false });
          store.playing = true;
          store.playing = previousPlayingState;
          store.setCurrentPlayheadPositionMs(0);
          return;
        }
        store.setJumpTo({ phraseStarts: 1, playSinglePhrase: false });
        setTimeout(() => {
          // if (store.song.id === 1765) {
          //   store.song = { ...songData.songs[1], src: songSrcTwo };
          //   store.currentSrc = songSrcTwo[0];
          // } else {
          //   store.song = { ...songData.songs[0], src: songSrcOne };
          //   store.currentSrc = songSrcOne[0];
          // }

          store.phrase = PhraseDefault;
        }, 1000);
      },
      switchTrack: (idx, playedSeconds) => {
        if (store.playing) store.setShouldRecordHit(false);
        store.setSong(store.song.src[idx]);
        store.setLastPlayheadPositionMs(Math.round(playedSeconds * 1000));
        store.setCurrentPlayheadPositionMs(Math.round(playedSeconds * 1000));
        store.setJumpTo({
          phraseStarts: +new Decimal(playedSeconds).times(1000),
          playSinglePhrase: false,
        });

        // this is for safari as it restarts playback when new audio is loaded
        // set player to playedSeconds timestamp if it is greater than the current timestamp
        // setTimeout(() => {
        //   if (playedSeconds > store.progress.playedSeconds) {
        //     store.setJumpTo({
        //       phraseStarts: playedSeconds * 1000,
        //       playSinglePhrase: false,
        //     });
        //     store.setProgress({
        //       played: playedSeconds * 1000,
        //       playedSeconds,
        //       loaded: playedSeconds * 1000,
        //       loadedSeconds: playedSeconds,
        //     });
        //   }
        // }, 2000);

        currentSongTypeId(idx);
      },
      switchSlide: (offset, currentSlide) => {
        if (offset === -1 && currentSlide === 0) return;
        const previoudPlayingState = store.playing;
        store.setPlaying(true);
        store.setLastPlayheadPositionMs(
          Math.round(store.progress.playedSeconds * 1000)
        );
        const millisecondsStart =
          store.song.pages[currentSlide + offset].millisecondsStart;
        store.setJumpTo({
          phraseStarts: millisecondsStart,
          playSinglePhrase: false,
        });
        store.setCurrentPlayheadPositionMs(Math.round(millisecondsStart));
        store.setPlaying(previoudPlayingState);
      },
      setShowSlide: (toggle) => {
        store.showSlide = toggle;
      },
      resetJumpTo: () => {
        store.jumpTo = JumpToDefault;
      },
      setJumpTo: ({
        phraseStarts,
        phraseEnds = -1,
        playSinglePhrase,
        newPhraseID = 0,
      }) => {
        const date = new Date();
        store.jumpTo = {
          phraseStarts: phraseStarts || 1,
          phraseEnds,
          setAt: date,
          complete: !playSinglePhrase,
          playSinglePhrase,
          newPhraseID,
        };
      }, // time defaults to 1ms as react-player can't jumpTo(0)
      setPlaying: (playing: boolean) => (store.playing = playing),
      setDuration: (duration) => (store.duration = duration),
      setVolume: (volume) => (store.volume = volume),
      setSong: (song: string) => (store.currentSrc = song),
      setProgress: (progress) => (store.progress = progress),
      setSeeking: (isSeeking) => (store.seeking = isSeeking),
      setPage: (page: Page) => (store.page = page),
      setPlaylist: (songs) => (store.playlist = songs),
      setReady: (ready) => (store.ready = ready),
      setShareId: (id) => (store.shareId = id),
      setPlayerHit: (hit) => (store.playerHit = hit),
      setShouldRecordHit: (value) => (store.shouldRecordHit = value),
      setShouldUpdateHit: (value) => (store.shouldUpdateHit = value),
      setError: (value) => (store.error = value),
      setLastPlayheadPositionMs: (value: number) =>
        (store.lastPlayheadPositionMs = value),
      setCurrentPlayheadPositionMs: (value) =>
        (store.currentPlayheadPositionMs = value),
    })
  );
  if (playerActive()) store.setPlaying(true);
  return store;
};
