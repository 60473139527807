import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

const MultiFilterSection = ({ tags, onChange, value = [] }) => {
  const [items, setItems] = useState(value);

  useEffect(() => {
    setItems(value);
  }, [value]);

  const pushData = (data) => {
    if (data === 'all') {
      const newItems = [];
      setItems(newItems);
      onChange(newItems);
    } else {
      const newItems = [...(items || [])];
      const index = items?.findIndex((item) => item === data);

      if (index >= 0) {
        newItems.splice(index, 1);
      } else {
        newItems.push(data);
      }

      setItems(newItems);
      onChange(newItems);
    }
  };

  return (
    <div className="filter-pills">
      <div className="filter-pills__content">
        <button
          type="button"
          className={classNames('button', {
            active: items?.length <= 0,
          })}
          onClick={() => pushData('all')}
        >
          All
        </button>
        {tags &&
          tags.map((tag) => {
            const buttonClassName = classNames('button', {
              active: items?.findIndex((item) => item === tag.value) >= 0,
            });

            return (
              <button
                type="button"
                key={tag.value}
                className={buttonClassName}
                onClick={() => pushData(tag.value)}
              >
                {tag.label}
              </button>
            );
          })}
      </div>
    </div>
  );
};

MultiFilterSection.displayName = 'MultiFilterSection';

export default MultiFilterSection;
