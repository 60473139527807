import React from 'react';
import { showModal } from '../../../../styles/js/modals';

const Share = ({ show }) => {
  if (!show) return null;

  return (
    <div className="mb-20 text__black text__fs-16">
      <a
        href=""
        className="text__black"
        data-dismiss="modal"
        id="share"
        onClick={(e) => {
          e.preventDefault();
          showModal('share-modal');
        }}
      >
        <i className="fa fa-share-alt text__turquoise-medium text__fs-20 mr-4" />{' '}
        Share
      </a>
    </div>
  );
};

export default Share;
