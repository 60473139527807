import React from 'react';
import { toast } from 'react-toastify';
import useGetCategoryPage from '../../../../hooks/useGetCategoryPage';
import GuidesAndResources from '../../../Shared/GuidesAndResources';
import PlaylistCards from '../../../Shared/PlaylistCards';
import SongCards from '../../../Shared/SongCards/Index';

const MainSection = ({
  songs,
  resources,
  displayResources = true,
  displaySongs = true,
  displayPlaylists = true,
  filterParams,
}) => {
  const { data, loading } = useGetCategoryPage('sing for fun');
  loading && toast.info('Loading...', { toastId: 'loading' });

  return (
    <section className="main__container mt-40">
      <div className="px__sm-3">
        {displaySongs && (
          <SongCards
            title="Community Songs"
            songs={songs}
            pageTitle="Community Songs"
            name="Community/Charity"
            type="OOTA Singing School"
            filterParams={filterParams}
          />
        )}
        {displayResources && (
          <GuidesAndResources
            resources={resources}
            title="Community/Charity"
            type="OOTA Singing School"
          />
        )}
        {displayPlaylists && (
          <PlaylistCards
            playlists={data?.categoryPage?.playlists}
            title="Community playlists"
            disableDeletePlaylist
          />
        )}
      </div>
    </section>
  );
};

export default MainSection;
