/* eslint-disable react/no-danger */
import React from 'react';
import { Link } from 'react-router-dom';
import { albumArtDefault } from '../../../../utils/helpers';
import PlaySong from '../../../Shared/PlaySong';

const Song = ({ title: songTitle, id, albumArt }) => (
  <div className="col-6 mb-15">
    <div className="assembly__song__card">
      <div className="assembly__song__card__thumbnail">
        <img
          className="assembly__song__card__img"
          src={albumArt || albumArtDefault}
          alt=""
        />
        <PlaySong id={id} />
      </div>
      <Link to={`/song/${id}`}>
        <p
          className="assembly__song__card__title text__fs-12"
          dangerouslySetInnerHTML={{ __html: songTitle }}
        />
      </Link>
    </div>
  </div>
);

export default Song;
