import React from 'react';
import Footer from './Footer';
import ModalBody from './ModalBody/Index';
import ModalHeader from './ModalHeader';

const ManageSubscriptionModal = () => (
  <div
    className="modal fade"
    id="manageSubscriptionModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="manageSubscriptionModal"
    aria-hidden="true"
  >
    <div
      className="modal-dialog modal-dialog-centered custom__modal__invite-colleagues"
      role="document"
    >
      <div className="modal-content custom__modal__leave-organization__content">
        <ModalHeader />
        <ModalBody />
        <Footer />
      </div>
    </div>
  </div>
);

export default ManageSubscriptionModal;
