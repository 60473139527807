/* eslint-disable operator-linebreak */
import React, { forwardRef } from 'react';
import { returnNumberRange } from '../../../utils/helpers';

const AgeRangeSelect = forwardRef(({ name, handleAgeRangeChange }, ref) => {
  const range =
    name === 'min_age' ? returnNumberRange(3, 13) : returnNumberRange(4, 14);

  return (
    <select
      name={name}
      className="filter__select-lg"
      defaultValue={name === 'min_age' ? 5 : 11}
      ref={ref}
      onChange={(e) => handleAgeRangeChange(name, e.target.value)}
    >
      {range.map((num) => (
        <option value={num} key={num}>
          {num}
        </option>
      ))}
    </select>
  );
});

AgeRangeSelect.displayName = 'AgeRangeSelect';

export default AgeRangeSelect;
