/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Link } from 'react-router-dom';
import PickAndMix from './PickAndMix';
import Plan from './Plan';

const MainSection = ({ lessonPlan }) => (
  <div className="cp__container">
    {lessonPlan.pickAndMix ? (
      <PickAndMix lessonPlan={lessonPlan} />
      ) : (
        <Plan lessonPlan={lessonPlan} />
      )}

    <Link to="/music-curriculum" className="p-3 px-lg-0 text__white d-flex">
      <i className="fa fa-chevron-left mr-3" />
      <p className="text__fs-14">Back to Music Curriculum</p>
    </Link>
  </div>
  );
export default MainSection;
