import React from 'react';
import AudioBar from '../../components/Resources/AudioBar';
import Video from '../../components/Shared/Video/Video';
import ImageModule from '../../components/Song/Sections/SongsPageContent/Modules/ImageModule';
import TextModule from '../../components/Song/Sections/SongsPageContent/Modules/TextModule';
import IconModule from '../../components/Shared/IconModule';
import SoundcloudAudio from '../../components/Shared/SoundcloudAudio';

const useRenderResources = (item) => {
  if (item.layout === 'wysiwyg') {
    return (
      <TextModule
        text={item.attributes.body}
        key={item.key}
        noMargin={item.attributes.noMargin}
      />
    );
  }
  if (item.layout === 'image') {
    return (
      <div className="mx-lg-n32 mx-sm-n16">
        <ImageModule
          url={item.attributes.image}
          title={item.attributes.title}
          key={item.key}
          noMargin={item.attributes.noMargin}
          fullWidth
          resourcePage
        />
      </div>
    );
  }
  if (item.layout === 'icon-bullet') {
    return (
      <IconModule
        iconUrl={item.attributes.image}
        title={item.attributes.title}
        text={item.attributes.body}
        key={item.key}
        noMargin={item.attributes.noMargin}
      />
    );
  }
  if (item.layout === 'video') {
    return (
      <div className="card-nt">
        <Video
          url={item.attributes.url}
          title={item.attributes.title}
          noMargin={item.attributes.noMargin}
        />
      </div>
    );
  }
  if (item.layout === 'audio') {
    return (
      <AudioBar
        title={item.attributes.title}
        url={item.attributes.audio}
        noMargin={item.attributes.noMargin}
      />
    );
  }

  if (item.layout === 'soundcloud-audio') {
    return (
      <SoundcloudAudio
        url={item.attributes.soundcloud_link}
        noMargin={item.attributes.noMargin}
      />
    );
  }
};

export default useRenderResources;
