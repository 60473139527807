import React from 'react';
import AccountSegment from './AccountSegment/AccountSegment';
import OrganisationsSegment from './OrganisationsSegment/Index';

const MainSection = () => (
  <>
    <AccountSegment />
    <hr className="hr__grey" />
    <OrganisationsSegment />
  </>
);

export default MainSection;
