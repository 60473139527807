import { useReactiveVar } from '@apollo/client';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import useCreateCollection from '../../hooks/Collection/useCreateCollection';
import { displayLoadingMessage } from '../../utils/helpers';
import {
  addToCollectionItemObj,
  organisationLoggedInAs,
} from '../../utils/reactiveVars';
import FormError from '../Shared/FormError';

const CreateCollection = () => {
  const [state, setState] = useState(
    'dropdown-menu dropdown-menu-right p-25 text__fs-14 mycollections__create'
  );

  const closeDropdown = () =>
    setState(
      'dropdown-menu dropdown-menu-right p-25 text__fs-14 mycollections__create hide'
    );

  const { register, handleSubmit, errors, reset } = useForm();
  const { createCollectionRequest, loading } = useCreateCollection(
    reset,
    closeDropdown
  );

  const loggedIntoOrganisation = useReactiveVar(organisationLoggedInAs);
  const shareWithOrganisationStatus = loggedIntoOrganisation
    ? ''
    : 'disable-element';

  const handleCreateCollection = ({ title, description, share }) => {
    const shareWithOganisation = loggedIntoOrganisation && share;
    const organisationRef = shareWithOganisation
      ? loggedIntoOrganisation.id
      : null;

    createCollectionRequest.send(title, description, organisationRef);
  };

  loading && displayLoadingMessage();

  return (
    <div className="dropdown">
      <button
        className="btn btn-lg button__turquoise ml-auto"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => {
          setState(
            'dropdown-menu dropdown-menu-right p-25 text__fs-14 mycollections__create show'
          );
          addToCollectionItemObj({});
        }}
      >
        Create Collection
      </button>
      <div className={state}>
        <form>
          <div className="mb-5">
            <div className="d-flex justify-content-between mb-4">
              <h3>Create collection</h3>
              <i
                className="fas fa-times text__fs-20"
                onClick={() =>
                  setState(
                    'dropdown-menu dropdown-menu-right p-25 text__fs-14 mycollections__create hide'
                  )
                }
              />
            </div>
            <p>Enter a name for your new collection below</p>
          </div>
          <div className="input__control">
            <div className="mb-20">
              <input
                type="text"
                name="title"
                placeholder="Collection name"
                className="text-dark"
                ref={register({ required: true })}
              />
              {errors.title && <FormError text="required" />}
            </div>

            <div className="mb-5">
              <textarea
                placeholder="Collection description"
                className="w-100 text-dark"
                rows="5"
                name="description"
                ref={register({ required: true })}
              />
              {errors.description && <FormError text="required" />}
            </div>

            <div
              className={`checkbox__white checkbox__turquoise-medium mb-12 ${shareWithOrganisationStatus}`}
            >
              <input
                type="checkbox"
                name="share"
                id="share"
                ref={register({ required: false })}
              />
              <label htmlFor="share" className="text__fs-14">
                Share with my organization
              </label>
            </div>
            <button
              className="button button__md button__turquoise"
              onClick={handleSubmit(handleCreateCollection)}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCollection;
