import React from 'react';
import Body from './Body/Index';
import Header from './Header';

const RelatedGuidesAndResources = ({ resources, title }) => {
  if (resources.length < 1) return null;

  return (
    <>
      <Header resources={resources} title={title} />
      <Body resources={resources} />
    </>
  );
};

export default RelatedGuidesAndResources;
