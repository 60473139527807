/* eslint-disable operator-linebreak */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { songbookList, songChoiceSongs } from '../../utils/reactiveVars';
import SongbookItem from '../Shared/Songbook/SongbookItem';
import NativitiesAndMusicalsItem from './NativitiesAndMusicalItem';
import NeedMoreMusicals from './Sections/NeedMoreMusicals';

const NativitiesAndMusicalsCards = ({
  title,
  musicals,
  songbooks,
  songs,
  type,
}) => {
  const shouldHideSeeAllLink =
    (!songbooks || songbooks?.length < 1) && (!songs || songs?.length < 1);

  const seeAllLinkClassName = `text__white text__fs-10 ${
    shouldHideSeeAllLink ? 'd-none' : ''
  }`;

  const itemsToDisplayCount = musicals ? 4 : 5;
  const location = useLocation();
  const hasSong = songs?.length > 0;
  const hasSongbook = songbooks?.length > 0;
  const songChoiceDefault = {
    path: '',
    pageTitle: '',
    songs: null,
    type: '',
  };
  const songbookListDefault = {
    songbooks: null,
    type: '',
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-20 px-xl-0 px-3">
        <p className="heading__section text-uppercase">{title}</p>
        <Link
          to={hasSong ? '/song-choice' : '/see-all/songbooks'}
          className={seeAllLinkClassName}
          onClick={() => {
            if (hasSong) {
              songChoiceSongs({
                path: location.pathname,
                pageTitle: title,
                songs,
                type,
              });
            } else if (hasSongbook) {
              songbookList({ songbooks, type });
            } else {
              songChoiceSongs(songChoiceDefault);
              songbookList(songbookListDefault);
            }
          }}
        >
          SEE ALL
        </Link>
      </div>
      <div className="px-xl-0 px-3">
        <div className="row mb-20">
          {hasSong &&
            songs
              .slice(0, itemsToDisplayCount)
              .map((song) => (
                <NativitiesAndMusicalsItem key={song.id} song={song} />
              ))}

          {hasSongbook &&
            songbooks
              .slice(0, itemsToDisplayCount)
              .map(({ id, albumArt, infoName, imageFileMedium }) => (
                <SongbookItem
                  key={id}
                  id={id}
                  title={infoName}
                  src={albumArt}
                  imgMedium={imageFileMedium}
                  nativitiesAndMusicals
                  large
                />
              ))}
          {musicals && <NeedMoreMusicals />}
        </div>
      </div>
    </>
  );
};

export default NativitiesAndMusicalsCards;
