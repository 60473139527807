/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';

const TreatBox = ({ location, history }) => (
  <li className="sidebar__list__item d-none d-md-block">
    <Tappable
      component="a"
      className="sidebar__list__item__link "
      onTap={() => {
        history.push('/treat-box');
      }}
    >
      <img src="/img/treatbox-icon-present.svg" alt="" />
      <p className={location.pathname === '/treat-box' ? 'text__white font-weight-bold' : ''}>
        Treat Box!
      </p>
    </Tappable>
  </li>
);

export default withRouter(TreatBox);
