import React from 'react';
import Header from '../../components/NativitiesAndMusicals/Sections/Header';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import MainSection from '../../components/NativitiesAndMusicals/Sections/MainSection';

const NativitiesAndMusicals = () => (
  <>
    <Header />
    <MainSection />
  </>
);

export default withPageWrapper({ pageTitle: 'All Nativities And Musicals | Sparkyard' })(NativitiesAndMusicals);
