/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React, { useState } from 'react';
import FilterSection from '../../components/CategoryPages/Assemblies/Sections/FilterSection';
import Header from '../../components/CategoryPages/Assemblies/Sections/Header';
import MainContent from '../../components/CategoryPages/Assemblies/Sections/MainContent/Index';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useGetPlaylists from '../../hooks/Playlist/useGetPlaylists';
import useGetResources from '../../hooks/Resource/useGetResources';
import useGetSongs from '../../hooks/Song/useGetSongs';
import useGetSongbooks from '../../hooks/Songbook/useGetSongbooks';
import { returnHasTags } from '../../utils/helpers';
import { updateQuery } from '../../utils/objects';
import { buildResourceParams } from '../../utils/resource';

const Assemblies = () => {
  const [displaySongs, setDisplaySongs] = useState(true);
  const [displayResources, setDisplayResources] = useState(true);
  const [displayPlaylists, setDisplayPlaylists] = useState(true);
  const [displaySongbooks, setDisplaySongbooks] = useState(true);
  const [displaySongVideos, setDisplaySongVideos] = useState(true);
  const [displayAssemblies, setDisplayAssemblies] = useState(true);
  const [filterParams, setFilterParams] = useState({});

  const { data: classAssembly, fetchMore: fetchMoreClassAssemblies } =
    useGetSongbooks({
      limit: 20,
      classAssembly: true,
    });

  const { data: playlists, fetchMore: fetchMorePlaylists } = useGetPlaylists(
    'sy',
    {
      hasCategoryPages: {
        column: 'NAME',
        value: 'Assemblies',
      },
    }
  );

  const {
    data,
    loading: loadingSongs,
    fetchMore: fetchMoreSongs,
  } = useGetSongs(
    6,
    returnHasTags('hasSongTags', 'OOTA Singing School', 'Assembly', true),
    true
  );

  const { data: signingVideos, fetchMore: fetchMoreSongVideos } = useGetSongs(
    6,
    returnHasTags('hasSongTags', 'Has Video', 'Yes', true),
    true
  );

  const {
    data: resources,
    loading: loadingResources,
    fetchMore: fetchMoreResources,
  } = useGetResources(
    2,
    returnHasTags('hasTags', 'OOTA Singing School', 'Assembly')
  );

  const handleFilter = async (params) => {
    const resourceParams = buildResourceParams(params);
    fetchMorePlaylists({
      variables: {
        filtered: params.filtered,
      },
      ...updateQuery,
    });
    fetchMoreClassAssemblies({
      variables: {
        filtered: params.filtered,
      },
      ...updateQuery,
    });
    fetchMoreSongs({
      variables: { ...params },
      ...updateQuery,
    });
    fetchMoreResources({
      variables: { ...resourceParams },
      ...updateQuery,
    });
    fetchMoreSongVideos({
      variables: { ...params },
      ...updateQuery,
    });
    setDisplaySongs(true);
    setDisplayResources(true);
    setDisplayPlaylists(true);
    setDisplaySongbooks(true);
    setDisplaySongVideos(true);
    setDisplayAssemblies(true);
    setFilterParams(params);
  };

  const loading = loadingResources || loadingSongs;

  return (
    <>
      <Header />
      <FilterSection handleFilterSongs={handleFilter} loading={loading} />
      <MainContent
        songs={data?.songs?.data}
        resources={resources?.resources?.data}
        signingVideos={signingVideos?.songs?.data}
        displayResources={displayResources}
        displaySongs={displaySongs}
        displayPlaylists={displayPlaylists}
        displaySongbooks={displaySongbooks}
        displaySongVideos={displaySongVideos}
        displayAssemblies={displayAssemblies}
        playlists={playlists?.playlists}
        classAssembly={classAssembly?.esongbooks?.data}
        filterParams={filterParams}
      />
    </>
  );
};

export default withPageWrapper({})(Assemblies);
