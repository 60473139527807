/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useGenerateUrlHash from '../../hooks/Others/useGenerateUrlHash';
import useLoginUser from '../../hooks/User/useLoginUser';
import { displayLoadingMessage } from '../../utils/helpers';
import { clearLoginPreferences } from '../../utils/user';

const Login = () => {
  const useGetQueryParams = () => new URLSearchParams(useLocation().search);

  const query = useGetQueryParams();
  const code = query.get('code');
  const { loginRequest, loading, data } = useLoginUser(code);
  const { data: dataURL, generateUrlHashRequest } = useGenerateUrlHash();

  const purchasenow = query.get('purchasenow');
  const redirectUrl = `${document.location.origin}/manage-account`;

  useEffect(() => {
    clearLoginPreferences();
    loginRequest.send();
    if (purchasenow) {
      generateUrlHashRequest(redirectUrl);
    }
  }, []);

  useEffect(() => {
    if (data && !purchasenow) {
      window.location.href = '/';
    }

    if (purchasenow === 'true' && dataURL && data) {
      localStorage.removeItem('purchasenow');
      window.location.href = `${process.env.REACT_APP_SUBSCRIPTION_URL}${data?.loginUser?.accessToken}&url_return=${redirectUrl}&url_return_signature=${dataURL?.generateUrlHash}`;
    }
  }, [dataURL, data]);

  loading && displayLoadingMessage('Please wait...');
  // if (data) window.location.href = '/';

  return (
    <>
      <section className="main__container">
        {data && <h1>Login successful!</h1>}
      </section>
    </>
  );
};

export default Login;
