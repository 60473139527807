import { gql } from '@apollo/client';
import { GlossaryFieldsFragment } from './fragments';

export const GET_GLOSSARIES = gql`
  query Glossaries {
    glossaries(active: true) {
      ...GlossaryFields
    }
  }
  ${GlossaryFieldsFragment}
`;
