/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import CopyModelModal from '../../../components/Shared/Modals/CopyModelModal';
import SongsList from '../../../components/Songs/Sections/Songslist';
import useGetPlaylist from '../../../hooks/Playlist/useGetPlaylist';
import { showModal } from '../../../styles/js/modals';
import { displayLoadingMessage } from '../../../utils/helpers';

const Playlist = ({ id }) => {
  const { data, loading } = useGetPlaylist(id);
  loading && displayLoadingMessage('Loading songs...');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (data?.playlist && !copied) {
      showModal('copyModelModal');
    }
  }, [data]);

  return (
    <section className="main__container">
      {data?.playlist?.songs && (
        <>
          <div className="songslist__subheader">
            <h2 className="songslist__subheader__title">
              {data.playlist.name}
            </h2>
          </div>
          <SongsList songs={data.playlist.songs} />
        </>
      )}
      <CopyModelModal type="playlist" id={id} setCopied={setCopied} />
    </section>
  );
};

export default Playlist;
