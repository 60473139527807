import { gql } from '@apollo/client';
import { PlaylistShareLinkFieldsFragment } from '../../queries/playlist/fragments';

export const CREATE_PLAYLIST = gql`
  mutation CreatePlaylist($input: CreatePlaylistInput!) {
    createPlaylist(input: $input) {
      id
    }
  }
`;

export const ADD_TO_PLAYLIST = gql`
  mutation AddToPlaylist($input: AddToPlaylistInput!) {
    addToPlaylist(input: $input) {
      id
    }
  }
`;

export const REMOVE_FROM_PLAYLIST = gql`
  mutation RemoveFromPlaylist($input: RemoveFromPlaylistInput!) {
    removeFromPlaylist(input: $input) {
      id
    }
  }
`;

export const DELETE_PLAYLIST = gql`
  mutation DeletePlaylist($id: ID!) {
    deletePlaylist(id: $id)
  }
`;

export const EDIT_PLAYLIST = gql`
  mutation EditPlaylist($id: ID!, $input: EditPlaylistInput!) {
    editPlaylist(id: $id, input: $input) {
      id
    }
  }
`;

export const UPDATE_PLAYLIST_SONG_ORDERING = gql`
  mutation UpdatePlaylistSongOrdering($id: ID!, $input: UpdatePlaylistInput!) {
    updatePlaylistSongOrdering(id: $id, input: $input) {
      id
    }
  }
`;

export const GENERATE_PLAYLIST_SHARE_LINK = gql`
  mutation GeneratePlaylistShareLink($playlist_id: ID!) {
    generatePlaylistShareLink(playlist_id: $playlist_id) {
      ...PlaylistShareLinkFields
    }
  }
  ${PlaylistShareLinkFieldsFragment}
`;

export const COPY_PLAYLIST = gql`
  mutation CopyPlaylist($id: ID!) {
    copyPlaylist(id: $id)
  }
`;

export const VERIFY_PLAYLIST_SHARE_LINK = gql`
  mutation VerifyPlaylistShareLink(
    $where: MutationVerifyPlaylistShareLinkWhereWhereConditions
  ) {
    verifyPlaylistShareLink(where: $where) {
      id
      userId
    }
  }
`;

export const HIDE_SHARED_PLAYLIST = gql`
  mutation HideSharedPlaylist($playlist_id: ID!, $organisation_ref: ID!) {
    hideSharedPlaylist(
      playlist_id: $playlist_id
      organisation_ref: $organisation_ref
    ) {
      id
    }
  }
`;

export const UPDATE_PLAYLISTS_ORDERING = gql`
  mutation UpdatePlaylistsOrdering($input: [UpdatePlaylistsOrderingInput]!) {
    updatePlaylistsOrdering(input: $input)
  }
`;
