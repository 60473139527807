import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { shortenLongDescription } from '../../utils/helpers';
import { resourceList } from '../../utils/reactiveVars';
import { getResourceDescription } from '../../utils/resource';

const GuidesAndResources = ({ resources, title, type }) => {
  if (resources?.length < 1) return null;
  const location = useLocation();

  const mapTitle = {
    Assembly: 'Assembly',
    'Collective Worship': 'Collective Worship',
    'Community/Charity': 'Community',
    'Concerts/Performance': 'Choirs and Concerts',
    Curriculum: 'Curriculum',
    'Routines & Signals': 'School Routines and Signals',
    'Just for Fun': 'Sing for Fun',
    Wellbeing: 'Wellbeing',
  };

  const getTitle = (input) => mapTitle[input];

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3 px-xl-0">
        <p className="text__fs-18 mb-0 text-uppercase">
          {`${getTitle(title)} Resources` || 'Guides and resources'}
        </p>
        <Link
          to="/see-all/resources"
          className="text__white text__fs-10"
          onClick={() => {
            resourceList({
              path: location.pathname,
              title,
              type,
            });
          }}
        >
          SEE ALL
        </Link>
      </div>
      <div className="grid__two-items mb__lg-60 mb-40 px-xl-0">
        {resources?.map((resource) => (
          <div
            className="card__plain bg__white mb-0 border__radius-10"
            key={resource.id}
          >
            <div className="card__plain__body">
              <p className="text__fs-14 text__blue-sky mb-13 text__fw-600">
                <Link
                  to={resource.href || `/resources/${resource.slug}`}
                  className="text__blue-sky"
                >
                  {resource.title}
                </Link>
              </p>
              <p className="text__black summary__text">
                {shortenLongDescription(getResourceDescription(resource), 115)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default GuidesAndResources;
