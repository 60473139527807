/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ADD_TO_PLAYLIST } from '../../graphql/mutations/playlist';
import { GET_PLAYLISTS } from '../../graphql/queries/playlist/playlist';
import hideModal from '../../styles/js/modals';
import {
  dismissLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { toastErrorObj, toastSuccessObj } from '../../utils/objects';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useAddToPlaylist = (songId, modalId) => {
  const [addToPlaylist, { loading, data }] = useMutation(ADD_TO_PLAYLIST, {
    refetchQueries: [
      {
        query: GET_PLAYLISTS,
        variables: {
          createdBy: 'user',
          organisation_ref: organisationLoggedInAs()?.id || null,
        },
      },
    ],
  });

  const addToPlaylistRequest = {
    send: (attachIds, detachIds) => {
      addToPlaylist({
        variables: {
          input: {
            song_id: parseInt(songId, 10),
            attach: attachIds,
            detach: detachIds,
          },
        },
      })
        .then((res) => {
          displaySuccessMessage();
          dismissLoadingMessage();
          hideModal(modalId);
        })
        .catch((e) => {
          toast.dismiss('loading');
          toast.error('Operation failed!', toastErrorObj);
        });
    },
  };

  return { loading, data, addToPlaylistRequest };
};

export default useAddToPlaylist;
