/* eslint-disable implicit-arrow-linebreak */
import { useState } from 'react';
import { updateQuery } from '../utils/objects';

const useRefetchTags = (
  allTagTypesRefetchMapDefault,
  categoryPageTagName = null
) => {
  let allTagTypesRefetchMap = { ...allTagTypesRefetchMapDefault };

  const [selectedTags, setSelectedTags] = useState({});
  const [ageRange, setAgeRange] = useState({
    min_age: 5,
    max_age: 11,
  });

  const handleClearFilters = () => {
    delete allTagTypesRefetchMap.content_type;
    Object.values(allTagTypesRefetchMap).forEach((value) => {
      value({
        ...updateQuery,
      });
    });

    allTagTypesRefetchMap = { ...allTagTypesRefetchMapDefault };
    setSelectedTags({});
  };

  /**
   * passing in agerange and selectedTags here cos
   * they're not being picked from state on first click
   */
  const refetchNewTags = ({ ageRangeValue, selectedTagsValue }) => {
    const tags = selectedTagsValue
      ? Object.values({ ...selectedTagsValue })
      : Object.values({ ...selectedTags });
    categoryPageTagName && tags.push(categoryPageTagName);

    Object.values(allTagTypesRefetchMap).forEach((value) => {
      value({
        variables: {
          withContent: {
            tags,
            min_age: ageRangeValue?.min_age || ageRange.min_age,
            max_age: ageRangeValue?.max_age || ageRange.max_age,
          },
        },
        ...updateQuery,
      });
    });
  };

  const handleAgeRangeChange = async (type, value) => {
    await setAgeRange({ ...ageRange, [type]: parseInt(value, 10) });
    refetchNewTags({
      ageRangeValue: { ...ageRange, [type]: parseInt(value, 10) },
    });
  };

  const handleTagChange = async (tag, type) => {
    if (tag === 'all') return;
    await setSelectedTags({ ...selectedTags, [type]: tag });
    delete allTagTypesRefetchMap[type];

    refetchNewTags({ selectedTagsValue: { ...selectedTags, [type]: tag } });
  };

  return [handleClearFilters, handleTagChange, handleAgeRangeChange];
};

export default useRefetchTags;
