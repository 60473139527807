import { useMutation } from '@apollo/client';
import { COPY_COLLECTION } from '../../graphql/mutations/collection';
import { GET_USER_COLLECTIONS } from '../../graphql/queries/collection/collection';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/helpers';

const useCopyCollection = (setCopied) => {
  const [copyCollection, { loading }] = useMutation(COPY_COLLECTION, {
    refetchQueries: [
      {
        query: GET_USER_COLLECTIONS,
      },
    ],
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage();
    },
    onCompleted: () => {
      dismissLoadingMessage();
      displaySuccessMessage('Collection copied!');
      setCopied(true);
    },
  });

  loading && dismissLoadingMessage('Please wait...');

  const copyCollectionRequest = (id) => {
    copyCollection({
      variables: {
        id,
      },
    });
  };

  return { copyCollectionRequest, loading };
};

export default useCopyCollection;
