import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Copyright from '../../components/Shared/Copyright';
import RelatedGuidesAndResources from '../../components/Shared/RelatedGuidesAndResources/Index';
import SongCards from '../../components/Shared/SongCards/Index';
import SongsHeader from '../../components/Song/Sections/SongsHeader/Index';
import SongsPageContent from '../../components/Song/Sections/SongsPageContent/Index';
import useGetSong from '../../hooks/Song/useGetSong';
import { currentSong } from '../../utils/reactiveVars';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useGetCategories from '../../hooks/Others/useGetCategories';
import LoadJquery from '../../components/Shared/LoadJquery';
import { displayLoadingMessage } from '../../utils/helpers';

const Song = () => {
  const { id } = useParams();
  const { data, loading } = useGetSong(id);
  const { data: categories } = useGetCategories();

  data?.song && currentSong(data.song);
  loading && displayLoadingMessage();

  useEffect(() => {
    document.title = `${data?.song?.title || ''} | Song | Sparkyard`;
  }, [data]);

  if (data?.song === null) return <Redirect to="/404" />;

  return (
    <>
      {data?.song && categories && (
        <>
          <SongsHeader id={data.song.id} />
          <LoadJquery />
          <section className="main__container">
            <SongsPageContent categories={categories.categories} />
            <SongCards title="Related songs" />
            <RelatedGuidesAndResources
              resources={data.song.recommendedResources}
            />
            <Copyright />
          </section>
        </>
      )}
    </>
  );
};

export default withPageWrapper({})(Song);
