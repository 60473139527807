import React from 'react';

const NoSubscriptionModal = () => (
  <div
    className="modal fade"
    id="noSubscriptionModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="invitesModnoSubscriptionModalal"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="custom__modal__content bg__cello px-sm-32 px-74 text__fs-14">
          <div className="custom__modal__header ">
            <div className="float-right" />
            <a href="" className="custom__modal__close " data-dismiss="modal">
              <img src="/img/plus-icon.svg" alt="" />
            </a>
          </div>
          <p>
            Unfortunately you do not have an active Sparkyard subscription on
            this account or organisation. Please either switch to another
            account/organisation or purchase a subscription.
          </p>
          <p> Something wrong? Contact Customer Services</p>
        </div>
      </div>
    </div>
  </div>
);

export default NoSubscriptionModal;
