/* eslint-disable react/no-danger */
import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { albumArtDefault } from '../../../../utils/helpers';
import { currentSong } from '../../../../utils/reactiveVars';
import PlaySong from '../../../Shared/PlaySong';

const SongsHeaderInfo = ({ history }) => {
  const { title, author, esongbooks } = currentSong();
  // const { album } = JSON.parse(audios[0].metadata);
  // const albumTitle = esbTrack?.esongbook?.infoName || album || '';

  return (
    <div className="songpage__header__info">
      <div className="songpage__header__thumbnail">
        <img src={esongbooks?.[0]?.albumArt || albumArtDefault} alt="" />
        <PlaySong id={currentSong().id} />
      </div>
      <div>
        <h2
          className="songpage__header__title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {esongbooks?.map((esongbook, idx) => (
          <>
            <Tappable
              component="a"
              className="songpage__header__subtitle text-white"
              onTap={() => {
                history.push(`/songbook/${esongbook.id}`);
              }}
            >
              {idx > 0 && ', '}
              {esongbook.infoName}
            </Tappable>
          </>
        ))}
        <p className="songpage__header__subtext">By {author && author}</p>
      </div>
    </div>
  );
};

export default withRouter(SongsHeaderInfo);
