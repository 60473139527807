import { useReactiveVar } from '@apollo/client';
import React, { useRef } from 'react';
import { returnNumberRange } from '../../../../../utils/helpers';
import { user } from '../../../../../utils/reactiveVars';
import SectionHeader from '../SectionHeader';

const WhichAgeGroupDoYouTeach = ({ handleShowSection, handleDataChange }) => {
  const { minAge, maxAge } = useReactiveVar(user);
  const minAgeRef = useRef(null);
  const maxAgeRef = useRef(null);

  return (
    <>
      <SectionHeader text="Which age group do you teach?" />
      <div className="form-row justify-content-center mb-5 pb-4">
        <div className="form-group col-5 col-md-3">
          <select
            className="form-control form-control-lg input__age-range"
            name="min_age"
            ref={minAgeRef}
            defaultValue={minAge}
          >
            {returnNumberRange(3, 13).map((num) => (
              <option value={num} key={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-5 col-md-3">
          <select
            className="form-control form-control-lg input__age-range"
            name="max_age"
            ref={maxAgeRef}
            defaultValue={maxAge}
          >
            {returnNumberRange(4, 14).map((num) => (
              <option value={num} key={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
        <button
          className="btn button__age-group-check"
          onClick={() => {
            handleShowSection('areasYoureInterestedIn');
            handleDataChange({
              min_age: Number(minAgeRef.current.value),
              max_age: Number(maxAgeRef.current.value),
            });
          }}
        >
          <i className="fas fa-check" />
        </button>
      </div>
    </>
  );
};

export default WhichAgeGroupDoYouTeach;
