import { useQuery } from '@apollo/client';
import { GET_ASSEMBLIES } from '../../graphql/queries/assembly/assembly';
import { displayErrorMessage } from '../../utils/helpers';

const useGetAssemblies = (first) => {
  const { data, loading, error } = useQuery(GET_ASSEMBLIES, {
    variables: { first },
    onError: () => {
      displayErrorMessage('Could not load assemblies');
    },
  });

  return { data, loading, error };
};

export default useGetAssemblies;
