/* eslint-disable no-plusplus */
/* eslint-disable operator-linebreak */
import React from 'react';
import { toast } from 'react-toastify';
import FilterSection from '../../components/AssemblyPlans/Sections/FilterSection';
import Header from '../../components/AssemblyPlans/Sections/Header';
import MainContent from '../../components/AssemblyPlans/Sections/MainContent/Index';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useGetFeaturedAssemblies from '../../hooks/Assembly/useGetFeaturedAssemblies';

const AssemblyPlans = () => {
  const { loading, data } = useGetFeaturedAssemblies();

  loading &&
    toast.info('Loading assemblies...', {
      toastId: 'loading',
    });

  return (
    <>
      <Header />
      {data && (
        <>
          <FilterSection />
          <MainContent data={data} />
        </>
      )}
    </>
  );
};

export default withPageWrapper({ pageTitle: 'Assembly Plans | Sparkyard' })(
  AssemblyPlans
);
