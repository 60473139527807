import { gql } from '@apollo/client';

export const GET_SONGBOOK_SERIES = gql`
  query GetSongbookSeries {
    getSongbookSeries {
      id
      infoName
      infoDescription
      imageFileOriginal
      imageFileMedium
      content
      active
    }
  }
`;
