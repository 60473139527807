import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_CATEGORY_PAGE } from '../graphql/queries/categoryPage/categoryPage';

const useGetCategoryPage = (name) => {
  const { data, loading, error } = useQuery(GET_CATEGORY_PAGE, {
    variables: {
      name,
    },
    onCompleted: () => toast.dismiss('loading'),
    onError: () => {
      toast.dismiss('loading');
      toast.error('Could not load category page');
    },
  });

  return { data, loading, error };
};

export default useGetCategoryPage;
