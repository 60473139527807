/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

const Card = ({
  topText,
  middleText,
  bottomText,
  footerText,
  cardColour,
  modalTarget,
  onClick,
  disabled,
}) => (
  <div
    className={`manage-account__card-section__card__${cardColour} ${
      disabled ? 'disable-element' : ''
    }`}
  >
    <div className="manage-account__card-section__card__content">
      <div dangerouslySetInnerHTML={{ __html: topText }} />
      <div className="text__fs-28 text__fw-500 my-3">{middleText}</div>
      <div dangerouslySetInnerHTML={{ __html: bottomText }} />
    </div>
    <div
      className={`manage-account__card-section__card__footer cursor-pointer bg__${cardColour}`}
      data-target={`#${modalTarget}`}
      data-toggle="modal"
      onClick={onClick}
    >
      {footerText}
    </div>
  </div>
);

export default Card;
