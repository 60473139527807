import { useEffect } from 'react';
import jquery from '../../styles/js/app';

const LoadJquery = () => {
  useEffect(() => {
    jquery();
  }, []);

  return null;
};

export default LoadJquery;
