import classNames from 'classnames';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import Tappable from 'react-tappable';
import useStores from '../../player/hooks/useStores';

const PopupInfo = ({ isHeader, position }) => {
  const { mediaPlayerStore } = useStores();
  const isGuest = !!mediaPlayerStore.shareId;
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: position ? position : 'bottom',
  });

  const [infoShow, setInfoShow] = useState(false);

  return (
    <div
      className={classNames('popup-info', isHeader ? `popup-info--header` : '')}
    >
      {infoShow && (
        <div
          className="popup-info__modal"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <img
            src="/img/close-black.svg"
            className="popup-info__modal-close"
            alt="close"
            onClick={() => setInfoShow(false)}
          />
          <h3>What is Sparkyard?</h3>
          <p>
            Sparkyard is subscription service with songs, assemblies, resources,
            a flexible music curriculum and much more.
          </p>
          <p className="mb-0">© Out of the Ark</p>
        </div>
      )}

      {isGuest ? (
        <Tappable
          component="a"
          className={'popup-info__action'}
          onTap={() => {
            setInfoShow(true);
          }}
        >
          <img src="/img/logo.png" alt="" />
          <div ref={setReferenceElement}></div>
        </Tappable>
      ) : (
        <div className="popup-info__action">
          <img src="/img/logo.png" alt="" />
          <div ref={setReferenceElement}></div>
        </div>
      )}
    </div>
  );
};

export default PopupInfo;
