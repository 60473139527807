import React from 'react';

const Header = () => (
  <div className="main__container bg__blue-navy mt-0">
    <div className="assembly__header singfun__edition">
      <h2 className="assembly__header__title">Sing for fun</h2>
      <p className="assembly__header__text">
        Bring a smile to everyone’s faces and sing just for the pure joy of it!
      </p>
    </div>
  </div>
);

export default Header;
