import React from 'react';

const TrialEndedModal = () => (
  <div
    className="modal fade"
    id="trialEndedModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="trialEndedModal"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="custom__modal__content bg__cello px-sm-32 px-74 text__fs-14">
          <div className="custom__modal__header ">
            <div className="float-right" />
            <a href="" className="custom__modal__close " data-dismiss="modal">
              <img src="/img/plus-icon.svg" alt="" />
            </a>
          </div>
          <p>
            Your trial has ended. Please click on ‘Manage your subscription’ to
            take out a subscription and access all of your songs and resources
            again.
          </p>
          <p> Something wrong? Contact Customer Services</p>
        </div>
      </div>
    </div>
  </div>
);

export default TrialEndedModal;
