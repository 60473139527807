import { gql } from '@apollo/client';
import { SongShareFieldsFragments } from './fragments';

export const GET_SHARE_LINK = gql`
  query GetShareLink($song_id: ID!, $organisation_ref: ID) {
    getShareLink(song_id: $song_id, organisation_ref: $organisation_ref) {
      ...SongShareFields
    }
  }
  ${SongShareFieldsFragments}
`;

export const GET_SHARED_LINKS = gql`
  query GetSharedLinks($organisation_ref: ID) {
    getSharedLinks(organisation_ref: $organisation_ref) {
      ...SongShareFields
    }
  }
  ${SongShareFieldsFragments}
`;
