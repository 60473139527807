/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
import Header from '../../components/ResourcesFavourite/Sections/Header';
import MainSection from '../../components/ResourcesFavourite/Sections/MainSection';
import FilterSection from '../../components/ResourcesFavourite/Sections/FilterSection';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useGetFavouriteAssemblies from '../../hooks/Favourite/useGetFavouriteAssemblies';
import useGetFavouriteResources from '../../hooks/Favourite/useGetFavouriteResources';
import { displayLoadingMessage, matchesAgeRange } from '../../utils/helpers';
import { filterTypeMap } from '../../utils/objects';

const ResourcesFavourites = () => {
  const { loading, data } = useGetFavouriteResources();
  const { data: favouriteAssemblies, loading: loadingFavAssemblies } =
    useGetFavouriteAssemblies();

  const originalResourceList = data?.userFavouriteResources;
  const originalAssembliesList = favouriteAssemblies?.userFavouriteAssemblies;

  const [favResources, setFavResources] = useState(originalResourceList);
  const [favAssemblies, setFavAssemblies] = useState(originalAssembliesList);

  useEffect(() => {
    setFavResources(data?.userFavouriteResources);
    setFavAssemblies(favouriteAssemblies?.userFavouriteAssemblies);
  }, [data, favouriteAssemblies]);

  (loading || loadingFavAssemblies) && displayLoadingMessage();

  /**
   * Split ageRange key on assembly object into minAge and Max age keys
   *
   * @param {object} assembly
   * @returns {object}
   */
  const splitAgeRange = (assembly) => {
    const assemblyCopy = { ...assembly };
    const ageRangeSplit = assemblyCopy.ageRange.split('-');

    return {
      ...assemblyCopy,
      minAge: parseInt(ageRangeSplit?.[0], 10) || 0,
      maxAge: parseInt(ageRangeSplit?.[1], 10) || 0,
    };
  };

  const filter = async (filterData) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { resource_type: resourceType, min_age, max_age } = filterData;
    const resourceResultListCopy = [...originalResourceList];

    const inputMinAge = parseInt(min_age, 10);
    const inputMaxAge = parseInt(max_age, 10);

    if (['all', 'Assembly'].includes(resourceType)) {
      const assemblyListCopy = [...originalAssembliesList];
      const filteredAssemblies = assemblyListCopy.filter((assembly) => {
        const { minAge, maxAge } = splitAgeRange(assembly);
        return matchesAgeRange(inputMinAge, inputMaxAge, minAge, maxAge);
      });

      setFavAssemblies(filteredAssemblies);
    } else {
      setFavAssemblies([]);
    }

    const filteredResources = resourceResultListCopy.filter(
      ({ category: { name }, minAge, maxAge }) => {
        if (matchesAgeRange(inputMinAge, inputMaxAge, minAge, maxAge)) {
          if (filterTypeMap[resourceType]) {
            return filterTypeMap[resourceType](name);
          }
          return name === resourceType;
        }
        return false;
      }
    );

    setFavResources(filteredResources);
  };

  const reset = async () => {
    setFavResources(originalResourceList);
    setFavAssemblies(originalAssembliesList);
  };

  return (
    <div className="main__container px-0 blue mt-0 pb-5">
      <Header />
      <FilterSection filter={filter} resetFilter={reset} />
      {favResources && favAssemblies && (
        <MainSection resources={favResources} assemblies={favAssemblies} />
      )}
    </div>
  );
};

export default withPageWrapper({
  pageTitle: 'Favourite Resources | Sparkyard',
})(ResourcesFavourites);
