import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import useSendInvite from '../../../../../hooks/Organisation/useSendInvite';
import {
  dismissErrorMessage,
  dismissLoadingMessage,
  displayErrorMessage,
  displaySuccessMessage,
} from '../../../../../utils/helpers';
import FormError from '../../../FormError';

const FORM_LIMIT = 5;
const SendInviteSection = ({ organisationId }) => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    getValues,
    trigger,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      invites: [
        {
          form: {
            firstname: '',
            email: '',
            willBeAdmin: false,
          },
        },
      ],
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'invites',
  });

  const { sendInviteRequest } = useSendInvite(organisationId);

  const [invitesLoading, setInvitesLoading] = useState({});
  const [inviteAllLoading, setInviteAllLoading] = useState(false);

  const watchInvites = watch('invites');

  const inviteAll = async (data) => {
    dismissErrorMessage();
    setInviteAllLoading(true);

    const promises = [];

    const newInvitesLoading = { ...invitesLoading };

    for (let i = 0; i < data.invites.length; i++) {
      if (validateRow(i)) {
        const value = data.invites[i];

        newInvitesLoading[fields[i].id] = true;

        promises.push(
          sendInviteRequest({
            ...value,
            willBeAdmin: value.willBeAdmin || false,
            organisation_id: parseInt(organisationId, 10),
          })
        );
      }
    }

    setInvitesLoading(newInvitesLoading);

    try {
      await Promise.all(promises);
      displaySuccessMessage('Invites sent!');
    } catch (err) {
      displayErrorMessage(err);
    } finally {
      setInviteAllLoading(false);
      setInvitesLoading({});
      dismissLoadingMessage();

      reset({
        invites: [
          {
            form: {
              firstname: '',
              email: '',
            },
          },
        ],
      });
    }
  };

  const validateRow = (index) => {
    return (
      watchInvites[index]?.firstname &&
      watchInvites[index]?.email &&
      (!errors.invites || !errors.invites[index])
    );
  };

  const disabledSubmit =
    inviteAllLoading ||
    Object.keys(errors).length >= 1 ||
    watchInvites.every((_, index) => !validateRow(index));

  return (
    <form
      onSubmit={handleSubmit(inviteAll)}
      autoComplete="off"
      className="manage-account text__fs-13"
    >
      <div className="row">
        <div className="col-md-5">Name</div>
        <div className="col-md-5">Email</div>
        <div className="col-md-2 text-center">Admin?</div>
      </div>
      {fields.map((field, index) => (
        <div className="row mt-3" key={index}>
          <div className="col-md-5">
            <input
              type="text"
              className="form-control"
              name={`invites[${index}].firstname`}
              ref={register({
                validate: {
                  required: (value) => {
                    if (!value && getValues(`invites[${index}].email`))
                      return 'Required';
                    return true;
                  },
                },
              })}
              onChange={() => {
                trigger(`invites[${index}].email`);
              }}
            />
            {errors.invites && errors.invites[index]?.firstname && (
              <FormError text="Required" />
            )}
          </div>
          <div className="col-md-5">
            <input
              type="text"
              className="form-control"
              name={`invites[${index}].email`}
              ref={register({
                validate: {
                  required: (value) => {
                    if (!value && getValues(`invites[${index}].firstname`))
                      return 'Required';
                    return true;
                  },
                },
                pattern:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
              onChange={() => {
                trigger(`invites[${index}].firstname`);
              }}
            />
            {errors.invites && errors.invites[index]?.email && (
              <FormError text="Please enter a valid email address" />
            )}
          </div>
          <div className="col-md-2 text-center">
            <input
              type="checkbox"
              id={`${field.id}-willBeAdmin`}
              name={`invites[${index}].willBeAdmin`}
              ref={register({ required: false })}
            />
          </div>
        </div>
      ))}
      <div className="row mt-4 align-items-center">
        <div className="col-md-10">
          {fields.length < FORM_LIMIT && (
            <button
              type="button"
              onClick={() => {
                append({
                  form: {
                    firstname: '',
                    email: '',
                  },
                });
              }}
              className="text-orange font-medium hover:underline"
            >
              Add User
            </button>
          )}
        </div>
        <div className="col-md-2">
          <button
            type="submit"
            disabled={disabledSubmit}
            className="btn button__green-meadow w-100"
          >
            Invite
          </button>
        </div>
      </div>
    </form>
  );
};

export default SendInviteSection;
