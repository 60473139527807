import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';

const ManageAccountDropdown = ({ avatarSrc, history }) => (
  <div className="main__nav__info__avatar dropdown">
    <a
      className="dropdown-toggle"
      href="#"
      role="button"
      id="dropdownMenuLink"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img src={avatarSrc} alt="" />
    </a>
    <div
      className="dropdown-menu dropdown-menu-right"
      aria-labelledby="dropdownMenuLink"
    >
      <a
        className="dropdown-item"
        href="https://sparkyard.zendesk.com/hc/en-gb"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/img/help-icon.svg" alt="" />
        <p>Help centre</p>
      </a>
      <a className="dropdown-item" href="https://outoftheark.co.uk">
        <img src="/img/cart-icon.svg" alt="" />
        <p>Buy songs and songbooks</p>
      </a>
      <Tappable
        component="a"
        className="dropdown-item"
        onTap={() => {
          history.push('/manage-account');
        }}
      >
        <img src="/img/settings-icon.svg" alt="" />
        <p>Manage account</p>
      </Tappable>
      <Tappable
        component="a"
        className="dropdown-item"
        onTap={() => {
          history.push('/signout');
        }}
      >
        <img src="/img/signout-icon.svg" alt="" />
        <p>Sign out</p>
      </Tappable>
    </div>
  </div>
);
export default withRouter(ManageAccountDropdown);
