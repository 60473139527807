import { gql } from '@apollo/client';
import { SongShareFieldsFragments } from '../../queries/share/fragments';

export const GENERATE_SHARE_LINK = gql`
  mutation GenerateShareLink($input: GenerateShareInput!) {
    generateShareLink(input: $input) {
      ...SongShareFields
    }
  }
  ${SongShareFieldsFragments}
`;

export const VERIFY_SHARE_LINK = gql`
  mutation VerifyShareLink(
    $where: MutationVerifyShareLinkWhereWhereConditions
  ) {
    verifyShareLink(where: $where) {
      id
      ootaUUID
    }
  }
`;

export const DELETE_SHARED_LINK = gql`
  mutation DeleteSharedLink($id: ID!) {
    deleteSharedLink(id: $id) {
      id
    }
  }
`;

export const RENEW_SHARED_LINK = gql`
  mutation RenewSharedLink($id: ID!) {
    renewSharedLink(id: $id) {
      id
    }
  }
`;
