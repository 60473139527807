/* eslint-disable react/no-array-index-key */
import React from 'react';
import { albumArtDefault } from '../../utils/helpers';

const FeaturedAlbumArt = ({ songs, roundedCorners }) => {
  if (songs?.length < 1) {
    const borderRadius = roundedCorners ? 'border-10' : '';
    return (
      <div className={`w-100 h-100 ${borderRadius}`}>
        <img
          className={`d-inline w-100 h-100 ${borderRadius}`}
          src={albumArtDefault}
          alt=""
        />
      </div>
    );
  }

  const albumArts = [];
  songs?.forEach((song) => {
    albumArts.push(song.albumArt || albumArtDefault);
  });

  const featuredAlbumArts = [...new Set(albumArts)].splice(0, 4);
  const borderRadius = roundedCorners ? 'border-10' : '';
  const radiusMap = {
    0: { borderTopLeftRadius: '10px' },
    1: { borderTopRightRadius: '10px' },
    2: { borderBottomLeftRadius: '10px' },
    3: { borderBottomRightRadius: '10px' },
  };

  return (
    <>
      {featuredAlbumArts.length < 4 ? (
        <div className={`w-100 h-100 ${borderRadius}`}>
          <img
            className={`d-inline w-100 h-100 ${borderRadius}`}
            src={featuredAlbumArts[0] || albumArtDefault}
            alt=""
          />
        </div>
      ) : (
        <div className="collection__featured-album-art">
          {featuredAlbumArts.map((img, i) => (
            <img
              src={img}
              key={i}
              alt="album"
              style={roundedCorners ? radiusMap[i] : {}}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default FeaturedAlbumArt;
