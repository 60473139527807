/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import { useMutation } from '@apollo/client';
import { CREATE_HIT, UPDATE_HIT } from '../../graphql/mutations/player';
import useStores from '../../player/hooks/useStores';

const usePlayerHit = () => {
  const { mediaPlayerStore } = useStores();

  const [createPlayerHit, { loading, data: createPlayerHitData }] = useMutation(
    CREATE_HIT,
    {
      onError: (err) => {
        if (err.message === 'Invalid hit') {
          window.location.reload();
        }
      },
      onCompleted: () => {
        mediaPlayerStore.setShouldRecordHit(true);
        mediaPlayerStore.setShouldUpdateHit(true);
      },
    }
  );

  const [updatePlayerHit] = useMutation(UPDATE_HIT, {
    onError: () => {},
  });

  const createPlayerHitRequest = (input) =>
    createPlayerHit({
      variables: {
        input,
      },
    });

  const updatePlayerHitRequest = (input) =>
    updatePlayerHit({
      variables: {
        input,
      },
    });

  return {
    createPlayerHitRequest,
    updatePlayerHitRequest,
    loading,
    createPlayerHitData,
  };
};

export default usePlayerHit;
