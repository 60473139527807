/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import { defaultFilterValues } from '../../../utils/objects';

const FilterButton = ({
  onClick,
  disabled,
  handleClearFilters,
  reset,
  hideClearFilters,
}) => {
  const cookies = new Cookies();
  return (
    <>
      <button
        className="button button__form button__filter button__turquoise ml-3"
        onClick={onClick}
        disabled={disabled}
      >
        GO
      </button>
      {!hideClearFilters && (
        <button
          className="btn btn-link ml-3 text-white d-none d-md-block"
          onClick={() => {
            reset(defaultFilterValues);
            cookies.remove('filterSingInEverySubject');
            handleClearFilters();
            toast.info('Loading...', {
              toastId: 'resetFilter',
              autoClose: 1000,
            });
          }}
        >
          Clear filters
        </button>
      )}
    </>
  );
};

export default FilterButton;
