import { gql } from '@apollo/client';
import { MinSongFieldsFragment } from '../song/fragments';

export const PlaylistFieldsFragment = gql`
  fragment PlaylistFields on Playlist {
    id
    name
    color
    isUserPlaylist
    ordering
    songs {
      ...MinSongFields
    }
  }
  ${MinSongFieldsFragment}
`;

export const PlaylistShareLinkFieldsFragment = gql`
  fragment PlaylistShareLinkFields on PlaylistShareLink {
    id
    uuid
    userId
    playlistId
  }
`;
