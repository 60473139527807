import React from 'react';

const ImageModule = ({ url, title, assembly, noMargin }) => (
  <div>
    {title && (
      <p
        className={`summary__text-sm text__black mb-2 pt-2 ${
          assembly ? '' : 'pl-lg-32'
        } pl-sm-16`}
      >
        <b>{title}</b>
      </p>
    )}
    <img
      src={url}
      className={`${
        noMargin === true || noMargin === 1 ? 'mb-0' : 'mb-5'
      } img-fluid`}
      alt=""
    />
  </div>
);

export default ImageModule;
