/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { toast } from 'react-toastify';
import useGetNativitiesAndMusicals from '../../../hooks/Songbook/useGetNativitiesAndMusicals';
import Songbooks from '../../Shared/Songbook/Index';
import NativitiesAndMusicalsCards from '../NativitiesAndMusicalCards';

const MainSection = () => {
  const { loading, data } = useGetNativitiesAndMusicals();

  loading && toast.info('Loading', { toastId: 'loading' });

  return (
    <section className="main__container pt-0 pt-lg-5 pt-xl-0">
      {data && (
        <>
          <NativitiesAndMusicalsCards
            title="Nativities"
            songbooks={data.nativitiesMusicals.nativities}
            type="Purchased Nativities"
          />
          <NativitiesAndMusicalsCards
            title="Musicals"
            musicals
            songbooks={data.nativitiesMusicals.musicals}
            type="Purchased Musicals"
          />
          <Songbooks
            songbooks={data.nativitiesMusicals.songbooks}
            type="Purchased"
            nativitiesMusicals
          />
          <NativitiesAndMusicalsCards
            title="Songs"
            songs={data.nativitiesMusicals.songs}
            type="Purchased"
          />
        </>
      )}
    </section>
  );
};

export default MainSection;
