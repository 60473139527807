/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import getVideoId from 'get-video-id';
import { getVimeoVideoPadding } from '../../../utils/helpers';
import useMediaQuery from '../../../hooks/Others/useMediaQuery';

const Video = ({ url, title, noMargin, mini }) => {
  if (!url) return null;

  const [padding, setPadding] = useState('56.25% 0 0 0');
  const height = mini ? '233px' : '370px';

  const embedUrl = getVideoId(url);
  if (!embedUrl) return null;
  const { service, id } = embedUrl;

  useEffect(() => {
    if (service === 'vimeo') {
      const vimeoPaddingReq = async () => {
        const vimeoPadding = await getVimeoVideoPadding(url);
        setPadding(vimeoPadding);
      };
      vimeoPaddingReq();
    }
  }, []);

  const isMobile = useMediaQuery(
    '(max-width: 428px), screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape)'
  );

  const vimeoStyle = {
    position: 'absolute',
    top: isMobile ? '-79px' : 0,
    left: 0,
    width: '100%',
    height,
  };

  return (
    <div className={`songpage__vid${!!parseInt(noMargin) ? ' mb-0' : ''}`}>
      <div>
        {service === 'youtube' && (
          <iframe
            height={height}
            src={`https://youtube.com/embed/${id}`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            width="100%"
          />
        )}
        {service === 'vimeo' && (
          <>
            <div
              style={{
                padding,
                position: 'relative',
              }}
            >
              <iframe
                src={`https://player.vimeo.com/video/${id}`}
                style={vimeoStyle}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            </div>
            <script src="https://player.vimeo.com/api/player.js" />
          </>
        )}
      </div>
      {title && (
        <div
          className={`songpage__vid__title ${
            service === 'youtube' ? 'mt-n2' : ''
          }`}
        >
          <p>
            <span className="text__fw-600">Video </span> {title}
          </p>
        </div>
      )}
    </div>
  );
};
export default Video;
