/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

const LastCard = ({ onClick }) => (
  <div className="manage-account__card-section__card bg-white">
    <div className="manage-account__card-section__card__content px-5">
      <div>
        <img src="/img/oota-logo-pink.png" alt="oota-logo" />
      </div>
      <div className="mt-5 text__fw-500 text-dark">
        Explore musicals and nativities
      </div>
    </div>
    <div
      className="manage-account__card-section__card__footer bg__blue-sky cursor-pointer"
      onClick={onClick}
    >
      Buy songs and songbooks
    </div>
  </div>
);

export default LastCard;
