import React, { forwardRef } from 'react';
import { toast } from 'react-toastify';
import { defaultFilterValues } from '../../../utils/objects';
import FilterButton from '../Buttons/FilterButton';
import Select from './Select';

const SubmitSection = forwardRef(
  (
    {
      label,
      tags,
      name,
      onClick,
      disabled,
      reset,
      handleTagChange,
      handleClearFilters,
      hideClearFilters,
    },
    ref
  ) => (
    <>
      <div className="assembly__songfinder__selection">
        {label && <label htmlFor="">{label}</label>}

        <div className="assembly__songfinder__selection__selectors">
          {tags && (
            <Select
              ref={ref}
              tags={tags}
              name={name}
              handleTagChange={handleTagChange}
            />
          )}
          <FilterButton
            onClick={onClick}
            disabled={disabled}
            reset={reset}
            handleClearFilters={handleClearFilters}
            hideClearFilters={hideClearFilters}
          />
        </div>
      </div>
      {!hideClearFilters && (
        <button
          className="btn btn-link ml-3 text-white d-md-none"
          onClick={() => {
            reset(defaultFilterValues);
            handleClearFilters();
            toast.info('Loading...', {
              toastId: 'resetFilter',
              autoClose: 1000,
            });
          }}
        >
          Clear all filters
        </button>
      )}
    </>
  )
);

SubmitSection.displayName = 'SubmitSection';

export default SubmitSection;
