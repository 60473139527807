/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import withPageWrapper from '../../../components/Shared/HOCs/withPageWrapper';
import useVerifyPlaylistShareLink from '../../../hooks/Playlist/useVerifyPlaylistShareLink';
import { user } from '../../../utils/reactiveVars';

import Playlist from './Playlist';

const SharedPlaylist = () => {
  const { playlistId, uuid } = useParams();
  const { verifyPlaylistShareLink, data } = useVerifyPlaylistShareLink(
    playlistId,
    uuid
  );

  useEffect(() => {
    verifyPlaylistShareLink();
  }, []);

  if (!data?.verifyPlaylistShareLink?.id) return null;

  const { id } = user();
  if (data?.verifyPlaylistShareLink?.userId === id) {
    return <Redirect to={`/songs/playlists/${playlistId}`} />;
  }

  return <Playlist id={playlistId} />;
};

export default withPageWrapper({})(SharedPlaylist);
