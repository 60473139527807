import React from 'react';
import ReactPlayer from 'react-player';
import useStores from '../../hooks/useStores';
import { formatTime, ProgressBar } from '../ProgressBar';

const Progress = ({
  player,
}: {
  player: React.MutableRefObject<ReactPlayer>;
}): JSX.Element => {
  const { mediaPlayerStore } = useStores();

  return (
    <div className="mt-5">
      <div className="d-flex justify-content-between">
        <span className="time">
          {formatTime(mediaPlayerStore.progress.playedSeconds)}
        </span>
        <div
          className="position-relative w-100 mx-3 mt-2"
          style={{ flexBasis: 'auto' }}
        >
          <ProgressBar player={player} />
        </div>
        <span className="time">{formatTime(mediaPlayerStore.duration)}</span>
      </div>
    </div>
  );
};

export default Progress;
