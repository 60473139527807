/* eslint-disable operator-linebreak */

import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { UPDATE_USER } from '../../graphql/mutations/user';
import { GET_USER } from '../../graphql/queries/user/user';
import hideModal from '../../styles/js/modals';
import {
  dismissLoadingMessage,
  dismissWarningMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
  displayWarningMessage,
} from '../../utils/helpers';
import { user } from '../../utils/reactiveVars';

const useUpdateUser = () => {
  const [updateUser, { data, loading, error }] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: GET_USER }],
  });

  const { id: userId, email } = user();
  const history = useHistory();

  const updateUserRequest = {
    send: (input) => {
      const emailHasChanged = email !== input.email;
      if (emailHasChanged) {
        displayWarningMessage('You will need to verify your new email.');
      }

      updateUser({
        variables: {
          id: parseInt(userId, 10),
          input,
        },
      })
        .then((res) => {
          dismissLoadingMessage();
          displaySuccessMessage();
          user(res.data?.updateUser);
          localStorage.setItem('user', JSON.stringify(res.data.updateUser));
          hideModal();
          if (emailHasChanged) {
            dismissWarningMessage();
            history.push('/restricted');
          }
        })
        .catch((err) => {
          const errorMessage =
            err?.message === 'Validation failed for the field [updateUser].'
              ? 'Email is already taken.'
              : 'Could not update user';
          dismissLoadingMessage();
          displayErrorMessage(errorMessage);
        });
    },
  };

  loading && displayLoadingMessage();

  return { updateUserRequest, data, error };
};

export default useUpdateUser;
