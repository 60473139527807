/* eslint-disable operator-linebreak */
import React from 'react';
import useGenerateCollectionShareLink from '../../../../../../../hooks/Collection/useGenerateCollectionShareLink';

const GenerateShareLink = ({ setGeneratedShareLink, hide, collectionId }) => {
  if (hide) return null;

  const { generateCollectionShareLinkRequest, loading } =
    useGenerateCollectionShareLink(setGeneratedShareLink);

  return (
    <>
      <button
        className="btn bg__cello text-white mt-2"
        onClick={() => generateCollectionShareLinkRequest(collectionId)}
        disabled={loading}
      >
        Generate share link
      </button>
    </>
  );
};

export default GenerateShareLink;
