import React from 'react';

const ContentHereSquare = () => (
  <div className="col-lg-2 col-md-4 col-6">
    <div className="song__details">
      <div className="album-card">
        <img
          src="/img/content-here-square.svg"
          alt="find some content to go here"
        />
      </div>
    </div>
  </div>
);

export default ContentHereSquare;
