import React from 'react';

const ModalHeader = () => (
  <>
    <div className="custom__modal__header">
      <div className="w-100 pt-2 text-center">
        <h5 className="custom__modal__edit-account__content__title ml-auto">
          CREATE USAGE REPORT
        </h5>
      </div>
      <a href="" className="custom__modal__close" data-dismiss="modal">
        <img src="/img/plus-icon.svg" alt="" />
      </a>
    </div>
    <div className="text-left text__fs-14 my-3">
      <p className="text__fw-500 mb-5">
        To help you review which songs and resources have been used across the
        school, and to assist in reporting usage to CCLI, CLA and SPML, we have
        created this easy-to-use reporting tool. For reporting to the
        third-party organizations above, you will need to discount any material
        you may have accessed or used solely for personal preparation. Find out
        more{' '}
        <a
          href="https://sparkyard.zendesk.com/hc/en-gb/articles/6679699618193"
          className="text-white text__underline"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
      </p>
    </div>
  </>
);

export default ModalHeader;
