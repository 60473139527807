import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { resourceList } from '../../../utils/reactiveVars';

const Header = ({ resources, history, title }) => (
  <div className="d-flex align-items-center justify-content-between mb-20 px-xl-0 px-3">
    <p className="heading__section text-uppercase">
      {title || ' Related guides and resources'}
    </p>
    <Tappable
      className="text__white text__fs-10"
      onClick={() => {
        resourceList({
          title: title || 'Related Guides and Resources',
          resources,
        });
        history.push('/see-all/resources');
      }}
    >
      SEE ALL
    </Tappable>
  </div>
);

export default withRouter(Header);
