import React from 'react';

const MarketingConsent = ({ handleDataChange }) => {
  const handleChange = (event) => {
    handleDataChange({ marketingConsent: event.target.checked ? 1 : 0 });
  };

  return (
    <>
      <div
        className="row col text__fs-13 checkbox__white"
        style={{ marginTop: '40px' }}
      >
        <input
          type="checkbox"
          id="marketing_consent"
          name="marketing_consent"
          onChange={handleChange}
          style={{ float: 'left', marginRight: '15px' }}
        />
        <label
          htmlFor="marketing_consent"
          className="text-white"
          style={{ display: 'inline' }}
        >
          Would you like to sign up to our Sparkyard email mailing list to
          receive monthly tips, ideas and suggestions for making the most out of
          your subscription? You can change your settings here at any time.{' '}
          <a href="https://www.sparkyard.com/privacy-notice">
            Visit our Privacy Notice.
          </a>
        </label>
      </div>
    </>
  );
};

export default MarketingConsent;
