import { gql } from '@apollo/client';
import { AssemblyFieldsFragment, MinAssemblyFieldsFragment } from './fragments';

export const GET_ASSEMBLIES_BY_THEME = gql`
  query AssembliesSearchTheme(
    $where: QueryAssembliesSearchThemeWhereWhereConditions
  ) {
    assembliesSearchTheme(where: $where) {
      ...MinAssemblyFields
    }
  }
  ${MinAssemblyFieldsFragment}
`;

export const GET_ASSEMBLY = gql`
  query Assembly($id: ID!) {
    assembly(id: $id) {
      ...AssemblyFields
    }
  }
  ${AssemblyFieldsFragment}
`;

export const GET_ASSEMBLIES = gql`
  query Assemblies($first: Int!) {
    assemblies(first: $first) {
      data {
        ...MinAssemblyFields
      }
    }
  }
  ${MinAssemblyFieldsFragment}
`;

export const GET_ANIMATED_ASSEMBLY_MEDIA = gql`
  query AnimatedAssemblyMedia {
    animatedAssemblyMedia {
      id
      media
    }
  }
`;

export const GET_FEATURED_ASSEMBLIES = gql`
  query FeaturedAssemblies {
    featuredAssemblies {
      id
      title
      theme
      description
      thumbnail
    }
  }
`;

export const GET_FAVOURITE_ASSEMBLIES = gql`
  query UserFavouriteAssemblies($organisation_ref: ID) {
    userFavouriteAssemblies(organisation_ref: $organisation_ref) {
      ...MinAssemblyFields
    }
  }
  ${MinAssemblyFieldsFragment}
`;

export const GET_RANDOM_FEATURED_ASSEMBLIES = gql`
  query FeaturedAssembliesOnCategoryPage {
    featuredAssembliesOnCategoryPage {
      ...MinAssemblyFields
    }
  }
  ${MinAssemblyFieldsFragment}
`;
