import React from 'react';

const HeaderAndDescription = ({ organisationName }) => (
  <>
    <div className="custom__modal__header">
      <div className="w-100 pt-2 text-center">
        <h5 className="custom__modal__edit-account__content__title ml-auto">
          LEAVE {organisationName?.toUpperCase() || 'ORGANIZATION'}
        </h5>
      </div>
      <a href="" className="custom__modal__close" data-dismiss="modal">
        <img src="/img/plus-icon.svg" alt="" />
      </a>
    </div>
    <div className="custom__modal__leave-organization__notice mt-5">
      <p>
        If you leave this organization your account will be held dormant for
        up to 3 years. During this time you can associate with a different
        organisation, or take out your own subscription, retaining your
        playlists and collections.
      </p>
      <p className="text__fw-600 my-5">
        Please confirm your email address or provide a new email address if
        your current school email account will no longer exist
      </p>
    </div>
  </>
  );

export default HeaderAndDescription;
