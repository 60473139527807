import { useMutation } from '@apollo/client';
import { EDIT_COLLECTION } from '../../graphql/mutations/collection';
import { GET_COLLECTION } from '../../graphql/queries/collection/collection';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';

const useShareCollection = (id, organisationRef) => {
  const [shareCollection, { loading }] = useMutation(EDIT_COLLECTION, {
    refetchQueries: [
      {
        query: GET_COLLECTION,
        variables: {
          id,
          organisation_ref: organisationRef,
        },
      },
    ],
  });

  const shareCollectionRequest = (deleteId = null) => {
    const inputPayload = deleteId
      ? { delete: [deleteId] }
      : { upsert: [{ organisation_ref: organisationRef }] };

    shareCollection({
      variables: {
        id,
        input: {
          shares: {
            ...inputPayload,
          },
        },
      },
    })
      .then(() => {
        displaySuccessMessage();
        dismissLoadingMessage();
      })
      .catch(() => {
        displayErrorMessage();
        dismissLoadingMessage();
      });
  };

  loading && displayLoadingMessage();

  return { shareCollectionRequest, loading };
};

export default useShareCollection;
