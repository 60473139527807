/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useForm } from 'react-hook-form';
import Cookies from 'universal-cookie';
import useGetTag from '../../../../hooks/useGetTag';
import useRefetchTags from '../../../../hooks/useRefetchTags';
import { buildFilterParams } from '../../../../utils/helpers';
import AgeRangeSelect from '../../../Shared/CategoryPages/AgeRangeSelect';
import SelectSection from '../../../Shared/CategoryPages/SelectSection';
import SubmitSection from '../../../Shared/CategoryPages/SubmitSection';

const FilterSection = ({ handleFilter, loading }) => {
  const cookies = new Cookies();
  const { register, handleSubmit, reset, getValues } = useForm();

  const {
    data: subject,
    fetchMore: fetchMoreSubjects,
    loading: loadingSubject,
  } = useGetTag('Curriculum Subject Links');

  const {
    data: topicOrTheme,
    fetchMore: fetchMoreTopicsOrThemes,
    loading: loadingTopicOrTheme,
  } = useGetTag('Topic/Theme');

  const {
    data: curriculumConcept,
    fetchMore: fetchMoreCurriculumConcepts,
    loading: loadingCurriculumConcept,
  } = useGetTag('Curriculum Concept');

  const allTagTypesRefetchMapDefault = {
    curriculum_subject_links: fetchMoreSubjects,
    topic_or_theme: fetchMoreTopicsOrThemes,
    curriculum_concept: fetchMoreCurriculumConcepts,
  };

  const [handleClearFilters, handleTagChange, handleAgeRangeChange] =
    useRefetchTags(allTagTypesRefetchMapDefault);

  const tagsChanged = (data) => {
    const tagKeys = [
      'curriculum_subject_links',
      'topic_or_theme',
      'curriculum_concept',
    ];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(data)) {
      if (tagKeys.includes(key) && value !== 'all') {
        return true;
      }
    }
    return false;
  };

  const disableButton =
    loading ||
    loadingSubject ||
    loadingTopicOrTheme ||
    loadingCurriculumConcept;

  const subjectObjs = subject?.tags
    ?.slice()
    .sort((a, b) =>
      a.formattedName.toLowerCase().localeCompare(b.formattedName.toLowerCase())
    );

  const topicOrThemeObjs = topicOrTheme?.tags
    ?.slice()
    .sort((a, b) =>
      a.formattedName.toLowerCase().localeCompare(b.formattedName.toLowerCase())
    );

  const curriculumConceptObjs = curriculumConcept?.tags
    ?.slice()
    .sort((a, b) =>
      a.formattedName.toLowerCase().localeCompare(b.formattedName.toLowerCase())
    );

  const handleFilterParams = (data) => {
    cookies.set('filterSingInEverySubject', buildFilterParams(data), {
      path: '/',
      maxAge: 600,
    });
    handleFilter(buildFilterParams(data), tagsChanged(data));
  };

  return (
    <div className="main__container bg__blue-nile mt-0">
      <div className="assembly__songfinder">
        <h2 className="assembly__songfinder__title">Find resources</h2>
        {subject && topicOrTheme && curriculumConcept && (
          <div className="assembly__filter">
            <div className="assembly__songfinder__range">
              <label htmlFor="">Age range</label>
              <div className="assembly__songfinder__range__selectors">
                <AgeRangeSelect
                  ref={register}
                  name="min_age"
                  handleAgeRangeChange={handleAgeRangeChange}
                />
                <p>to</p>
                <AgeRangeSelect
                  ref={register}
                  name="max_age"
                  handleAgeRangeChange={handleAgeRangeChange}
                />
              </div>
            </div>

            <SelectSection
              label="Subject"
              ref={register}
              tags={subjectObjs}
              name="curriculum_subject_links"
              handleTagChange={handleTagChange}
              value={getValues('curriculum_subject_links')}
            />

            <SelectSection
              label="Topic / Theme"
              ref={register}
              tags={topicOrThemeObjs}
              name="topic_or_theme"
              handleTagChange={handleTagChange}
              value={getValues('topic_or_theme')}
            />

            <SubmitSection
              label="Curriculum concept"
              ref={register}
              tags={curriculumConceptObjs}
              name="curriculum_concept"
              onClick={handleSubmit(handleFilterParams)}
              disabled={disableButton}
              reset={reset}
              handleTagChange={handleTagChange}
              handleClearFilters={() => {
                handleClearFilters();
                handleSubmit(handleFilter({}, false));
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterSection;
