/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect } from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import {
  albumArtDefault,
  shortenLongDescription,
  stripHtml,
} from '../../utils/helpers';
import PlaySong from '../Shared/PlaySong';
import useGetCategories from '../../hooks/Others/useGetCategories';
import { getResourceThumbnail } from '../../utils/resource';
import SearchResultImage from './Sections/SearchResultImage';

const SearchResult = ({
  title,
  type,
  description,
  id,
  media,
  slug,
  tags,
  link,
  searchText,
  history,
}) => {
  const [categoryNames, setCategoryNames] = useState([]);
  const { data } = useGetCategories();

  useEffect(() => {
    if (data) {
      const names = data.categories.map(({ name }) => name);
      setCategoryNames(names);
    }
  }, [data]);

  const linkMap = {
    MusicCurriculum: `/music-curriculum/${title}`,
    Song: `/song/${id}`,
    Songbook: `/songbook/${id}`,
    Assembly: `/assembly/${id}/${title}`,
    Playlist: `/songs/playlists/${id}`,
    'Music Curriculum Activity': link,
    'Song Calendar Activity': link,
    Collection: `/collection/${id}`,
  };

  const getLink = () => {
    if (
      categoryNames.includes(type) &&
      !['Music Curriculum Activity', 'Song Calendar Activity'].includes(type)
    ) {
      return `/resources/${slug}`;
    }
    return linkMap[type];
  };

  const getDisplayImage = () => {
    if (categoryNames.includes(type)) return getResourceThumbnail(media, type);
    return media || albumArtDefault;
  };

  const searchWords = [searchText];

  const highlightStyle = { color: '#f61a5a', background: '#fcd4e3' };

  return (
    <>
      {data && (
        <div className="resources-favourites__card">
          {type === 'Song' ? (
            <div className="songslist__search">
              <div className="songslist__search__thumbnail">
                <img
                  src={getDisplayImage()}
                  alt={title}
                  className="songslist__search__thumbnail__bg"
                />
                <PlaySong id={id} />
              </div>
              <div className="resources-favourites__card__info">
                <Tappable
                  component="a"
                  className="songslist__search__title"
                  onTap={() => {
                    history.push(getLink());
                  }}
                >
                  {`${type.toUpperCase()}:  ${title}`}
                </Tappable>
                <div>
                  <Highlighter
                    highlightStyle={highlightStyle}
                    searchWords={searchWords}
                    textToHighlight={shortenLongDescription(
                      stripHtml(description),
                      250
                    )}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex">
              <SearchResultImage
                type={type}
                title={title}
                id={id}
                getDisplayImage={getDisplayImage}
              />
              <div className="resources-favourites__card__info">
                <Tappable
                  component="a"
                  onTap={() => {
                    history.push(getLink());
                  }}
                >{`${type.toUpperCase()}:  ${title}`}</Tappable>
                <div>
                  {description &&
                    shortenLongDescription(stripHtml(description), 165)}
                </div>
                <div className="mt-2 mb-3">
                  {categoryNames.includes(type) &&
                    tags?.map(
                      ({ type: tagType, formattedName }) =>
                        tagType === 'Curriculum Subject Links' && (
                          <div
                            className="tag d-inline-block mr-2"
                            key={formattedName}
                          >
                            {formattedName}
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default withRouter(SearchResult);
