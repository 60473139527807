/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Shared/Loading';
import useVerifyShareLink from '../../hooks/Share/useVerifyShareLink';

const SharedSong = () => {
  const { songId, uuid } = useParams();
  const { verifyShareLink, loading } = useVerifyShareLink(songId, uuid);

  useEffect(() => {
    verifyShareLink();
  }, []);

  loading && <Loading />;

  return <h1>Please wait...</h1>;
};

export default SharedSong;
