/* eslint-disable implicit-arrow-linebreak */
import tw, { styled } from 'twin.macro';
import 'styled-components/macro';

type CanvasContainerProps = {
  scale: number;
  containerDimensions: { width: number; height: number };
  padding: [number, number, number, number];
};
const CanvasContainer = styled('div')<CanvasContainerProps>`
  ${tw`absolute block top-0 left-0 w-full h-full z-10`}
  width: ${(props) => props.scale * 1023.65}px;
  height: ${(props) => props.scale * 622}px;
  position: absolute;
  left: ${(props) =>
    (props.containerDimensions.width - 1023.65 * props.scale) / 2}px;
  top: ${(props) =>
    (props.containerDimensions.height - 622 * props.scale) / 2}px;
  padding: ${({ scale, padding }) => scale * padding[0]}px
    ${({ scale, padding }) => scale * padding[1]}px
    ${({ scale, padding }) => scale * padding[2]}px
    ${({ scale, padding }) => scale * padding[3]}px;
`;

export default CanvasContainer;
