/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import React, { useEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Header from '../../components/CurriculumPlan/Sections/Header';
import MainSection from '../../components/CurriculumPlan/Sections/MainSection/Index';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import LoadJquery from '../../components/Shared/LoadJquery';
import SongCards from '../../components/Shared/SongCards/Index';
import useGetLessonPlan from '../../hooks/useGetLessonPlan';
import { displayLoadingMessage } from '../../utils/helpers';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const CurriculumPlan = () => {
  const organisationRef = useReactiveVar(organisationLoggedInAs)?.id;

  const { id } = useParams();
  const { data: { lessonPlan = {} } = {}, loading } = useGetLessonPlan(
    id,
    organisationRef
  );

  useEffect(() => {
    document.title = `${
      lessonPlan?.title
        ? `Year ${lessonPlan.step.term.year.year} Term ${lessonPlan.step.term.term} Step ${lessonPlan.step.step}`
        : ''
    } | Music Curriculum | Sparkyard`;
  }, [lessonPlan]);

  loading && displayLoadingMessage('Loading lesson plan...');

  let songs = lessonPlan?.musicCurriculumActivities?.map(
    (activity) => activity.songs
  );
  if (songs) {
    songs = [].concat(...songs);
    songs = songs.filter((item, index) => songs.indexOf(item) === index);
  }

  const downloadables = useMemo(() => {
    const downloadablesArr = [];

    // use the downloadables on the lesson plan if it is a pick and mix lesson plan
    // if not get use the downloadables on all the resources on the plan
    if (lessonPlan?.pickAndMix) {
      JSON.parse(lessonPlan.downloadables)?.map((downloadable) =>
        downloadablesArr.push(downloadable)
      );
    } else {
      lessonPlan?.musicCurriculumActivities?.map((resource) =>
        JSON.parse(resource.downloadables)?.map((downloadable) =>
          downloadablesArr.push(downloadable)
        )
      );
    }
    return JSON.stringify(downloadablesArr);
  }, [lessonPlan]);

  return (
    <>
      {lessonPlan?.id && (
        <>
          <Header
            lessonPlan={lessonPlan}
            organisationRef={organisationRef}
            resource={{
              resourceTags: lessonPlan.lessonPlanTags,
              downloadables,
            }}
          />
          <section className="main__container">
            <div className="mt-5 p-3 mt-md-0 p-md-0" />
            {!lessonPlan.pickAndMix && (
              <SongCards
                title="Song choice"
                songs={songs}
                pageTitle={lessonPlan.title}
              />
            )}
            <MainSection lessonPlan={lessonPlan} />
          </section>
          <LoadJquery />
        </>
      )}
    </>
  );
};

export default withPageWrapper({})(CurriculumPlan);
