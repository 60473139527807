import React from 'react';
import ModalHeader from './ModalHeader';
import Table from './Table';

const CreateUsageReport = () => (
  <>
    <div
      className="modal fade"
      id="createUsageReportModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="createUsageReportModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered custom__modal__invite-colleagues"
        role="document"
      >
        <div className="modal-content custom__modal__leave-organization__content">
          <ModalHeader />
          <Table />
        </div>
      </div>
    </div>
  </>
);

export default CreateUsageReport;
