import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_TAGS } from '../graphql/queries/tag/tag';

const useGetTag = (ofType, variables = {}) => {
  const { data, loading, error, fetchMore } = useQuery(GET_TAGS, {
    variables: {
      ofType,
      ...variables,
    },
    onError: () => {
      toast.error('Something went wrong', { toastId: 'tagErr' });
    },
    notifyOnNetworkStatusChange: true,
  });

  return { data, loading, error, fetchMore };
};

export default useGetTag;
