import React from 'react';
import useGetAnimatedAssemblyMedia from '../../../../hooks/Assembly/useGetAnimatedAssemblyMedia';
import Mp4 from '../../../Shared/Mp4';

const AnimatedAssemblyMedia = () => {
  const {
    data: { animatedAssemblyMedia: { media } = {} } = {},
  } = useGetAnimatedAssemblyMedia();

  const mediaType = media?.slice(-3);

  return (
    <div className="text-center main__container p-0 m-0">
      {mediaType === 'mp4' ? (
        <Mp4 url={media} />
      ) : (
        <img src={media} className="img-fluid w-100" alt="" />
      )}
    </div>
  );
};

export default AnimatedAssemblyMedia;
