import React from 'react';

const Header = ({ title, subtitle }) => (
  <>
    <div className="main__container d-flex align-items-center py-4 px-md-5 mt-0">
      <img
        src="/img/music-curriculum-icon.svg"
        alt="calendar icon"
        className="mc__header__icon"
      />
      <span className="mc__header__toptitle">{title}</span>
    </div>
    <div className="main__container bg__pumpkin mt-0 d-flex px-md-5 mc__header__container mc__header__banner">
      <span className="mc__header__description">{subtitle}</span>
      <img
        src="/img/Category-Banners-MusicCurriculumV2-25.png"
        className="img-fluid mc__header__banner__img"
        alt="banner"
      />
    </div>{' '}
  </>
);

export default Header;
