import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_MUSIC_CURRICULUM } from '../../graphql/queries/musicCurriculum/musicCurriculum';

const useGetMusicCurriculum = (organisationRef) => {
  const { data, loading, error } = useQuery(GET_MUSIC_CURRICULUM, {
    variables: { organisationRef },
    onCompleted: () => toast.dismiss('loading'),
    onError: () => {
      toast.dismiss('loading');
      toast.error('Could not load curriculum', { toastId: 'musicCurErr' });
    },
  });

  return { data, loading, error };
};

export default useGetMusicCurriculum;
