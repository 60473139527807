/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import { formatTime } from '../../utils/helpers';

const AudioBar = ({ title, url, noMargin }) => {
  const audioRef = useRef(null);
  const [audioTime, setAudioTime] = useState(0);
  const [state, setstate] = useState(false);

  useEffect(() => {
    setAudioTime(
      formatTime(Math.floor(audioRef.current ? audioRef.current.duration : 0))
    );
  }, []);

  const PlayIcon = () => (
    <>
      <img src="/img/pause.svg" alt="" style={{ display: 'none' }} />
      <img
        src={state ? '/img/pause.svg' : '/img/play-icon-circle.svg'}
        onClick={() => {
          state ? audioRef.current.pause() : audioRef.current.play();
          setstate(!state);
        }}
        alt=""
      />
    </>
  );

  return (
    <div className={`audio__tag ${noMargin ? 'mb-0' : 'mb-3'}`}>
      <audio
        src={url}
        onTimeUpdate={() =>
          setAudioTime(
            formatTime(audioRef.current ? audioRef.current.currentTime : 0)
          )
        }
        preload="metadata"
        ref={audioRef}
      />
      <span className="audio__tag__cta">
        <PlayIcon />
      </span>
      <div className="audio__tag__info bg__blue-curious">
        <p className="audio__tag__info__title">
          <b>Audio</b> {title}
        </p>
        <p className="audio__tag__info__duration">{audioTime}</p>
      </div>
    </div>
  );
};

export default AudioBar;
