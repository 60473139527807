import React from 'react';

const SubscriptionModalContent = ({ message = '' }) => (
  <div className="flex flex-col justify-center">
    <img
      src="/img/Incidental-banners-manage-subs-09-09.png"
      className="w-full"
      alt=""
    />
    <p className="text-3xl  text-center font-bold">{message}</p>
  </div>
);

export default SubscriptionModalContent;
