import React from 'react';
import { showModal } from '../../../../styles/js/modals';

const SharePlaylist = ({ show }) => {
  if (!show) return null;

  return (
    <div className="mb-20 text__black text__fs-16">
      <a
        href=""
        className="text__black"
        id="sharePlaylist"
        data-dismiss="modal"
        onClick={() => showModal('sharePlaylistModal')}
      >
        <i className="fa fa-share-alt text__turquoise-medium text__fs-20 mr-4" />{' '}
        Share this playlist
      </a>
    </div>
  );
};

export default SharePlaylist;
