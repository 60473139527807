/* eslint-disable react/no-danger */
import React from 'react';
import { Link } from 'react-router-dom';
import { shortenLongDescription } from '../../../../utils/helpers';

const Event = ({ day, description, title, slug }) => (
  <div className="song-calendar__event">
    <div className="song-calendar__event__date">{day}</div>
    <div className="song-calendar__event__content py-2">
      <Link to={`/event/${slug}`}>{title}</Link>
      <p
        className="mb-0"
        dangerouslySetInnerHTML={{
          __html: shortenLongDescription(description, 140),
        }}
      />
    </div>
  </div>
);

export default Event;
