import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { EDIT_COLLECTION } from '../../graphql/mutations/collection';
import { GET_COLLECTION } from '../../graphql/queries/collection/collection';
import hideModal from '../../styles/js/modals';
import {
  dismissLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { collectionDefaultObj, toastErrorObj } from '../../utils/objects';

const useEditCollection = (id, reset) => {
  const [editCollection] = useMutation(EDIT_COLLECTION, {
    refetchQueries: [
      {
        query: GET_COLLECTION,
        variables: {
          id,
        },
      },
    ],
  });

  const editCollectionRequest = {
    send: (title, description) => {
      editCollection({
        variables: {
          id,
          input: {
            title,
            description,
          },
        },
      })
        .then(() => {
          reset(collectionDefaultObj);
          displaySuccessMessage('Collection edited successfully!');
          dismissLoadingMessage();
          hideModal();
        })
        .catch(() => {
          toast.error('Could not edit collection.', toastErrorObj);
          toast.dismiss('loading');
        });
    },
  };

  return { editCollectionRequest };
};

export default useEditCollection;
