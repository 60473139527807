import React from 'react';
import AudioBar from '../../components/Resources/AudioBar';
import Header from '../../components/Resources/Header';
import Video from '../../components/Shared/Video/Video';
import Spacer from '../../components/Resources/Spacer';
import ImageModule from '../../components/Song/Sections/SongsPageContent/Modules/ImageModule';
import TextModule from '../../components/Song/Sections/SongsPageContent/Modules/TextModule';
import IconModule from '../../components/Shared/IconModule';
import SoundcloudAudio from '../../components/Shared/SoundcloudAudio';
import BioModule from '../../components/Shared/BioModule';

const useRenderResource = () => {
  const render = (resource, assembly = null, wrapperClassNames = null) => {
    if (resource.layout === 'icon-bullet') {
      return (
        <IconModule
          iconUrl={resource.attributes.image}
          title={resource.attributes.title}
          text={resource.attributes.body}
          key={resource.key}
          noMargin={resource.attributes.noMargin}
        />
      );
    }
    if (resource.layout === 'wysiwyg') {
      return (
        <TextModule
          text={resource.attributes.body}
          key={resource.key}
          noMargin={resource.attributes.noMargin}
        />
      );
    }

    if (resource.layout === 'image') {
      if (assembly) {
        return (
          <ImageModule
            url={resource.attributes.image}
            title={resource.attributes.title}
            key={resource.key}
            assembly
            noMargin={resource.attributes.noMargin}
          />
        );
      }

      return (
        <div className={wrapperClassNames || 'mx-lg-n32 mx-sm-n16'}>
          <ImageModule
            url={resource.attributes.image}
            title={resource.attributes.title}
            key={resource.key}
            noMargin={resource.attributes.noMargin}
          />
        </div>
      );
    }

    if (resource.layout === 'title-block') {
      return (
        <div className="card-nt">
          <Header
            title={resource.attributes.title}
            backgroundColor={resource.attributes.background_color}
          />
        </div>
      );
    }

    if (resource.layout === 'spacer') {
      return <Spacer height={resource.attributes.spacer} />;
    }

    if (resource.layout === 'audio') {
      return (
        <AudioBar
          title={resource.attributes.title}
          url={resource.attributes.audio}
        />
      );
    }

    if (resource.layout === 'video') {
      if (assembly) {
        return (
          <Video
            url={resource.attributes.url}
            title={resource.attributes.title}
            noMargin={resource.attributes.noMargin}
          />
        );
      }
      return (
        <div className={wrapperClassNames || 'card-nt'}>
          <Video
            url={resource.attributes.url}
            title={resource.attributes.title}
            noMargin={resource.attributes.noMargin}
          />
        </div>
      );
    }

    if (resource.layout === 'soundcloud-audio') {
      return (
        <SoundcloudAudio
          url={resource.attributes.soundcloud_link}
          noMargin={resource.attributes.noMargin}
        />
      );
    }

    if (resource.layout === 'bio') {
      return (
        <BioModule
          title={resource.attributes.title}
          image={resource.attributes.image}
          text={resource.attributes.text}
        />
      );
    }

    if (resource.layout === 'tags') {
      const tags = resource.attributes.tags?.split('\n');

      return (
        <div className="d-flex flex-wrap mb-4">
          {tags?.map((tag) => (
            <div className="tag mb-23 mr-2 d-inline-block" key="tag">
              {tag}
            </div>
          ))}
        </div>
      );
    }
  };

  return { render };
};

export default useRenderResource;
