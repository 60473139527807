import React from 'react';
import Loading from '../../Shared/Loading';
import PlaylistList from '../PlaylistList';

const SharedPlaylists = ({ playlists, isLoading }) => (
  <>
    <div className="text__fs-12 mb-4">
      You are following Playlists in this area. They may be edited
      or deleted by the person who created them. To keep a copy of your own,
      click on the three dots in the desired playlist and select "copy
      playlist".
    </div>
    {isLoading && <Loading />}
    {playlists?.length > 0 && (
      <>
        <PlaylistList
          playlists={playlists}
          disableDeletePlaylist
          sharedWithUser
        />
      </>
    )}
  </>
);

export default SharedPlaylists;
