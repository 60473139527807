import React from 'react';

const SectionHeader = ({ text, tickAll }) => (
  <div className="text__fs-14 text__center mb-20 my-5">
    <span>{text}</span>
    {tickAll && (
      <>
        <br />
        <span>Tick all that apply</span>
      </>
    )}
  </div>
);

export default SectionHeader;
