/* eslint-disable jsx-a11y/anchor-is-valid */
import { useReactiveVar } from '@apollo/client';
import React, { useRef } from 'react';
import useListenToOutsideClicks from '../../../hooks/useListenToOutsideClicks';
import { isMobileSidebarOpen } from '../../../utils/reactiveVars';
import Logo from './Sections/Logo';
import SidebarList from './Sections/SidebarList/Index';
import SidebarProfile from './Sections/SidebarProfile';

const Sidebar = () => {
  const wrapperRef = useRef(null);
  useListenToOutsideClicks(wrapperRef);

  return (
    <aside
      className={`aside ${useReactiveVar(isMobileSidebarOpen) ? 'active' : ''}`}
      id="sidebar"
    >
      <div className="sidebar" ref={wrapperRef}>
        <Logo />
        <SidebarProfile />
        <SidebarList />
      </div>
    </aside>
  );
};

export default Sidebar;
