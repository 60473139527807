/* eslint-disable operator-linebreak */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import PageHeader from '../../components/Shared/PageHeader';
import MainSection from '../../components/Collections/Sections/MainSection';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import LoadJquery from '../../components/Shared/LoadJquery';
import useGetCollections from '../../hooks/Collection/useGetCollections';
import { organisationLoggedInAs } from '../../utils/reactiveVars';
import CreateCollection from '../../components/Collections/CreateCollection';

const Collections = () => {
  const organisationRef = useReactiveVar(organisationLoggedInAs)?.id;

  const {
    data: userCollections,
    loading: loadingUserCollections,
    refetch,
  } = useGetCollections('user', organisationRef);

  const { data: syCollections, loading: loadingSyCollections } =
    useGetCollections('sparkyard');

  const { data: sharedCollections, loading: loadingSharedCollections } =
    useGetCollections('shared', organisationRef);

  return (
    <>
      <PageHeader
        icon={<img src="/img/collections_white.svg" alt="" />}
        title="Collections"
        description="Gather together songs, guides, curriculum links and much more in
        personalized collections you can organize to suit you."
        banner={(
          <img
            src="/img/collections_banner.png"
            className="img-fluid"
            alt="banner"
          />
        )}
        additionalComponents={(
          <div className="ml-4">
            <CreateCollection refetch={refetch} />
          </div>
        )}
        headerClass="page-header--collection"
        descriptionBarClass="bg__green-meadow"
      />
      <MainSection
        userCollections={userCollections?.userCollections}
        syCollections={syCollections?.sparkyardCollections}
        sharedCollections={sharedCollections?.collectionsSharedWithOrganisation}
        loadingUserCollections={loadingUserCollections}
        loadingSyCollections={loadingSyCollections}
        loadingSharedCollections={loadingSharedCollections}
        organisationRef={organisationRef}
      />
      <LoadJquery />
    </>
  );
};

export default withPageWrapper({ pageTitle: 'All Collections | Sparkyard' })(
  Collections
);
