/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useRenewSharedLink from '../../../../hooks/Share/useRenewSharedLink';
import { displaySuccessMessage } from '../../../../utils/helpers';

const RenewLink = ({ shareLink, setLoadingRenewShareLink }) => {
  const { renewSharedLinkRequest, loading, data } = useRenewSharedLink();
  let className = 'alert alert-warning text__fs-10';
  if (loading) {
    className += ' disable-element';
  }

  data?.renewSharedLink && displaySuccessMessage();

  useEffect(() => {
    setLoadingRenewShareLink(loading);
  }, [loading]);

  return (
    <div className={className} role="alert">
      This song's share link has expired.{' '}
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          renewSharedLinkRequest(shareLink.id);
        }}
        className="text__underline"
      >
        Click here
      </a>{' '}
      to renew.
    </div>
  );
};

export default RenewLink;
