import { useMutation } from '@apollo/client';
import {
  GET_MEMBERSHIPS,
  LEAVE_ORGANISATION,
} from '../../graphql/queries/organisation/organisation';
import hideModal, { showModal } from '../../styles/js/modals';
import {
  dismissErrorMessage,
  dismissLoadingMessage,
  dismissWarningMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displayWarningMessage,
} from '../../utils/helpers';
import { user } from '../../utils/reactiveVars';
import useUpdateUser from '../User/useUpdateUser';

const useLeaveOrganisation = (id) => {
  const [leaveOrganisation, { loading }] = useMutation(LEAVE_ORGANISATION, {
    refetchQueries: [{ query: GET_MEMBERSHIPS }],
  });
  const { updateUserRequest, error } = useUpdateUser();

  const leaveOrganisationRequest = (email) => {
    const {
      email: existingEmail,
      firstname,
      surname,
      minAge,
      maxAge,
      roles,
      subjects,
    } = user();
    const emailHasChanged = email !== existingEmail;
    if (emailHasChanged) {
      displayWarningMessage('You will need to verify your new email.');
    }

    dismissErrorMessage();

    leaveOrganisation({
      variables: {
        id,
      },
    })
      .then(() => {
        dismissLoadingMessage();
        hideModal();

        if (emailHasChanged) {
          const input = {
            email,
            firstname,
            surname,
            roles,
            subjects,
            min_age: parseInt(minAge, 10),
            max_age: parseInt(maxAge, 10),
          };

          updateUserRequest.send(input);
        }
      })
      .catch(() => {
        dismissLoadingMessage();
        displayErrorMessage();
      });
  };

  if (error) {
    showModal('editAccountModal');
    dismissWarningMessage();
  }

  loading && displayLoadingMessage();

  return { leaveOrganisationRequest };
};

export default useLeaveOrganisation;
