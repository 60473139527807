import React from 'react';

const LeftIcon = () => {
  return (
    <svg
      width="18"
      height="28"
      viewBox="0 0 18 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16_2)">
        <path
          d="M0 14C0 13.6989 0.110039 13.4384 0.330116 13.2184L13.222 0.330025C13.4421 0.110008 13.7027 0 14.0039 0C14.305 0 14.5656 0.110008 14.7857 0.330025L17.6699 3.2134C17.89 3.43342 18 3.69396 18 3.99504C18 4.29611 17.89 4.55666 17.6699 4.77667L8.44402 14L17.6699 23.2233C17.89 23.4433 18 23.7039 18 24.005C18 24.306 17.89 24.5666 17.6699 24.7866L14.7857 27.67C14.5656 27.89 14.305 28 14.0039 28C13.7027 28 13.4421 27.89 13.222 27.67L0.330116 14.7816C0.110039 14.5616 0 14.3011 0 14Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_2">
          <rect
            width="18"
            height="28"
            fill="white"
            transform="matrix(-1 0 0 -1 18 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LeftIcon;
