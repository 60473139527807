import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { assemblyList } from '../../../utils/reactiveVars';
import Card from '../Card';

const AssemblyCards = ({
  title,
  assemblies,
  type,
  linkToSeeAllPage,
  history,
}) => {
  if (!assemblies || assemblies?.length < 1) return null;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-26">
        <p className="text__fs-18 text-uppercase mb-0">{title}</p>
        <Tappable
          component="a"
          className="text__white text__fs-10"
          onClick={() => {
            assemblyList({ assemblies, type });
            history.push(linkToSeeAllPage || '/see-all/assemblies');
          }}
        >
          SEE ALL
        </Tappable>
      </div>
      <div className="assembly__grid theme-page__grid">
        {assemblies?.slice(0, 2)?.map((assembly) => (
          <Card
            id={assembly.id}
            title={assembly.title}
            description={assembly.description}
            key={assembly.id}
            thumbnail={assembly.thumbnail}
            themePage
            type={type}
          />
        ))}
      </div>
    </>
  );
};

export default withRouter(AssemblyCards);
