import React from 'react';

const Header = () => (
  <>
    <div className="main__container d-flex align-items-center py-4 px-md-5 mt-0 resources-favourites__header__sub">
      <img
        src="/img/favourite-white.svg"
        alt="favourite"
        className="resources-favourites__header__icon"
      />
      <span className="resources-favourites__header__text">My Favourites</span>
      <span className="resources-favourites__header__text__muted">Songs</span>
    </div>

    <div className="main__container bg__cerise-dark mt-0 d-flex px-md-5 song-calendar__header__container">
      <span className="song-calendar__header__description">
        Never lose a song you love! Click the heart icon and the song will
        appear here.
      </span>
      <div>
        <img
          src="/img/favourite-songs-banner.png"
          className="img-fluid resources-favourites__header__banner"
          alt="banner"
        />
      </div>
    </div>
  </>
);

export default Header;
