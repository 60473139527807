import React from 'react';
import FeaturedAlbumArt from '../../Shared/FeaturedAlbumArt';
import { albumArtDefault } from '../../../utils/helpers';
import useGetPlaylist from '../../../hooks/Playlist/useGetPlaylist';

const SearchPlaylistArt = ({ id }) => {
  const { data } = useGetPlaylist(id);

  return (
    <>
      {data ? (
        <div className="resources-favourites__card__thumbnail__show">
          <FeaturedAlbumArt songs={data?.playlist?.songs} />
        </div>
      ) : (
        <img
          src={albumArtDefault}
          alt="playlist"
          className="resources-favourites__card__thumbnail__show"
        />
      )}
    </>
  );
};

export default SearchPlaylistArt;
