/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { GET_SONG, GET_SONG_AS_GUEST } from '../../graphql/queries/song/song';
import useStores from '../../player/hooks/useStores';
import {
  dismissErrorMessage,
  displayErrorMessage,
  getSongAudioSrcs,
} from '../../utils/helpers';
import {
  currentSongTypeId,
  isMobileFullscreen,
  isPlayerFullscreen,
  mediaPlayerSelectedSongState,
  playerActive,
  selectedSongId,
} from '../../utils/reactiveVars';
import useMediaQuery from '../Others/useMediaQuery';
import useWrapLines from './useWrapLines';

const useGetSongForPlayer = (guest = false, esongbookId, organisation_ref) => {
  const wrapLines = useWrapLines();
  const { mediaPlayerStore } = useStores();
  const [data, setData] = useState(null);
  const [shareId, setShareId] = useState(null);

  const isMobile = useMediaQuery(
    '(max-width: 767px), screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) '
  );

  const query = guest ? GET_SONG_AS_GUEST : GET_SONG;
  const resKey = guest ? 'songAsGuest' : 'song';

  const [getSong, { loading, error, refetch }] = useLazyQuery(query, {
    onCompleted: (res) => {
      dismissErrorMessage();
      // eslint-disable-next-line no-shadow
      const data = { song: res[resKey] };

      setData({ song: res[resKey] });
      mediaPlayerSelectedSongState({ loading, data, error });
      if (res[resKey] === null) return;
      // sort audios cos sometimes they do not come in the correct order
      const songCopy = { ...data.song };
      const sortedAudios = [...songCopy.audios]?.sort(
        (a, b) => b.isDefault - a.isDefault
      );
      songCopy.audios = sortedAudios;

      const audioSrs = getSongAudioSrcs(sortedAudios);

      const modifiedPages = wrapLines(songCopy.pages, songCopy.styleBodySize);

      mediaPlayerStore.song = {
        ...songCopy,
        src: audioSrs,
        pages: modifiedPages,
      };
      mediaPlayerStore.setSong(audioSrs[0]);
      mediaPlayerStore.setReady(true);
      currentSongTypeId(0);
      mediaPlayerStore.setLastPlayheadPositionMs(0);
      mediaPlayerStore.setCurrentPlayheadPositionMs(0);
      mediaPlayerStore.setPlayerHit({
        sessionUUID: uuidv4(),
        audioId: sortedAudios[0].id,
        songId: songCopy.id,
        shareId,
        updateExpected: true,
        updateSequence: 0,
        esongbookId,
      });

      if (guest) {
        playerActive(true);
        setTimeout(() => {
          mediaPlayerStore.setShowSlide(true);
        }, 3000);
        isPlayerFullscreen(true);
        if (isMobile && isPlayerFullscreen()) {
          isMobileFullscreen(true);
        }
        mediaPlayerStore.setShareId(shareId);
      }
    },
    onError: (err) => {
      mediaPlayerStore.setSong(null);
      mediaPlayerStore.setReady(false);
      mediaPlayerStore.setError(true);

      const errorMessage =
        err?.message === 'This action is unauthorized.'
          ? 'Access denied!'
          : 'Something went wrong';

      displayErrorMessage(errorMessage);
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const getSongRequest = useCallback(
    (id, songShareId = null, ootaShareUUID) => {
      getSong({
        variables: {
          id,
          ...(!guest && {
            organisation_ref,
          }),
        },
        ...(guest && {
          context: {
            headers: {
              ootaShareUUID,
            },
          },
        }),
      });
      // storing id in global state cos of some apollo bugs
      selectedSongId(id);
      setShareId(songShareId);
      return id;
    },
    []
  );

  loading && mediaPlayerStore.setReady(false);

  return { getSongRequest, data, refetch };
};

export default useGetSongForPlayer;
