/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import useRefetchTags from '../../../hooks/useRefetchTags';
import AgeRangeSelect from '../../Shared/CategoryPages/AgeRangeSelect';
import SubmitSection from '../../Shared/CategoryPages/SubmitSection';
import { defaultFilterValues } from '../../../utils/objects';

const FilterSection = ({ filter, resetFilter }) => {
  const { register, handleSubmit, reset } = useForm();

  const allTagTypesRefetchMapDefault = {};

  const types = [
    'Assembly',
    'Blog or Guide',
    'Curriculum links and more',
    'Music Activity',
    'SEND Resource',
  ].map((item) => ({ formattedName: item, id: item }));

  const filterResources = (data) => filter(data);

  const [handleTagChange, handleAgeRangeChange] = useRefetchTags(
    allTagTypesRefetchMapDefault,
    'Songs'
  );

  return (
    <div className="main__container bg__blue-nile mt-0">
      <div className="assembly__songfinder">
        <h2 className="assembly__songfinder__title">Find Resources</h2>
        <div className="assembly__filter assembly__filter__choir">
          <div className="assembly__songfinder__range">
            <label htmlFor="">Age range</label>
            <div className="assembly__songfinder__range__selectors">
              <AgeRangeSelect
                ref={register}
                name="min_age"
                handleAgeRangeChange={handleAgeRangeChange}
              />
              <p>to</p>
              <AgeRangeSelect
                ref={register}
                name="max_age"
                handleAgeRangeChange={handleAgeRangeChange}
              />
            </div>
          </div>

          <SubmitSection
            label="Content Types"
            ref={register}
            tags={types}
            name="resource_type"
            onClick={handleSubmit(filterResources)}
            reset={reset}
            handleTagChange={handleTagChange}
            handleClearFilters={() => {
              toast.info('Loading...', {
                toastId: 'resetFilter',
                autoClose: 2000,
              });
              resetFilter();
              reset(defaultFilterValues);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
