import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

const SortingDropdown = ({ sortings, onChange }) => {
  const [activeSortingIndex, setActiveSortingIndex] = useState(0);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="d-flex align-items-center dropdown-sorting">
      <span className="mr-3">Sort</span>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          className="d-flex align-items-center px-4 py-3"
          color="light"
        >
          {sortings[activeSortingIndex]?.label || sortings[0].label}{' '}
          <span className="ml-2">
            <i className="fa fa-chevron-down" />
          </span>
        </DropdownToggle>
        <DropdownMenu className="">
          {sortings.map((sorting, index) => (
            <DropdownItem
              key={index}
              onClick={() => {
                setActiveSortingIndex(index);
                onChange(sortings[index].value);
              }}
              className="d-flex"
            >
              {sorting.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SortingDropdown;
