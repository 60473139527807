/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/no-danger */
import React from 'react';
import useRenderResource from '../../../../hooks/Resource/useRenderResource';
import { typeObj } from '../../../../utils/objects';
import LessonPlanSong from '../../../CurriculumPlan/Song';
// import TextModule from '../../../Shared/TextModule';
import Song from './Song';

const Content = ({ type, text, title, songs, mb20 }) => {
  const { render } = useRenderResource();
  const formattedContent = text ? JSON.parse(text) : null;
  if (!formattedContent?.length > 0 && !songs) return null;

  if (type === 'sing') {
    if (songs?.length < 1) return null;

    return (
      <div className="card__plain mb-13">
        <div className="card__plain__header bg__blue-pacific">
          <p className="card__plain__heading mb-0">{title || 'Sing'}</p>
        </div>
        <div className="card__plain__body bg__white">
          <div className="row">
            {songs?.map((song) => (
              <Song
                title={song.title}
                key={song.id}
                id={song.id}
                albumArt={song.albumArt}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (type === 'singMusicCurriculum') {
    if (songs?.length < 1) return null;
    return (
      <div className="card__plain">
        <div className="card__plain__header bg__blue-pacific">
          <p className="card__plain__heading mb-0">Song choices</p>
        </div>
        <div className="card__plain__body bg__white">
          <div className="row cp__songchoice">
            {songs?.map(({ title: songTitle, id }) => (
              <LessonPlanSong title={songTitle} key={id} id={id} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`card__plain ${mb20 ? 'mb-20' : 'mb-13'}`}>
      <div
        className={`card__plain__header resource-header ${
          typeObj[type] || 'bg__mandy'
        }`}
      >
        <p className="card__plain__heading mb-0">{title}</p>
      </div>
      <div
        className="card__plain__body bg__white"
        style={{ paddingLeft: '32px', paddingRight: '32px' }}
      >
        {formattedContent?.map((resource) => (
          <React.Fragment key={resource.key}>
            {render(resource, true)}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Content;
