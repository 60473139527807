import { gql } from '@apollo/client';
import { MinSongFieldsFragment } from '../song/fragments';

export const AssemblyPlanFieldsFragments = gql`
  fragment AssemblyPlanFields on AssemblyPlan {
    id
    slug
    content
    engage
    wonder
    resource
    resourceQuestions
    reflect
    prayer
    goDeeper
    customSection
    downloadLink
    talkAbout
    bibleBit
    respond
    bibleBitOrRespond
    bibleBitOrReflect
    engagementActivity
    talkOrThinkAbout
    responsiveActivity
    prayerActivity
    playAnimation
    readStory
    songs {
      ...MinSongFields
    }
    assembly {
      id
    }
    assemblyPlanTags {
      id
      type
      formattedName
    }
  }
  ${MinSongFieldsFragment}
`;

export const AssemblyFieldsFragment = gql`
  fragment AssemblyFields on Assembly {
    id
    title
    description
    theme
    ageRange
    videoTitle
    videoUrl
    thumbnail
    ordering
    assemblyPlans {
      ...AssemblyPlanFields
    }
  }
  ${AssemblyPlanFieldsFragments}
`;

export const MinAssemblyFieldsFragment = gql`
  fragment MinAssemblyFields on Assembly {
    id
    title
    description
    theme
    ageRange
    thumbnail
    ordering
  }
`;
