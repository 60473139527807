import React from 'react';
import { toast } from 'react-toastify';
import useGetCategoryPage from '../../../../../hooks/useGetCategoryPage';
import GuidesAndResources from '../../../../Shared/GuidesAndResources';
import PlaylistCards from '../../../../Shared/PlaylistCards';
import SongCards from '../../../../Shared/SongCards/Index';

const MainSection = ({
  songs,
  resources,
  displayResources = true,
  displaySongs = true,
  displayPlaylists = true,
  filterParams,
}) => {
  const { data, loading } = useGetCategoryPage('choirs and concerts');
  loading && toast.info('Loading...', { toastId: 'loading' });

  return (
    <section className="main__container mt-40">
      <div className="px__sm-3">
        {displaySongs && (
          <SongCards
            title="Choirs and Concerts Songs"
            songs={songs}
            pageTitle="Choirs and Concerts Songs"
            name="Concerts/Performance"
            type="OOTA Singing School"
            filterParams={filterParams}
          />
        )}
        {displayResources && (
          <GuidesAndResources
            resources={resources}
            title="Concerts/Performance"
            type="OOTA Singing School"
          />
        )}
        {displayPlaylists && (
          <PlaylistCards
            playlists={data?.categoryPage?.playlists}
            title="Choirs and Concerts playlists"
            disableDeletePlaylist
          />
        )}
      </div>
    </section>
  );
};

export default MainSection;
