import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { albumArtDefault } from '../../utils/helpers';
import PlaySong from '../Shared/PlaySong';

const NativitiesAndMusicalsItem = ({ song, history }) => {
  const { albumArt, title, id, author } = song;

  return (
    <div className="col-lg-3 col-md-3 col-6">
      <div className="song__details">
        <div className="song__thumbnail mb-4 nativities">
          <>
            <img
              className="song__thumbnail__img"
              src={albumArt || albumArtDefault}
              alt={title}
            />
            <div>
              <PlaySong id={id} nativities />
            </div>
          </>
        </div>
        <div className="song__title text-white">
          <Tappable
            component="a"
            className="text-white"
            onTap={() => {
              history.push(`/song/${id}`);
            }}
          >
            {title}
          </Tappable>
        </div>
        <div className="my-3 text__fs-12">By {author}</div>
      </div>
    </div>
  );
};

export default withRouter(NativitiesAndMusicalsItem);
