import { useQuery } from '@apollo/client';
import { GET_SHARED_LINKS } from '../../graphql/queries/share/share';
import { displayErrorMessage } from '../../utils/helpers';

const useGetSharedLinks = (organisationRef) => {
  const { data, loading } = useQuery(GET_SHARED_LINKS, {
    variables: {
      organisation_ref: organisationRef,
    },
    onError: () => displayErrorMessage('Could not get shared links'),
  });

  return { data, loading };
};

export default useGetSharedLinks;
