import React from 'react';
import { showModal } from '../../../../styles/js/modals';

const EditPlaylist = ({ show }) => {
  if (!show) return null;

  return (
    <div className="mb-20 text__black text__fs-16">
      <a
        href=""
        className="text__black"
        data-target="#editPlaylistModal"
        data-toggle="modal"
        data-dismiss="modal"
        id="addTo"
        onClick={(e) => {
          e.preventDefault();
          showModal('editPlaylistModal');
        }}
      >
        <i className="fas fa-pencil-alt mr-3 text__fs-20 text__turquoise-medium" />{' '}
        Edit playlist
      </a>
    </div>
  );
};
export default EditPlaylist;
