/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Tappable from 'react-tappable';
import { useLocation, withRouter } from 'react-router-dom';
import { songChoiceSongs } from '../../../utils/reactiveVars';
import ContentHereSquare from '../ContentHereSquare';
import SongItem from '../SongItem';
import classNames from 'classnames';

const SongCards = ({
  title,
  songs,
  pageTitle,
  name,
  type,
  signingVideos,
  showContentHereImg,
  history,
  filterParams,
  seeAllRoute,
  limit = 6,
  onTap,
}) => {
  if ((!songs || songs?.length < 1) && !showContentHereImg) return null;
  const location = useLocation();

  // eslint-disable-next-line operator-linebreak
  const linkLocation =
    type === 'Recommended' ? '/recommended-songs' : '/song-choice';

  return (
    <>
      <div className="card-section-header mb-20 px-3 px-xl-0">
        <h3 className="text-uppercase">{title}</h3>
        <Tappable
          component="a"
          className="text__white text__fs-10 mr-3"
          onTap={() => {
            if (onTap) {
              onTap();
            } else {
              songChoiceSongs({
                path: location.pathname,
                pageTitle,
                name,
                type,
                songs,
                filterParams,
              });
              history.push(seeAllRoute || linkLocation);
            }
          }}
        >
          SEE ALL
        </Tappable>
      </div>
      <div className="px-xl-0 px-3">
        <div className="row mb-20">
          {songs
            ?.slice(0, limit)
            .map(({ title: songTitle, id, albumArt }, index) => (
              <div
                className={classNames({
                  'col-6': limit === 2,
                  'col-lg-2 col-md-4 col-6': limit === 6,
                })}
                key={index}
              >
                <SongItem
                  title={songTitle}
                  id={id}
                  albumArt={albumArt}
                  songs={songs}
                  signingVideos={!!signingVideos}
                />
              </div>
            ))}
          {showContentHereImg && <ContentHereSquare />}
        </div>
      </div>
    </>
  );
};

export default withRouter(SongCards);
