/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useCallback, useMemo, useState } from 'react';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useGetSongbooks from '../../hooks/Songbook/useGetSongbooks';
import SongbookSeries from '../../components/Shared/Songbook/SongbookSeries';
import useGetSongbookSeries from '../../hooks/Songbook/useGetSongbookSeries';
import SongbookSeriesModal from '../../components/Shared/Modals/SongbookSeriesModal/Index';
import Header from '../../components/Songbooks/Sections/Header';
import AllSongbookSeries from '../../components/Songbooks/Sections/AllSongbookSeries';

const Songbooks = () => {
  const [modalContent, setModalContent] = useState(null);
  const { songbooksGrouped } = useGetSongbooks(
    { excludeNativitiesMusicals: true },
    { fetchPolicy: 'network-only' }
  );
  const { data } = useGetSongbookSeries();

  const getModalContent = useCallback(
    (id) => {
      const series = data?.getSongbookSeries.find((item) => +item.id === +id);
      return series?.active ? series.content : null;
    },
    [data]
  );

  // Reorder the songbook series and put those categorized as 'Other' last
  const seriesReordered = useMemo(() => {
    const entries = Object.entries(songbooksGrouped);
    return [
      ...entries?.filter((el) => el[0] !== 'Other'),
      ...entries?.filter((el) => el[0] === 'Other'),
    ];
  }, [songbooksGrouped]);

  return (
    <>
      <Header />
      <section className="main__container pt-0 pt-lg-5 pt-xl-0 px-sm-10">
        <AllSongbookSeries songbookSeries={data?.getSongbookSeries} />
        <div className="px-xl-0 mt-5">
          {seriesReordered?.map((entry) => (
            <SongbookSeries
              title={entry[0]}
              songbooks={entry[1]}
              key={entry[0]}
              onClick={() =>
                setModalContent(getModalContent(entry[1][0].seriesId))
              }
              showModalContent={!!getModalContent(entry[1][0].seriesId)}
            />
          ))}
        </div>
      </section>
      <SongbookSeriesModal modalContent={modalContent} />
    </>
  );
};

export default withPageWrapper({ pageTitle: 'All Songbooks | Sparkyard' })(
  Songbooks
);
