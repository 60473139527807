import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { REMOVE_FROM_COLLECTION } from '../../graphql/mutations/collection';
import { GET_COLLECTION } from '../../graphql/queries/collection/collection';
import hideModal from '../../styles/js/modals';
import {
  dismissLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { toastErrorObj } from '../../utils/objects';
import {
  assemblyList,
  playlistList,
  resourceList,
  songbookList,
  songChoiceSongs,
} from '../../utils/reactiveVars';

const useRemoveFromCollection = (collectionId, itemType, itemId) => {
  const [removeFromCollection, { loading }] = useMutation(
    REMOVE_FROM_COLLECTION,
    {
      refetchQueries: [
        { query: GET_COLLECTION, variables: { id: collectionId } },
      ],
    }
  );

  loading && toast.info('Please wait...', { toastId: 'loading' });

  const removeFromCollectionRequest = {
    send: () => {
      removeFromCollection({
        variables: {
          id: parseInt(collectionId, 10),
          input: {
            item_type: itemType.toUpperCase(),
            item_id: parseInt(itemId, 10),
          },
        },
      })
        .then((res) => {
          displaySuccessMessage();
          dismissLoadingMessage();
          songChoiceSongs({
            ...songChoiceSongs(),
            songs: res.data.removeFromCollection.songs,
          });
          playlistList({
            ...playlistList(),
            playlists: res.data.removeFromCollection.playlists,
          });
          songbookList({
            ...songbookList(),
            songbooks: res.data.removeFromCollection.esongbooks,
          });
          assemblyList({
            ...assemblyList(),
            assemblies: res.data.removeFromCollection.assemblies,
          });
          resourceList({
            ...resourceList(),
            resources: res.data.removeFromCollection.resources,
          });
          hideModal();
        })
        .catch(() => {
          toast.dismiss('loading');
          toast.error('Operation failed.', toastErrorObj);
        });
    },
  };

  return { removeFromCollectionRequest };
};

export default useRemoveFromCollection;
