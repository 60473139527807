import React, { useEffect } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import TagManager from 'react-gtm-module';
const initializeGTM = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-WBCQK86',
  };
  TagManager.initialize(tagManagerArgs);
};

export const CookieBanner = () => {
  useEffect(() => {
    if (getCookieConsentValue('sy_cookie') === 'true') {
      initializeGTM();
    }
  }, []);

  return (
    <CookieConsent
      containerClasses="sy_cookie_container"
      style={{
        background: '#e5e5e5',
        color: '#000',
        fontFamily: '"Spartan", sans-serif, Bravura',
      }}
      contentClasses="sy_cookie_content"
      onAccept={initializeGTM}
      enableDeclineButton
      declineButtonText="Reject additional cookies"
      buttonText="accept additional cookies"
      buttonWrapperClasses="sy_cookie_wrapper"
      cookieName="sy_cookie"
      declineButtonStyle={{
        borderRadius: '5px',
        textDecoration: 'underline',
        background: 'transparent',
        color: '#de2962',
        backgroundBlendMode: 'normal',
        fontSize: '14px',
      }}
      flipButtons
      buttonStyle={{
        borderRadius: '5px',
        background: '#de2962',
        backgroundBlendMode: 'normal',
        color: '#FFF',
        textTransform: 'uppercase',
        fontSize: '14px',
      }}
    >
      We use necessary cookies to make our site work. We would also like to use
      additional analytics cookies to help us understand usage and make
      improvements.{' '}
      <a
        href="https://sparkyard.com/cookie-policy"
        style={{ color: '#DE2962' }}
      >
        You can view our cookies notice here.
      </a>
    </CookieConsent>
  );
};
