/* eslint-disable operator-linebreak */
import { useLazyQuery } from '@apollo/client';
import {
  GET_ORGANISATION_USAGE_REPORTS,
  GET_USER_USAGE_REPORTS,
} from '../../graphql/queries/report/report';
import { displayErrorMessage } from '../../utils/helpers';

const useGetUsageReport = (type) => {
  const query =
    type === 'user' ? GET_USER_USAGE_REPORTS : GET_ORGANISATION_USAGE_REPORTS;

  const [getUsageReport, { data, loading }] = useLazyQuery(query, {
    onError: () => displayErrorMessage('Could not get usage report'),
    notifyOnNetworkStatusChange: true,
  });

  const getUsageReportRequest = (input) => {
    getUsageReport({
      variables: { input },
    });
  };

  return { getUsageReportRequest, data, loading };
};

export default useGetUsageReport;
