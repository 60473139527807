import React from 'react';

const NeedMoreMusicals = () => (
  <div className="col-lg-2 col-md-4 col-6 col-md-auto pr-0 pr-md-4 pr-lg-0">
    <div className="nativities__details">
      <div className="nativities__thumbnail mb-4">
        <a href="https://outoftheark.co.uk">
          <img
            className="nativities__thumbnail__img"
            src="/img/need-more-musicals.png"
            alt=""
          />
        </a>
      </div>
    </div>
  </div>
);

export default NeedMoreMusicals;
