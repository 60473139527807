/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-shadow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-return-assign */
/* eslint-disable no-continue */
/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */
const useWrapLines = () => {
  function splitPhraseIntoLines(content) {
    // let newLines = content.split(" ");
    const newLines = content;
    if (newLines.length < 2) {
      return [content];
    }

    let openTags = [];
    const lines = [];
    newLines.forEach((contentLine) => {
      contentLine = contentLine.trim();
      openTags.forEach((openTag) => {
        contentLine = `<${openTag}>` + contentLine;
      });

      const closedTags = [];
      const openedTags = [];
      let inTag = false;
      let tagName = '';
      for (let i = contentLine.length; i >= 1; i--) {
        const char = contentLine[i - 1];
        if (char === '>') {
          inTag = true;
          tagName = '';
          continue;
        }
        if (inTag && char === '/') {
          inTag = false;
          closedTags.push(tagName.split('').reverse().join(''));
          i--;
          continue;
        }
        if (inTag && char === '<') {
          inTag = false;
          openedTags.push(tagName.split('').join(''));
          continue;
        }
        if (inTag) {
          tagName = char + tagName;
        }
      }

      if (openedTags.length > closedTags.length) {
        openTags = openedTags.slice(0, openedTags.length - closedTags.length);
      }
      const tagsToClose = openedTags.slice(closedTags.length).reverse();
      tagsToClose.forEach((tagToClose) => {
        contentLine += `</${tagToClose}>`;

        const pattern = new RegExp(
          `<${tagToClose}[^>]*><\\/${tagToClose}[^>]*>`
        );
        contentLine = contentLine.replace(pattern, '');
      });

      lines.push(contentLine);
    });

    return lines;
  }

  const wrapLines = (pages, fontSize) => {
    const threshold = fontSize > 40 ? 47 : 62;
    const modifiedPages = [];
    pages.forEach((page) => {
      const modifiedGroups = [];

      const pageCopy = { ...page };

      pageCopy.groups.forEach((group) => {
        const modifiedLines = [];

        group.lines.forEach((line) => {
          if (line.phrases.length === 1) {
            const { content } = line.phrases[0];
            if (content.length > threshold) {
              if (content.includes('<') && content.includes('>')) {
                const threshold = 52;
                const tags = content.match(/<([^<>]*)>/gm);
                const uniqueTags = [...new Set(tags)];
                let contentWithoutTags = content;
                uniqueTags.forEach(
                  (tag) =>
                    (contentWithoutTags = contentWithoutTags.replaceAll(
                      tag,
                      ''
                    ))
                );

                if (contentWithoutTags.length > threshold) {
                  const threshold = 55 + tags.length;
                  const contentArr = content.split(' ');
                  let modifiedContent = ['', ''];
                  for (let i = 0; i < contentArr.length; i++) {
                    if (modifiedContent[0].length < threshold) {
                      modifiedContent[0] = contentArr.slice(0, i + 1).join(' ');
                    } else {
                      modifiedContent[1] = contentArr.slice(i).join(' ');
                      break;
                    }
                  }

                  modifiedContent = splitPhraseIntoLines(modifiedContent);

                  modifiedLines.push(
                    {
                      ...line,
                      id: modifiedLines.length + line.id,
                      phrases: [
                        { ...line.phrases[0], content: modifiedContent[0] },
                      ],
                    },
                    {
                      ...line,
                      id: modifiedLines.length + line.id + 1,
                      phrases: [
                        { ...line.phrases[0], content: modifiedContent[1] },
                      ],
                    }
                  );
                } else {
                  modifiedLines.push({
                    ...line,
                    id: modifiedLines.length + line.id,
                  });
                }
              } else {
                const contentArr = content.split(' ');
                const modifiedContent = ['', ''];
                for (let i = 0; i < contentArr.length; i++) {
                  if (modifiedContent[0].length < threshold) {
                    modifiedContent[0] = contentArr.slice(0, i + 1).join(' ');
                  } else {
                    modifiedContent[1] = contentArr.slice(i).join(' ');
                    break;
                  }
                }
                modifiedLines.push({
                  ...line,
                  id: modifiedLines.length + line.id,
                  phrases: [
                    { ...line.phrases[0], content: modifiedContent[0] },
                  ],
                });
                if (modifiedContent[1]) {
                  modifiedLines.push({
                    ...line,
                    id: modifiedLines.length + line.id + 1,
                    phrases: [
                      { ...line.phrases[0], content: modifiedContent[1] },
                    ],
                  });
                }
              }
            } else {
              modifiedLines.push({
                ...line,
                id: modifiedLines.length + line.id,
              });
            }
          } else {
            modifiedLines.push({ ...line, id: modifiedLines.length + line.id });
          }
        });
        modifiedGroups.push({ ...group, lines: modifiedLines });
      });
      modifiedPages.push({ ...page, groups: modifiedGroups });
    });

    return modifiedPages;
  };

  return wrapLines;
};

export default useWrapLines;
