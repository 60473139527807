/* eslint-disable operator-linebreak */
import React from 'react';
import useHideSharedCollection from '../../../hooks/Collection/useHideSharedCollection';
import useHideSharedPlaylist from '../../../hooks/Playlist/useHideSharedPlaylist';

const HidePCModal = ({ type, id }) => {
  const { hideSharedCollectionRequest, loading: loadingHideSharedCollection } =
    useHideSharedCollection();
  const { hideSharedPlaylistRequest, loading: loadingHideSharedPlaylist } =
    useHideSharedPlaylist();

  const fnMap = {
    collection: hideSharedCollectionRequest,
    playlist: hideSharedPlaylistRequest,
  };

  const shouldDisableButton =
    loadingHideSharedCollection || loadingHideSharedPlaylist;

  return (
    <div
      className="modal fade"
      id="hidePCModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="hidePCModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="custom__modal__content bg__cello px-sm-32 px-74">
            <h6 className="text__fs-18 text__fw-200 text__white mt-20  mb-5 text__center lh-140">
              You will lose access to this {type}. Are you sure?
            </h6>

            <button
              className="button button__md button__green-meadow mx-auto mt-6"
              onClick={() => fnMap[type](id)}
              disabled={shouldDisableButton}
              data-dismiss="modal"
            >
              Continue
            </button>
            <button
              className="button button__md button__cerise-dark mx-auto mt-6"
              data-dismiss="modal"
              disabled={shouldDisableButton}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HidePCModal;
