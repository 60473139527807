/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-return-assign */
import { useReactiveVar } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import useGenerateShareLink from '../../../../hooks/Share/useGenerateShareLink';
import useGetSharedLinks from '../../../../hooks/Share/useGetSharedLinks';
import useGetShareLink from '../../../../hooks/Share/useGetShareLink';
import { organisationLoggedInAs, user } from '../../../../utils/reactiveVars';
import ModalHeader from './ModalHeader';
import RenewLink from './RenewLink';

export const generateShareLink = (uuid, songId) =>
  `${document.location.origin}/shared/song/${songId}/${uuid}`;

const ShareSongModal = ({ itemType, songId }) => {
  if (itemType !== 'SONG') return null;

  const [isCopied, setIsCopied] = useState(false);
  const [generatedShareLink, setGeneratedShareLink] = useState(null);
  const [loadingRenewShareLink, setLoadingRenewShareLink] = useState(false);

  const selectedOrganisation = useReactiveVar(organisationLoggedInAs);
  const organisationRef = selectedOrganisation?.id || null;

  const { data: getShareLinkData, loading: loadingGetShareLink } =
    useGetShareLink(songId, organisationRef);

  const {
    data: generateShareLinkData,
    generateShareLinkRequest,
    loading: loadingGenerateShareLink,
  } = useGenerateShareLink(songId, organisationRef);

  const { data: sharedLinks } = useGetSharedLinks(organisationRef);
  const { activeSysub } = user();

  const sharedLinksCount = sharedLinks?.getSharedLinks?.length || 0;
  const sharedLinksCountLimit = activeSysub ? 35 : 5;
  const hasReachedSharingLimit = sharedLinksCount >= sharedLinksCountLimit;

  useEffect(() => {
    setTimeout(() => setIsCopied(false), 5000);
  }, [isCopied]);

  // reset mutation data stored in state when song id or organisation changes
  useEffect(() => {
    setGeneratedShareLink(null);
  }, [songId, selectedOrganisation]);

  useEffect(() => {
    if (generateShareLinkData?.generateShareLink) {
      setGeneratedShareLink(generateShareLinkData?.generateShareLink);
    }
  }, [generateShareLinkData]);

  const loading =
    loadingGetShareLink || loadingGenerateShareLink || loadingRenewShareLink;
  const shareLink = getShareLinkData?.getShareLink || generatedShareLink;

  const disableButton = loading || hasReachedSharingLimit;

  return (
    <div
      className="modal fade"
      id="share-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="share-modal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered custom__modal__filter"
        role="document"
      >
        <div className="modal-content">
          <div className="custom__modal__content">
            {loading && (
              <div className="alert alert-info" role="alert">
                Please wait...
              </div>
            )}
            <ModalHeader
              hasReachedSharingLimit={hasReachedSharingLimit}
              shareLink={shareLink}
            />
            {shareLink?.status === 'Expired' && (
              <RenewLink
                shareLink={shareLink}
                setLoadingRenewShareLink={setLoadingRenewShareLink}
              />
            )}

            {shareLink && (
              <div className="row mb-0 pb-0">
                <div className="col-10 mr-0 pr-0">
                  <div className="input__control">
                    <input
                      type="text"
                      name=""
                      id=""
                      value={generateShareLink(shareLink?.uuid, songId)}
                      className="share__input"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-2 align-items-center">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigator.clipboard.writeText(
                        generateShareLink(shareLink?.uuid, songId)
                      );
                      setIsCopied(true);
                    }}
                  >
                    <i className="far fa-clone pt-1 share__copy-icon" />
                  </a>
                </div>
              </div>
            )}
            {!shareLink && (
              <button
                className="btn bg__cello text-white mt-4"
                onClick={() => generateShareLinkRequest()}
                disabled={disableButton}
              >
                Generate Share Link
              </button>
            )}

            {isCopied && (
              <span className="text-success text__fs-11">copied!</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareSongModal;
