/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { modalActions } from '../../utils/reactiveVars';

const LikeAndAddIcons = ({
  headerText,
  darkColor,
  favourite,
  addToPlaylist,
  addToCollection,
  share,
  modalId,
  type,
  deletePlaylist,
  songsList,
  id,
  itemType,
  largeIcon,
  editPlaylist,
  playlistName,
  sharePlaylist,
  canCopyPlaylist,
  copyCollection,
  hidePC,
}) => (
  <>
    <div
      className={`d-lg-flex align-items-center ${
        songsList ? 'ml-0' : 'ml-auto'
      }`}
    >
      <a
        href=""
        className="icon__add"
        data-toggle="modal"
        data-target={`#${modalId}`}
        onClick={() =>
          modalActions({
            headerText,
            favourite,
            addToPlaylist,
            addToCollection,
            share,
            modalId,
            type,
            deletePlaylist,
            id,
            itemType,
            editPlaylist,
            playlistName,
            sharePlaylist,
            canCopyPlaylist,
            copyCollection,
            hidePC,
          })
        }
      >
        <i
          className={`fas fa-ellipsis-v ${
            largeIcon ? 'text__fs-28' : 'text__fs-20'
          } text__fs-20 ${darkColor ? 'text-dark' : ''}`}
        />
      </a>
    </div>
  </>
);

export default LikeAndAddIcons;
