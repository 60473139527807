/* eslint-disable implicit-arrow-linebreak */
import { styled } from 'twin.macro';
import 'styled-components/macro';

type BackgroundContainerProps = {
  scale: number;
  containerDimensions: { width: number; height: number };
};
const BackgroundContainer = styled('div')<BackgroundContainerProps>`
  position: absolute;
  width: 1023.65px;
  height: 622px;
  transform: scale(${(props) => props.scale});
  transform-origin: 0 0;
  top: 0;
  left: ${(props) =>
    (props.containerDimensions.width - 1023.65 * props.scale) / 2}px;
`;

export default BackgroundContainer;
