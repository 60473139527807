import classNames from 'classnames';
import React from 'react';

const PageHeader = ({
  title,
  description,
  icon,
  banner,
  additionalComponents,
  headerClass,
  titlebarClass,
  descriptionBarClass,
}) => (
  <div className={classNames('page-header', headerClass)}>
    <div
      className={classNames(
        'section-padding mt-0 page-header__title-bar',
        titlebarClass
      )}
    >
      <div className="page-header__title-container">
        <div className="page-header__icon">{icon}</div>
        <h3 className="page-header__title">{title}</h3>
      </div>
      {additionalComponents}
    </div>
    <div
      className={classNames(
        'section-padding page-header__description-bar',
        descriptionBarClass
      )}
    >
      <span className="page-header__description">{description}</span>
      <div className="page-header__banner">{banner}</div>
    </div>
  </div>
);

export default PageHeader;
