/* eslint-disable operator-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import RouterLinkOrNot from '../../../../RouterLinkOrNot';
import SearchCollectionArt from '../../../../../Search/Sections/SearchCollectionArt';
import { albumArtDefault } from '../../../../../../utils/helpers';
import SearchPlaylistArt from '../../../../../Search/Sections/SearchPlaylistArt';
import useMarkUserNotificationsAsRead from '../../../../../../hooks/Notification/useMarkUserNotificationsAsRead';

const NotificationItem = ({ notification }) => {
  const {
    id,
    image,
    slug,
    title,
    subtitle,
    read,
    type,
    typeId,
    customLink,
    openInNewTab,
  } = notification;

  const { markUserNotificationsAsReadRequest } =
    useMarkUserNotificationsAsRead();

  const link = useMemo(() => {
    const linkMap = {
      song: `/song/${typeId}`,
      songbook: `/songbook/${typeId}`,
      assembly_plan: `/assembly/${typeId}`,
      collection: `/collection/${typeId}`,
      playlist: `/songs/playlists/${typeId}`,
      guide: `/resources/${slug}`,
      blog: `/resources/${slug}`,
      song_calendar: typeId === '0' ? `/song-calendar` : `/event/${slug}`,
      custom: customLink,
      user: '/manage-account',
      before_trial_ends: '/manage-account',
      before_sub_ends: '/manage-account',
      after_first_login: customLink,
    };
    return linkMap[type];
  }, []);

  const handleMarkAsRead = () => {
    markUserNotificationsAsReadRequest(id);
  };

  const TitleAndSubtitle = () => (
    <div className="notifications__item__content">
      <div className="notifications__item__content__header">{title}</div>
      <div>{subtitle}</div>
    </div>
  );

  const notificationImage = useMemo(() => {
    if (image) return image;
    return type === 'song_calendar'
      ? '/img/Resource-thumbnail_Calendar-resources.png'
      : albumArtDefault;
  }, []);

  return (
    <>
      {type === 'custom' ? (
        <a
          href={customLink}
          target={openInNewTab ? '_blank' : ''}
          rel="noreferrer"
          onClick={handleMarkAsRead}
          className={`notifications__item ${
            !read ? 'notifications__unread' : ''
          } w-100 text-white`}
        >
          <img src={image} className="notifications__item__image" alt="thumb" /> 
          <TitleAndSubtitle />
        </a>
      ) : (
        <RouterLinkOrNot
          href={customLink || link}
          target={openInNewTab ? '_blank' : ''}
          onClick={handleMarkAsRead}
          className={`notifications__item ${
            !read ? 'notifications__unread' : ''
          } w-100 text-white`}
        >
          {type === 'collection' && <SearchCollectionArt id={typeId} />}
          {type === 'playlist' && <SearchPlaylistArt id={typeId} />}
          {type === 'user' && (
            <>
              {image ? (
                <img
                  src={image}
                  className="notifications__item__image"
                  alt="thumb"
                />
              ) : (
                <div className="notifications__item__image notifications__item__no-avatar">
                  {subtitle.split(' ').slice(0, 2)[0][0]}{' '}
                  {subtitle.split(' ').slice(0, 2)[1][0]}
                </div>
              )}
            </>
          )}
          {!['collection', 'playlist', 'user'].includes(type) && (
            <img
              src={notificationImage}
              className="notifications__item__image"
              alt="thumb"
            />
          )}
          <TitleAndSubtitle />
        </RouterLinkOrNot>
      )}
    </>
  );
};

export default NotificationItem;
