/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useForm } from 'react-hook-form';
import useGetTag from '../../../../hooks/useGetTag';
import useRefetchTags from '../../../../hooks/useRefetchTags';
import { buildFilterParams } from '../../../../utils/helpers';
import AgeRangeSelect from '../../../Shared/CategoryPages/AgeRangeSelect';
import SubmitSection from '../../../Shared/CategoryPages/SubmitSection';

const FilterSection = ({ handleFilter, loading }) => {
  const withContent = {
    tags: ['Routines & Signals'],
    min_age: 5,
    max_age: 11,
  };
  const { data: routines, fetchMore: fetchMoreRoutines } = useGetTag(
    'Routines',
    { withContent }
  );

  const routineObjs = routines?.tags
    ?.slice()
    .sort((a, b) =>
      a.formattedName.toLowerCase().localeCompare(b.formattedName.toLowerCase())
    );

  const { register, handleSubmit, reset } = useForm();

  const allTagTypesRefetchMapDefault = {
    routines: fetchMoreRoutines,
  };

  const [
    handleClearFilters,
    handleTagChange,
    handleAgeRangeChange,
  ] = useRefetchTags(allTagTypesRefetchMapDefault, 'Routines & Signals');

  const handleFilterParams = (data) => handleFilter(buildFilterParams(data));

  return (
    <div className="main__container bg__blue-nile mt-0">
      <div className="assembly__find singsubject__songs__find">
        <h2 className="assembly__find__title">Find resources</h2>
        <div className="assembly__filter singsubject__age-range">
          <div className="assembly__songfinder__range">
            <label htmlFor="">Age range</label>
            <div className="assembly__songfinder__range__selectors">
              <AgeRangeSelect
                ref={register}
                name="min_age"
                handleAgeRangeChange={handleAgeRangeChange}
              />
              <p>to</p>
              <AgeRangeSelect
                ref={register}
                name="max_age"
                handleAgeRangeChange={handleAgeRangeChange}
              />
            </div>
          </div>

          <SubmitSection
            label="Uses"
            ref={register}
            tags={routineObjs}
            name="routines"
            onClick={handleSubmit(handleFilterParams)}
            disabled={loading}
            reset={reset}
            handleTagChange={handleTagChange}
            handleClearFilters={() => {
              handleClearFilters();
              handleSubmit(handleFilter({}));
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default FilterSection;
