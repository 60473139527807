import { gql } from '@apollo/client';
import { CategoryPageFieldsFragment } from './fragments';

export const GET_CATEGORY_PAGE = gql`
  query CategoryPage($name: String!) {
    categoryPage(name: $name) {
      ...CategoryPageFields
    }
  }
  ${CategoryPageFieldsFragment}
`;
