import React from 'react';
import Loading from '../../Shared/Loading';
import PlaylistList from '../PlaylistList';

const SparkyardPlaylists = ({ playlists, isLoading }) => (
  <>
    {isLoading && <Loading />}
    <PlaylistList playlists={playlists} disableDeletePlaylist />
  </>
);

export default SparkyardPlaylists;
