import { gql } from '@apollo/client';

export const HomepageFieldsFragment = gql`
  fragment HomepageFields on Homepage {
    id
    songId
    songTitle
    songDescription
    songImage
    songbookId
    songbookTitle
    songbookDescription
    songbookImage
    collectionId
    collectionTitle
    collectionDescription
    collectionImage
    blogTitle
    blogDescription
    blogThumbnail
    blogUrl
    mainContent
    eventsContent
    featuredSyResourceImage
    featuredSyResourceType
    featuredSyResourceTypeId
    featuredSyResourceTitle
    featuredSyResourceDescription
    featuredSyResourceOpenInNewTab
    openBlogLinkInNewTab
    newSparks {
      id
      resources {
        id
        slug
        title
        summary
        thumbnail
      }
    }
  }
`;
