/* eslint-disable operator-linebreak */
import React from 'react';
import useRenderResource from '../../../../hooks/Resource/useRenderResource';
import { formatResourceContent } from '../../../../utils/resource';

const SongbookSeriesModal = ({ modalContent }) => {
  const { render } = useRenderResource();

  return (
    <div
      className="modal fade"
      id="songbookSeriesModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="songbookSeriesModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog custom__modal__songbook-series modal-dialog-centered"
        role="document"
      >
        <div className="modal-content ">
          <div className="custom__modal__content custom__modal__songbook-series__content px-sm-32 px-32">
            <div className="custom__modal__header float-right">
              <a href="" className="custom__modal__close" data-dismiss="modal">
                <img src="/img/plus-icon.svg" alt="" />
              </a>
            </div>
            {formatResourceContent(modalContent)?.map((item) => (
              <React.Fragment key={item.key}>
                {render(item, null, ' ')}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SongbookSeriesModal;
