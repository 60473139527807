import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_EVENT } from '../graphql/queries/calendar/events';

const useGetEvent = (slug) => {
  const { data, loading, error } = useQuery(GET_EVENT, {
    variables: {
      slug,
    },
    onCompleted: () => toast.dismiss('loading'),
    onError: () => {
      toast.dismiss('loading');
      toast.error('Could not load event', { toastId: 'eventErr' });
    },
  });

  return { data, loading, error };
};

export default useGetEvent;
