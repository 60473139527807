/* eslint-disable func-names */
import $ from 'jquery';

const jquery = () => {
  $(document).ready(() => {
    // manually init carousel
    $('.carousel').carousel();
    // sidebar nav expand collapse for children
    // $('.sidebar__list__item').click(function () {
    //   $(this).toggleClass('active');
    // });
    // /* mobile sidebar */
    // $('#sidebar-cta').click((e) => {
    //   $('.aside').toggleClass('active');
    //   e.stopPropagation();
    // });
    // $(document).click(() => {
    //   $('#sidebar').removeClass('active');
    // });

    // desktop search input
    $('#searchbar').click(() => {
      $('.searchbar__dropdown').removeClass('d-none');
      $('.searchbar__icon-search').addClass('d-none');
      $('.searchbar__icon-cross').removeClass('d-none');
    });
    // mobile dropdown
    let show = false;
    $('body').on('click', '#search-dropdown-cta', function () {
      if (!show) {
        $('.searchbar__dropdown').removeClass('d-none');
        $('.searchbar__icon-search').addClass('d-none');
        $('.searchbar__icon-cross').removeClass('d-none');
        show = true;
      } else {
        $('.searchbar__dropdown').addClass('d-none');
        $('.searchbar__icon-search').removeClass('d-none');
        $('.searchbar__icon-cross').addClass('d-none');
        show = false;
      }
    });
    $('.searchbar__icon-search').click(() => {
      $('.searchbar__dropdown').addClass('d-none');
      $('.searchbar__icon-search').removeClass('d-none');
      $('.searchbar__icon-cross').addClass('d-none');
    });
    $('.searchbar__input, .searchbar__mobile__input').on('keyup', (e) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        $('.searchbar__dropdown').addClass('d-none');
        $('.searchbar__icon-search').removeClass('d-none');
        $('.searchbar__icon-cross').addClass('d-none');
      }
    });
    $('.searchbar__icon-cross').click(() => {
      $('.searchbar__input').val('');
      $('.searchbar__mobile__input').val('');
      $('.searchbar__dropdown').addClass('d-none');
      $('.searchbar__icon-search').removeClass('d-none');
      $('.searchbar__icon-cross').addClass('d-none');
    });
    // songs filter dropdown
    $(
      '#cta-filter-dropdown, #cta-filter-dropdown-cancel, #cta-filter-dropdown-apply, #cta-filter-dropdown-recommended'
    ).click(() => {
      $('.filter__dropdown').toggleClass('active');
    });
    // songs favorite
    $('.songslist__item__favorite, .icon__favorite, .item__favorite').click(
      () => {
        $(this).toggleClass('active');
      }
    );
    // song download and about
    $('.songpage__item__about__cta').click(() => {
      $('.songpage__item__about').toggleClass('active');
    });
    // resource download and about
    $('.resource__item__about__cta').click(() => {
      $('.resource__item__about').toggleClass('active');
    });
    // esongbook resource dropdown
    $('.esongbook__resources__cta').click(() => {
      $('.esongbook__resources').toggleClass('active');
    });

    // tooltips
    $('[data-toggle="tooltip"]').tooltip();

    // modals
    $('#addTo, #share').click(() => {
      $('#actions').modal('hide');
    });
    if (document.location.pathname !== '/manage-account') {
      $('body').on('hidden.bs.modal', () => {
        $('.modal-backdrop').remove();
      });
    }
  });
  $(document).mouseup((e) => {
    const container = $('.searchbar__dropdown');

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.addClass('d-none');
      $('.searchbar__icon-search').removeClass('d-none');
      $('.searchbar__icon-cross').addClass('d-none');
    }
  });

  // const matches = $('.main__container :not(script)')
  //   .contents()
  //   .filter(function () {
  //     return this.nodeType === 3;
  //   })

  //   console.log(matches);
  //   matches.replaceWith(function () {
  //     // console.log(this.nodeValue);
  //     return this.nodeValue.replace('the', 'test new string');
  //   });

  // for (let i = 0; i < matches.length; i++) {
  //   matches[i].replaceWith(function () {
  //     // console.log(this.nodeValue);
  //     return this.nodeValue.replace('the', 'The new string');
  //   });
  // }

  // console.log(
  //   $('.main__container :not(script)')
  //     .contents()
  //     .filter(function () {
  //       return this.nodeType === 3;
  //     })
  // );
};

export const hideToolTips = () => $('div').tooltip('hide');

export default jquery;
