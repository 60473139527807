import { useMutation } from '@apollo/client';
import { ACCEPT_OR_DECLINE_INVITE } from '../../graphql/mutations/organisation';
import { GET_USER } from '../../graphql/queries/user/user';
import {
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { persistOrganisationLoggedInAs } from '../../utils/user';

const useAcceptOrDeclineInvite = () => {
  const [acceptOrDeclineInvite, { loading }] = useMutation(
    ACCEPT_OR_DECLINE_INVITE,
    {
      refetchQueries: [{ query: GET_USER }],
      onError: () => displayErrorMessage('Something went wrong.'),
    }
  );

  const acceptOrDeclineInviteRequest = (input) => {
    const { organisationRef } = input;

    acceptOrDeclineInvite({
      variables: {
        input,
      },
    }).then(() => {
      persistOrganisationLoggedInAs(organisationRef);
      displaySuccessMessage();
      setTimeout(() => window.location.reload(), 1000);
    });
  };

  return { acceptOrDeclineInviteRequest, loading };
};

export default useAcceptOrDeclineInvite;
