import React from 'react';

const FormatSelection = ({ selectedFormat, setSelectedFormat }) => (
  <div className="manage-account__subscription-details-row checkbox__white">
    <span className="text__fw-600 pr-3">File format</span>
    <select
      className="form-control form-control-lg input__age-range"
      name="min_age"
      value={selectedFormat}
      onChange={(e) => setSelectedFormat(e.target.value)}
      style={{ width: '200px' }}
    >
      <option value="xlsx">XLSX</option>
      <option value="csv">CSV</option>
    </select>
  </div>
);

export default FormatSelection;
