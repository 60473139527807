import React from 'react';
import { redirectToOota } from '../../../../../../utils/helpers';
import {
  organisationId,
  subscriptionDetails,
} from '../../../../../../utils/reactiveVars';
import Card from './Card';
import LastCard from './LastCard';

const CardsSectionForAdmins = ({
  data,
  organisation,
  daysLeftOnSubscription,
  sysubs,
  addresses,
  sharedLinksCount,
  bottomText,
  topText,
  hasManualSubs,
  hasMultiplePaidSubs,
  playsPast30Days,
  isAdmin,
  effectiveUserLimit,
}) => (
  <>
    <div className="manage-account__card-section">
      <Card
        topText="You are using"
        middleText={`${data?.organisation.effectiveUserCount} out of ${data?.organisation.effectiveUserLimit}`}
        bottomText="available user accounts"
        cardColour="fuchsia"
        footerText="Invite & Manage Colleagues"
        modalTarget="inviteAndManageColleaguesModal"
        onClick={() => organisationId(organisation.organisationRef)}
      />
      <Card
        topText="In the last 30 days"
        middleText={`${playsPast30Days} songs`}
        bottomText="were played"
        cardColour="orange"
        footerText="Create usage report"
        modalTarget="createUsageReportModal"
      />
      <Card
        topText={topText || 'You have'}
        middleText={daysLeftOnSubscription}
        bottomText={bottomText || 'remaining in your subscription'}
        cardColour="blue-navy"
        footerText="Manage your subscription"
        modalTarget="manageSubscriptionModal"
        onClick={() => {
          subscriptionDetails({
            sysubs,
            addresses,
            hasManualSubs,
            hasMultiplePaidSubs,
            effectiveUserLimit,
          });
        }}
        disabled={!isAdmin}
      />
    </div>
    <div className="manage-account__card-section mb-5 mt__sm-0">
      <Card
        topText="You have"
        middleText={`${sharedLinksCount} songs`}
        bottomText="shared with students"
        cardColour="cerise-dark"
        footerText="Manage student links"
        modalTarget="manageStudentLinksModal"
      />
      {/* <Card
        topText="You have"
        middleText="12 songs"
        bottomText="purchased and shared"
        cardColour="cello"
        footerText="Manage OOTA account"
        onClick={redirectToOota}
      /> */}
      <LastCard onClick={redirectToOota} />
    </div>
  </>
);

export default CardsSectionForAdmins;
