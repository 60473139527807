import { useQuery } from '@apollo/client';
import { GET_COLLECTION_SHARE_LINK } from '../../graphql/queries/collection/collection';
import { displayErrorMessage } from '../../utils/helpers';

const useGetCollectionShareLink = (collectionId) => {
  const { data, loading } = useQuery(GET_COLLECTION_SHARE_LINK, {
    variables: {
      collection_id: collectionId,
    },
    onError: () => displayErrorMessage(),
  });

  return { data, loading };
};

export default useGetCollectionShareLink;
