/* eslint-disable operator-linebreak */
import { useObserver } from 'mobx-react-lite';
import React from 'react';
import tw, { css, styled } from 'twin.macro';
import 'styled-components/macro';
import useStores from '../hooks/useStores';
import { MediaButton } from './Icon';

type ButtonShadowProps = {
  leftButton?: boolean;
  rightButton?: boolean;
  navigation?: boolean;
  active?: boolean;
  isMobile?: boolean;
};
export const ButtonShadow = styled('div')<ButtonShadowProps>(
  ({ leftButton, rightButton, navigation, active, isMobile }) => [
    tw`h-18 w-22 flex items-center justify-center`,
    isMobile && tw`h-18 w-22`,
    leftButton && tw`rounded-br-xl`,
    leftButton && isMobile && tw`rounded-br-none rounded-bl-xl`,
    rightButton && tw`rounded-tr-xl`,
    rightButton && isMobile && tw`rounded-tr-none rounded-br-xl`,
    rightButton &&
      navigation &&
      css`
        padding-right: 19px;
      `,
    css`
      background-color: rgba(19, 27, 32, 0.9);
      z-index: 1001;
    `,
    active &&
      css`
        background-color: #40c0c0;
      `,
    css`
      @media (max-width: 767px),
        screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
        display: inline-flex;
      }
    `,
  ]
);

type SlideSwitcherContainerProps = { gridPosition: number; showSlide: boolean };
const SlideSwitcherContainer = styled.div<SlideSwitcherContainerProps>`
  ${tw`h-full w-full flex justify-center flex-col-reverse`}
  @media (max-width: 767px),screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    display: none;
  }
`;

export const PagesTab = styled('div')(() => [
  tw`h-16 w-22  mx-auto inset-x-0 rounded-lg box-border text-white text-center`,
  css`
    // background-color: rgba(36, 52, 62, 0.5);
    font-family: 'Spartan-Regular', 'Spartan', sans-serif;
    top: -0.75rem;
    font-size: 10px;
    line-height: 4.75rem; /* height - -top = lineheight (in rem's) */
  `,
]);

type SlideSwitcherProps = {
  gridPosition: number;
  currentSlide: number;
  totalSlides: number;
};

const SlideSwitcher = ({
  gridPosition,
  currentSlide,
  totalSlides,
}: SlideSwitcherProps): JSX.Element => {
  const { mediaPlayerStore } = useStores();
  // const switchSlide = (offset: number) => {
  //   const previoudPlayingState = mediaPlayerStore.playing;
  //   mediaPlayerStore.setPlaying(true);
  //   mediaPlayerStore.setJumpTo({
  //     phraseStarts:
  //       mediaPlayerStore.song.pages[currentSlide + offset].millisecondsStart,
  //     playSinglePhrase: false,
  //   });
  //   mediaPlayerStore.setPlaying(previoudPlayingState);
  // };

  const enablePlainTextView = () => mediaPlayerStore.enablePlainTextView();
  const enableFancyTextView = () => mediaPlayerStore.enableFancyTextView();

  return useObserver(() => (
    <SlideSwitcherContainer
      gridPosition={gridPosition}
      showSlide={mediaPlayerStore.showSlide}
    >
      <ButtonShadow active={mediaPlayerStore.plainTextView} leftButton>
        <MediaButton
          type="button"
          icon="Plaintext"
          onClick={enablePlainTextView}
        />
      </ButtonShadow>
      <ButtonShadow active={!mediaPlayerStore.plainTextView} rightButton>
        <MediaButton
          type="button"
          icon="Fancytext"
          onClick={enableFancyTextView}
        />
      </ButtonShadow>
      <div className="my-4" />
      <ButtonShadow leftButton>
        <MediaButton
          icon="ChangeSlide"
          rotate={-135}
          disabled={currentSlide === 0}
          onClick={() => {
            mediaPlayerStore.switchSlide(-1, currentSlide);
          }}
        >
          {'<'}
        </MediaButton>
      </ButtonShadow>
      <ButtonShadow>
        <PagesTab>
          {currentSlide + 1}/{totalSlides}
        </PagesTab>
      </ButtonShadow>
      <ButtonShadow rightButton navigation>
        <MediaButton
          icon="ChangeSlide"
          rotate={45}
          disabled={currentSlide === totalSlides - 1}
          onClick={() => {
            mediaPlayerStore.switchSlide(1, currentSlide);
          }}
        >
          {'>'}
        </MediaButton>
      </ButtonShadow>
      <div className="mb-var" />
    </SlideSwitcherContainer>
  ));
};

export default SlideSwitcher;
