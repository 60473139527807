import React from 'react';
import ModalBody from './ModalBody/Index';
import ModalHeader from './ModalHeader';

const SharePlaylistModal = ({ id, sharePlaylist }) => (
  <div
    className="modal fade"
    id="sharePlaylistModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="sharePlaylistModal"
    aria-hidden="true"
  >
    <div
      className="modal-dialog modal-dialog-centered custom__modal__filter"
      role="document"
    >
      <div className="modal-content">
        <div className="custom__modal__content">
          <ModalHeader />
          <ModalBody id={id} sharePlaylist={sharePlaylist} />
        </div>
      </div>
    </div>
  </div>
);

export default SharePlaylistModal;
