/* eslint-disable react/require-default-props */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { isMobileFullscreen } from '../../utils/reactiveVars';

const Spacer = (): JSX.Element => {
  const type = useReactiveVar(isMobileFullscreen)
    ? 'song-controls__spacer-mobile-fs'
    : 'song-controls__spacer';

  return <div className={type} />;
};

export default Spacer;
