import { useMutation } from '@apollo/client';
import { RENEW_SHARED_LINK } from '../../graphql/mutations/share';
import { GET_SHARED_LINKS } from '../../graphql/queries/share/share';

const useRenewSharedLink = () => {
  const [renewSharedLink, { loading, data }] = useMutation(RENEW_SHARED_LINK, {
    refetchQueries: [
      {
        query: GET_SHARED_LINKS,
      },
    ],
  });

  const renewSharedLinkRequest = (id) => {
    renewSharedLink({
      variables: { id },
    });
  };

  return { renewSharedLinkRequest, loading, data };
};

export default useRenewSharedLink;
