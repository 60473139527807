import { useLottie } from "lottie-react";

import treatboxSurpriseJson from '../../lottieJsonFiles/treatbox-surprise-animation.json';
import treatboxSurpriseJsonMobile from '../../lottieJsonFiles/treatbox-surprise-animation-mobile.json';


const TreatboxSurpriseAnimation: React.FC = () => {
  const options = {
    animationData: treatboxSurpriseJson,
    loop: false
  };
  const { View } = useLottie(options);
  return View;
};

const TreatboxSurpriseAnimationMobile: React.FC = () => {
  const options = {
    animationData: treatboxSurpriseJsonMobile,
    loop: false
  };
  const { View } = useLottie(options);
  return View;
};

export {
  TreatboxSurpriseAnimation,
  TreatboxSurpriseAnimationMobile,
};
