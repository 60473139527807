import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_MUSIC_CURRICULUM_OVERVIEW } from '../../graphql/queries/musicCurriculum/musicCurriculum';

const useGetMusicCurriculumOverview = () => {
  const { data, loading, error } = useQuery(GET_MUSIC_CURRICULUM_OVERVIEW, {
    onError: () => {
      toast.error('Something went wrong', { toastId: 'error' });
    },
  });

  return { data, loading, error };
};

export default useGetMusicCurriculumOverview;
