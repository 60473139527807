import React from 'react';

const ModalHeader = ({ headerText }) => (
  <div className="custom__modal__header mb-5">
    <h5 className="custom__modal__title font-weight-normal">{headerText}</h5>
    <a href="" className="custom__modal__close" data-dismiss="modal">
      <img src="/img/plus-icon.svg" alt="" />
    </a>
  </div>
);

export default ModalHeader;
