import { useQuery } from '@apollo/client';
import { GET_MEMBERSHIPS } from '../../graphql/queries/organisation/organisation';

const useGetMemberships = () => {
  const { data, loading, error } = useQuery(GET_MEMBERSHIPS);

  return { data, loading, error };
};

export default useGetMemberships;
