/* eslint-disable operator-linebreak */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-danger */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  formatList,
  removeHtmlClassFromString,
} from '../../../../utils/helpers';

const Tabs = ({
  selectedPlan,
  handleSelectedPlan,
  assemblyPlans,
  preparationTime,
}) => {
  const content = selectedPlan.content
    ? JSON.parse(selectedPlan.content)
    : null;

  let timeIcon;
  if (preparationTime?.length > 0) {
    const preparationTimeValue = preparationTime[0].formattedName;
    if (preparationTimeValue === 'Mild preparation') {
      timeIcon = '/img/icons-preptime-1.png';
    } else if (preparationTimeValue === 'Moderate preparation') {
      timeIcon = '/img/icons-preptime-2.png';
    } else if (preparationTimeValue === 'Advanced preparation') {
      timeIcon = '/img/icons-preptime-3.png';
    }
  }

  return (
    <div className="assembly__song__tabs mb-1">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {assemblyPlans.length !== 1 &&
          assemblyPlans.map((item, index) => (
            <li className="nav-item" key={item.id}>
              <Link
                className={`nav-link ${
                  item.id == selectedPlan.id ? 'active' : ''
                }`}
                data-toggle="tab"
                to={`/assembly/${item.assembly.id}/${item.slug}`}
                role="tab"
                aria-controls="first"
                aria-selected="true"
                onClick={() => handleSelectedPlan(index)}
              >
                {index + 1}
              </Link>
            </li>
          ))}
      </ul>
      <div className="tab-content" id="myTabContent">
        {(selectedPlan.downloadLink || content.length > 0) && (
          <div
            className="tab-pane fade show active"
            id="first"
            role="tabpanel"
            aria-labelledby="first-tab"
          >
            <div className="assembly__song__tabs__first">
              <div className="row">
                <div className="col-md-7 mb-4 pb-3 mb-md-0 pb-md-0">
                  {content.map((item, index) => (
                    <div
                      className={`d-flex align-items-start ${
                        index + 1 === content.length ? '' : 'mb-26'
                      }`}
                      key={item.key}
                    >
                      <img
                        src={item.attributes.image}
                        className="mr-25"
                        alt={item.attributes.title}
                        style={{ width: '25px', height: '25px' }}
                      />
                      <div>
                        <p className="summary__text-sm text__black text__fw-600">
                          {item.attributes.title}
                        </p>
                        <p
                          className="summary__text-sm text__black"
                          dangerouslySetInnerHTML={{
                            __html: formatList(
                              removeHtmlClassFromString(item.attributes.body)
                            ),
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {selectedPlan.downloadLink || preparationTime?.length > 0 ? (
                  <div className="col-lg-5">
                    {selectedPlan.downloadLink && (
                      <div className="d-flex align-items-start mb-4">
                        <i className="fas fa-cloud-download-alt mr-25 text__fs-16 text__blue-dodger" />
                        <div>
                          <p className="summary__text-sm text__black text__fw-600 mb-21">
                            Download printable assembly plan (PDF)
                          </p>
                          <a
                            href={selectedPlan.downloadLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src="/img/pdf-img.svg" alt="" />
                          </a>
                        </div>
                      </div>
                    )}
                    {preparationTime?.length > 0 && (
                      <div className="d-flex align-items-start">
                        <div>
                          <p className="summary__text-sm text__black ml-5 pl-4 text__fw-600 mb-21">
                            Preparation time
                            <br />
                            <img
                              src={timeIcon}
                              alt={preparationTime[0]}
                              width="70px"
                              className="mt-2"
                            />
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
