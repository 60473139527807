import { makeVar } from '@apollo/client';
import { mediaPlayerSelectedSongStateDefault } from './helpers';

export const currentSong = makeVar({});
export const currentResource = makeVar({});
export const currentSongTypeId = makeVar(0);
export const playerActive = makeVar(false);
export const isPlayerFullscreen = makeVar(false);
export const glossaries = makeVar([]);
export const songChoiceSongs = makeVar([]);
export const lastPhraseWidth = makeVar(60.54);
export const isMobileSidebarOpen = makeVar(false);
export const user = makeVar(null);
export const isMobileFullscreen = makeVar(false);
export const resourceList = makeVar([]);
export const playlistList = makeVar({});
export const videoList = makeVar([]);
export const songbookList = makeVar({});
export const assemblyList = makeVar({});
export const openTabType = makeVar('');
export const mediaPlayerSelectedSongState = makeVar(
  mediaPlayerSelectedSongStateDefault
);
export const modalActions = makeVar({
  headerText: '',
  favourite: false,
  addToPlaylist: false,
  addToCollection: false,
  share: false,
  modalId: 0,
  type: null,
  deletePlaylist: false,
});

export const userFavouriteSongs = makeVar([]);
export const userFavouriteResources = makeVar([]);
export const userFavouriteAssemblies = makeVar([]);
export const addToPlaylistSongId = makeVar(null);
export const addToCollectionItemObj = makeVar({});
export const deleteModalObj = makeVar({});
export const organisationDetails = makeVar({});
export const organisationId = makeVar();
export const selectedSongId = makeVar();
export const subscriptionDetails = makeVar({});
export const organisationLoggedInAs = makeVar(null);
export const loggedInAsPrivateUser = makeVar(false);
export const currentOrganisationHasActiveSub = makeVar(false);
export const userHasActiveSubOrTrial = makeVar(false);
export const currentlySelectedSubscription = makeVar({});
export const allSongsPageSongs = makeVar([]);
