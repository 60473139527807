/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import UserPlaylists from '../Tabs/UserPlaylists';
import SparkyardPlaylists from '../Tabs/SparkyardPlaylists';
import SharedPlaylists from '../Tabs/SharedPlaylists';
import SortingDropdown from '../SortingDropdown';
import { sortings } from '../datas';
import useGetPlaylists from '../../../hooks/Playlist/useGetPlaylists';
import { displayLoadingMessage } from '../../../utils/helpers';
import { useLocation } from 'react-router-dom';

const MainSection = () => {
  const { data: userPlaylists, loading: loadingUserPlaylists } =
    useGetPlaylists('user');

  const { data: syPlaylists, loading: loadingSyPlaylists } =
    useGetPlaylists('sy');

  const { data: sharedPlaylists, loading: loadingSharedPlaylists } =
    useGetPlaylists('shared');

  (loadingUserPlaylists || loadingSyPlaylists || loadingSharedPlaylists) &&
    displayLoadingMessage();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const toggleTab = (tab) => {
    if (activeTabIndex !== tab) setActiveTabIndex(tab);
  };

  const [selectedSorting, setSelectedSorting] = useState(sortings[0].value);

  const sortPlaylists = (value) => {
    setSelectedSorting(value);
  };

  const sortedUserPlaylists = useMemo(() => {
    const userPlaylistsLocal = userPlaylists
      ? [...userPlaylists.playlists]
      : [];

    switch (selectedSorting) {
      case 'date-created':
        return userPlaylistsLocal.sort(
          (a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)
        );

      case 'a-to-z':
        return userPlaylistsLocal.sort((a, b) => a.name.localeCompare(b.name));
      case 'manual':
        return userPlaylistsLocal;
      default:
        return userPlaylistsLocal;
    }
  }, [selectedSorting, userPlaylists]);

  const sortedSyPlaylists = useMemo(() => {
    const syPlaylistsLocal = syPlaylists ? [...syPlaylists.playlists] : [];

    switch (selectedSorting) {
      case 'date-created':
        return syPlaylistsLocal.sort(
          (a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)
        );
      case 'a-to-z':
        return syPlaylistsLocal.sort((a, b) => a.name.localeCompare(b.name));
      default:
        return syPlaylistsLocal;
    }
  }, [selectedSorting, syPlaylists]);

  const sortedSharedPlaylists = useMemo(() => {
    const sharedPlaylistsLocal = sharedPlaylists
      ? [...sharedPlaylists.playlistsSharedWithOrganisation]
      : [];

    switch (selectedSorting) {
      case 'date-created':
        return sharedPlaylistsLocal.sort(
          (a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)
        );
      case 'a-to-z':
        return sharedPlaylistsLocal.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      default:
        return sharedPlaylistsLocal;
    }
  }, [selectedSorting, sharedPlaylists]);

  const tabs = [
    {
      id: 'my-playlists',
      title: 'My Playlists',
      component: (
        <UserPlaylists
          isLoading={loadingUserPlaylists}
          playlists={sortedUserPlaylists}
          showDragIcon={selectedSorting === 'manual'}
        />
      ),
    },
    {
      id: 'sparkyard-playlists',
      title: 'Sparkyard Playlists',
      component: (
        <SparkyardPlaylists
          isLoading={loadingSyPlaylists}
          playlists={sortedSyPlaylists}
        />
      ),
    },
    {
      id: 'shared-with-me',
      title: 'Shared with me',
      component: (
        <SharedPlaylists
          isLoading={loadingSharedPlaylists}
          playlists={sortedSharedPlaylists}
        />
      ),
    },
  ];

  const filteredSortings = useMemo(() => {
    const currentTab = tabs[activeTabIndex];
    return sortings.filter((sorting) =>
      sorting.availableFor.includes(currentTab.id)
    );
  }, [sortings, tabs, activeTabIndex]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get('tab') || '';

  useEffect(() => {
    const index = tabs.findIndex((tab) => tab.id === activeTab);
    if (index >= 0) {
      setActiveTabIndex(index);
    }
  }, [activeTab]);

  return (
    <section className="main__container">
      <div className="px__sm-3">
        <div className="w-full max-w-mdX px-2 py-16 sm:px-0">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <Nav className="nav-tabs-simple mb-4 mb-md-0">
              {tabs.map((tab, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={classNames({ active: activeTabIndex === index })}
                    onClick={() => {
                      toggleTab(index);
                    }}
                  >
                    {tab.title}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <SortingDropdown
              sortings={filteredSortings}
              onChange={sortPlaylists}
            />
          </div>
          <TabContent activeTab={activeTabIndex} className="pt-5">
            {tabs.map((tab, index) => (
              <TabPane key={index} tabId={index}>
                {tab.component}
              </TabPane>
            ))}
          </TabContent>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
