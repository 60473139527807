import { useQuery } from '@apollo/client';
import {
  GET_COLLECTIONS_SHARED_WITH_ORGANISATION,
  GET_SY_COLLECTIONS,
  GET_USER_COLLECTIONS,
} from '../../graphql/queries/collection/collection';
import {
  dismissLoadingMessage,
  displayErrorMessage,
} from '../../utils/helpers';

const useGetCollections = (type, organisationRef = null) => {
  const queryMap = {
    user: GET_USER_COLLECTIONS,
    sparkyard: GET_SY_COLLECTIONS,
    shared: GET_COLLECTIONS_SHARED_WITH_ORGANISATION,
  };

  const query = queryMap[type];
  // add string "user" to array if you want to fetch only collections created in that organisation
  const includeOrganisationRefForTypes = ['shared'];

  const { data, loading, error, refetch } = useQuery(query, {
    onCompleted: () => dismissLoadingMessage(),
    ...(includeOrganisationRefForTypes.includes(type) && {
      variables: {
        organisation_ref: organisationRef,
      },
    }),
    onError: () => {
      // suppress error for shared collection as organisation ref
      // may not be available on page load yet
      if (type !== 'shared') {
        displayErrorMessage('Could not get collections');
      }
    },
  });

  return { data, loading, error, refetch };
};

export default useGetCollections;
