import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { useForm } from 'react-hook-form';

import useCreatePlaylist from '../../../../hooks/Playlist/useCreatePlaylist';
import { addToPlaylistSongId } from '../../../../utils/reactiveVars';
import FormError from '../../FormError';

const CreatePlaylistModal = () => {
  const { register, handleSubmit, errors, reset } = useForm();

  const songId = useReactiveVar(addToPlaylistSongId);
  const { createPlaylistRequest } = useCreatePlaylist(reset, songId);

  const handleCreatePlaylist = (playlist) => {
    createPlaylistRequest.send(playlist.name);
  };

  return (
    <div
      className="modal fade"
      id="createPlaylistModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="createPlaylistModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered custom__modal__filter"
        role="document"
      >
        <div className="modal-content">
          <div className="custom__modal__content">
            <div className="custom__modal__header">
              <h5 className="custom__modal__title">Add to...</h5>
              <a href="" className="custom__modal__close" data-dismiss="modal">
                <img src="/img/plus-icon.svg" alt="" />
              </a>
            </div>
            <h6 className="text__fs-14 text__fw-600 text__black mb-10">
              Enter a name for your new playlist below:
            </h6>
            <div className="input__control mb-3">
              <input
                type="text"
                name="name"
                placeholder="Playlist name"
                className="text-dark"
                ref={register({ required: true })}
              />
              {errors.name && <FormError text="required" />}
            </div>
            <button
              href=""
              className="button button__turquoise"
              onClick={handleSubmit(handleCreatePlaylist)}
              disabled={!!errors.name}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePlaylistModal;
