import { useMutation } from '@apollo/client';
import { COPY_PLAYLIST } from '../../graphql/mutations/playlist';
import { GET_PLAYLISTS } from '../../graphql/queries/playlist/playlist';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useCopyPlaylist = (setCopied) => {
  const [copyPlaylist, { loading }] = useMutation(COPY_PLAYLIST, {
    refetchQueries: [
      {
        query: GET_PLAYLISTS,
        variables: {
          createdBy: 'user',
          organisation_ref: organisationLoggedInAs()?.id || null,
        },
      },
    ],
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage();
    },
    onCompleted: () => {
      displaySuccessMessage('Playlist copied!');
      setCopied(true);
    },
  });

  const copyPlaylistRequest = (id) => {
    copyPlaylist({
      variables: { id },
    });
  };

  loading && dismissLoadingMessage('Please wait...');

  return { copyPlaylistRequest, loading };
};

export default useCopyPlaylist;
