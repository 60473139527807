/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-template */
import { useReactiveVar } from '@apollo/client';
import React, { useState } from 'react';
import {
  deleteModalObj,
  organisationLoggedInAs,
} from '../../../utils/reactiveVars';
import EditCollectionModal from '../../Shared/Modals/Collection/EditCollectionModal';
import ShareCollectionModal from '../../Shared/Modals/Collection/ShareCollectionModal/Index';
import { DeleteCollection } from './DeleteCollection';
import EditCollection from './EditCollection';
import ShareCollection from './ShareCollection';

const Dropdown = ({
  title,
  description,
  id,
  hasSharedWithOrganisation,
  shareId,
}) => {
  const [state, setState] = useState(
    'dropdown-menu dropdown-menu-right p-25 text__fs-16 mycollections__create'
  );

  // eslint-disable-next-line operator-linebreak
  const hideDropdownClassname =
    'dropdown-menu dropdown-menu-right p-25 text__fs-16 mycollections__create hide';

  const shareCollection = useReactiveVar(organisationLoggedInAs)
    ? { hasSharedWithOrganisation, shareId }
    : null;

  const hideDropdown = () => setState(hideDropdownClassname);

  return (
    <div className="dropdown">
      <a
        href="#"
        onClick={() => setState(state + ' show')}
        className="text-white"
      >
        <i
          className="fas fa-ellipsis-v text__fs-20"
          aria-haspopup="true"
          aria-expanded="false"
        />
      </a>

      <div className={state} style={{ top: 'inherit' }}>
        <form>
          <div className="d-flex justify-content-between mb-4">
            <span className="text__fs-18">Collection options</span>
            <i className="fas fa-times text__fs-24" onClick={hideDropdown} />
          </div>
          <div>
            <EditCollection onClick={hideDropdown} />
            <DeleteCollection
              onClick={() => {
                hideDropdown();
                deleteModalObj({ itemType: 'COLLECTION', id });
              }}
            />
            <ShareCollection onClick={hideDropdown} />
          </div>
        </form>
      </div>
      <EditCollectionModal title={title} description={description} id={id} />
      <ShareCollectionModal id={id} shareCollection={shareCollection} />
    </div>
  );
};

export default Dropdown;
