import React from 'react';

const Header = () => (
  <div className="main__container bg__lime mt-0">
    <div className="assembly__header collective__edition">
      <h2 className="assembly__header__title">Collective Worship</h2>
      <p className="assembly__header__text">
        Inspirational songs and resources to help you put the wow and wonder
        into your acts of collective worship.
      </p>
      <div className="assembly__header__buttons">
        <a href="" className="button__md button__white w-100">
          Assembly plans
        </a>
      </div>
    </div>
  </div>
);

export default Header;
