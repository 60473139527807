import { useQuery } from '@apollo/client';
import { GET_PLAYLIST_SHARE_LINK } from '../../graphql/queries/playlist/playlist';
import { displayErrorMessage } from '../../utils/helpers';

const useGetPlaylistShareLink = (playlistId) => {
  const { data, loading } = useQuery(GET_PLAYLIST_SHARE_LINK, {
    variables: {
      playlist_id: playlistId,
    },
    onError: () => displayErrorMessage(),
  });

  return { data, loading };
};

export default useGetPlaylistShareLink;
