import { gql } from '@apollo/client';
import { SysubFieldsFragment } from '../organisation/fragments';

export const UserInviteFieldsFragment = gql`
  fragment UserInviteFields on UserInvite {
    id
    organisationRef
    organisationName
  }
`;

export const UserFieldsFragment = gql`
  fragment UserFields on User {
    id
    avatar
    email
    firstname
    surname
    company
    accessToken
    refreshToken
    tokenExpires
    roles
    subjects
    minAge
    maxAge
    isValidated
    ootaId
    activeSysub
    activeTrial
    trialDateFrom
    trialDateUntil
    validationRequired
    loginCount
    categories
    playsPast30Days
    playsThisMonth
    isAdmin
    questions
    sysubs {
      ...SysubFields
    }
    invites {
      ...UserInviteFields
    }
    marketingConsent
  }
  ${SysubFieldsFragment}
  ${UserInviteFieldsFragment}
`;
