import { gql } from '@apollo/client';
import { MinSongFieldsFragment } from '../song/fragments';
import { EsongbookFieldsFragments } from '../songbook/fragments';

export const GET_NATIVITIES_MUSICALS = gql`
  query NativitiesMusicals {
    nativitiesMusicals {
      nativities {
        ...EsongbookFields
      }
      musicals {
        ...EsongbookFields
      }
      songbooks {
        ...EsongbookFields
      }
      songs {
        ...MinSongFields
      }
    }
  }
  ${EsongbookFieldsFragments}
  ${MinSongFieldsFragment}
`;

export const GET_PURCHASED_NATIVITIES = gql`
  query PurchasedNativities {
    purchasedNativities {
      ...EsongbookFields
    }
  }
  ${EsongbookFieldsFragments}
`;

export const GET_PURCHASED_MUSICALS = gql`
  query PurchasedMusicals {
    purchasedMusicals {
      ...EsongbookFields
    }
  }
  ${EsongbookFieldsFragments}
`;

export const GET_PURCHASED_SONGBOOKS = gql`
  query PurchasedSongbooks {
    purchasedSongbooks {
      ...EsongbookFields
    }
  }
  ${EsongbookFieldsFragments}
`;

export const GET_PURCHASED_SONGS = gql`
  query PurchasedSongs {
    purchasedSongs {
      ...MinSongFields
    }
  }
  ${MinSongFieldsFragment}
`;
