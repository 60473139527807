/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import tw, { styled } from 'twin.macro';
import 'styled-components/macro';

const GridContainer = styled('div')<{
  gridPosition: number;
  mobileFullscreen?: boolean;
}>`
  ${tw`flex flex-row justify-between items-center z-30`}
  ${({ gridPosition }) => gridPosition && `-ms-grid-column:  ${gridPosition}`};
  ${({ gridPosition }) => gridPosition && `grid-column:  ${gridPosition}`};
  width: 120px;

  @media (min-width: 767px) and (max-width: 1024px) {
    width: 10rem;
    padding-right: 10px;
  }
  @media (max-width: 767px),
    screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    width: 85px !important;
    padding-left: 21px;
    padding-right: 7px;
  }
  ${({ mobileFullscreen }) =>
    mobileFullscreen &&
    `
    @media (max-width: 767px),screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
      width: 183px !important;
      padding: 0;
    }`};
`;

export default GridContainer;
