import React from 'react';
import GeneratePlaylistShareLink from './GeneratePlaylistShareLink/Index';
import SharePlaylistWithOrganisation from './SharePlaylistWithOrganisation';

const ModalBody = ({ id, sharePlaylist }) => (
  <>
    <SharePlaylistWithOrganisation id={id} sharePlaylist={sharePlaylist} />
    <GeneratePlaylistShareLink playlistId={id} />
  </>
);

export default ModalBody;
