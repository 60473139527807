import { useQuery } from '@apollo/client';
import { GET_RANDOM_FEATURED_ASSEMBLIES } from '../../graphql/queries/assembly/assembly';

const useGetRandomFeaturedAssemblies = () => {
  const { data, loading } = useQuery(GET_RANDOM_FEATURED_ASSEMBLIES, {
    fetchPolicy: 'network-only',
  });

  return { data, loading };
};

export default useGetRandomFeaturedAssemblies;
