import React from 'react';

const ShareCollection = ({ onClick }) => (
  <div className="mb-26">
    <a
      href=""
      data-target="#shareCollectionModal"
      data-toggle="modal"
      className="text__black"
      onClick={onClick}
    >
      <i className="fa fa-share-alt text__turquoise-medium text__fs-20 mr-4" />{' '}
      Share this collection
    </a>
  </div>
);

export default ShareCollection;
