import React, { forwardRef } from 'react';

const FormRow = forwardRef(({ labelText, name, mini, value }, ref) => (
  <div className="form-row">
    <div className={`form-group ${mini ? 'col-md-4' : 'col-md-8'}`}>
      <label htmlFor="">{labelText}</label>
      <input
        type="text"
        className="form-control"
        name={name}
        ref={ref}
        defaultValue={value}
        key={value}
      />
    </div>
  </div>
));
FormRow.displayName = 'FormRow';

export default FormRow;
