import React from 'react';

const CategoryHeader = ({ title, backgroundColor }) => (
  <div
    className="card__plain__header resource-header"
    style={{ background: backgroundColor || '#8dc261' }}
  >
    <p className="card__plain__heading mb-0">{title}</p>
  </div>
);

export default CategoryHeader;
