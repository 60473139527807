import React from 'react';
import { useReactiveVar } from '@apollo/client';
import './css/index.css';
import App from './components/App';
import './css/tailwind.generated.css';
import { playerActive } from '../utils/reactiveVars';

const Player = (): JSX.Element | null => {
  if (!useReactiveVar(playerActive)) return null;

  return <App />;
};

export default Player;
