/* eslint-disable operator-linebreak */
import { format, getYear } from 'date-fns';
import React from 'react';
import { eventColourObject } from '../../../../utils/objects';
import Event from './Event';

const Month = ({ events }) => {
  const mappingEvents = (event) => {
    const eventDatestring = event.startDate.split(' ')[0];
    const eventDate = new Date(eventDatestring);

    return (
      <Event
        key={event.id}
        day={format(eventDate, 'dd')}
        description={event.description}
        title={event.title}
        slug={event.slug}
      />
    );
  };

  const firstEventDatestring = events[0].startDate.split(' ')[0];
  const firstEventDate = new Date(firstEventDatestring);
  return (
    <>
      <div
        className={`card__plain__header resource-header ${
          eventColourObject[format(firstEventDate, 'M')] ||
          eventColourObject[Math.floor(format(firstEventDate, 'M') / 2)] ||
          'bg__vivid-orange'
        } `}
      >
        <p className="card__plain__heading mb-0">
          {`${format(firstEventDate, 'MMMM')} ${getYear(firstEventDate)}`}
        </p>
      </div>
      <div className="card__plain__body plus-padding bg__white">
        {events
          ?.sort((a, b) => {
            const aDatestring = a.startDate.split(' ')[0];
            const aEventDate = new Date(aDatestring);
            const bDatestring = b.startDate.split(' ')[0];
            const bEventDate = new Date(bDatestring);
            return format(aEventDate, 'dd') - format(bEventDate, 'dd');
          })
          .map(mappingEvents)}
      </div>
    </>
  );
};

export default Month;
