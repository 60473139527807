import React from 'react';
import BannerQuote from '../components/Shared/BannerQuote/Index';
import QuotesModule from '../components/Song/Sections/SongsPageContent/Modules/QuotesModule';

const useRenderSparkles = (sparkle) => {
  if (sparkle.imageUrl) {
    return (
      <BannerQuote
        title={sparkle.title}
        text={sparkle.text}
        imageUrl={sparkle.imageUrl}
        backgroundColor={sparkle.backgroundColor}
      />
    );
  }
  return (
    <QuotesModule
      title={sparkle.title}
      backgroundColor={sparkle.backgroundColor}
      text={sparkle.text}
    />
  );
};

export default useRenderSparkles;
