/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import { displaySuccessMessage } from '../../../../../../../utils/helpers';

const CopyShareLink = ({ shareLink }) => {
  if (!shareLink) return null;
  const { playlistId, uuid } = shareLink;

  const [copiedLinkId, setCopiedLinkId] = useState(null);

  const shareLinkUrl = `${document.location.origin}/shared/playlist/${playlistId}/${uuid}`;

  useEffect(() => {
    setTimeout(() => setCopiedLinkId(null), 5000);
  }, [copiedLinkId]);

  copiedLinkId && displaySuccessMessage('Link copied!');

  return (
    <div className="row mt-2">
      <div className="col-10 mr-0 pr-0">
        <div className="input__control">
          <input
            type="text"
            name=""
            id=""
            value={shareLinkUrl}
            className="share__input"
            readOnly
          />
        </div>
      </div>
      <div className="col-2 align-items-center">
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(shareLinkUrl);
            setCopiedLinkId(shareLink.id);
          }}
        >
          <i className="far fa-clone pt-1 share__copy-icon" />
        </a>
      </div>
    </div>
  );
};

export default CopyShareLink;
