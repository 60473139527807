import { useQuery } from '@apollo/client';
import { GET_RECOMMENDED_RESOURCES } from '../../graphql/queries/resource/resource';
import {
  dismissLoadingMessage,
  displayErrorMessage,
} from '../../utils/helpers';

const useGetRecommendedResources = (first, page) => {
  const { data, loading, fetchMore } = useQuery(GET_RECOMMENDED_RESOURCES, {
    variables: {
      first,
      page,
    },
    onCompleted: () => dismissLoadingMessage(),
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage('Could not load recommended resources');
    },
  });

  return { data, loading, fetchMore };
};

export default useGetRecommendedResources;
