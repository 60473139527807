import { gql } from '@apollo/client';
import { UserFieldsFragment } from '../../queries/user/fragments';

export const LOGIN_USER = gql`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      ...UserFields
    }
  }
  ${UserFieldsFragment}
`;
