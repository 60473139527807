import { useQuery } from '@apollo/client';
import { GET_FAVOURITE_ASSEMBLIES } from '../../graphql/queries/assembly/assembly';

const useGetFavouriteAssemblies = () => {
  const { data, loading, error } = useQuery(GET_FAVOURITE_ASSEMBLIES, {
    onError: () => {},
  });

  return { data, loading, error };
};

export default useGetFavouriteAssemblies;
