/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Song from '../../Songs/Song';
import { organisationLoggedInAs } from '../../../utils/reactiveVars';

const ReorderableSongsList = ({
  songs,
  type,
  esongbookId,
  isUserResource,
  orderingFunction,
}) => {
  const [state, setstate] = useState([]);

  useEffect(() => {
    const songsCopy = [...songs];
    const sortedResources = songsCopy.sort((a, b) => a.ordering - b.ordering);
    setstate(sortedResources);
  }, [songs]);

  const buildInput = (songs) =>
    songs.map(({ id }, index) => ({
      id,
      ordering: index + 1,
    }));

  const handleOnDragEnd = (result) => {
    const items = Array.from(state);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination?.index, 0, reorderedItem);
    setstate(items);
    const input = buildInput(items);
    orderingFunction(input);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable-1" type="FAVOURITES">
        {(provided, snapshot) => (
          <div
            className="songslist__container"
            ref={provided.innerRef}
            style={{ maxWidth: '698px' }}
          >
            {state?.map(({ id, title, duration, albumArt }, index) => (
              <Draggable
                draggableId={id}
                index={index}
                key={id}
                isDragDisabled={!isUserResource}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    snapshot={snapshot}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Song
                      key={id}
                      id={id}
                      title={title}
                      duration={duration || 0}
                      albumArt={albumArt}
                      type={type}
                      songs={songs}
                      esongbookId={esongbookId}
                      dragEnabled={isUserResource}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ReorderableSongsList;
