import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_ASSEMBLIES_BY_THEME } from '../../graphql/queries/assembly/assembly';

const useGetAssembliesByTheme = (where) => {
  const { data, loading, error } = useQuery(GET_ASSEMBLIES_BY_THEME, {
    variables: {
      where,
    },
    onCompleted: () => toast.dismiss('loading'),
    onError: () => {
      toast.dismiss('loading');
      toast.error('Could not load assemblies', { toastId: 'error' });
    },
    notifyOnNetworkStatusChange: true,
  });

  return { data, loading, error };
};

export default useGetAssembliesByTheme;
