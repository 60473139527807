import React from 'react';

const ContentBanner = () => (
  <div className="assembly__card theme-page rounded-0">
    <a href="" className="assembly__card__thumbnail">
      <img src="/img/content-here.png" alt="" style={{ objectFit: 'cover' }} />
    </a>
    <div className="text-black text__fs-14 text__fw-400">FIND SOME CONTENT TO GO HERE </div>
  </div>
);

export default ContentBanner;
