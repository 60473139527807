/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Image } from 'react-konva';

type Props = {
  x: number;
  y: number;
  src: string;
};

function URLImage({ src, x, y }: Props) {
  const imageRef = useRef<undefined | HTMLImageElement>(undefined);
  const [image, setImage] = useState<undefined | HTMLImageElement>(undefined);

  const handleLoad = useCallback(() => {
    setImage(imageRef.current);
  }, []);

  const loadImage = useCallback(() => {
    const img = new window.Image();
    img.src = src;
    // img.crossOrigin = 'Anonymous';
    imageRef.current = img;
    imageRef.current.addEventListener('load', handleLoad);
  }, [imageRef, handleLoad, src]);

  useEffect(() => {
    loadImage();
    return () => {
      if (imageRef.current !== undefined) {
        imageRef.current.removeEventListener('load', handleLoad);
      }
    };
  }, [imageRef, loadImage, handleLoad]);

  return <Image x={x} y={y} image={image} />;
}

export default URLImage;
