import { gql } from '@apollo/client';

export const GET_USER_USAGE_REPORTS = gql`
  query UserUsage($input: UserUsageInput!) {
    userUsage(input: $input) {
      csv
    }
  }
`;

export const GET_ORGANISATION_USAGE_REPORTS = gql`
  query OrganisationUsage($input: OrganisationUsageInput!) {
    organisationUsage(input: $input) {
      file
    }
  }
`;
