import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { HIDE_SHARED_COLLECTION } from '../../graphql/mutations/collection';
import { GET_COLLECTIONS_SHARED_WITH_ORGANISATION } from '../../graphql/queries/collection/collection';
import {
  dismissLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useHideSharedCollection = () => {
  const organisationRef = organisationLoggedInAs()?.id || null;
  const history = useHistory();

  const [hideSharedCollection, { loading }] = useMutation(
    HIDE_SHARED_COLLECTION,
    {
      refetchQueries: [
        {
          query: GET_COLLECTIONS_SHARED_WITH_ORGANISATION,
          variables: {
            organisation_ref: organisationRef,
          },
        },
      ],
      onCompleted: () => {
        dismissLoadingMessage();
        displaySuccessMessage('Collection unfollowed!');
        history.push('/collections');
      },
    }
  );

  const hideSharedCollectionRequest = (collectionId) => {
    hideSharedCollection({
      variables: {
        collection_id: collectionId,
        organisation_ref: organisationRef,
      },
    });
  };

  return { hideSharedCollectionRequest, loading };
};

export default useHideSharedCollection;
