import React from 'react';

const Header = () => (
  <div className=" main__container bg__bush-lilac-dark">
    <div className="singcommunity__header">
      <div className="mt-0">
        <h2 className="singcommunity__header__title">Sing In The Community</h2>
        <p className="singcommunity__header__text">
          Songs to help you build and strengthen community, both inside and
          outside of school.
        </p>
      </div>
    </div>
  </div>
);

export default Header;
