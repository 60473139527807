/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useUpdateFavourites from '../../../hooks/Favourite/useUpdateFavourites';
import ResourceItem from '../ResourceItem';

const MainSection = ({ resources, assemblies }) => {
  const allResources = [...resources, ...assemblies];
  const sortedResources = allResources.sort((a, b) => a.ordering - b.ordering);

  const [state, setstate] = useState(sortedResources);
  const { updateFavouritesRequest } = useUpdateFavourites();

  useEffect(() => {
    // set state when props change due to filters being applied
    setstate(allResources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources, assemblies]);

  const getIds = (items) => {
    const resourceIds = [];
    const assemblyIds = [];

    const fnMap = {
      Resource: resourceIds,
      Assembly: assemblyIds,
    };

    const idMap = {
      Resource: 'resource_id',
      Assembly: 'assembly_id',
    };

    items.forEach((element, index) => {
      fnMap[element.__typename].push({
        [idMap[element.__typename]]: element.id,
        ordering: index + 1,
      });
    });

    return { resources: resourceIds, assemblies: assemblyIds };
  };

  const handleOnDragEnd = (result) => {
    const items = Array.from(state);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setstate(items);
    const input = getIds(items);
    updateFavouritesRequest.send(input);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable-1" type="FAVOURITES">
        {(provided, snapshot) => (
          <section className="main__container mt-5 px-sm-15" ref={provided.innerRef}>
            {state?.map((resource, index) => (
              <Draggable
                draggableId={`${resource.__typename}-${resource.id}`}
                index={index}
                key={resource.id}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    snapshot={snapshot}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ResourceItem resource={resource} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </section>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default MainSection;
