import { useMutation } from '@apollo/client';
import { VERIFY_COLLECTION_SHARE_LINK } from '../../graphql/mutations/collection';
import {
  displayErrorMessage,
  displayLoadingMessage,
} from '../../utils/helpers';

const useVerifyCollectionShareLink = (collectionId, uuid) => {
  const [verifyCollectionShareLink, { loading, data }] = useMutation(
    VERIFY_COLLECTION_SHARE_LINK,
    {
      variables: {
        where: {
          AND: [
            {
              column: 'COLLECTIONID',
              value: collectionId,
            },
            {
              column: 'UUID',
              value: uuid,
            },
          ],
        },
      },
      onCompleted: (res) => {
        const shareId = res.verifyCollectionShareLink?.id;
        if (!shareId) {
          return displayErrorMessage('Invalid link!');
        }
      },
      onError: () => displayErrorMessage('Something went wrong!'),
    }
  );

  loading && displayLoadingMessage('Verifiying link...');

  return { verifyCollectionShareLink, loading, data };
};

export default useVerifyCollectionShareLink;
