import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { defaultAvatarMid } from '../../../../utils/objects';
import { user } from '../../../../utils/reactiveVars';
import EditAccountModal from '../../../Shared/Modals/ManageAccount/EditAccountModal/EditAccountModal';
import PrivateUserCardsSection from './PrivateUserCardsSection';

const AccountSegment = () => {
  const {
    firstname,
    surname,
    avatar,
    roles,
    sysubs,
    activeSysub,
    activeTrial,
    trialDateFrom,
    trialDateUntil,
  } = useReactiveVar(user);

  const avatarSrc = avatar || defaultAvatarMid;

  return (
    <>
      <div className="manage-account__segment">
        <span className="manage-account__segment__header">YOUR ACCOUNT</span>
        <div className="row justify-content-between">
          <div className="col d-flex align-items-center">
            <div className="d-inline-block manage-account__avatar__md">
              <img src={avatarSrc} alt="user" />
            </div>
            <div className="manage-account__segment__name">
              <span className="manage-account__segment__name__primary">
                {firstname} {surname}
              </span>
              <span className="manage-account__segment__name__secondary">
                {roles?.map((role, roleIdx) => (
                  <>
                    <span>{role}</span>
                    {roleIdx !== roles?.length - 1 && <>, </>}
                  </>
                ))}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-auto">
            <div className="mt-10 mt-md-0" />
            <button
              className="btn button__green-meadow w-sm-100 manage-account__segment__button-edit-account"
              data-target="#editAccountModal"
              data-toggle="modal"
            >
              Edit your account details
            </button>
          </div>
        </div>
        <PrivateUserCardsSection
          sysubs={sysubs}
          activeSysub={activeSysub}
          activeTrial={activeTrial}
          trialDateFrom={trialDateFrom}
          trialDateUntil={trialDateUntil}
        />
      </div>
      <EditAccountModal />
    </>
  );
};

export default AccountSegment;
