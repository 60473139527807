/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { toast } from 'react-toastify';
import useGetRandomFeaturedAssemblies from '../../../../../hooks/Assembly/useGetRandomFeaturedAssemblies';
import useGetCategoryPage from '../../../../../hooks/useGetCategoryPage';
import AssemblyCards from '../../../../Shared/AssemblyCards/Index';
import GuidesAndResources from '../../../../Shared/GuidesAndResources';
import PlaylistCards from '../../../../Shared/PlaylistCards';
import Songbooks from '../../../../Shared/Songbook/Index';
import SongCards from '../../../../Shared/SongCards/Index';
// import AssembliesBanner from './Sections/AssembliesBanner';

const MainContent = ({
  songs,
  resources,
  signingVideos,
  displayResources = true,
  displaySongs = true,
  displayPlaylists = true,
  displaySongbooks = true,
  displaySongVideos = true,
  displayAssemblies = true,
  playlists,
  classAssembly,
  filterParams,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { data: categoryPage, loading } = useGetCategoryPage('assemblies');
  loading && toast.info('Loading...', { toastId: 'loading' });

  const { data: randomFeaturedAssemblies } = useGetRandomFeaturedAssemblies();

  return (
    <section className="main__container">
      {displaySongs && (
        <SongCards
          title="Assembly Songs"
          songs={songs}
          pageTitle="Assembly"
          name="Assembly"
          type="OOTA Singing School"
          filterParams={filterParams}
        />
      )}
      <AssemblyCards
        assemblies={randomFeaturedAssemblies?.featuredAssembliesOnCategoryPage}
        linkToSeeAllPage="/assembly-plans"
        title="Assembly Plans"
      />
      {displayPlaylists && (
        <PlaylistCards
          playlists={playlists}
          title="Assembly playlists"
          disableDeletePlaylist
        />
      )}
      <Songbooks songbooks={classAssembly} title="Class Assembly Series" />
      {displaySongVideos && (
        <SongCards
          title="Songs with signing videos"
          songs={signingVideos}
          pageTitle="Assembly"
          name="Yes"
          type="Has Video"
          signingVideos
        />
      )}
      {/* <AssembliesBanner /> */}
      {displayAssemblies && (
        <SongCards title="Recommended assembly songs for you" />
      )}
      {displaySongbooks && <SongCards title="Recommended eSongbooks" />}
      {displayResources && (
        <GuidesAndResources
          resources={resources}
          title="Assembly"
          type="OOTA Singing School"
        />
      )}
    </section>
  );
};

export default MainContent;
