import { gql } from '@apollo/client';
import { NotificationFieldsFragment } from '../../queries/notification/fragments';

export const MARK_USER_NOTIFICATIONS_AS_READ = gql`
  mutation MarkUserNotificationsAsRead($id: ID) {
    markUserNotificationsAsRead(id: $id) {
      ...NotificationFields
    }
  }
  ${NotificationFieldsFragment}
`;
