/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { formatTime } from '../../player/components/ProgressBar';
import useStores from '../../player/hooks/useStores';
import { albumArtDefault } from '../../utils/helpers';
import LikeAndAddIcons from '../Shared/LikeAddIcons';
import PlaySong from '../Shared/PlaySong';

const Song = ({
  title,
  duration,
  id,
  albumArt,
  type,
  songs,
  esongbookId,
  history,
  dragEnabled,
}) => {
  const { mediaPlayerStore } = useStores();

  const orderedSongs = [...songs].sort((a, b) => {
    if (a.pivot) {
      return a.pivot.ordering - b.pivot.ordering;
    }

    return (a.ordering || 0) - (b.ordering || 0);
  });

  return (
    <>
      <div className="songslist__item">
        <div
          className="songslist__item__thumbnail"
          onClick={() => mediaPlayerStore.setPlaylist(orderedSongs)}
        >
          <img
            src={albumArt || albumArtDefault}
            className="songslist__item__thumbnail__bg"
            alt={title}
          />
          <PlaySong id={id} esongbookId={esongbookId} mini />
        </div>
        <Tappable
          component="a"
          className="songslist__item__title"
          onTap={() => {
            history.push(`/song/${id}`);
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Tappable>
        <p className="songslist__item__duration">
          {formatTime(duration / 1000)}
        </p>
        {dragEnabled && (
          <img
            src="/img/order-white.svg"
            alt="order"
            className="pr-4"
            width="48"
          />
        )}
        <LikeAndAddIcons
          type={type}
          headerText="Song options"
          modalId="songlistActions"
          id={id}
          favourite
          addToCollection
          addToPlaylist
          share
          songsList
          itemType="SONG"
        />
      </div>
    </>
  );
};

export default withRouter(Song);
