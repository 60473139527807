/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

const ModalHeader = () => (
  <>
    <div className="custom__modal__header">
      <h5 className="custom__modal__title">Share Options</h5>
      <a href="" className="custom__modal__close" data-dismiss="modal">
        <img src="/img/plus-icon.svg" alt="" />
      </a>
    </div>
  </>
);

export default ModalHeader;
