/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
import React from 'react';

const Checkbox = ({ role, checked, handleChange, onCheck }) => {
  const roleFormatted = role?.replaceAll('/', '/<wbr>');

  return (
    <div
      className="col-4 pr-0 d-flex align-items-start mb-3 checkbox__white "
      key={role}
    >
      <input
        type="checkbox"
        id={role}
        name="roles"
        className="checkbox__white mr-3"
        checked={checked}
        onChange={(e) => {
          handleChange(role, e.target.checked);
          onCheck();
        }}
      />
      <label
        htmlFor={role}
        className="text-white d-inline"
        style={{ wordBreak: 'break-word' }}
        dangerouslySetInnerHTML={{ __html: roleFormatted }}
      />
    </div>
  );
};

export default Checkbox;
