import { useQuery } from '@apollo/client';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
} from '../../utils/helpers';
import { GET_SONGBOOK_SERIES } from '../../graphql/queries/songbook/songbookSeries';

const useGetSongbookSeries = () => {
  const { data, loading } = useQuery(GET_SONGBOOK_SERIES, {
    onCompleted: () => dismissLoadingMessage(),
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage('Could not load songbook series');
    },
  });

  loading && displayLoadingMessage('Loading songbook series...');

  return { data };
};

export default useGetSongbookSeries;
