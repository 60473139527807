import { useMutation } from '@apollo/client';
import { VERIFY_PLAYLIST_SHARE_LINK } from '../../graphql/mutations/playlist';
import { displayErrorMessage, displayLoadingMessage } from '../../utils/helpers';

const useVerifyPlaylistShareLink = (playlistId, uuid) => {
  const [verifyPlaylistShareLink, { loading, data }] = useMutation(
    VERIFY_PLAYLIST_SHARE_LINK,
    {
      variables: {
        where: {
          AND: [
            {
              column: 'PLAYLISTID',
              value: playlistId,
            },
            {
              column: 'UUID',
              value: uuid,
            },
          ],
        },
      },
      onCompleted: (res) => {
        const shareId = res.verifyPlaylistShareLink?.id;
        if (!shareId) {
          return displayErrorMessage('Invalid link!');
        }
      },
      onError: () => displayErrorMessage('Something went wrong!'),
    }
  );

  loading && displayLoadingMessage('Verifiying link...');

  return { verifyPlaylistShareLink, loading, data };
};

export default useVerifyPlaylistShareLink;
