/* eslint-disable operator-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useReactiveVar } from '@apollo/client';
import ResourceItem from '../../components/ResourcesFavourite/ResourceItem';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useUpdateCollection from '../../hooks/Collection/useUpdateCollection';
import { resourceList } from '../../utils/reactiveVars';
import Header from '../../components/Collection/SeeAllResources/Header';
import { filterTypeMap } from '../../utils/objects';

const SeeAllResources = () => {
  const { resources, isUserCollection, collectionId, title } =
    useReactiveVar(resourceList);
  if (!resources) return <span className="mx-5">No data</span>;

  const resourceCopy = [...resources];
  const sortedResources = resourceCopy.sort((a, b) => a.ordering - b.ordering);
  const [state, setstate] = useState(sortedResources);

  const { updateCollectionRequest } = useUpdateCollection(
    collectionId,
    'resources'
  );

  const buildInput = (resources) =>
    resources.map(({ id }, index) => ({
      id,
      ordering: index + 1,
    }));

  const handleOnDragEnd = (result) => {
    const items = Array.from(state);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setstate(items);
    const input = buildInput(items);
    updateCollectionRequest.send(input);
  };

  const filter = (filterData) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { resource_type: resourceType } = filterData;
    const resourceResultListCopy = [...sortedResources];

    const filteredResources = resourceResultListCopy.filter(
      ({ category: { name } }) => {
        if (filterTypeMap[resourceType]) {
          return filterTypeMap[resourceType](name);
        }
        return name === resourceType;
      }
    );

    setstate(filteredResources);
  };

  const reset = async () => {
    setstate(sortedResources);
  };

  // remove any resources that are not in the new resources variable from state
  // state is not being set directly with the new resources array
  // because filters may have been applied
  useEffect(() => {
    const stateCopy = [...state];
    const newState = stateCopy?.filter((resource) =>
      resources?.find((newResource) => resource.id === newResource.id)
    );
    setstate(newState);
  }, [resources]);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Header
        filter={filter}
        reset={reset}
        collectionId={collectionId}
        title={title}
      />
      <Droppable droppableId="droppable-1" type="FAVOURITES">
        {(provided, snapshot) => (
          <section className="main__container" ref={provided.innerRef}>
            {state?.map((resource, index) => (
              <Draggable
                draggableId={`${resource.__typename}-${resource.id}`}
                index={index}
                key={resource.id}
                isDragDisabled={!isUserCollection}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    snapshot={snapshot}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ResourceItem
                      resource={resource}
                      isDragDisabled={!isUserCollection}
                      collectionId={collectionId}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </section>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default withPageWrapper({})(SeeAllResources);
