/* eslint-disable react/no-danger */
import React from 'react';

const QuotesModule = ({ title, text, backgroundColor }) => (
  <div className="card__plain mb-4">
    <div
      className="card__plain__body"
      style={{ background: backgroundColor || '#d166c7' }}
    >
      <p className="card__plain__heading mb-17 lh-140">{title}</p>
      {text && (
        <p
          className="summary__text-sm mb-0"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </div>
  </div>
);

export default QuotesModule;
