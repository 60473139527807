import { gql } from '@apollo/client';

export const ADD_LESSON_PLAN_BOOKMARK = gql`
  mutation AddLessonPlanBookmark($lessonPlanId: ID!, $organisationRef: ID) {
    addLessonPlanBookmark(
      lessonPlanId: $lessonPlanId
      organisationRef: $organisationRef
    ) {
      id
    }
  }
`;

export const REMOVE_LESSON_PLAN_BOOKMARK = gql`
  mutation RemoveLessonPlanBookmark($id: ID!) {
    removeLessonPlanBookmark(id: $id)
  }
`;
