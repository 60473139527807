/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { user } from '../../../../../utils/reactiveVars';
import { defaultAvatarMid } from '../../../../../utils/objects';
import OrganisationsDropdown from '../OrganisationsDropdown/OrganisationsDropdown';
import OrganisationName from './OrganisationName';
import ManageAccountDropdown from '../ManageAccountDropdown';
import Notifications from './Notifications/Notifications';

const MainNav = () => {
  const { handleSubmit, register } = useForm();
  const history = useHistory();
  const handleSearch = (data) =>
    history.push(`/search?text=${encodeURIComponent(data.text)}`);
  const location = useLocation();
  const { text: searchParam } = queryString.parse(location.search);

  const { avatar } = useReactiveVar(user);

  const avatarSrc = avatar || defaultAvatarMid;

  return (
    <div className="main__container mt-0">
      <nav className="main__nav pr-0">
        <form
          autoComplete="off"
          className="searchbar"
          onSubmit={handleSubmit(handleSearch)}
        >
          <input
            name="text"
            type="text"
            className="searchbar__input"
            id="searchbar"
            placeholder="Search"
            ref={register}
            defaultValue={searchParam}
          />
          <img
            src="/img/search-icon.png"
            className="searchbar__icon-search"
            alt=""
            onClick={handleSubmit(handleSearch)}
          />
          <img
            src="/img/plus-icon.svg"
            className="searchbar__icon-cross d-none"
            alt=""
          />
        </form>
        <div className="main__nav__info">
          <OrganisationName />
          <OrganisationsDropdown />
          <Notifications />
          <ManageAccountDropdown avatarSrc={avatarSrc} />
        </div>
      </nav>
    </div>
  );
};

export default MainNav;
