/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';

const Avatar = ({ img, onChange }) => {
  const avatarRef = useRef(null);

  return (
    <>
      <div
        className="col-lg-3 text-center text__fs-14"
        onClick={() => avatarRef.current.click()}
        style={{ cursor: 'pointer' }}
      >
        <img src={img.url} alt="" className="manage-account__avatar" />
        <br />
        Change photo
      </div>
      <input
        type="file"
        id="file"
        ref={avatarRef}
        style={{ display: 'none' }}
        onChange={(e) => onChange.getImageFile(e)}
      />
    </>
  );
};

export default Avatar;
