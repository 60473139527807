/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import React from 'react';
import { toast } from 'react-toastify';
import useGetAssembliesByTheme from '../../../../hooks/Assembly/useGetAssembliesByTheme';
import { assemblyThemesObj } from '../../../../utils/objects';
import OtherAssemblies from '../OtherAssemblies';
import Card from './Card';

const MainSection = ({ theme, ageRange }) => {
  const whereConditions = [];

  if (ageRange) {
    whereConditions.push({ column: 'AGERANGE', value: ageRange });
  }
  if (theme) {
    whereConditions.push({ column: 'THEME', value: theme.toUpperCase() });
  }

  const whereConditionsObj = {
    AND: whereConditions,
  };

  const { data: { assembliesSearchTheme: data = [] } = {}, loading } =
    useGetAssembliesByTheme(whereConditionsObj);

  if (data.length === 0 && loading) {
    toast.info('Loading assemblies...', {
      toastId: 'loading',
    });
  }

  return (
    <section className="main__container">
      <div className="songpage__container">
        <h2 className="text-center fs__28 text__fw-600 lh-26 mb-5 text__fs-28">
          {theme && assemblyThemesObj[theme.toUpperCase()]}
        </h2>
        {data.length === 0 && !loading && (
          <div className="text-center">No data</div>
        )}
        {data?.map((assembly) => (
          <Card
            id={assembly.id}
            key={assembly.id}
            title={assembly.title}
            description={assembly.description}
            thumbnail={assembly.thumbnail}
          />
        ))}
      </div>
      <OtherAssemblies />
    </section>
  );
};

export default MainSection;
