/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import withPageWrapper from '../../../components/Shared/HOCs/withPageWrapper';
import useVerifyCollectionShareLink from '../../../hooks/Collection/useVerifyCollectionShareLink';
import { user } from '../../../utils/reactiveVars';
import Collection from './Collection';

const SharedCollection = () => {
  const { collectionId, uuid } = useParams();
  const { verifyCollectionShareLink, data } = useVerifyCollectionShareLink(
    collectionId,
    uuid
  );

  useEffect(() => {
    verifyCollectionShareLink();
  }, []);

  if (!data?.verifyCollectionShareLink?.id) return null;

  const { id } = user();
  if (data?.verifyCollectionShareLink?.userId === id) {
    return <Redirect to={`/collection/${collectionId}`} />;
  }

  return <Collection id={collectionId} />;
};

export default withPageWrapper({})(SharedCollection);
