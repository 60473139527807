/* eslint-disable operator-linebreak */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import { playlistList } from '../../utils/reactiveVars';
import PlaylistList from '../../components/Playlists/PlaylistList';

const SeeAll = () => {
  const { type, playlists, disableDeletePlaylist, orderingFunction } =
    useReactiveVar(playlistList);

  return (
    <section className="main__container">
      <div className="mylist__container pt-md-0 pt-5">
        <PlaylistList
          playlists={playlists}
          type={type}
          disableDeletePlaylist={disableDeletePlaylist}
          orderingFunction={orderingFunction}
        />
      </div>
    </section>
  );
};

export default withPageWrapper({})(SeeAll);
