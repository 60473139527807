import React from 'react';
import { showModal } from '../../../../styles/js/modals';
import { deleteModalObj } from '../../../../utils/reactiveVars';

const DeletePlaylist = ({ itemType, id, show }) => {
  if (!show) return null;

  return (
    <div className="mb-20 text__fs-16">
      <a
        href=""
        className="disabled text-dark d-flex align-items-start"
        id="delete"
        data-dismiss="modal"
        onClick={(e) => {
          e.preventDefault();
          deleteModalObj({ itemType, id });
          showModal('deleteModal');
        }}
      >
        <img src="/img/bin.svg" alt="bin" className="mr-4 d-inline" />
        Delete playlist
      </a>
    </div>
  );
};

export default DeletePlaylist;
