/* eslint-disable react/no-array-index-key */
import React from 'react';
import { isPlayerFullscreen } from '../../utils/reactiveVars';
import useStores from '../hooks/useStores';
import { MediaButton } from './Icon';
import PopupInfo from '../../components/Shared/PopupInfo';

// const SongType = styled.div<{ gridPosition: number; hideOnMobile: boolean }>`
//   ${tw`flex items-center text-white`}
//   ${({ gridPosition }) => gridPosition && `-ms-grid-column:  ${gridPosition}`};
//   ${({ gridPosition }) => gridPosition && `grid-column:  ${gridPosition}`};
//   display: grid;
//   display: -ms-grid;
//   margin-right: 20px;
//   grid-template-columns: 4fr 1fr;
//   ${({ hideOnMobile }) =>
//     hideOnMobile &&
//     ` @media (max-width: 415px) {
//     display: none;
//   }`};
//   @media (min-width: 415px) and (max-width: 768px) {
//     margin-right: 10px;
//   }
//   // grid-template-columns:auto;
//   // grid-template-rows: 0.5fr 0.5fr;
// `;

// const SongTypeWrapper = styled.div`
//   border-radius: 5px;
//   border: 1px #4f4f4f solid;
// `;

export const getIconType = (
  infoName: string
): 'Vocal' | 'Instrumental' | 'MusicalNote' => {
  if (infoName === 'Vocal' || infoName === 'Instrumental') {
    return infoName;
  }
  return 'MusicalNote';
};

const SongTypes = (): JSX.Element | null => {
  if (!isPlayerFullscreen()) return null;

  const { mediaPlayerStore } = useStores();

  return (
    <div className="song-types">
      <div className="song-types__container">
        <div>
          {mediaPlayerStore.song.audios.map(({ infoName }, idx) => (
            <MediaButton
              key={infoName + idx}
              disabled={idx >= mediaPlayerStore.song.src.length}
              icon={getIconType(infoName)}
              content={infoName}
              onClick={() => {
                mediaPlayerStore.switchTrack(
                  idx,
                  mediaPlayerStore.progress.playedSeconds
                );
              }}
              id={idx}
              bordered
              tabletDimensions={16}
              audioBg
            />
          ))}
        </div>

        <PopupInfo position="bottom-end" isHeader={false} />
      </div>
    </div>
  );
};

export default SongTypes;
