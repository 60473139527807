/* eslint-disable operator-linebreak */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { useForm } from 'react-hook-form';
import useLeaveOrganisation from '../../../../../hooks/Organisation/useLeaveOrganisation';
import { organisationDetails, user } from '../../../../../utils/reactiveVars';
import FormError from '../../../FormError';
import HeaderAndDescription from './HeaderAndDescription';

const LeaveOrganisationModal = () => {
  const { detailName, displayName, organisationRef } =
    useReactiveVar(organisationDetails);
  const { register, handleSubmit, errors, getValues } = useForm();
  const { leaveOrganisationRequest } = useLeaveOrganisation(organisationRef);

  const { email } = useReactiveVar(user);

  const handleLeaveOrganisation = (data) => {
    leaveOrganisationRequest(data.email);
  };

  return (
    <div
      className="modal fade"
      id="leaveOrganisationModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="leaveOrganisationModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered custom__modal__leave-organization"
        role="document"
      >
        <div className="modal-content custom__modal__leave-organization__content">
          <HeaderAndDescription organisationName={displayName || detailName} />
          <div className="row">
            <div className="col">
              <form className="manage-account text__fs-13">
                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor="inputAddress">Email address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      ref={register({
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                      defaultValue={email}
                    />
                  </div>
                </div>
                {errors.email && (
                  <FormError
                    text="Please enter a valid email address"
                    className="mt-n4 mb-4"
                  />
                )}
                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor="inputAddress">Re-enter email address</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputAddress"
                      name="confirmEmail"
                      ref={register({
                        required: true,
                        validate: (value) => value === getValues('email'),
                      })}
                      defaultValue={email}
                    />
                  </div>
                </div>
                {errors.confirmEmail && (
                  <FormError text="Emails do not match." className="mt-n4" />
                )}
              </form>
            </div>
          </div>

          <div className="mt-auto d-flex justify-content-between">
            <button
              data-dismiss="modal"
              className="btn button__green-meadow button__w-144 button__transparent__green border__green"
            >
              Cancel
            </button>
            <button
              className="btn button__green-meadow button__w-214"
              onClick={handleSubmit(handleLeaveOrganisation)}
            >
              Leave organization
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveOrganisationModal;
