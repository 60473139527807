/* eslint-disable implicit-arrow-linebreak */
import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import useGetTag from '../../../../../hooks/useGetTag';
import { user } from '../../../../../utils/reactiveVars';
import SectionHeader from '../SectionHeader';
import Checkbox from '../Checkbox';

const AreasYoureInterestedIn = ({ handleShowSection, handleDataChange }) => {
  const { data: availableSubjectsData } = useGetTag('Curriculum Subject Links');
  const [availableSubjects, setAvailableSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const { subjects } = useReactiveVar(user);

  useEffect(() => {
    setSelectedSubjects(subjects);
    if (availableSubjectsData?.tags) {
      const tagsFormatted = availableSubjectsData.tags.map(
        (tag) => tag.formattedName
      );
      setAvailableSubjects(tagsFormatted);
    }
  }, [availableSubjectsData, subjects]);

  const isChecked = (subject) =>
    !!selectedSubjects.find((item) => item === subject);

  const handleChange = (subject, checked) => {
    const newSubjects = checked
      ? [...selectedSubjects, subject]
      : selectedSubjects.filter(
          (existingSubject) => existingSubject !== subject
        );

    setSelectedSubjects(newSubjects);
    handleDataChange({ subjects: newSubjects });
  };

  return (
    <>
      <SectionHeader
        text="Tell us the areas you teach or are interested in."
        tickAll
      />
      <div className="row text__fs-13 pb-4" style={{ wordBreak: 'break-word' }}>
        {availableSubjects?.map((subject) => (
          <Checkbox
            key={subject}
            role={subject}
            checked={isChecked(subject)}
            handleChange={handleChange}
            onCheck={() => handleShowSection('singWithYourChildren')}
          />
        ))}
      </div>
    </>
  );
};

export default AreasYoureInterestedIn;
