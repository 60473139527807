import React from 'react';

const Header = () => (
  <>
    <div className="main__container d-flex align-items-center py-4 px-md-5 mt-0 resources-favourites__header__sub">
      <img src="/img/song-icon-white.svg" alt="" className="mr-5" />
      <span className="resources-favourites__header__text">Songbooks</span>
    </div>

    <div className="main__container bg__cerise-dark mt-0 d-flex px-md-5 song-calendar__header__container">
      <span className="song-calendar__header__description">
        Our beloved songbooks collected all in one place from the last 30+ years
        and arranged by series for you to enjoy
      </span>
      <div>
        <img
          src="/img/favourite-songs-banner.png"
          className="img-fluid resources-favourites__header__banner"
          alt="banner"
        />
      </div>
    </div>
  </>
);

export default Header;
