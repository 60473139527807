/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_PLAYLISTS } from '../../graphql/queries/playlist/playlist';
import { DELETE_PLAYLIST } from '../../graphql/mutations/playlist';
import hideModal from '../../styles/js/modals';
import { toastErrorObj, toastSuccessObj } from '../../utils/objects';
import {
  dismissLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useDeletePlaylist = (playlistId) => {
  const [deletePlaylist] = useMutation(DELETE_PLAYLIST, {
    refetchQueries: [
      {
        query: GET_PLAYLISTS,
        variables: {
          createdBy: 'user',
          organisation_ref: organisationLoggedInAs()?.id || null,
        },
      },
    ],
  });

  const deletePlaylistRequest = {
    send: () => {
      deletePlaylist({
        variables: {
          id: parseInt(playlistId, 10),
        },
      })
        .then((res) => {
          displaySuccessMessage();
          dismissLoadingMessage();
          hideModal();
        })
        .catch((e) => {
          toast.error(e.message, toastErrorObj);
          hideModal();
        });
    },
  };

  return { deletePlaylistRequest };
};

export default useDeletePlaylist;
