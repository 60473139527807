/* eslint-disable @typescript-eslint/naming-convention */
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_SONG } from '../../graphql/queries/song/song';
import {
  dismissLoadingMessage,
  displayErrorMessage,
} from '../../utils/helpers';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useGetSong = (id) => {
  const organisation_ref = useReactiveVar(organisationLoggedInAs)?.id || null;
  const { data, loading, error } = useQuery(GET_SONG, {
    variables: {
      id,
      organisation_ref,
    },
    onCompleted: () => dismissLoadingMessage(),
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage('Could not load song');
    },
  });

  return { data, loading, error };
};

export default useGetSong;
