import React from 'react';

const Description = ({ text }) => {
  if (!text) return null;
  return (
    <div className="card__plain mb-0">
      <div className="card__plain__header resource-header bg__fuchsia">
        <p className="card__plain__heading mb-15">Assembly summary</p>
        <p className="card__plain__heading assembly-desc text__fs-14 text_fw-600 mb-0">{text}</p>
      </div>
    </div>
  );
};

export default Description;
