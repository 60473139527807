/* eslint-disable implicit-arrow-linebreak */
import React from 'react';

const MCActivityHeader = ({ title, tags }) => (
  <>
    <div className="card__plain__header resource-header bg__orange mx-lg-n32 mx-sm-n16 mb-4">
      <p className="card__plain__heading mb-0">{title}</p>
    </div>
    {tags?.map(
      (tag) =>
        tag.type === 'Curriculum Subject Links' && (
          <div className="tag mb-23 mr-2 d-inline-block" key={tag.id}>
            {tag.formattedName}
          </div>
        )
    )}
  </>
);

export default MCActivityHeader;
