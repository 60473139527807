import { gql } from '@apollo/client';
import { TagFieldsFragment } from './fragments';

export const GET_TAGS = gql`
  query Tags($ofType: String!, $withContent: Content) {
    tags(ofType: $ofType, withContent: $withContent) {
      ...TagFields
    }
  }
  ${TagFieldsFragment}
`;
