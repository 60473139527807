/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect } from 'react';
import useGetOrganisation from '../../../../../../hooks/Organisation/useGetOrganisation';
import Loading from '../../../../../Shared/Loading';
// import CardsSectionForNonAdminUsers from './CardsSectionForNonAdminUsers';
import AllCards from './AllCards';
import {
  calculateDaysLeftInSubscription,
  getSubscriptionCardTexts,
} from '../../../../../../utils/helpers';

const CardsSection = ({ organisation, sharedLinksCount, isAdmin }) => {
  const { organisationRef } = organisation;

  const { data, loading, getOrganisation } = useGetOrganisation();

  useEffect(() => {
    if (organisationRef) {
      getOrganisation(organisationRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationRef]);

  if (loading) return <Loading />;
  if (!data && !organisation) return null;

  // const firstActiveSub = organisation.sysubs?.find((sub) => sub.isActive);
  // const selectedSub = firstActiveSub || organisation.sysubs[0];

  const subInfo = data?.organisation.sysubs;

  // const initialDateRenewal = selectedSub?.dateRenewal;
  // const initialIsActive = selectedSub?.isActive;

  const firstActiveSubFromBackend = data?.organisation.sysubs?.find(
    (sub) => sub.isActive
  );
  const selectedSubFromBackend =
    firstActiveSubFromBackend || data?.organisation.sysubs[0];

  const dateRenewal = selectedSubFromBackend?.dateRenewal;

  const isActive = selectedSubFromBackend?.isActive;

  const daysLeftOnSubscription = calculateDaysLeftInSubscription(
    dateRenewal,
    isActive
  );

  const {
    topText,
    middleText,
    bottomText,
    hasManualSubs,
    hasMultiplePaidSubs,
  } = getSubscriptionCardTexts(subInfo, daysLeftOnSubscription);

  const organisationObj = data ? data?.organisation : organisation;

  const { sysubs, addresses, playsPast30Days } = organisationObj;

  // if (!isAdmin) {
  //   return (
  //     <CardsSectionForNonAdminUsers
  //       daysLeftOnSubscription={middleText}
  //       sharedLinksCount={sharedLinksCount}
  //       bottomText={bottomText}
  //       topText={topText}
  //       playsPast30Days={playsPast30Days}
  //     />
  //   );
  // }

  return (
    <AllCards
      data={data}
      organisation={organisation}
      daysLeftOnSubscription={middleText}
      sysubs={sysubs}
      addresses={addresses}
      sharedLinksCount={sharedLinksCount}
      topText={topText}
      bottomText={bottomText}
      hasManualSubs={hasManualSubs}
      hasMultiplePaidSubs={hasMultiplePaidSubs}
      playsPast30Days={playsPast30Days}
      isAdmin={isAdmin}
      effectiveUserLimit={organisationObj.effectiveUserLimit}
    />
  );
};

export default CardsSection;
