import React from 'react';
import LikeAndAddIcons from '../../Shared/LikeAddIcons';
import Dropdown from '../Dropdown';

const SongbookDetails = ({
  title,
  albumArt,
  id,
  resources,
  excludeResources,
}) => (
  <div className="esongbook__details__grid">
    <div className="esongbook__album">
      <img src={albumArt} className="esongbook__album__thumbnail" alt="" />
      <p className="esongbook__album__title text__fw-500">{title}</p>
      <div className="mr-auto mt-1">
        <LikeAndAddIcons
          addToCollection
          headerText="Options"
          itemType="ESONGBOOK"
          modalId="songbookId"
          id={id}
        />
      </div>
    </div>
    {!excludeResources && <Dropdown resources={resources} id={id} />}
  </div>
);

export default SongbookDetails;
