import React from 'react';
import { useReactiveVar } from '@apollo/client';
import ReorderableSongsList from '../Shared/Song/ReorderableSongsList';
import { songChoiceSongs } from '../../utils/reactiveVars';
import useUpdateCollection from '../../hooks/Collection/useUpdateCollection';
import withPageWrapper from '../Shared/HOCs/withPageWrapper';

const SeeAllSongs = () => {
  const { songs, type = '' } = useReactiveVar(songChoiceSongs);

  const { updateCollectionRequest } = useUpdateCollection(
    type?.parentId,
    'songs'
  );

  return (
    <section className="main__container">
      <ReorderableSongsList
        songs={songs}
        isUserResource={!!type}
        type={type}
        orderingFunction={updateCollectionRequest.send}
      />
    </section>
  );
};

export default withPageWrapper({})(SeeAllSongs);
