/* eslint-disable no-shadow */
/* eslint-disable no-restricted-properties */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
/**
 * A hook to return the scale up required for the canvas
 * @param containerDimensions {Object}
 * @returns {number} scale
 */
const useScale = (containerDimensions: {
  width: number;
  height: number;
}): number => {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    let scale = 8751.294
      + (-0.2362459 - 8751.294)
        / (1 + Math.pow(containerDimensions.height / 5433188, 1.000205));
    // NOTE: Scale is plotted from the original web-apps dimensions of the container

    if (scale < 0.406) scale = 0.406;
    if (scale * 1023.65 >= containerDimensions.width) scale = containerDimensions.width / 1023.65;
    // if (scale * 622 >= containerHeight) scale = containerHeight / 622;

    setScale(scale);
  }, [containerDimensions.height, containerDimensions.width]);

  return scale;
};

export default useScale;
