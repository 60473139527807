import { useEffect } from 'react';
import { isMobileSidebarOpen } from '../utils/reactiveVars';

const useListenToOutsideClicks = (ref) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        isMobileSidebarOpen(false);
        document.body.style.overflow = 'visible';
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref]);
};

export default useListenToOutsideClicks;
