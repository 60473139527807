import React from 'react';
const RightShadow = () => {
  return (
    <svg
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="143px"
      height="46px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          x1="894"
          y1="47.730290456431526"
          x2="1034.3376348547717"
          y2="47.730290456431526"
          id="LinearGradient2"
        >
          <stop id="Stop2" stopColor="#222f39" stopOpacity="0" offset="0" />
          <stop id="Stop3" stopColor="#222f39" offset="1" />
        </linearGradient>
      </defs>
      <g transform="matrix(1 0 0 1 -894 0 )">
        <path
          d="M 894 0  L 1037 0  L 1037 91  L 894 91  L 894 0  Z "
          fillRule="nonzero"
          fill="url(#LinearGradient2)"
          stroke="none"
        />
      </g>
    </svg>
  );
};

export default RightShadow;
