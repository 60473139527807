import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_SONGS } from '../../graphql/queries/song/song';
import {
  dismissLoadingMessage,
  displayErrorMessage,
} from '../../utils/helpers';

const useGetSongs = (
  number,
  variables = null,
  notifyOnNetworkStatusChange = false
) => {
  const { data, loading, error, fetchMore } = useQuery(GET_SONGS, {
    variables: {
      first: number,
      orderBy: [{ column: 'INFO_NAME', order: 'ASC' }],
      ...variables,
    },
    onCompleted: () => toast.dismiss('loading'),
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage('Could not load songs');
    },
    notifyOnNetworkStatusChange,
  });

  return { data, loading, error, fetchMore };
};

export default useGetSongs;
