/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useMutation } from '@apollo/client';
import { CREATE_PLAYLIST } from '../../graphql/mutations/playlist';
import { GET_PLAYLISTS } from '../../graphql/queries/playlist/playlist';
import hideModal from '../../styles/js/modals';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { playlistDefaultObj } from '../../utils/objects';
import { organisationLoggedInAs } from '../../utils/reactiveVars';
import useAddToPlaylist from './useAddToPlaylist';

const useCreatePlaylist = (reset, createWithSong = null) => {
  const { addToPlaylistRequest } = useAddToPlaylist(createWithSong);
  const [createPlaylist, { data, loading }] = useMutation(CREATE_PLAYLIST, {
    refetchQueries: [
      {
        query: GET_PLAYLISTS,
        variables: {
          createdBy: 'user',
          organisation_ref: organisationLoggedInAs()?.id || null,
        },
      },
    ],
  });

  const createPlaylistRequest = {
    send: (name) => {
      createPlaylist({
        variables: {
          input: {
            name,
            organisation_ref: organisationLoggedInAs()?.id || null,
          },
        },
      })
        .then((res) => {
          reset(playlistDefaultObj);
          displaySuccessMessage('Playlist created!');
          dismissLoadingMessage();
          hideModal();
          if (createWithSong) {
            addToPlaylistRequest.send(
              [parseInt(res?.data?.createPlaylist?.id, 10)],
              []
            );
          }
        })
        .catch((e) => {
          dismissLoadingMessage();
          displayErrorMessage('Could not create playlist');
        });
    },
  };

  return { createPlaylistRequest, loading, data };
};

export default useCreatePlaylist;
