/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { useState, useEffect } from 'react';
import { dismissErrorMessage, displayErrorMessage } from '../../utils/helpers';

const useUploadImage = (defaultImage) => {
  const [img, setImg] = useState({
    url: defaultImage,
    error: null,
  });

  useEffect(
    () => setImg((prevState) => ({ ...prevState, url: defaultImage })),
    [defaultImage]
  );

  const onChange = {
    isValidImage: (file, callback) => {
      const reader = new FileReader();
      if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        return callback(false);
      }
      reader.onload = (e) => {
        const imgFile = new Image();
        imgFile.onerror = () => {
          callback(false);
        };
        imgFile.src = e.target.result;
      };
      reader.readAsDataURL(file);

      return callback({ url: URL.createObjectURL(file), file });
    },
    getImageFile: function (event) {
      event.stopPropagation();
      event.preventDefault();
      dismissErrorMessage();

      const file = event.target.files[0];
      this.isValidImage(file, (validImage) => {
        if (!validImage) {
          setImg({
            url: defaultImage,
            error: true,
          });
          return displayErrorMessage('Please upload a valid image file.');
        }
        setImg({
          url: validImage.url,
          error: false,
          file: validImage.file,
        });
      });
    },
  };

  return { onChange, img };
};

export default useUploadImage;
