import React from 'react';
import { Link } from 'react-router-dom';
import useGetAssemblies from '../../../hooks/Assembly/useGetAssemblies';
import AssemblyPlanCard from '../../Shared/Card';

const OtherAssemblies = () => {
  const { loading, data } = useGetAssemblies(6);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-26">
        <p className="text__fs-18 mb-0">
          <Link to="/assembly-plans" className="text__white">
            Other assembly plans
          </Link>
        </p>
        <Link to="/assembly-plans" className="text__white text__fs-10">
          SEE ALL
        </Link>
      </div>
      <div className=" mt-2 text-center">{loading && 'Loading...'}</div>
      <div className="assembly__grid theme-page__grid">
        {data?.assemblies?.data?.map((assembly) => (
          <AssemblyPlanCard
            id={assembly.id}
            title={assembly.title}
            description={assembly.description}
            key={assembly.id}
            thumbnail={assembly.thumbnail}
            themePage
          />
        ))}
      </div>
    </>
  );
};

export default OtherAssemblies;
