/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React, { useMemo, useState } from 'react';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import NotificationItem from '../../components/Shared/Header/Sections/MainNav/Notifications/NotificationItem';
import useGetUserNotifications from '../../hooks/Notification/useGetUserNotifications';
import {
  displayErrorMessage,
  displayLoadingMessage,
} from '../../utils/helpers';
import MarkAsRead from '../../components/Notifications/MarkAsRead';

const Notifications = () => {
  const { data, loading, error } = useGetUserNotifications();
  loading && displayLoadingMessage();
  error && displayErrorMessage('Could not load notifications');

  const [showAllNotifications, setShowAllNotifications] = useState(false);

  const notificationsToDisplay = useMemo(
    () =>
      showAllNotifications
        ? data?.getUserNotifications
        : data?.getUserNotifications.filter((item) => !item.read),
    [data, showAllNotifications]
  );

  const handleTabChange = (e, showAll) => {
    e.preventDefault();
    setShowAllNotifications(showAll);
  };

  const TabItem = ({ active, text, onClick }) => (
    <a href="" className={`${active ? 'active' : ''}`} onClick={onClick}>
      {text}
    </a>
  );

  return (
    <>
      <div className="main__container d-flex align-items-center py-4 px-md-5 mt-0 resources-favourites__header__sub">
        <img
          src="/img/notification-icon-grey.svg"
          alt="notifications"
          className="resources-favourites__header__icon mr-2"
        />
        <span className="resources-favourites__header__text__muted">
          Notifications
        </span>
      </div>
      {data && (
        <section className="main__container">
          <div className="notifications__tabs">
            <TabItem
              active={showAllNotifications}
              text="All"
              onClick={(e) => handleTabChange(e, true)}
            />
            <TabItem
              active={!showAllNotifications}
              text="Unread"
              onClick={(e) => handleTabChange(e, false)}
            />
            <MarkAsRead />
          </div>
          <div>
            {notificationsToDisplay.map((notification) => (
              <NotificationItem
                notification={notification}
                key={notification.id}
              />
            ))}
          </div>
        </section>
      )}
    </>
  );
};

export default withPageWrapper({ pageTitle: 'Notifications | Sparkyard' })(
  Notifications
);
