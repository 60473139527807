import React, { useEffect, useState } from 'react';
import useSharePlaylist from '../../../../../../hooks/Playlist/useSharePlaylist';
import { organisationLoggedInAs } from '../../../../../../utils/reactiveVars';

const SharePlaylistWithOrganisation = ({ id, sharePlaylist }) => {
  const organisationRef = organisationLoggedInAs()?.id || null;
  const { sharePlaylistRequest, loading } = useSharePlaylist(
    id,
    organisationRef
  );

  const [state, setState] = useState(sharePlaylist?.hasSharedWithOrganisation);
  const text = state ? 'Stop sharing' : 'Share with my Organization';

  useEffect(() => {
    setState(sharePlaylist?.hasSharedWithOrganisation);
  }, [id, sharePlaylist]);

  const handleSharePlaylist = () => {
    const deleteId = state ? sharePlaylist.shareId : null;
    sharePlaylistRequest(deleteId);
  };

  return (
    <>
      <p className="text-dark text__fs-12 mb-0">
        Share this Playlist with everyone within your organization. You will
        still be able to edit it, but other users can also create their own copy
      </p>
      <button
        className="btn bg__cello text-white mt-2"
        onClick={() => handleSharePlaylist()}
        disabled={loading}
        data-dismiss="modal"
      >
        {text}
      </button>
    </>
  );
};

export default SharePlaylistWithOrganisation;
