import { useReactiveVar } from '@apollo/client';
import React from 'react';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import SongbookList from '../../components/Shared/Songbook/SongbookList';
import { songbookList } from '../../utils/reactiveVars';

const SeeAllSongbooks = () => {
  const { type, songbooks } = useReactiveVar(songbookList);

  return (
    <section className="main__container">
      <SongbookList songbooks={songbooks} type={type} />
    </section>
  );
};

export default withPageWrapper({})(SeeAllSongbooks);
