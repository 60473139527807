import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { playerActive } from '../../utils/reactiveVars';

const BottomSpacer = () => {
  if (!useReactiveVar(playerActive)) return null;
  return <div className="mb-90" />;
};

export default BottomSpacer;
