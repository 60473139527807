/* eslint-disable operator-linebreak */
/* eslint-disable react/no-danger */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { subscriptionDetails } from '../../../../../../utils/reactiveVars';

const PackageDetails = () => {
  const { sysubs, hasManualSubs, effectiveUserLimit } =
    useReactiveVar(subscriptionDetails);
  if (!sysubs?.[0]) return null;

  const firstActiveSub = sysubs?.find((sub) => sub.isActive);
  const selectedSub = firstActiveSub || sysubs[0];

  const { syprodDescription, syprodPupilsMax, syprodReadablePrice } =
    selectedSub;

  const packageInfoAvailable = syprodDescription && syprodReadablePrice;

  const manualSubPackageDetailsText =
    selectedSub.manualRationale === 'Temporary Customer Access'
      ? 'A temporary account has been created for you while your payment is being processed. Your full subscription will become active when your invoice has been settled.'
      : 'Your subscription has been created manually by Out of the Ark. Please email info@sparkyard.com if you have any questions or queries.';

  const Row = ({ text }) => (
    <div className="d-flex align-items-center mb-5">
      <div style={{ flexShrink: 0 }}>
        <img
          src="/img/check-mark-rounded-border.svg"
          alt="check"
          width={26}
          height={39}
          className="mr-3"
        />
      </div>
      <div className="text__fs-14">{text}</div>
    </div>
  );

  return (
    <div className="col mt-5 mt-md-0 mb-5">
      <div className="mb-41">
        <span className="text__fs-18">Current package</span>
      </div>

      {hasManualSubs && (
        <div className="text__fs-13">{manualSubPackageDetailsText}</div>
      )}

      {!hasManualSubs && (
        <>
          {packageInfoAvailable ? (
            <div className="bg__white border-10 px-34 text-center">
              <div className="manage-account__package-title">
                {syprodDescription}
              </div>
              <div className="text-dark text-left">
                {!!syprodPupilsMax && (
                  <Row text={`School Size: Up to ${syprodPupilsMax} pupils`} />
                )}
                {!!effectiveUserLimit && (
                  <Row text={`${effectiveUserLimit} User Licenses`} />
                )}
                <Row text="Access to all of the Sparkyard features and benefits for 1 year" />
              </div>
              <div
                className="manage-account__package-price"
                dangerouslySetInnerHTML={{ __html: syprodReadablePrice }}
              />
            </div>
          ) : (
            <span>No package information available</span>
          )}
        </>
      )}
    </div>
  );
};
export default PackageDetails;
