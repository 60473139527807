import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import Header from '../../components/Assembly/Sections/Header';
import MainSection from '../../components/Assembly/Sections/MainSection/Index';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import LoadJquery from '../../components/Shared/LoadJquery';
import useGetAssembly from '../../hooks/Assembly/useGetAssembly';
import { displayLoadingMessage } from '../../utils/helpers';

const Assembly = () => {
  const { id, slug } = useParams();
  const { data: { assembly } = {}, loading } = useGetAssembly(id);

  useEffect(() => {
    document.title = `${assembly?.title || ''} | Assembly Plan | Sparkyard`;
  }, [assembly]);

  loading && displayLoadingMessage('Loading assembly...');

  if (assembly === null) return <Redirect to="/404" />;

  return (
    <>
      {assembly && (
        <>
          <Header
            id={assembly.id}
            title={assembly.title}
            theme={assembly.theme}
            thumbnail={assembly.thumbnail}
          />
          <MainSection assembly={assembly} slug={slug} />
          <LoadJquery />
        </>
      )}
    </>
  );
};

export default withPageWrapper({})(Assembly);
