import tw, { styled } from 'twin.macro';
import 'styled-components/macro';

type SlideElementProps = {
  scale: number;
  containerDimensions: { width: number; height: number };
};
const SlideElement = styled('div')<SlideElementProps>`
  ${tw`w-full h-full flex flex-col justify-center border-red-300 border border-solid z-10 relative`}
  background: #434343;
`;

export default SlideElement;
