/* eslint-disable no-restricted-syntax */
/* eslint-disable default-case */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
import 'isomorphic-unfetch';
import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { getUserFromLocalStorage } from '../utils/user';

const graphQLServerURL = process.env.REACT_APP_API_URL;
const httpLink = createUploadLink({
  uri: `${graphQLServerURL}/graphql`,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  let refreshToken, accessToken;

  if (getUserFromLocalStorage()) {
    refreshToken = getUserFromLocalStorage().refreshToken;
    accessToken = getUserFromLocalStorage().accessToken;
  }
  return {
    headers: {
      ...headers,
      ...(accessToken && { authorization: `Bearer ${accessToken}` }),
      ...(refreshToken && { refreshToken }),
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.message) {
          case 'Unauthenticated.':
            window.location.reload();
            // Retry the request, returning the new observable
            return forward(operation);
        }
      }
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 1000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (_, _operation) => _operation.operationName === 'CreateHit',
  },
});

const client = new ApolloClient({
  link: from([errorLink, retryLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
