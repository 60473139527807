import { useMutation } from '@apollo/client';
import { ACCEPT_OR_DECLINE_JOIN_REQUEST } from '../../graphql/mutations/organisation';
import { GET_ORGANISATION } from '../../graphql/queries/organisation/organisation';

import {
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/helpers';

const useAcceptOrDeclineJoinRequest = (organisationRef, email) => {
  const [acceptOrDeclineJoinRequest, { loading }] = useMutation(
    ACCEPT_OR_DECLINE_JOIN_REQUEST,
    {
      refetchQueries: [
        {
          query: GET_ORGANISATION,
          variables: {
            id: organisationRef,
          },
        },
      ],
      onError: () => displayErrorMessage('Something went wrong.'),
      onCompleted: () => {
        displaySuccessMessage(
          `${email} has been successfully been added to your organisation.`
        );
      },
    }
  );

  const sendAcceptOrDeclineJoinRequest = (input) => {
    acceptOrDeclineJoinRequest({
      variables: {
        input: {
          ...input,
          organisation_ref: organisationRef,
        },
      },
    });
  };

  return { sendAcceptOrDeclineJoinRequest, loading };
};

export default useAcceptOrDeclineJoinRequest;
