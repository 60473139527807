/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import useRenderSparkles from '../../../hooks/useRenderSparkles';
import PlaylistCards from '../../Shared/PlaylistCards';
import VideoCards from '../../Shared/Video/VideoCards';
import Header from './Header';
import Resources from './Resources';
import SongCards from '../../Shared/SongCards/Index';
import Songbooks from '../../Shared/Songbook/Index';
import AssemblyCards from '../../Shared/AssemblyCards/Index';
import { organisationLoggedInAs } from '../../../utils/reactiveVars';
import useUpdateCollection from '../../../hooks/Collection/useUpdateCollection';

const Collection = ({ collection }) => {
  const {
    title,
    description,
    isUserCollection,
    id: collectionId,
    songs,
    resources,
    playlists,
    esongbooks,
    assemblies,
    videos,
    sparkles,
    hasSharedWithOrganisation,
    shareId,
    wasSharedWithUser,
    pdfs,
  } = collection;

  const getType = (itemType) =>
    isUserCollection
      ? { item: itemType, from: 'collection', parentId: collectionId }
      : null;

  const organisationRef = useReactiveVar(organisationLoggedInAs)?.id;

  const { updateCollectionRequest } = useUpdateCollection(
    collectionId,
    'playlists'
  );

  return (
    <>
      <Header
        title={title}
        description={description}
        isUserCollection={isUserCollection}
        hasSharedWithOrganisation={hasSharedWithOrganisation}
        organisationRef={organisationRef}
        shareId={shareId}
        id={collectionId}
        resource={{
          resourceTags: [],
          downloadables: pdfs,
        }}
        wasSharedWithUser={wasSharedWithUser}
      />
      <section className="main__container">
        <div className="mt-5 p-3 mt-md-0 p-md-0" />

        <SongCards
          title="Songs"
          songs={songs}
          pageTitle={title}
          type={getType('song')}
          showContentHereImg={isUserCollection && songs?.length < 6}
          seeAllRoute="/collection/songs/see-all"
        />

        <Resources
          resources={resources}
          parentId={collectionId}
          isUserCollection={isUserCollection}
          pageTitle={title}
        />

        <PlaylistCards
          playlists={playlists}
          title="Playlists"
          collection={isUserCollection}
          type={getType('playlist')}
          disableDeletePlaylist
          orderingFunction={
            isUserCollection ? updateCollectionRequest.send : null
          }
        />

        <Songbooks
          songbooks={esongbooks}
          collection={isUserCollection}
          type={getType('esongbook')}
        />
        <AssemblyCards
          title="ASSEMBLIES"
          assemblies={assemblies}
          type={getType('assembly')}
        />

        <VideoCards videos={JSON.parse(videos)} />

        {sparkles?.map((sparkle) => (
          <React.Fragment key={sparkle.id}>
            {useRenderSparkles(sparkle)}
          </React.Fragment>
        ))}
      </section>
    </>
  );
};

export default Collection;
