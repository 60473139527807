/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable function-paren-newline */
/* eslint-disable no-return-assign */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import sub from 'date-fns/sub';
import useGetOrganisationPlays from '../../../../../hooks/Organisation/useGetOrganisationPlays';
import useDeleteSharedLink from '../../../../../hooks/Share/useDeleteSharedLink';
import useRenewSharedLink from '../../../../../hooks/Share/useRenewSharedLink';
import { displaySuccessMessage } from '../../../../../utils/helpers';
import { organisationLoggedInAs } from '../../../../../utils/reactiveVars';
import { generateShareLink } from '../../ShareSongModal/ShareSongModal';

const TableBody = ({ sharedLinks }) => {
  const [copiedLinkId, setCopiedLinkId] = useState(null);
  const organisationId = organisationLoggedInAs()?.id || null;

  const { deleteSharedLinkRequest } = useDeleteSharedLink(organisationId);
  const { renewSharedLinkRequest, loading } = useRenewSharedLink();
  const { getOrganisationPlays, data } = useGetOrganisationPlays();

  useEffect(() => {
    if (sharedLinks?.length > 0) {
      let songRefCSV = '';
      let shareIDsSCV = '';

      sharedLinks.map((share, idx) => {
        if (idx === 0) {
          songRefCSV = `${share.songId}`;
          shareIDsSCV = `${share.id}`;
        }
        songRefCSV += `,${share.songId}`;
        shareIDsSCV += `,${share.id}`;
      });

      const date = new Date();

      const input = {
        organisationRef: organisationId,
        songRef: songRefCSV,
        dayInclusiveFrom: '2022-01-01',
        dayInclusiveTo: format(sub(date, { days: 1 }), 'yyyy-MM-dd'),
        optionalShareIDs: shareIDsSCV,
      };

      getOrganisationPlays(input);
    }
  }, [sharedLinks]);

  const shouldBeDisabled = (status) =>
    status === 'Active' ? 'disable-element' : '';
  const className = 'd-none d-md-table-cell';

  const DisplayLinkStatus = ({ status }) => {
    if (status === 'Expired') {
      return (
        <td className={`manage-account__invited ${className}`}>Expired</td>
      );
    }
    return <td className={`manage-account__active ${className}`}>Active</td>;
  };

  useEffect(() => {
    setTimeout(() => setCopiedLinkId(null), 5000);
  }, [copiedLinkId]);

  copiedLinkId && displaySuccessMessage('Link copied!');

  return (
    <tbody className="manage-account__invite-colleagues__tbody">
      {sharedLinks?.map(
        ({ id, status, uuid, song: { id: songId, infoName } }) => (
          <tr key={id} className={loading ? 'disable-element' : ''}>
            <th scope="row">{infoName}</th>
            <td className={className}>
              {JSON.parse(data?.organisationPlays?.counts || '{}')?.[songId]}
            </td>
            <td className={className}>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(
                    generateShareLink(uuid, songId)
                  );
                  setCopiedLinkId(id);
                }}
              >
                <img src="/img/copy-icon-green.png" alt="copy" width="19" />
              </a>
            </td>
            <DisplayLinkStatus status={status} />
            <td className={className}>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  renewSharedLinkRequest(id);
                }}
                className={shouldBeDisabled(status)}
              >
                <img src="/img/renew.png" alt="renew" width="20" height="20" />
              </a>
            </td>
            <td>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  deleteSharedLinkRequest(id);
                }}
              >
                <img src="/img/bin.png" alt="remove" width="11" height="13" />
              </a>
            </td>
          </tr>
        )
      )}
    </tbody>
  );
};

export default TableBody;
