/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
// import TextModule from '../../Modules/TextModule';

const LinkedAssemblies = ({ assemblyPlans, history }) => {
  if (assemblyPlans?.length === 0) return null;
  const getClassName = (idx) =>
    idx === assemblyPlans.length - 1
      ? 'd-flex align-items-baseline'
      : 'd-flex align-items-baseline mb-20';

  const assembliesUnique = [
    ...new Set(assemblyPlans.flatMap((ap) => ap.assembly)),
  ];

  return (
    <div className="card__plain">
      <div className="card__plain__body bg__cerise-dark">
        <p className="card__plain__heading mb-20">Linked Assemblies</p>
        {assembliesUnique?.map((assembly, idx) => (
          <div className={getClassName(idx)} key={assembly.id}>
            <div className="icon__box mr-12 bg__white flex-shrink-0" />
            <p className="summary__text-sm">
              <Tappable
                component="a"
                className="text-white"
                onTap={() => {
                  history.push(`/assembly/${assembly.id}`);
                }}
              >
                {assembly.title.toUpperCase()}
              </Tappable>
              <br />
              <small>{assembly.description}</small>
            </p>
          </div>
        ))}

        {/* <div className="d-flex align-items-baseline">
        <div className="icon__box mr-12 bg__white flex-shrink-0" />
        <p className="summary__text-sm">
          Use the SUPER SEEDS assembly plan to help you with your assembly about
          seeds.
        </p>
      </div> */}
      </div>
    </div>
  );
};

export default withRouter(LinkedAssemblies);
