import { useLazyQuery } from '@apollo/client';
import { GET_ORGANISATION } from '../../graphql/queries/organisation/organisation';
import {
  dismissLoadingMessage,
  displayErrorMessage,
} from '../../utils/helpers';

const useGetOrganisation = () => {
  const [getOrganisationRequest, { data, loading, error }] = useLazyQuery(
    GET_ORGANISATION,
    {
      onError: () => {
        dismissLoadingMessage();
        displayErrorMessage();
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const getOrganisation = (organisationRef) => {
    getOrganisationRequest({
      variables: {
        id: organisationRef,
      },
    });
  };

  return { getOrganisation, data, loading, error };
};

export default useGetOrganisation;
