/* eslint-disable operator-linebreak */
import React, { useRef } from 'react';

const DateRange = ({ setDayInclusiveFrom, setDayInclusiveTo, yesterday }) => {
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  return (
    <div className="manage-account__subscription-details-row">
      <span className="text__fw-600 pr-3">Date range</span>
      <div className="d-inline-flex">
        <div className="d-inline-flex mr-5">
          <input
            type="date"
            ref={startDateRef}
            onChange={(e) => setDayInclusiveFrom(e.target.value)}
            max={yesterday}
            onClick={() => {
              // adding this cos chrome does not open the datepicker on the padding
              // conditional check because site crashes on safari
              startDateRef.current?.showPicker &&
                startDateRef.current?.showPicker();
            }}
          />
        </div>
        <span className="mr-5">to</span>
        <div className="d-inline-flex">
          <input
            type="date"
            ref={endDateRef}
            onChange={(e) => setDayInclusiveTo(e.target.value)}
            max={yesterday}
            onClick={() => {
              endDateRef.current?.showPicker &&
                endDateRef.current?.showPicker();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DateRange;
