/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/no-named-as-default */
import React, { useRef } from 'react';
import Tappable from 'react-tappable';
import { useReactiveVar } from '@apollo/client';
import Button, { IButton } from './Button';
import { currentSongTypeId } from '../../utils/reactiveVars';
import Icon from './Styles/Icon';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const MediaButton = ({
  icon,
  content,
  onClick,
  disabled,
  rotate,
  type,
  gridPosition,
  bordered,
  id,
  hideOnMobile,
  showOnMobileFs,
  mobileDimensions,
  tabletDimensions,
  gridPositionMobile,
  audioBg,
  mobileFullscreenDimensions,
  pulsate,
}: IButton) => {
  const buttonRef = useRef<null | HTMLButtonElement>(null);

  return (
    <Tappable
      component="button"
      onTap={() => {
        if (!disabled && onClick) {
          return onClick();
        }
      }}
    >
      <Button
        ref={buttonRef}
        type={type}
        disabled={disabled}
        variant="media"
        rotate={rotate}
        gridPosition={gridPosition}
        bordered={bordered}
        active={id === useReactiveVar(currentSongTypeId) || false}
        hideOnMobile={hideOnMobile}
        mobileDimensions={mobileDimensions}
        tabletDimensions={tabletDimensions}
        gridPositionMobile={gridPositionMobile}
        showOnMobileFs={showOnMobileFs}
        audioBg={audioBg}
        onMouseUp={() => {
          if (buttonRef.current) {
            buttonRef.current.blur();
          }
        }}
      >
        {icon && (
          <Icon
            variant={icon}
            mobileDimensions={mobileDimensions}
            tabletDimensions={tabletDimensions}
            mobileFullscreenDimensions={mobileFullscreenDimensions}
            pulsate={pulsate}
          />
        )}
        {content}
      </Button>
    </Tappable>
  );
};
