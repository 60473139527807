import React, { useEffect } from 'react';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import CheckYourMailModal from '../../components/Shared/Modals/EmailVerification/CheckYourMailModal';
import useGetUser from '../../hooks/User/useGetUser';
import hideModal, { showModal } from '../../styles/js/modals';
import { user } from '../../utils/reactiveVars';

const Restricted = () => {
  useEffect(() => {
    showModal('checkYourMailModal', true);
  }, []);

  useGetUser({
    pollInterval: 10000,
  });

  if (user()?.isValidated) {
    hideModal('checkYourMailModal');
    window.location.href = '/';
    return;
  }

  return (
    <>
      <h1 className="ml-5 mt-3 pl-3">Restricted</h1>
      <CheckYourMailModal />
    </>
  );
};

export default withPageWrapper({})(Restricted);
