import { gql } from '@apollo/client';
import { SearchResultResponseFieldsFragments } from '../../queries/search/fragments';

export const SEARCH = gql`
  mutation Search($text: String!, $type: SearchType, $types: [SearchType]) {
    search(text: $text, type: $type, types: $types) {
      SONG {
        ...SearchResultResponseFields
      }
      ESONGBOOK {
        ...SearchResultResponseFields
      }
      PLAYLIST {
        ...SearchResultResponseFields
      }
      COLLECTION {
        ...SearchResultResponseFields
      }
      MUSIC_ACTIVITY {
        ...SearchResultResponseFields
      }
      BLOG_OR_GUIDE {
        ...SearchResultResponseFields
      }
      CURRICULUM_LINKS_AND_MORE {
        ...SearchResultResponseFields
      }
      SEND_RESOURCE {
        ...SearchResultResponseFields
      }
    }
  }
  ${SearchResultResponseFieldsFragments}
`;
