import { useQuery } from '@apollo/client';
import { GET_COMPOSERS } from '../../graphql/queries/song/song';

const useGetComposers = () => {
  const { data, loading, error } = useQuery(GET_COMPOSERS);

  return { data, loading, error };
};

export default useGetComposers;
