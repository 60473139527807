import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_LESSON_PLAN } from '../graphql/queries/musicCurriculum/musicCurriculum';

const useGetLessonPlan = (id, organisationRef) => {
  const { data, loading, error } = useQuery(GET_LESSON_PLAN, {
    variables: { id, organisationRef },
    onCompleted: () => toast.dismiss('loading'),
    onError: () => {
      toast.dismiss('loading');
      toast.error('Could not load lesson plan', { toastId: 'error' });
    },
  });

  return { data, loading, error };
};

export default useGetLessonPlan;
