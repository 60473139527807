import { useMutation } from '@apollo/client';
import { UPDATE_FAVOURITE_SONGS_ORDERING } from '../../graphql/mutations/favourite';
import { GET_FAVOURITE_SONGS } from '../../graphql/queries/song/song';
import { user } from '../../utils/reactiveVars';
import { displayErrorMessage } from '../../utils/helpers';

const useUpdateFavouriteSongsOrdering = () => {
  const [updateFavouriteSongsOrdering] = useMutation(
    UPDATE_FAVOURITE_SONGS_ORDERING,
    { refetchQueries: [{ query: GET_FAVOURITE_SONGS }] }
  );

  const updateFavouriteSongsOrderingRequest = (songs) => {
    updateFavouriteSongsOrdering({
      variables: {
        id: user()?.id,
        input: { songs: { syncWithoutDetaching: songs } },
      },
    }).catch(() => displayErrorMessage());
  };

  return { updateFavouriteSongsOrderingRequest };
};

export default useUpdateFavouriteSongsOrdering;
