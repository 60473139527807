import React from 'react';
import ImageModule from '../../../Song/Sections/SongsPageContent/Modules/ImageModule';
import { formatResourceContent } from '../../../../utils/resource';
import useRenderResource from '../../../../hooks/Resource/useRenderResource';

const PickAndMix = ({ lessonPlan }) => {
  const { render } = useRenderResource();

  return (
    <>
      <div className="card__plain mb-0">
        <ImageModule url={lessonPlan.headerImage} />
        <div className="card__plain__body pt-0 bg__white">
          {formatResourceContent(lessonPlan.content)?.map((item) => (
            <React.Fragment key={item.key}>{render(item)}</React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default PickAndMix;
