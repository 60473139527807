import { useMutation } from '@apollo/client';
import { GENERATE_COLLECTION_SHARE_LINK } from '../../graphql/mutations/collection';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';

const useGenerateCollectionShareLink = (setGeneratedShareLink) => {
  const [generateCollectionShareLink, { loading }] = useMutation(
    GENERATE_COLLECTION_SHARE_LINK,
    {
      onError: () => {
        dismissLoadingMessage();
        displayErrorMessage('Could not generate collection share link.');
      },
      onCompleted: (res) => {
        setGeneratedShareLink(res.generateCollectionShareLink);
        dismissLoadingMessage();
        displaySuccessMessage('Collection share link generated!');
      },
    }
  );

  const generateCollectionShareLinkRequest = (id) => {
    generateCollectionShareLink({
      variables: { collection_id: id },
    });
  };

  loading && displayLoadingMessage();

  return { generateCollectionShareLinkRequest };
};

export default useGenerateCollectionShareLink;
