import React from 'react';
import MainSection from '../../components/Playlists/Sections/MainSection';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import CreatePlaylistModal from '../../components/Shared/Modals/Playlist/CreatePlaylistModal';
import PageHeader from '../../components/Shared/PageHeader';
import CreatePlaylistButton from '../../components/Playlists/CreatePlaylistButton';

const SongsPlaylists = () => (
  <>
    <PageHeader
      icon={(
        <img
          src="/img/audio-icon-grey.svg"
          className="songslist__header__icon"
          alt="Songs and Playlists"
        />
      )}
      title="Songs and Playlists"
      description="Your own playlists, playlists shared by your colleagues, and playlists we think you might like: They're all here!"
      banner={(
        <img
          src="/img/songs-banner.png"
          className="img-fluid songslist__header__banner__imgS"
          alt="Songs and Playlists"
        />
      )}
      additionalComponents={(
        <div className="ml-4">
          <CreatePlaylistButton />
        </div>
      )}
      headerClass="page-header--playlists"
    />
    <MainSection />
    <CreatePlaylistModal />
  </>
);

export default withPageWrapper({ pageTitle: 'All Playlists | Sparkyard' })(
  SongsPlaylists
);
