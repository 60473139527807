/* eslint-disable operator-linebreak */
import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_GLOSSARIES } from '../../../graphql/queries/glossary/glossary';
import useGetFavouriteAssemblies from '../../../hooks/Favourite/useGetFavouriteAssemblies';
import useGetFavouriteResources from '../../../hooks/Favourite/useGetFavouriteResources';
import useGetFavouriteSongs from '../../../hooks/Favourite/useGetFavouriteSongs';
import {
  glossaries,
  userFavouriteAssemblies,
  userFavouriteResources,
  userFavouriteSongs,
} from '../../../utils/reactiveVars';

const GetCommonData = () => {
  const { data: glossaryData } = useQuery(GET_GLOSSARIES);
  if (glossaryData?.glossaries) {
    // sort glossaries according to the number of words in descending order
    // so that multiword words get matched first
    const sortedGlossaries = [...glossaryData?.glossaries].sort(
      (a, b) => b.word.split(' ').length - a.word.split(' ').length
    );
    glossaries(sortedGlossaries);
  }

  const { data: favouriteSongs } = useGetFavouriteSongs();
  favouriteSongs && userFavouriteSongs(favouriteSongs?.userFavouriteSongs);

  const { data: favouriteResources } = useGetFavouriteResources();
  favouriteResources &&
    userFavouriteResources(favouriteResources?.userFavouriteResources);

  const { data: favouriteAssemblies } = useGetFavouriteAssemblies();
  favouriteAssemblies &&
    userFavouriteAssemblies(favouriteAssemblies?.userFavouriteAssemblies);

  return <></>;
};

export default GetCommonData;
