import { gql } from '@apollo/client';

export const NotificationFieldsFragment = gql`
  fragment NotificationFields on Notification {
    id
    title
    subtitle
    type
    typeId
    image
    read
    slug
    customLink
    openInNewTab
  }
`;
