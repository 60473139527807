import { gql } from '@apollo/client';
import {
  MinSongFieldsFragment,
  ResourceFieldsFragments,
  SparklesFieldsFragment,
} from '../song/fragments';

export const EventFieldsFragment = gql`
  fragment EventFields on Event {
    id
    title
    slug
    startDate
    endDate
    description
    colour
    downloadables
    songs {
      ...MinSongFields
    }
    sparkles {
      ...SparklesFields
    }
    resources {
      ...ResourceFields
    }
    assemblyPlans {
      id
      name
      slug
      assembly {
        id
        title
        description
      }
    }
  }
  ${MinSongFieldsFragment}
  ${SparklesFieldsFragment}
  ${ResourceFieldsFragments}
`;

export const MinEventFieldsFragment = gql`
  fragment MinEventFields on Event {
    id
    title
    slug
    startDate
    endDate
    description
    colour
  }
`;
