import React from 'react';
import SearchResult from '../SearchResult';

const SearchResultList = ({ searchResults, searchText }) => (
  <div className="songslist__container">
    {searchResults?.length < 1 && <div>No results available</div>}
    {searchResults?.map(
      ({ id, type, title, description, media, slug, category, tags, link }) => (
        <SearchResult
          key={`${type}-${id}`}
          id={id}
          title={title}
          type={type}
          description={description}
          media={media}
          slug={slug}
          category={category}
          tags={tags}
          link={link}
          searchText={searchText}
        />
      )
    )}
  </div>
);

export default SearchResultList;
