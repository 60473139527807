/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import useGetTag from '../../../hooks/useGetTag';
import useRefetchTags from '../../../hooks/useRefetchTags';
import { buildFilterParams } from '../../../utils/helpers';
import AgeRangeSelect from '../../Shared/CategoryPages/AgeRangeSelect';
import SelectSection from '../../Shared/CategoryPages/SelectSection';
import SubmitSection from '../../Shared/CategoryPages/SubmitSection';
import useGetComposers from '../../../hooks/Songbook/useGetComposers';

const FilterSection = ({ handleFilterSongs, loading }) => {
  const { register, handleSubmit, reset } = useForm();
  const { data: vocalRange, loading: loadingVocalRange } = useGetTag(
    'Vocal Range Description'
  );
  const { data: musicalStyle, loading: loadingMusicalStyle } =
    useGetTag('Musical Style');
  const { data: songType, loading: loadingSongType } = useGetTag('Song Type');
  const { data: seasonal, loading: loadingSeasons } = useGetTag('Seasonal');
  const { data: tempoDescription, loading: loadingTempo } =
    useGetTag('Tempo Description');
  const { data: topicOrTheme, loading: loadingTopicsOrThemes } =
    useGetTag('Topic/Theme');
  const { data: composers, loading: loadingComposers } = useGetComposers();

  const allTagTypesRefetchMapDefault = {};

  const isLoading =
    loading ||
    loadingVocalRange ||
    loadingMusicalStyle ||
    loadingSongType ||
    loadingTempo ||
    loadingTopicsOrThemes ||
    loadingSeasons ||
    loadingComposers;

  const composersSorted = composers?.composers
    ? [...composers.composers]
        .sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase(), 'en', {
            sensitivity: 'base',
          })
        )
        .map((item) => ({ formattedName: item, id: item }))
    : [];

  const musicalStyleObjs = musicalStyle?.tags
    ? musicalStyle?.tags
        ?.slice()
        .sort((a, b) =>
          a.formattedName
            .toLowerCase()
            .localeCompare(b.formattedName.toLowerCase())
        )
    : [];

  const songTypeObjs = songType?.tags
    ? songType?.tags
        ?.slice()
        .sort((a, b) =>
          a.formattedName
            .toLowerCase()
            .localeCompare(b.formattedName.toLowerCase())
        )
    : [];

  const topicOrThemeObjs = topicOrTheme?.tags
    ? topicOrTheme?.tags
        ?.slice()
        .sort((a, b) =>
          a.formattedName
            .toLowerCase()
            .localeCompare(b.formattedName.toLowerCase())
        )
    : [];

  const [handleClearFilters, handleTagChange, handleAgeRangeChange] =
    useRefetchTags(allTagTypesRefetchMapDefault, 'Songs');

  const handleFilter = (data) => handleFilterSongs(buildFilterParams(data));

  return (
    <div className="main__container bg__blue-nile mt-0">
      <div className="assembly__songfinder">
        <h2 className="assembly__songfinder__title">Find songs</h2>
        <div className="assembly__filter assembly__filter__choir">
          <div className="assembly__songfinder__range">
            <label htmlFor="">Age range</label>
            <div className="assembly__songfinder__range__selectors">
              <AgeRangeSelect
                ref={register}
                name="min_age"
                handleAgeRangeChange={handleAgeRangeChange}
              />
              <p>to</p>
              <AgeRangeSelect
                ref={register}
                name="max_age"
                handleAgeRangeChange={handleAgeRangeChange}
              />
            </div>
          </div>

          <SelectSection
            label="Song Type"
            ref={register}
            tags={songTypeObjs}
            handleTagChange={handleTagChange}
            name="song_type"
          />

          <SelectSection
            label="Topic / Theme"
            ref={register}
            tags={topicOrThemeObjs}
            name="topic_or_theme"
            handleTagChange={handleTagChange}
          />

          <SelectSection
            label="Season"
            ref={register}
            tags={seasonal?.tags}
            name="seasonal"
            handleTagChange={handleTagChange}
          />

          <SelectSection
            label="Musical Style"
            ref={register}
            tags={musicalStyleObjs}
            handleTagChange={handleTagChange}
            name="musical_style"
          />

          <SelectSection
            label="Tempo"
            ref={register}
            tags={tempoDescription?.tags}
            handleTagChange={handleTagChange}
            name="tempo_description"
          />

          <SelectSection
            label="Vocal Range"
            ref={register}
            tags={vocalRange?.tags}
            handleTagChange={handleTagChange}
            name="vocal_range_description"
          />

          <SubmitSection
            label="Composer(s)"
            ref={register}
            tags={composersSorted}
            name="composers"
            onClick={handleSubmit(handleFilter)}
            disabled={isLoading}
            reset={reset}
            handleTagChange={handleTagChange}
            handleClearFilters={() => {
              toast.info('Loading...', {
                toastId: 'resetFilter',
                autoClose: 2000,
              });
              handleClearFilters();
              handleSubmit(handleFilterSongs({}));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
