import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';
import { organisationLoggedInAs } from '../../utils/reactiveVars';
import FeaturedAlbumArt from '../Shared/FeaturedAlbumArt';
import LikeAndAddIcons from '../Shared/LikeAddIcons';

const Playlist = ({
  albumArtDefault,
  songs,
  id,
  disableDeletePlaylist,
  isUserPlaylist,
  sharePlaylist,
  name,
  type,
  sharedWithUser,
  showDragIcon,
}) => (
  <div className="mylist__item">
    <div
      className="mylist__item__thumbnail"
      style={{
        background: `url(${albumArtDefault})`,
        backgroundSize: 'cover',
        borderRadius: '10px',
      }}
    >
      <FeaturedAlbumArt songs={songs} roundedCorners />
    </div>
    <div>
      <Link to={`/songs/playlists/${id}`} className="mylist__item__title">
        {name}
      </Link>
      <span href="" className="mylist__item__subtitle">
        {songs?.length || 0} {songs?.length === 1 ? 'song' : 'songs'}
      </span>
    </div>

    <div className="d-flex ml-auto">
      {showDragIcon && (
        <img
          src="/img/order-white.svg"
          alt="order"
          className="pr-4"
          width="48"
        />
      )}
      <LikeAndAddIcons
        headerText="Playlist options"
        modalId="playlistActions"
        addToCollection
        deletePlaylist={!disableDeletePlaylist}
        editPlaylist={isUserPlaylist}
        id={id}
        itemType="PLAYLIST"
        playlistName={name}
        type={type}
        sharePlaylist={
          isUserPlaylist && useReactiveVar(organisationLoggedInAs)
            ? sharePlaylist
            : null
        }
        canCopyPlaylist={sharedWithUser}
        hidePC={sharedWithUser ? 'playlist' : null}
      />
    </div>
  </div>
);

export default Playlist;
