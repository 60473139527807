import { useMutation } from '@apollo/client';
import { GENERATE_URL_HASH } from '../../graphql/mutations/other';

const useGenerateUrlHash = () => {
  const [generateUrlHash, { data, loading }] = useMutation(GENERATE_URL_HASH);

  const generateUrlHashRequest = (url) => {
    generateUrlHash({ variables: { url } });
  };

  return { generateUrlHashRequest, data, loading };
};

export default useGenerateUrlHash;
