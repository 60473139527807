import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_ASSEMBLY } from '../../graphql/queries/assembly/assembly';

const useGetAssembly = (id) => {
  const { data, loading, error } = useQuery(GET_ASSEMBLY, {
    variables: { id },
    onCompleted: () => toast.dismiss('loading'),
    onError: () => {
      toast.dismiss('loading');
      toast.error('Could not load assembly', { toastId: 'error' });
    },
  });

  return { data, loading, error };
};

export default useGetAssembly;
