/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import React from 'react';
import useRenderResources from '../../../../../../hooks/Resource/useRenderResources';
import { currentSong } from '../../../../../../utils/reactiveVars';
import { formatResourceContent } from '../../../../../../utils/resource';
import TextModule from '../../Modules/TextModule';

const SongSummaryTeachingTips = () => {
  const {
    summary,
    teachingTips,
    songSummaryMedia,
    teachingTipsMedia,
  } = currentSong();

  return (
    <>
      {summary || teachingTips ? (
        <div className="card__plain">
          <div
            className={`card__plain__body plus-padding bg__white ${
              formatResourceContent(teachingTipsMedia)?.length > 0 ? 'pb-0' : ''
            }`}
          >
            {summary && <TextModule title="Song Summary" text={summary} />}
            {songSummaryMedia &&
              formatResourceContent(songSummaryMedia).map((medium) => (
                <React.Fragment key={medium.key}>
                  {useRenderResources(medium)}
                </React.Fragment>
              ))}
            {teachingTips && (
              <>
                <TextModule title="Teaching tips" text={teachingTips} list />
                <div className="mt-n4" />
              </>
            )}
            {teachingTipsMedia &&
              formatResourceContent(teachingTipsMedia).map((medium) => (
                <React.Fragment key={medium.key}>
                  {useRenderResources(medium)}
                </React.Fragment>
              ))}
          </div>
        </div>
      ) : null}
    </>
  );
};
export default SongSummaryTeachingTips;
