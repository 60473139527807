import { useMutation } from '@apollo/client';
import { CANCEL_INVITE } from '../../graphql/mutations/organisation';
import { GET_ORGANISATION } from '../../graphql/queries/organisation/organisation';
import {
  dismissErrorMessage,
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';

const useCancelInvite = (inviteRef, organisationRef) => {
  const [cancelInvite, { loading }] = useMutation(CANCEL_INVITE, {
    refetchQueries: [
      {
        query: GET_ORGANISATION,
        variables: {
          id: organisationRef,
        },
      },
    ],
  });

  const sendCancelInviteRequest = () => {
    dismissErrorMessage();

    cancelInvite({
      variables: {
        input: {
          invite_ref: parseInt(inviteRef, 10),
          organisation_ref: parseInt(organisationRef, 10),
        },
      },
    })
      .then(() => {
        dismissLoadingMessage();
        displaySuccessMessage('Invite cancelled!');
      })
      .catch(() => {
        dismissLoadingMessage();
        displayErrorMessage('Could not cancel invite');
      });
  };

  loading && displayLoadingMessage('Please wait...');

  return { sendCancelInviteRequest, loading };
};

export default useCancelInvite;
