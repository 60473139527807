export const sortings = [
  {
    value: '',
    label: 'Default',
    availableFor: ['sparkyard-playlists'],
  },
  {
    value: 'date-created',
    label: 'Date Created',
    availableFor: ['my-playlists', 'sparkyard-playlists', 'shared-with-me'],
  },
  {
    value: 'a-to-z',
    label: 'A to Z',
    availableFor: ['my-playlists', 'sparkyard-playlists', 'shared-with-me'],
  },
  {
    value: 'manual',
    label: 'Manual',
    availableFor: ['my-playlists', 'shared-with-me'],
  },
];
