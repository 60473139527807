import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from '../Loading';
import ResourcesList from '../../RecommendedResources/Sections/MainSection/ResourcesList';

const ResourcesListInfiniteScroll = ({ loadMore, hasMore, resources }) => (
  <InfiniteScroll
    pageStart={0}
    loadMore={loadMore}
    hasMore={hasMore}
    loader={<Loading key={0} />}
  >
    <ResourcesList resources={resources} />
  </InfiniteScroll>
);

export default ResourcesListInfiniteScroll;
