import { gql } from '@apollo/client';
import { UserFieldsFragment } from './fragments';

export const GET_USER = gql`
  query Me {
    me {
      ...UserFields
    }
  }
  ${UserFieldsFragment}
`;
