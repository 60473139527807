import tw, { styled } from 'twin.macro';
import 'styled-components/macro';

type SlideContainerProps = {
  scale: number;
  width: number | string;
  height: number | string;
  showSlide: boolean;
};
const SlideContainer = styled('div')<SlideContainerProps>`
  ${tw`flex flex-col items-center justify-center relative`}
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow: hidden;
  display: ${(props) => (props.showSlide ? 'block' : 'none')};
  top: -76px;
  @media (max-width: 767px),
    screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    top: 50px;
  }
`;

export default SlideContainer;
