import { gql } from '@apollo/client';
import {
  MembershipFieldsFragment,
  OrganisationFieldsFragment,
} from './fragments';

export const GET_MEMBERSHIPS = gql`
  query Memberships {
    memberships {
      ...MembershipFields
    }
  }
  ${MembershipFieldsFragment}
`;

export const GET_ORGANISATION = gql`
  query Organisation($id: ID!) {
    organisation(id: $id) {
      ...OrganisationFields
    }
  }
  ${OrganisationFieldsFragment}
`;

export const LEAVE_ORGANISATION = gql`
  mutation LeaveOrganisation($id: ID!) {
    leaveOrganisation(id: $id)
  }
`;

export const GET_ORGANISATION_PLAYS = gql`
  query OrganisationPlays($input: OrganisationPlaysInput!) {
    organisationPlays(input: $input) {
      counts
    }
  }
`;
