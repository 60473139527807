import { useQuery } from '@apollo/client';
import { GET_RECOMMENDED_SONGS } from '../../graphql/queries/song/song';
import {
  dismissLoadingMessage,
  displayErrorMessage,
} from '../../utils/helpers';

const useGetRecommendedSongs = (first = 6, variables = null) => {
  const { data, loading, fetchMore } = useQuery(GET_RECOMMENDED_SONGS, {
    variables: {
      first,
      ...variables,
    },
    onCompleted: () => dismissLoadingMessage(),
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage('Could not load recommended songs');
    },
  });

  return { data, loading, fetchMore };
};

export default useGetRecommendedSongs;
