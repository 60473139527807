/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import React from 'react';
import Card from './Card';

const ResourcesList = ({ resources, loading, actions, showTags }) => (
  <>
    <div className=" mt-2 text-center">{loading && 'Loading...'}</div>
    {!loading && (
      <>
        {resources?.length < 1 && <div>No resources available</div>}
        {resources?.map((resource) => (
          <Card
            key={resource.id}
            resource={resource}
            actions={actions}
            showTags={showTags}
          />
        ))}
      </>
    )}
  </>
);

export default ResourcesList;
