import { useMutation } from '@apollo/client';
import {
  ADD_ADDRESS,
  EDIT_ADDRESS,
} from '../../graphql/mutations/organisation';
import { GET_ORGANISATION } from '../../graphql/queries/organisation/organisation';
import hideModal from '../../styles/js/modals';
import {
  dismissErrorMessage,
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';

const useAddOrEditAddress = (organisationRef) => {
  const [addAddress, { loading }] = useMutation(ADD_ADDRESS, {
    refetchQueries: [
      {
        query: GET_ORGANISATION,
        variables: {
          id: organisationRef,
        },
      },
    ],
  });

  const [editAddress, { loading: loadingEditAddress }] = useMutation(
    EDIT_ADDRESS,
    {
      refetchQueries: [
        {
          query: GET_ORGANISATION,
          variables: {
            id: organisationRef,
          },
        },
      ],
    }
  );

  const sendAddAddressRequest = (input) => {
    dismissErrorMessage();

    addAddress({
      variables: { input },
    })
      .then(() => {
        dismissLoadingMessage();
        displaySuccessMessage();
        hideModal('editOrganisationModal');
      })
      .catch(() => {
        dismissLoadingMessage();
        displayErrorMessage();
      });
  };

  const sendEditAddressRequest = (input) => {
    dismissErrorMessage();

    editAddress({
      variables: { input },
    })
      .then(() => {
        dismissLoadingMessage();
        displaySuccessMessage();
        hideModal('editOrganisationModal');
      })
      .catch(() => {
        dismissLoadingMessage();
        displayErrorMessage();
      });
  };

  (loading || loadingEditAddress) && displayLoadingMessage('Please wait...');

  return {
    sendAddAddressRequest,
    sendEditAddressRequest,
    loading: loading || loadingEditAddress,
  };
};

export default useAddOrEditAddress;
