import { useMutation } from '@apollo/client';
import { MARK_USER_NOTIFICATIONS_AS_READ } from '../../graphql/mutations/notifications';
import { GET_USER_NOTIFICATIONS } from '../../graphql/queries/notification/notification';

const useMarkUserNotificationsAsRead = () => {
  const [markUserNotificationsAsRead, { loading }] = useMutation(
    MARK_USER_NOTIFICATIONS_AS_READ,
    { refetchQueries: [{ query: GET_USER_NOTIFICATIONS }] }
  );

  const markUserNotificationsAsReadRequest = (id) => {
    markUserNotificationsAsRead({ variables: { id } });
  };

  return { markUserNotificationsAsReadRequest, loading };
};

export default useMarkUserNotificationsAsRead;
