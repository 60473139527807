import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_CATEGORIES } from '../../graphql/queries/category/category';

const useGetCategories = () => {
  const { data } = useQuery(GET_CATEGORIES, {
    onCompleted: () => toast.dismiss('lcaterror'),
    onError: () => {
      toast.error('Something went wrong', { toastId: 'lcaterror' });
    },
  });

  return { data };
};

export default useGetCategories;
