import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { isPlayerFullscreen } from '../../../utils/reactiveVars';
import ActionsModal from '../Modals/ActionsModal/Index';

const ActionsModalWrapper = () => {
  const isFullScreen = useReactiveVar(isPlayerFullscreen);

  return <>{isFullScreen ? null : <ActionsModal />}</>;
};

export default ActionsModalWrapper;
