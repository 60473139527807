/* eslint-disable implicit-arrow-linebreak */

import { shortenLongDescription, stripHtml } from './helpers';
import {
  categoryMap,
  defaultAssemblyThumbnail,
  defaultResourceThumbnail,
} from './objects';

/* eslint-disable no-confusing-arrow */
export const formatResourceContent = (content) =>
  content ? JSON.parse(content) : null;

export const getResourceTags = (tags, type) =>
  tags.filter((tag) => tag.type === type);

export const getCategoryResources = (resources, category) =>
  resources.filter((resource) => resource.category?.name === category);

export const buildResourceParams = (params) => ({
  ...params,
  where: params.hasSongDetail,
  ...(params.hasSongTags && { hasTags: params.hasSongTags }),
});

export const getResourceImage = (category) =>
  categoryMap.find((element) => element.name === category)?.image;

export const getResourceThumbnail = (thumbnail, resourceCategory) => {
  if (['Blog', 'Guide'].includes(resourceCategory)) {
    return getResourceImage(resourceCategory);
  }

  return (
    thumbnail || getResourceImage(resourceCategory) || defaultResourceThumbnail
  );
};

export const getResourceDescription = (resource) => {
  const { thumbnailText, summary, content } = resource;
  if (thumbnailText || summary) return thumbnailText || stripHtml(summary);

  const firstCopyBlock = JSON.parse(content)?.find(
    ({ layout }) => layout === 'wysiwyg'
  );

  return stripHtml(firstCopyBlock?.attributes?.body) || '';
};

export const getThumbnail = (resource) => {
  const { thumbnail } = resource;
  if (resource.__typename === 'Resource') {
    return getResourceThumbnail(thumbnail, resource.category.name);
  }
  return thumbnail || defaultAssemblyThumbnail;
};

export const getLink = (resource) => {
  if (resource.__typename === 'Resource') {
    if (resource.category.name === 'Song Calendar Activity') {
      return `/event/${resource.calendarEvents[0]?.slug}`;
    }
    return `/resources/${resource.slug}`;
  }
  return `/assembly/${resource.id}`;
};

export const getTitle = (resource) => {
  if (resource.__typename === 'Resource') {
    return `${resource.category.name.toUpperCase()} > ${resource.title}`;
  }
  return `ASSEMBLY > ${resource.theme.replace('_', ' ')} > ${resource.title}`;
};

/**
 * Get description for resource or assembly
 * @param {object} resource object
 * @returns string
 */
export const getDescription = (resource) => {
  if (resource.__typename === 'Resource') {
    const description = getResourceDescription(resource);
    return shortenLongDescription(description, 165);
  }
  return shortenLongDescription(resource.description, 165);
};
