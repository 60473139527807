/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import useGetPlaylistShareLink from '../../../../../../../hooks/Playlist/useGetPlaylistShareLink';
import CopyShareLink from './CopyShareLink';
import GenerateShareLink from './GenerateShareLink';

const GeneratePlaylistShareLink = ({ playlistId }) => {
  const { data: getShareLinkData, loading: loadingGetShareLink } =
    useGetPlaylistShareLink(playlistId);

  const [generatedShareLink, setGeneratedShareLink] = useState(null);

  const shareLink =
    getShareLinkData?.getPlaylistShareLink || generatedShareLink;

  return (
    <>
      <p className="text-dark text__fs-12 mt-5 mb-0">
        Create a link to share a copy of this playlist with anyone who has a
        Sparkyard subscription.
      </p>
      {loadingGetShareLink && <span>Please wait...</span>}

      <CopyShareLink shareLink={shareLink} />
      <GenerateShareLink
        setGeneratedShareLink={setGeneratedShareLink}
        hide={shareLink}
        playlistId={playlistId}
      />
    </>
  );
};

export default GeneratePlaylistShareLink;
