import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
  <>
    <div className="main__container d-flex align-items-center py-4 px-md-5 mt-0">
      <img
        src="/img/assembly-icon-white.svg"
        alt="assembly plan icon"
        className="song-calendar__header__icon"
      />
      <span className="song-calendar__header__text">Assembly Plans</span>
    </div>
    <div className="main__container bg__fuchsia mt-0 d-flex px-md-5 song-calendar__header__container">
      <span className="song-calendar__header__description assembly__header__description">
        Packed with song choices, resources and ready-to-go assembly plans for
        the whole school year.
        <br /> To explore more assembly songs and resources, check out our{' '}
        <Link to="/assemblies" className="text-light">
          <u>Assemblies Category Page</u>
        </Link>
      </span>
      <img
        src="/img/clipboard-lady-lg.png"
        className="img-fluid assembly__header__banner"
        alt="banner"
      />
    </div>
  </>
);

export default Header;
