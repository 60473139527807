import React from 'react';

const Header = () => (
  <div className="main__container bg__fuchsia mt-0">
    <div className="assembly__header">
      <h2 className="assembly__header__title">ASSEMBLIES</h2>
      <p className="assembly__header__text">
        Packed with song choices, resources and ready-to-go assembly plans for
        the whole school year.
      </p>
    </div>
  </div>
);

export default Header;
