import React from 'react';
import { isMobileFullscreen } from '../../../utils/reactiveVars';
import PopupInfo from '../../../components/Shared/PopupInfo';

const GuestHeader = ({ isGuest }: { isGuest: boolean }): JSX.Element | null => {
  if (!isMobileFullscreen() || !isGuest) return null;

  return (
    <nav className="main__nav__mobile header-nav fixed-top flip-element__no-height justify-content-center">
      <PopupInfo position='bottom' isHeader={true} />
    </nav>
  );
};

export default GuestHeader;
