import React from 'react';

const MainButton = ({ label, onClick, color }) => {
    return (
        <button onClick={() => onClick()} className={`banner__big bg__${color}`}>
            <h3 className="banner__big__text">
                {label}
            </h3>
        </button>
    );
};

export default MainButton;
