import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

export const RouterLinkOrNot = forwardRef(
  ({ children, href, target, ...props }, ref) => {
    const isHtmlLink =
      (href?.includes('://') ||
        href?.startsWith('#') ||
        href?.includes('mailto:')) ??
      false;

    const rel = target === '_blank' ? 'noopener noreferrer' : '';

    const baseProps = {
      href: href || '',
      target,
      rel,
    };

    if (isHtmlLink) {
      return (
        <a {...baseProps} {...props} ref={ref}>
          {children}
        </a>
      );
    }

    return <Link to={href} {...props}>{children}</Link>;
  }
);

export default RouterLinkOrNot;
