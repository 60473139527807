import React from 'react';
import { Link } from 'react-router-dom';
import FeaturedAlbumArt from './FeaturedAlbumArt';

const Playlist = ({ title, songs, id }) => (
  <div className="assembly__grid__playlist__item">
    <Link
      to={`/songs/playlists/${id}`}
      className="assembly__grid__playlist__item__thumb"
    >
      <div className="album-card">
        <FeaturedAlbumArt songs={songs} roundedCorners />
      </div>
    </Link>
    <Link
      className="assembly__grid__playlist__item__title"
      to={`/songs/playlists/${id}`}
    >
      {title}
    </Link>
  </div>
);

export default Playlist;
