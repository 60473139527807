import React from 'react';
import { Link } from 'react-router-dom';
import LikeAndAddIcons from '../../Shared/LikeAddIcons';

const Header = ({ title, theme, thumbnail, id }) => (
  <div className="main__container bg__cello">
    <div className=" mt-0">
      <div className="assembly__song__header">
        <div className="assembly__song__header__links">
          <Link to="/assembly-plans" className="mr-3 active">
            Assembly plans
          </Link>{' '}
          <Link to={`/assemblies/theme/${theme.toLowerCase()}`}>{theme}</Link>
        </div>
        <div className="assembly__song__header__info">
          <div className="assembly__song__header__thumb">
            <img src={thumbnail || '/img/assembly-thumbnail.png'} alt="" />
          </div>
          <h3 className="assembly__song__header__title">{title}</h3>
          <div className="d-flex align-items-center ml-auto">
            <LikeAndAddIcons
              favourite
              addToCollection
              headerText="Options"
              modalId="assemblyActions"
              itemType="ASSEMBLY"
              id={id}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Header;
