import React, { forwardRef } from 'react';

const Select = forwardRef(({ name, tags, handleTagChange }, ref) => (
  <select
    name={name}
    ref={ref}
    className="filter__select-lg"
    style={{ minHeight: '38px' }}
    onChange={(e) => handleTagChange(e.target.value, name)}
  >
    <option value="all">-- Select --</option>
    {tags?.map((tag) => (
      <option value={tag.formattedName} key={tag.id}>
        {tag.formattedName}
      </option>
    ))}
  </select>
));

Select.displayName = 'Select';

export default Select;
