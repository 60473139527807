/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useForm } from 'react-hook-form';
import useGetTag from '../../../../hooks/useGetTag';
import useRefetchTags from '../../../../hooks/useRefetchTags';
import { buildFilterParams } from '../../../../utils/helpers';
import AgeRangeSelect from '../../../Shared/CategoryPages/AgeRangeSelect';
import SelectSection from '../../../Shared/CategoryPages/SelectSection';
import SubmitSection from '../../../Shared/CategoryPages/SubmitSection';

const FilterSection = ({ handleFilterSongs, loading }) => {
  const withContent = {
    tags: ['Assembly'],
    min_age: 5,
    max_age: 11,
  };

  const {
    data: values,
    fetchMore: fetchMoreValues,
    loading: loadingValues,
  } = useGetTag('Values (PSHE, SMSC)', { withContent });

  const {
    data: topicOrTheme,
    fetchMore: fetchMoreTopicsOrThemes,
    loading: loadingTopicsOrThemes,
  } = useGetTag('Topic/Theme', { withContent });

  const {
    data: season,
    fetchMore: fetchMoreSeasons,
    loading: loadingSeasons,
  } = useGetTag('Seasonal', {
    withContent,
  });

  const isLoading =
    loading || loadingValues || loadingTopicsOrThemes || loadingSeasons;

  const allTagTypesRefetchMapDefault = {
    values: fetchMoreValues,
    topic_or_theme: fetchMoreTopicsOrThemes,
    seasonal: fetchMoreSeasons,
  };

  const [
    handleClearFilters,
    handleTagChange,
    handleAgeRangeChange,
  ] = useRefetchTags(allTagTypesRefetchMapDefault, 'Assembly');

  const { register, handleSubmit, reset } = useForm();

  const handleFilter = (data) => handleFilterSongs(buildFilterParams(data));

  const valuesObjs = values?.tags
    ?.slice()
    .sort((a, b) =>
      a.formattedName.toLowerCase().localeCompare(b.formattedName.toLowerCase())
    );

  const topicOrThemeObjs = topicOrTheme?.tags
    ?.slice()
    .sort((a, b) =>
      a.formattedName.toLowerCase().localeCompare(b.formattedName.toLowerCase())
    );

  return (
    <div className="main__container bg__blue-nile mt-0">
      <div className="assembly__songfinder">
        <h2 className="assembly__songfinder__title">Find resources</h2>
        <div className="assembly__filter">
          <div className="assembly__songfinder__range">
            <label htmlFor="">Age range</label>
            <div className="assembly__songfinder__range__selectors">
              <AgeRangeSelect
                ref={register}
                name="min_age"
                handleAgeRangeChange={handleAgeRangeChange}
              />
              <p>to</p>
              <AgeRangeSelect
                ref={register}
                name="max_age"
                handleAgeRangeChange={handleAgeRangeChange}
              />
            </div>
          </div>

          <SelectSection
            label="Values"
            ref={register}
            tags={valuesObjs}
            name="values"
            handleTagChange={handleTagChange}
          />

          <SelectSection
            label="Topic / Theme"
            ref={register}
            tags={topicOrThemeObjs}
            name="topic_or_theme"
            handleTagChange={handleTagChange}
          />

          <SubmitSection
            label="Select Season"
            ref={register}
            tags={season?.tags}
            name="seasonal"
            onClick={handleSubmit(handleFilter)}
            disabled={isLoading}
            reset={reset}
            handleTagChange={handleTagChange}
            handleClearFilters={() => {
              handleClearFilters();
              handleSubmit(handleFilterSongs({}));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
