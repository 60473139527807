/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import LikeAndAddIcons from './LikeAddIcons';

const ResourceHeader = ({ title, tags, id, categoryName }) => {
  const shouldDisplayTag = ({ type, formattedName }) =>
    type === 'Curriculum Subject Links' &&
    !(categoryName === 'SEND Resource' && formattedName === 'SEND');

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-13">
        <p className="card__plain__subheading text__dark mb-0 mt__sm-2">
          {title}
        </p>
        <LikeAndAddIcons
          headerText="Options"
          darkColor
          modalId="resourceHeaderActions"
          favourite
          addToCollection
          type={false}
          itemType="RESOURCE"
          id={id}
        />
      </div>
      {tags?.map(
        (tag) =>
          shouldDisplayTag(tag) && (
            <div className="tag mb-23 mr-2 d-inline-block" key={tag.id}>
              {tag.formattedName}
            </div>
          )
      )}
    </>
  );
};
export default ResourceHeader;
