import { useMutation } from '@apollo/client';
import { CREATE_FILE_STAT } from '../../graphql/mutations/other';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useFileStats = () => {
  const [createFileStat] = useMutation(CREATE_FILE_STAT, {
    onError: () => {},
  });

  const createFileStatRequest = (input) => {
    createFileStat({
      variables: {
        input: {
          ...input,
          organisation_id: organisationLoggedInAs()?.id || null,
        },
      },
    });
  };

  return { createFileStatRequest };
};

export default useFileStats;
