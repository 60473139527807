import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import SongbookDetails from '../../components/Songbook/Sections/SongbookDetails';
import Header from '../../components/Songbook/Sections/Header';
import SongsList from '../../components/Songs/Sections/Songslist';
import useGetSongbook from '../../hooks/Songbook/useGetSongbook';
import { albumArtDefault } from '../../utils/helpers';

const Songbook = () => {
  const { id } = useParams();
  const { data } = useGetSongbook(id);

  if (data?.esongbook === null) return <Redirect to="/404" />;
  if (data?.esongbook?.linkToOota) {
    window.location.href = 'https://outoftheark.co.uk';
    return null;
  }

  useEffect(() => {
    document.title = `${
      data?.esongbook?.infoName || ''
    } | eSongbook | Sparkyard`;
  }, [data]);

  const albumArt = data?.esongbook?.albumArt || albumArtDefault;

  return (
    <>
      <section className="main__container">
        <Header />
        {data?.esongbook && (
          <div className="esongbook__container">
            <SongbookDetails
              albumArt={albumArt}
              title={data.esongbook?.infoName}
              id={data.esongbook.id}
              resources={data.esongbook.resources}
              excludeResources={data.esongbook.excludeResources}
            />
            <SongsList
              songs={data.esongbook?.songs}
              esongbookId={data.esongbook.id}
            />
          </div>
        )}
      </section>
    </>
  );
};

export default withPageWrapper({})(Songbook);
