import { gql } from '@apollo/client';
import { UserFieldsFragment } from '../../queries/user/fragments';

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      ...UserFields
    }
  }
  ${UserFieldsFragment}
`;

export const SEND_VERIFICATION_MAIL = gql`
  mutation SendVerificationMail {
    sendVerificationMail
  }
`;
