/* eslint-disable react/no-danger */
import classNames from 'classnames';
import React from 'react';
import RouterLinkOrNot from '../RouterLinkOrNot';
import PlaySong from '../PlaySong';

const SimpleCard = ({
  image,
  title,
  description,
  link,
  linkTarget,
  noTopRadius,
  type,
  id,
}) => (
  <div
    className={classNames('simple-card', {
      'simple-card--no-top-radius': noTopRadius,
    })}
  >
    <div style={{ position: 'relative' }}>
      {type === 'SONG' && <PlaySong id={id} />}
      {image && (
        <RouterLinkOrNot href={link} target={linkTarget}>
          <div className="simple-card__image">
            <img src={image} alt="" className="simple-card__img" />
          </div>
        </RouterLinkOrNot>
      )}
    </div>

    {(title || description) && (
      <RouterLinkOrNot href={link} target={linkTarget}>
        <div className="simple-card__content">
          {title && <h3 className="simple-card__heading">{title}</h3>}
          {description && (
            <div
              className="simple-card__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      </RouterLinkOrNot>
    )}
  </div>
);

export default SimpleCard;
