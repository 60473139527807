/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { modalActions } from '../../../../utils/reactiveVars';
import { getUserFromLocalStorage } from '../../../../utils/user';
import AddToPlaylistAndCollectionModal from '../AddToPlaylistAndCollectionModal';
import DeleteModal from '../DeleteModal';
import EditPlaylistModal from '../Playlist/EditPlaylistModal';
import RemoveModal from '../RemoveModal';
import ShareSongModal from '../ShareSongModal/ShareSongModal';
import SharePlaylist from './SharePlaylist';
import ModalHeader from './ModalHeader';
import Favourite from './Favourite';
import EditPlaylist from './EditPlaylist';
import AddTo from './AddTo';
import Share from './Share';
import RemoveFrom from './RemoveFrom';
import DeletePlaylist from './DeletePlaylist';
import CopyPlaylist from './CopyPlaylist';
import CopyCollection from './CopyCollection';
import SharePlaylistModal from '../Playlist/SharePlaylistModal/Index';
import HidePC from './HidePC';
import HidePCModal from '../HidePCModal';

const ActionsModal = ({ fullscreenView }) => {
  useEffect(() => {
    getUserFromLocalStorage();
  }, []);

  if (!getUserFromLocalStorage()) return null;

  const {
    headerText,
    favourite,
    addToPlaylist,
    addToCollection,
    share,
    modalId,
    type,
    deletePlaylist,
    id,
    itemType,
    editPlaylist,
    playlistName,
    sharePlaylist,
    canCopyPlaylist,
    copyCollection,
    hidePC,
  } = useReactiveVar(modalActions);

  const showCollectionOrPlaylist = () => {
    if (addToCollection && addToPlaylist) return 'both';
    return addToCollection ? 'collection' : 'playlist';
  };

  return (
    <>
      <div
        // add dark background to modal when triggered from fullscreen view
        // as the dark background that comes from bootstrap is outside the
        // fullscreen component
        className={`modal fade ${fullscreenView ? 'modal__bg-dark' : ''}`}
        id={modalId}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="actions"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered custom__modal__filter-new"
          role="document"
        >
          <div className="modal-content">
            <div className="custom__modal__content">
              <ModalHeader headerText={headerText} />
              <Favourite itemType={itemType} id={id} show={!!favourite} />
              <EditPlaylist show={editPlaylist} />
              <AddTo
                modalId={modalId}
                show={addToPlaylist || addToCollection}
              />
              <Share show={share} />
              <RemoveFrom type={type} show={!!type?.item} />
              <SharePlaylist show={sharePlaylist} />
              <CopyPlaylist show={canCopyPlaylist} id={id} />
              <DeletePlaylist
                itemType={itemType}
                id={id}
                show={deletePlaylist}
              />
              <CopyCollection id={id} show={copyCollection} />
              <HidePC hidePC={hidePC} />
            </div>
          </div>
        </div>
      </div>
      <AddToPlaylistAndCollectionModal
        show={showCollectionOrPlaylist()}
        modalId={`${modalId}addToPlaylistAndCollection`}
        itemType={itemType}
        id={id}
      />
      {share && <ShareSongModal itemType={itemType} songId={id} />}
      {type && <RemoveModal type={type} childId={id} />}
      <DeleteModal />
      <EditPlaylistModal id={id} name={playlistName} />
      {sharePlaylist && (
        <SharePlaylistModal sharePlaylist={sharePlaylist} id={id} />
      )}
      {hidePC && <HidePCModal type={hidePC} id={id} />}
    </>
  );
};

export default withRouter(ActionsModal);
