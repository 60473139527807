import { useMutation } from '@apollo/client';
import {
  dismissErrorMessage,
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { REMOVE_LESSON_PLAN_BOOKMARK } from '../../graphql/mutations/curriculum';
import { GET_MUSIC_CURRICULUM } from '../../graphql/queries/musicCurriculum/musicCurriculum';

const useRemoveLessonPlanBookmark = (organisationRef) => {
  const [removeLessonPlanBookmark, { loading }] = useMutation(
    REMOVE_LESSON_PLAN_BOOKMARK,
    {
      refetchQueries: [
        { query: GET_MUSIC_CURRICULUM, variables: { organisationRef } },
      ],
      onCompleted: () => {
        displaySuccessMessage('Bookmark removed!');
        dismissErrorMessage();
        dismissLoadingMessage();
      },
      onError: () => displayErrorMessage('Failed to remove bookmark'),
      notifyOnNetworkStatusChange: true,
    }
  );

  loading && displayLoadingMessage();
  return { removeLessonPlanBookmark, loading };
};

export default useRemoveLessonPlanBookmark;
