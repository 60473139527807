/* eslint-disable operator-linebreak */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { defaultAvatarMid } from '../../../../utils/objects';
import { user } from '../../../../utils/reactiveVars';
import OrganisationsDropdown from '../../Header/Sections/OrganisationsDropdown/OrganisationsDropdown';

const SidebarProfile = () => {
  const loggedInUser = useReactiveVar(user);
  if (!loggedInUser) return null;
  const { firstname, surname, avatar } = loggedInUser;

  return (
    <ul className="sidebar__profile__list">
      <li className="sidebar__profile__list__item">
        <img src={avatar || defaultAvatarMid} alt="" />
        <p>
          {firstname} {surname}
        </p>
      </li>
      <li className="sidebar__profile__list__item px-0">
        <OrganisationsDropdown />
      </li>
    </ul>
  );
};

export default SidebarProfile;
