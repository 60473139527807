import React from 'react';

const ModalHeader = () => (
  <>
    <div className="custom__modal__header mb-0">
      <div className="text__white mt-4 mb-0 text-center w-100">
        <h6 className="text__fs-18 text__fw-600">Welcome back!</h6>
      </div>
      <a href="" className="custom__modal__close" data-dismiss="modal">
        <img src="/img/plus-icon.svg" alt="" />
      </a>
    </div>
    <h6 className="text__fs-18 text__fw-200 text-center mb-5">
      We hope you’re enjoying Sparkyard
    </h6>
    <p className="text__fs-14 text-center">
      We’d love to find out more about you, so we can make sure we recommend the
      best content and tell you about new releases.
    </p>
  </>
);

export default ModalHeader;
