/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import Countdown from './Countdown';

const Logo = ({ history }) => (
  <>
    <div className="sidebar__logo-container">
      <Tappable
          component="a"
          className="sidebar__logo"
          onTap={() => history.push('/')}
        >
        <img src="/img/logo.png" alt="" />
      </Tappable>
      <Countdown />
    </div>
  </>
  );

export default withRouter(Logo);
