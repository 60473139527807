/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Header from '../../components/Playlist/Sections/Header';
import ReorderableSongsList from '../../components/Shared/Song/ReorderableSongsList';
import Subheader from '../../components/Playlist/Sections/Subheader';
// import FilterDropdown from '../components/Shared/FilterDropdown';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useGetPlaylist from '../../hooks/Playlist/useGetPlaylist';
import { displayLoadingMessage } from '../../utils/helpers';
import useUpdatePlaylistSongOrdering from '../../hooks/Playlist/useUpdatePlaylistSongOrdering';

const Playlist = () => {
  const [dropdownActive, setDropdownActive] = useState(false);
  //   const [filterParams, setFilterParams] = useState(null);
  const handleDropdown = () => setDropdownActive(!dropdownActive);

  const { id } = useParams();
  //   const { data, loading, fetchMore } = useGetPlaylist(id);
  const { data, loading } = useGetPlaylist(id);
  //   console.log(data);

  //   const filterSongs = async (params) => {
  //     setFilterParams(params);
  //     fetchMore({
  //       variables: {
  //         ...params,
  //       },
  //       updateQuery: (prev, newQuery) =>
  //         newQuery.fetchMoreResult ? newQuery.fetchMoreResult : prev,
  //     });
  //   };

  useEffect(() => {
    document.title = `${data?.playlist.name || ''} | Playlist | Sparkyard`;
  }, [data]);

  loading && displayLoadingMessage();

  if (data?.playlist === null) return <Redirect to="/404" />;
  const isUserPlaylist = data?.playlist?.isUserPlaylist;

  const { updatePlaylistSongOrderingRequest } = useUpdatePlaylistSongOrdering(
    data?.playlist?.id
  );

  return (
    <section className="main__container">
      {data?.playlist && (
        <>
          <Header handleDropdown={handleDropdown} />
          <Subheader
            playlist={data.playlist}
            title={data?.playlist.name}
            disableDeletePlaylist={!isUserPlaylist}
            isUserPlaylist={isUserPlaylist}
            sharePlaylist={{
              hasSharedWithOrganisation:
                data?.playlist?.hasSharedWithOrganisation,
              shareId: data?.playlist?.shareId,
            }}
            id={data.playlist}
            isSharedWithUser={data?.playlist?.isSharedWithUser}
          />
          {/* <FilterDropdown
            active={dropdownActive}
            handleDropdown={handleDropdown}
            filter={filterSongs}
          /> */}
          {data?.playlist?.songs && (
            <ReorderableSongsList
              songs={data.playlist.songs}
              type={
                isUserPlaylist
                  ? {
                      from: 'playlist',
                      item: 'song',
                      parentId: data?.playlist?.id,
                    }
                  : null
              }
              isUserResource={isUserPlaylist}
              orderingFunction={updatePlaylistSongOrderingRequest}
            />
          )}
        </>
      )}
    </section>
  );
};

export default withPageWrapper({})(Playlist);
