/* eslint-disable operator-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import { useReactiveVar, useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import SongsList from '../../components/Songs/Sections/Songslist';
import useGetSongs from '../../hooks/Song/useGetSongs';
import { returnHasTags } from '../../utils/helpers';
import { toastErrorObj } from '../../utils/objects';
import { songChoiceSongs } from '../../utils/reactiveVars';
import { GET_PURCHASED_SONGS } from '../../graphql/queries/nativitiesAndMusicals/nativitiesAndMusicals';
import Loading from '../../components/Shared/Loading';

const SongChoice = ({ history }) => {
  const [allSongs, setAllSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [sort, setSort] = useState('asc');
  const {
    path,
    pageTitle,
    name = '',
    type = '',
    songs,
    filterParams,
  } = useReactiveVar(songChoiceSongs);

  let queryVar = {
    hasSongDetail: { column: 'RECOMMENDED', value: true },
    available: true,
  };

  let data = {};

  const { data: purchasedSongsData = {} } = useQuery(GET_PURCHASED_SONGS, {
    onError: () => {
      toast.dismiss('loading');
      toast.error('Could not load songs', toastErrorObj);
    },
  });

  if (type !== 'Recommended') {
    if (name === 'English') {
      queryVar = {
        hasSongTags: {
          AND: [
            { column: 'TYPE', value: 'Curriculum Subject Links' },
            { column: 'NAME', value: 'English' },
          ],
          OR: [
            { column: 'TYPE', value: 'Curriculum Subject Links' },
            { column: 'NAME', value: 'Literacy' },
          ],
        },
        available: true,
      };
    } else {
      queryVar = returnHasTags('hasSongTags', type, name);
    }
  }

  const { data: allSongsData = {} } = useGetSongs(2000, {
    ...queryVar,
    ...(filterParams || {}),
  });

  // const sortSongs = (param) => {
  //   setSort(param);
  //   const copiedAllSongs = allSongs.slice();
  //   let sortedSongs;

  //   if (param === 'asc') {
  //     sortedSongs = copiedAllSongs;
  //   } else {
  //     sortedSongs = copiedAllSongs.reverse();
  //   }

  //   setAllSongs(sortedSongs);
  // };

  useEffect(() => {
    if (type === 'Purchased') {
      toast.info('Loading...', { toastId: 'loading' });
      setAllSongs(purchasedSongsData.purchasedSongs);
      setLoading(false);
      toast.dismiss('loading');
    } else {
      data = allSongsData;
    }

    if ((name === '' || type === '') && songs?.length > 0) {
      // if (sort !== 'desc') {
      //   setAllSongs(songs);
      // }
      setAllSongs(songs);
      setLoading(false);
    } else if (data !== undefined && 'songs' in data) {
      setAllSongs(data.songs.data);
      setLoading(false);
    }
  }, [name, type, songs, data]);

  return (
    <section className="main__container">
      <div className="songslist__container" style={{ maxWidth: '698px' }}>
        <Tappable
          component="a"
          className="cp__header__breadcrumb"
          onTap={() => {
            history.push(path);
          }}
        >
          {`${pageTitle || ''} > Song choice`}
        </Tappable>
        {/* <div className="text__fs-12 mb-4">
          <span className="mr-3">Sort</span>
          <input
            type="radio"
            id="az"
            name="sort"
            value="az"
            className="mr-2"
            onClick={() => sortSongs('asc')}
            defaultChecked="true"
          />
          <label className="mb-0 mr-3" htmlFor="az">
            a - z
          </label>
          <input
            type="radio"
            id="za"
            name="sort"
            value="za"
            className="mr-2"
            onClick={() => sortSongs('desc')}
          />
          <label htmlFor="za" className="mb-0">
            z - a
          </label>
        </div> */}
      </div>
      {loading && <Loading />}
      <SongsList
        songs={allSongs
          ?.slice()
          .sort((a, b) =>
            a.title.toLowerCase().localeCompare(b.title.toLowerCase())
          )}
        type={type}
        loading={loading}
      />
    </section>
  );
};

export default withPageWrapper({})(withRouter(SongChoice));
