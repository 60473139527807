import { gql } from '@apollo/client';
import {
  CurriculumLessonPlanFieldsFragment,
  MusicCurriculumOverviewFieldsFragment,
} from './fragments';

export const GET_MUSIC_CURRICULUM = gql`
  query MusicCurriculum($organisationRef: ID) {
    musicCurriculum {
      id
      year
      pdf
      pdfCopy
      adminOnly
      terms {
        id
        term
        title
        description
        steps {
          id
          step
          title
          lessonPlans {
            id
            title
            lessonPlanColor
            downloadables
            active
            pickAndMix
            thumbnail
            hasBeenBookmarked(organisationRef: $organisationRef)
            bookmarkId(organisationRef: $organisationRef)
            step {
              id
              step
              title
              term {
                id
                term
                year {
                  id
                  year
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LESSON_PLAN = gql`
  query LessonPlan($id: ID!, $organisationRef: ID) {
    lessonPlan(id: $id) {
      ...CurriculumLessonPlanFields
      hasBeenBookmarked(organisationRef: $organisationRef)
      bookmarkId(organisationRef: $organisationRef)
    }
  }
  ${CurriculumLessonPlanFieldsFragment}
`;

export const GET_MUSIC_CURRICULUM_OVERVIEW = gql`
  query MusicCurriculumOverview {
    musicCurriculumOverview {
      ...MusicCurriculumOverviewFields
    }
  }
  ${MusicCurriculumOverviewFieldsFragment}
`;
