import React from 'react';
import { Link } from 'react-router-dom';
import { stepColorObj } from '../../../../utils/objects';
import Bookmark from '../../../CurriculumPlan/Bookmark';

const Step = ({ step }) => (
  <React.Fragment key={step.id}>
    <p className="text__fs-12 text__black text__fw-700 mb-10">
      STEP {step.step}: {step.title}
    </p>
    {step.lessonPlans && (
      <div className="mc__tabs__grid mb-22">
        {step.lessonPlans.map((plan) => (
          <Link
            to={`/music-curriculum-plan/year/${plan.step.term.year.year}/term/${plan.step.term.term}/step/${plan.step.step}/lesson-plan/${plan.id}`}
            className={`mc__card ${
              plan.lessonPlanColor ? '' : stepColorObj[step.step]
            } position-relative`}
            style={{
              background: plan.lessonPlanColor,
              paddingRight: '36px',
            }}
            key={plan.id}
          >
            <Bookmark
              lessonPlanId={plan.id}
              bookmarkId={plan.bookmarkId}
              hasBeenBookmarked={plan.hasBeenBookmarked}
            />
            <div className="d-flex justify-content-between">
              <p className="text__fs-12 text__fw-600 mb-0 d-flex">
                {!plan.pickAndMix && `Lesson Plan`} {plan.title}
              </p>
              {plan.active && plan.pickAndMix && (
                <img
                  src={plan.thumbnail}
                  alt="thumbnail"
                  style={{
                    maxHeight: '35px',
                    marginRight: '10px',
                    maxWidth: '40px',
                    marginTop: '-8px',
                    position: 'absolute',
                    right: '35px'
                  }}
                />
              )}
            </div>
          </Link>
        ))}
      </div>
    )}
  </React.Fragment>
);

export default Step;
