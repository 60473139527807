import { useMutation } from '@apollo/client';
import { VERIFY_SHARE_LINK } from '../../graphql/mutations/share';
import { displayErrorMessage } from '../../utils/helpers';
import useGetSongForPlayer from '../Song/useGetSongForPlayer';

const useVerifyShareLink = (songId, uuid) => {
  const { getSongRequest, data } = useGetSongForPlayer(true);

  const [verifyShareLink, { loading }] = useMutation(VERIFY_SHARE_LINK, {
    variables: {
      where: {
        AND: [
          {
            column: 'SONGID',
            value: songId,
          },
          {
            column: 'UUID',
            value: uuid,
          },
        ],
      },
    },
    onCompleted: (res) => {
      const shareId = res.verifyShareLink?.id;
      const ootaShareUUID = res.verifyShareLink?.ootaUUID;
      if (!shareId) {
        return displayErrorMessage('Invalid link!');
      }
      getSongRequest(songId, shareId, ootaShareUUID);
    },
    onError: () => displayErrorMessage('Something went wrong!'),
  });

  return { verifyShareLink, data, loading };
};

export default useVerifyShareLink;
