import React from 'react';

const Header = () => (
  <>
    <div className="main__container d-flex align-items-center py-4 px-md-5 mt-0 resources-favourites__header__sub">
      <img
        src="/img/favourite-white.svg"
        alt="favourite"
        className="resources-favourites__header__icon"
      />
      <span className="resources-favourites__header__text">My Favourites</span>
      <span className="resources-favourites__header__text__muted">
        Resources
      </span>
    </div>

    <div className="main__container bg__cerise-dark mt-0 d-flex px-md-5 song-calendar__header__container">
      <span className="song-calendar__header__description">
        Save your favourite guides, curriculum links, assembly plans and more
        here.
      </span>
      <div>
        <img
          src="/img/resource-favourites.png"
          className="img-fluid resources-favourites__header__banner"
          alt="banner"
        />
      </div>
    </div>
  </>
);

export default Header;
