import React, { useState } from 'react';
import FilterSection from '../../components/CategoryPages/SchoolRoutinesAndSignals/Sections/FilterSection';
import Header from '../../components/CategoryPages/SchoolRoutinesAndSignals/Sections/Header';
import MainSection from '../../components/CategoryPages/SchoolRoutinesAndSignals/Sections/MainSection/Index';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useGetPlaylists from '../../hooks/Playlist/useGetPlaylists';
import useGetResources from '../../hooks/Resource/useGetResources';
import useGetSongs from '../../hooks/Song/useGetSongs';
import { returnHasTags } from '../../utils/helpers';
import { updateQuery } from '../../utils/objects';
import { buildResourceParams } from '../../utils/resource';

const SchoolRoutinesAndSignals = () => {
  const [displaySongs, setDisplaySongs] = useState(true);
  const [displayResources, setDisplayResources] = useState(true);
  const [displayPlaylists, setDisplayPlaylists] = useState(true);
  const [filterParams, setFilterParams] = useState({});

  const {
    data: songs,
    loading: loadingSongs,
    fetchMore: fetchMoreSongs,
  } = useGetSongs(
    6,
    returnHasTags(
      'hasSongTags',
      'OOTA Singing School',
      'Routines & Signals',
      true
    ),
    true
  );

  const {
    data: resources,
    loading: loadingResources,
    fetchMore: fetchMoreResources,
  } = useGetResources(
    2,
    returnHasTags('hasTags', 'OOTA Singing School', 'Routines & Signals')
  );

  const { data: playlists, fetchMore: fetchMorePlaylists } = useGetPlaylists(
    'sy',
    {
      hasCategoryPages: {
        column: 'NAME',
        value: 'School Routines and Signals ',
      },
    }
  );

  const loading = loadingResources || loadingSongs;

  const handleFilter = (params) => {
    const resourceParams = buildResourceParams(params);
    fetchMorePlaylists({
      variables: { ...params },
      ...updateQuery,
    });
    fetchMoreSongs({
      variables: { ...params },
      ...updateQuery,
    });
    fetchMoreResources({
      variables: { ...resourceParams },
      ...updateQuery,
    });
    setDisplaySongs(true);
    setDisplayResources(true);
    setDisplayPlaylists(true);
    setFilterParams(params);
  };

  return (
    <>
      <Header />
      <FilterSection loading={loading} handleFilter={handleFilter} />
      <MainSection
        songs={songs?.songs?.data}
        resources={resources?.resources?.data}
        playlists={playlists?.playlists}
        displayResources={displayResources}
        displaySongs={displaySongs}
        displayPlaylists={displayPlaylists}
        filterParams={filterParams}
      />
    </>
  );
};

export default withPageWrapper({})(SchoolRoutinesAndSignals);
