import { Link } from 'react-router-dom';
import React from 'react';
import MCActivityHeader from '../../../Shared/MCActivityHeader';
import {
  formatResourceContent,
  getResourceTags,
} from '../../../../utils/resource';
import useRenderResource from '../../../../hooks/Resource/useRenderResource';
import TagsModule from '../../../Song/Sections/SongsPageContent/Sections/MusicActivity/TagsModule';
import useRenderSparkles from '../../../../hooks/useRenderSparkles';
import ImageModule from '../../../Song/Sections/SongsPageContent/Modules/ImageModule';
import TextModule from '../../../Shared/TextModule';

const Plan = ({ lessonPlan }) => {
  const { render } = useRenderResource();

  let sparkles = lessonPlan.musicCurriculumActivities?.map(
    (activity) => activity.sparkles
  );
  sparkles = [].concat(...sparkles);

  return (
    <>
      {lessonPlan?.musicCurriculumActivities?.map((activity) => (
        <div className="card__plain mb-0" key={activity.id}>
          <div className="card__plain__body pt-0 bg__white">
            <MCActivityHeader
              title={activity.title}
              tags={activity.resourceTags}
            />
            {formatResourceContent(activity.content)?.map((item) => (
              <React.Fragment key={item.key}>
                {render(item)}
              </React.Fragment>
            ))}
            <TagsModule
              title="Inter-related Dimensions of Music"
              tags={getResourceTags(activity.resourceTags, 'Music Checklist')}
            />
            <TagsModule
              title="National Curriculum coverage for Music"
              tags={getResourceTags(
                activity.resourceTags,
                'National Music Curriculum descriptor'
              )}
            />
            <TagsModule
              title="Musical Skills"
              tags={getResourceTags(activity.resourceTags, 'Musical Skills')}
            />
            <TagsModule
              title="Early Learning Goals"
              tags={getResourceTags(
                activity.resourceTags,
                'Early Learning Goals'
              )}
            />
          </div>
        </div>
      ))}
      <div className="mb-20" />

      {sparkles?.map((sparkle) => (
        <React.Fragment key={sparkle.id}>
          {useRenderSparkles(sparkle)}
        </React.Fragment>
      ))}

      {lessonPlan.callout && (
        <div className="card__plain mb-0">
          <div className="card__plain__body pt-0 bg__white">
            <MCActivityHeader title={lessonPlan.callout.calloutTitle} />
            <ImageModule url={lessonPlan.callout.calloutImage} />
            <TextModule text={lessonPlan.callout.copy} />
            <Link
              to={`/music-curriculum-plan/year/${lessonPlan.step.term.year.year}/term/${lessonPlan.step.term.term}/step/${lessonPlan.step.step}/lesson-plan/${lessonPlan.callout.id}`}
              className={`button btn__lg ${
                lessonPlan.lessonPlanColor ?? 'bg__lime'
              }`}
              style={{ background: lessonPlan.lessonPlanColor }}
            >
              <p className="text__fs-12 text__fw-600 mb-0 w-100 text-white">
                {lessonPlan.callout.buttonText}
              </p>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Plan;
