/* eslint-disable operator-linebreak */
import React from 'react';
import useGeneratePlaylistShareLink from '../../../../../../../hooks/Playlist/useGeneratePlaylistShareLink';

const GenerateShareLink = ({ setGeneratedShareLink, hide, playlistId }) => {
  if (hide) return null;

  const { generatePlaylistShareLinkRequest, loading } =
    useGeneratePlaylistShareLink(setGeneratedShareLink);

  return (
    <>
      <button
        className="btn bg__cello text-white mt-2"
        onClick={() => generatePlaylistShareLinkRequest(playlistId)}
        disabled={loading}
      >
        Generate share link
      </button>
    </>
  );
};

export default GenerateShareLink;
