import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import {
  getDescription,
  getLink,
  getThumbnail,
  getTitle,
} from '../../utils/resource';
import LikeAndAddIcons from '../Shared/LikeAddIcons';
import Tags from './Sections/Tags';

const ResourceItem = ({
  resource,
  isDragDisabled,
  collectionId = null,
  history,
}) => {
  const type = isDragDisabled
    ? false
    : {
        from: 'collection',
        item: 'resource',
        parentId: collectionId,
      };

  return (
    <div className="resources-favourites__card">
      <div className="d-flex">
        <img
          src={getThumbnail(resource)}
          alt="thumbnail"
          className="resources-favourites__card__thumbnail"
        />
        <div>
          <Tappable
            component="a"
            onTap={() => {
              history.push(getLink(resource));
            }}
          >
            {getTitle(resource)}
          </Tappable>
          <Tags tags={resource.resourceTags} />
          <p>{getDescription(resource)}</p>
        </div>
      </div>
      <div className="resources-favourites__card__actions">
        {!isDragDisabled && (
          <img src="/img/order.svg" alt="order" width={32} className="pt-2" />
        )}
        <LikeAndAddIcons
          headerText="Options"
          darkColor
          modalId="resourceHeaderActions"
          favourite
          addToCollection
          type={type}
          itemType={resource.__typename.toUpperCase()}
          id={resource.id}
          largeIcon
        />
      </div>
    </div>
  );
};

export default withRouter(ResourceItem);
