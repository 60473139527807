import React, { useState } from 'react';
import MainNav from './Sections/MainNav/MainNav';
import MainNavMobile from './Sections/MainNav/MainNavMobile';
import SearchBarDropdown from './Sections/SearchBarDropdown';

/* eslint-disable jsx-a11y/anchor-is-valid */
const Header = () => {
  const [showSearchMenu, setShowSearchMenu] = useState(false);

  return (
    <>
      <MainNav />
      <MainNavMobile
        showSearchMenu={showSearchMenu}
        setShowSearchMenu={setShowSearchMenu}
      />
      {showSearchMenu && <SearchBarDropdown />}
    </>
  );
};

export default Header;
