import { gql } from '@apollo/client';
import {
  MidSongFieldsFragment,
  MinSongFieldsFragment,
  SongFieldsFragments,
} from './fragments';

export const GET_SONG = gql`
  query Song($id: ID!, $organisation_ref: ID) {
    song(id: $id) {
      ...SongFields
      isFavourited(organisation_ref: $organisation_ref)
    }
  }
  ${SongFieldsFragments}
`;

export const GET_SONGS = gql`
  query Songs(
    $first: Int!
    $page: Int
    $hasSongTags: QuerySongsHasSongTagsWhereHasConditions
    $where: QuerySongsWhereWhereConditions
    $hasSongDetail: QuerySongsHasSongDetailWhereHasConditions
    $hasAssemblyPlans: WhereConditions
    $hasMusicCurriculumActivities: WhereConditions
    $orderBy: [QuerySongsOrderByOrderByClause!]
    $filtered: [TagInput]
    $primary: Boolean
    $available: Boolean
    $randomise: Boolean
    $excludeFromTreatbox: Boolean
  ) {
    songs(
      first: $first
      page: $page
      hasSongTags: $hasSongTags
      where: $where
      hasSongDetail: $hasSongDetail
      hasAssemblyPlans: $hasAssemblyPlans
      hasMusicCurriculumActivities: $hasMusicCurriculumActivities
      orderBy: $orderBy
      filtered: $filtered
      primary: $primary
      available: $available
      randomise: $randomise
      excludeFromTreatbox: $excludeFromTreatbox
    ) {
      data {
        ...MinSongFields
      }
      paginatorInfo {
        currentPage
        lastPage
        count
        hasMorePages
      }
    }
  }
  ${MinSongFieldsFragment}
`;

export const GET_FAVOURITE_SONGS = gql`
  query UserFavouriteSongs($organisation_ref: ID) {
    userFavouriteSongs(organisation_ref: $organisation_ref) {
      ...MinSongFields
      
    }
  }
  ${MinSongFieldsFragment}
`;

export const GET_RECOMMENDED_SONGS = gql`
  query RecommendedSongs($first: Int!, $page: Int, $randomise: Boolean) {
    recommendedSongs(first: $first, page: $page, randomise: $randomise) {
      data {
        ...MinSongFields
      }
      paginatorInfo {
        currentPage
        lastPage
        count
        hasMorePages
      }
    }
  }
  ${MinSongFieldsFragment}
`;

export const GET_COMPOSERS = gql`
  query Composers {
    composers
  }
`;

export const GET_SONG_AS_GUEST = gql`
  query SongAsGuest($id: ID!) {
    songAsGuest(id: $id) {
      ...MidSongFields
      esongbooks {
        id
        albumArt
        infoName
      }
    }
  }
  ${MidSongFieldsFragment}
`;
