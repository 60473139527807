import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_PLAYLIST } from '../../graphql/queries/playlist/playlist';
import {
  dismissLoadingMessage,
  displayErrorMessage,
} from '../../utils/helpers';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useGetPlaylist = (id) => {
  const organisationRef = useReactiveVar(organisationLoggedInAs)?.id || null;
  const { data, loading, error, fetchMore } = useQuery(GET_PLAYLIST, {
    variables: {
      id,
      organisation_ref: organisationRef,
    },
    onCompleted: () => dismissLoadingMessage(),
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage('Could not load playlist');
    },
  });

  return { data, loading, error, fetchMore };
};

export default useGetPlaylist;
