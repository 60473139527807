import React from 'react';

const ModalHeader = () => (
  <div className="custom__modal__header mb-5">
    <div className="w-100 pt-2 text-center">
      <h5 className="custom__modal__edit-account__content__title ml-auto">
        MANAGE YOUR SUBSCRIPTION
      </h5>
    </div>
    <a href="" className="custom__modal__close" data-dismiss="modal">
      <img src="/img/plus-icon.svg" alt="" />
    </a>
  </div>
);

export default ModalHeader;
