import React from 'react';

const TableHead = () => {
  const className = 'd-none d-md-table-cell';

  return (
    <thead>
      <tr className="py-5">
        <th scope="col">Name</th>
        <th scope="col" className={className}>
          Email
        </th>
        <th scope="col">Status</th>
        <th scope="col">Active</th>
        <th scope="col" className={className}>
          Admin
        </th>
        <th scope="col" className={className}>
          Remove
        </th>
      </tr>
    </thead>
  );
};

export default TableHead;
