/* eslint-disable operator-linebreak */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import Month from './Month';

const MainSection = ({ events }) => (
  <section className="main__container">
    <div className="songpage__container">
      <div className="card__plain">
        {events &&
          Object.values(events).map((month, idx) => (
            <Month key={idx} events={month} />
          ))}
      </div>
    </div>
  </section>
);

export default MainSection;
