/* eslint-disable react/no-danger */
import React from 'react';
import {
  addGlossaries,
  formatList,
  removeHtmlClassFromString,
} from '../../utils/helpers';
import { glossaries } from '../../utils/reactiveVars';

const IconModule = ({ iconUrl, title, text, noMargin }) => {
  const glossariesArr = glossaries();
  let formattedText = formatList(removeHtmlClassFromString(text));
  formattedText = addGlossaries(formattedText, glossariesArr);

  return (
    <div className={`d-flex align-items-start ${noMargin ? 'mb-0' : 'mb-15'}`}>
      <img
        src={iconUrl}
        className="mr-20"
        alt={title}
        style={{ width: '25px', height: '25px' }}
      />
      <div>
        <p className="summary__text-sm text__black">
          <b>{title}</b>
        </p>
        <p
          dangerouslySetInnerHTML={{
            __html: formatList(formattedText),
          }}
          className="summary__text-sm text__black"
        />
      </div>
    </div>
  );
};
export default IconModule;
