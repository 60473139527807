import { gql } from '@apollo/client';
import { HomepageFieldsFragment } from './fragments';

export const GET_HOMEPAGE = gql`
  query GetHomepage {
    getHomepage {
      ...HomepageFields
    }
  }
  ${HomepageFieldsFragment}
`;
