import React, { useMemo } from 'react';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import SongsList from '../../components/Songs/Sections/Songslist';
import { displayLoadingMessage } from '../../utils/helpers';
import useGetRecentlyPlayed from '../../hooks/Player/useGetSongs';

const RecentlyPlayed = () => {
  const { data, loading } = useGetRecentlyPlayed(20);

  loading && displayLoadingMessage('Loading recently played');

  const songs = useMemo(
    () => data?.recentlyPlayed.data.map((elm) => elm.song) || null,
    [data]
  );

  return (
    <>
      <div className="resource__favorite__header">
        <div className="main__container">
          <div className="resource__favorite__header__grid">
            <div className="resource__favorite__header__title">
              <img
                src="img/audio-icon-grey.svg"
                className="songslist__header__icon"
                alt=""
              />
              <h2>
                Song&nbsp;
                <span className="text__white">Recently Played</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="main__container">
        {songs && <SongsList songs={songs} loading={loading} />}
      </div>
    </>
  );
};

export default withPageWrapper({})(RecentlyPlayed);
