import React from 'react';

// const Header = ({ handleDropdown }) => (
const Header = () => (
  <div className="songslist__header mb__sm-0 mb__lg-30">
    <img
      src="/img/audio-icon-grey.svg"
      className="songslist__header__icon"
      alt=""
    />
    <h2 className="songslist__header__title d-md-block d-none">
      Songs and Playlists &nbsp;
      <span className="text__white">Playlists</span>
    </h2>
    <h2 className="songslist__header__title d-md-none">
      Music &nbsp;<span className="text__white">Playlists</span>
    </h2>
    {/* <a
      href=""
      className="songslist__header__filter"
      id="cta-filter-dropdown"
      onClick={(e) => {
        e.preventDefault();
        handleDropdown();
      }}
    >
      <i className="fas fa-sliders-h songslist__header__filter__icon" />
      <p>Filter</p>
    </a> */}
  </div>
);

export default Header;
