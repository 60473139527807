/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { assemblyList } from '../../utils/reactiveVars';
import Card from '../../components/AssembliesTheme/Sections/MainSection/Card';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';

const SeeAllAssemblies = () => {
  const { assemblies, type } = useReactiveVar(assemblyList);

  return (
    <div className="main__container">
      {assemblies?.map(({ id, title, description, thumbnail }) => (
        <Card
          key={id}
          title={title}
          description={description}
          id={id}
          thumbnail={thumbnail}
          type={type}
        />
      ))}
    </div>
  );
};

export default withPageWrapper({})(SeeAllAssemblies);
