import React, { useState } from 'react';
import useFileStats from '../../hooks/Others/useFileStats';

const Dropdown = ({ resources, id: esongbookId }) => {
  const [isActive, setIsActive] = useState(false);

  const getIcon = (type, linkUrl) => {
    if (type === 'pdf') return 'fas fa-cloud-download-alt';
    if (linkUrl.includes('youtube')) {
      return 'fab fa-youtube';
    }
  };

  const { createFileStatRequest } = useFileStats();

  const stat = {
    esongbook_id: esongbookId,
  };

  return (
    <div className={`esongbook__resources ${isActive && 'active'}`}>
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          setIsActive(!isActive);
        }}
        className="esongbook__resources__cta"
      >
        <p>Resources</p>
        <i className="fa fa-chevron-down" />
      </a>
      <div className="esongbook__resources__dropdown">
        <ul className="esongbook__resources__dropdown__list">
          {resources?.map(({ id, pdfFile, sfxFile, linkUrl, title, type }) => (
            <>
              {title !== 'Out of the Ark Website' && (
                <li key={id}>
                  <a
                    href={pdfFile || sfxFile || linkUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      if (sfxFile || pdfFile) {
                        createFileStatRequest({
                          ...stat,
                          event_type: sfxFile
                            ? 'OpenedESongbookResourceSFX'
                            : 'OpenedESongbookResourcePDF',
                          esongbook_resource_id: id,
                        });
                      }
                    }}
                  >
                    <i className={getIcon(type, linkUrl)} />
                    <p>{title}</p>
                  </a>
                </li>
              )}
            </>
          ))}
          {/*    <li>
            <a href="">
              <i className="fas fa-cloud-download-alt" />
              <p>All Lyrics PDFs</p>
            </a>
          </li>
          <li>
            <a href="">
              <i className="fas fa-cloud-download-alt" />
              <p>All Score PDFs</p>
            </a>
          </li>
          <li>
            <a href="">
              <i className="fas fa-file-image" />
              <p>Curriculum Links</p>
            </a>
          </li>
          <li>
            <a href="">
              <i className="fas fa-file-image" />
              <p>Music Activities</p>
            </a>
          </li>
          <li>
            <a href="">
              <i className="fas fa-file-image" />
              <p>Song Information</p>
            </a>
          </li>
          <li>
            <a href="">
              <i className="fas fa-file-image" />
              <p>Glossary</p>
            </a>
          </li>
          <li>
            <a href="">
              <i className="fab fa-youtube" />
              <p>Every Brand New - Signing Video</p>
            </a>
          </li>
          <li>
            <a href="">
              <i className="fas fa-shopping-basket" />
              <p>Buy on Out of the Ark store</p>
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
