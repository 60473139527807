import { styled } from 'twin.macro';
import 'styled-components/macro';

const PlayerContainer = styled.div`
  max-width: 1360px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 7px;
  // @media (max-width: 1024px) {
  //   max-width: 1024px;
  // }
  @media (max-width: 1600px) {
    max-width: 90%;
  }
  @media (max-width: 429px),
    screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    margin-top: 9px;
  }
  @media screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    margin-top: 0;
  }
`;

export default PlayerContainer;
