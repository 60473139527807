import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import UserCollections from '../Tabs/UserCollections';
import SparkyardCollections from '../Tabs/SparkyardCollections';
import SharedCollections from '../Tabs/SharedCollections';
import SortingDropdown from '../SortingDropdown';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { sortings } from '../datas';
import { useLocation } from 'react-router-dom';

export const CollectionsHeader = ({ title }) => (
  <div className="d-flex align-items-center mb-4">
    <img
      src="/img/sparkyard-logo-small.svg"
      alt="sparkyard-logo-mini"
      className="mr-3"
    />
    <span className="text__fs-16">{title}</span>
  </div>
);

const MainSection = ({
  userCollections,
  syCollections,
  sharedCollections,
  loadingUserCollections,
  loadingSyCollections,
  loadingSharedCollections,
  organisationRef,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const toggleTab = (tab) => {
    if (activeTabIndex !== tab) setActiveTabIndex(tab);
  };

  const [selectedSorting, setSelectedSorting] = useState(sortings[0].value);

  const sortCollections = (value) => {
    setSelectedSorting(value);
  };

  const sortedUserCollections = useMemo(() => {
    const userCollectionsLocal = userCollections ? [...userCollections] : [];

    switch (selectedSorting) {
      case 'date-created':
        return userCollectionsLocal.sort(
          (a, b) => parseInt(b.id) - parseInt(a.id)
        );

      case 'a-to-z':
        return userCollectionsLocal.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      case 'manual':
        return userCollectionsLocal;
      default:
        return userCollectionsLocal;
    }
  }, [selectedSorting, userCollections]);

  const sortedSyCollections = useMemo(() => {
    const syCollectionsLocal = syCollections ? [...syCollections] : [];

    switch (selectedSorting) {
      case 'date-created':
        return syCollectionsLocal.sort(
          (a, b) => parseInt(b.id) - parseInt(a.id)
        );
      case 'a-to-z':
        return syCollectionsLocal.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      default:
        return syCollectionsLocal;
    }
  }, [selectedSorting, syCollections]);

  const sortedSharedCollections = useMemo(() => {
    const sharedCollectionsLocal = sharedCollections
      ? [...sharedCollections]
      : [];

    switch (selectedSorting) {
      case 'date-created':
        return sharedCollectionsLocal.sort(
          (a, b) => parseInt(b.id) - parseInt(a.id)
        );
      case 'a-to-z':
        return sharedCollectionsLocal.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      default:
        return sharedCollectionsLocal;
    }
  }, [selectedSorting, sharedCollections]);

  const tabs = [
    {
      id: 'my-collections',
      title: 'My Collections',
      component: (
        <UserCollections
          isLoading={loadingUserCollections}
          collections={sortedUserCollections}
          showDragIcon={selectedSorting === 'manual'}
        />
      ),
    },
    {
      id: 'sparkyard-collections',
      title: 'Sparkyard Collections',
      component: (
        <SparkyardCollections
          isLoading={loadingSyCollections}
          collections={sortedSyCollections}
        />
      ),
    },
    {
      id: 'shared-with-me',
      title: 'Shared with me',
      component: organisationRef ? (
        <SharedCollections
          isLoading={loadingSharedCollections}
          collections={sortedSharedCollections}
        />
      ) : (
        <></>
      ),
    },
  ];

  const filteredSortings = useMemo(() => {
    const currentTab = tabs[activeTabIndex];
    return sortings.filter((sorting) =>
      sorting.availableFor.includes(currentTab.id)
    );
  }, [sortings, tabs, activeTabIndex]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get('tab') || '';

  useEffect(() => {
    const index = tabs.findIndex((tab) => tab.id === activeTab);
    if (index >= 0) {
      setActiveTabIndex(index);
    }
  }, [activeTab]);

  return (
    <section className="main__container">
      <div className="px__sm-3">
        <div className="w-full max-w-mdX px-2 py-16 sm:px-0">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <Nav className="nav-tabs-simple mb-4 mb-md-0">
              {tabs.map((tab, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={classNames({ active: activeTabIndex === index })}
                    onClick={() => {
                      toggleTab(index);
                    }}
                  >
                    {tab.title}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <SortingDropdown
              sortings={filteredSortings}
              onChange={sortCollections}
            />
          </div>
          <TabContent activeTab={activeTabIndex} className="pt-5">
            {tabs.map((tab, index) => (
              <TabPane key={index} tabId={index}>
                {tab.component}
              </TabPane>
            ))}
          </TabContent>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
