import React from 'react';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import Video from '../../components/Shared/Video/Video';
import { videoList } from '../../utils/reactiveVars';

const SeeAllVideos = () => (
  <div className="songpage__container">
    {videoList()?.map((video) => (
      <Video
          key={video.key}
          url={video.attributes.url}
          title={video.attributes.title}
        />
      ))}
  </div>
  );

export default withPageWrapper({})(SeeAllVideos);
