import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { shortenLongDescription } from '../../../../utils/helpers';

const RelatedGuidesCard = ({ title, content, thumbnail, slug, history }) => (
  <div className="card__plain bg__white mb-0 border__radius-10">
    <div className="card__plain__body p-0">
      <div className="row">
        <div className="col-12">
          <img
            src={thumbnail}
            alt={title}
            className="resource-card-img border__radius-10"
          />
        </div>
        <div className="col-12">
          <div className="resource-card-content">
            <div className="">
              <Tappable
                component="a"
                className="text__fs-14 text__dark mb-3 text__fw-600 d-block"
                onTap={() => {
                  history.push(`/resources/${slug}`);
                }}
              >
                {title}
              </Tappable>
              <p className="text__fs-12 text__black mb-0">
                {shortenLongDescription(content, 150)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default withRouter(RelatedGuidesCard);
