import React from 'react';
import { showModal } from '../../../../styles/js/modals';

const HidePC = ({ hidePC }) => {
  if (!hidePC) return null;

  return (
    <div className="mb-20 text__black text__fs-16">
      <a
        href=""
        className="text__black"
        data-toggle="modal"
        data-dismiss="modal"
        onClick={(e) => {
          e.preventDefault();
          showModal('hidePCModal');
        }}
      >
        <i className="fas fa-pencil-alt mr-3 text__fs-20 text__turquoise-medium" />{' '}
        Unfollow {hidePC}
      </a>
    </div>
  );
};

export default HidePC;
