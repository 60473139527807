/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '../../hooks/Others/useMediaQuery';
import { allSongsPageSongs } from '../../utils/reactiveVars';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  const topScrollPosition = {
    top: 0,
    left: 0,
    behavior: 'smooth',
  };

  useEffect(() => {
    const hasSongPageScrollPosition = allSongsPageSongs()?.length > 0;
    const isSongsPage = pathname === '/songs';

    if (!isSongsPage || (!hasSongPageScrollPosition && isSongsPage)) {
      window.scrollTo(topScrollPosition);
    }
  }, [pathname]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  const isMobile = useMediaQuery(
    '(max-width: 428px), screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape)'
  );

  if (scrollPosition < 100) return null;

  return (
    <a
      href=""
      style={{
        position: 'fixed',
        right: isMobile ? 16 : 50,
        bottom: 100,
        zIndex: 10,
      }}
      onClick={(e) => {
        e.preventDefault();
        window.scrollTo(topScrollPosition);
      }}
    >
      <div
        style={{
          background: '#fff',
          padding: isMobile ? '5px 9px' : '15px 20px',
          borderRadius: '50%',
          boxShadow: '-2px 0px 64px 5px rgba(0,0,0,0.75)',
        }}
      >
        <i
          className={`fa-solid fa-arrow-up ${
            isMobile ? 'text__fs-15' : 'text__fs-34'
          } text-dark`}
        />
      </div>
    </a>
  );
};

export default ScrollToTop;
