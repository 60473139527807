/* eslint-disable operator-linebreak */
import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import Header from '../../components/SongsFavourite/Header';
import useGetFavouriteSongs from '../../hooks/Favourite/useGetFavouriteSongs';
import { userFavouriteSongs } from '../../utils/reactiveVars';
import ReorderableSongsList from '../../components/Shared/Song/ReorderableSongsList';
import { displayLoadingMessage } from '../../utils/helpers';
import useUpdateFavouriteSongsOrdering from '../../hooks/Favourite/useUpdateFavouriteSongsOrdering';

const SongsFavourite = () => {
  const { data, loading } = useGetFavouriteSongs();
  const { updateFavouriteSongsOrderingRequest } =
    useUpdateFavouriteSongsOrdering();
  const favSongs = useReactiveVar(userFavouriteSongs);
  loading && displayLoadingMessage();

  const [songs, setSongs] = useState([]);

  useEffect(() => {
    if (data?.userFavouriteSongs) {
      setSongs(favSongs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favSongs, data]);

  return (
    <>
      <Header />
      <section className="main__container">
        <div className="songslist__container">
          {data?.userFavouriteSongs && (
            <ReorderableSongsList
              songs={songs}
              loading={loading}
              orderingFunction={updateFavouriteSongsOrderingRequest}
              isUserResource
            />
          )}
        </div>
      </section>
    </>
  );
};

export default withPageWrapper({ pageTitle: 'Favourite Songs | Sparkyard' })(
  SongsFavourite
);
