/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable function-paren-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { CREATE_COLLECTION } from '../../graphql/mutations/collection';
import { GET_USER_COLLECTIONS } from '../../graphql/queries/collection/collection';
import hideModal from '../../styles/js/modals';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { collectionDefaultObj } from '../../utils/objects';
import { organisationLoggedInAs } from '../../utils/reactiveVars';
import useAddToCollection from './useAddToCollection';

const useCreateCollection = (
  reset,
  closeDropdown,
  itemType = null,
  itemId = null
) => {
  const { addToCollectionRequest } = useAddToCollection(itemType, itemId);

  const [createCollection, { loading, data }] = useMutation(CREATE_COLLECTION, {
    refetchQueries: [
      {
        query: GET_USER_COLLECTIONS,
      },
    ],
  });

  const createCollectionRequest = {
    send: (title, description, organisationRef) => {
      createCollection({
        variables: {
          input: {
            title,
            description,
            ...(organisationRef && {
              share_with_organisation: organisationRef,
            }),
            organisation_ref: organisationLoggedInAs()?.id || null,
          },
        },
      })
        .then((res) => {
          reset(collectionDefaultObj);
          displaySuccessMessage('Collection created!');
          dismissLoadingMessage();
          closeDropdown && closeDropdown();
          if (itemType && itemId) {
            addToCollectionRequest.send(
              [parseInt(res?.data?.createCollection?.id, 10)],
              []
            );
          }
          hideModal();
        })
        .catch((e) => {
          {
            displayErrorMessage('Could not create collection');
            dismissLoadingMessage();
          }
        });
    },
  };

  return { createCollectionRequest, loading, data };
};

export default useCreateCollection;
