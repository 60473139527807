import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import SectionHeader from '../SectionHeader';
import Checkbox from '../Checkbox';
import { user } from '../../../../../utils/reactiveVars';

const SingWithYourChildren = ({ handleShowSection, handleDataChange }) => {
  const availableCategories = [
    'School Choir',
    'Music lessons',
    'Other curriculum subjects',
    'Assembly',
    'School routines',
    'School productions/performances',
    'N/A',
  ];

  const [selectedCategories, setSelectedCategories] = useState([]);

  const { categories } = useReactiveVar(user);

  useEffect(() => {
    setSelectedCategories(categories);
  }, [categories]);

  const isChecked = (category) => !!selectedCategories.find((item) => item === category);
  const handleChange = (category, checked) => {
    const newCategories = checked
      ? [...selectedCategories, category]
      : selectedCategories.filter(
          (existingCategory) => existingCategory !== category
        );

    setSelectedCategories(newCategories);
    handleDataChange({ categories: newCategories });
  };

  return (
    <>
      <SectionHeader
        text="When do you currently sing with your children?"
        tickAll
      />
      <div className="row text__fs-13" style={{ wordBreak: 'break-word' }}>
        {availableCategories?.map((role) => (
          <Checkbox
            key={role}
            role={role}
            checked={isChecked(role)}
            handleChange={handleChange}
            onCheck={() => handleShowSection('allDoneButton')}
          />
        ))}
      </div>
    </>
  );
};

export default SingWithYourChildren;
