import React from 'react';
import Loading from '../../Shared/Loading';
import Collection from '../CardCollection';

const SharedCollections = ({ collections, isLoading }) => (
  <>
    <div className="text__fs-12 mb-4">
      You are following Collections in this area. They may be edited
      or deleted by the person who created them. To keep a copy of your own,
      click on the three dots in the desired collection and select "copy
      collection".
    </div>
    {isLoading && <Loading />}
    {collections?.map(({ id, title, songs }) => (
      <Collection key={id} id={id} title={title} songs={songs} />
    ))}
  </>
);

export default SharedCollections;
