import $ from 'jquery';

const hideModal = (modalId) => {
  $('#createCollectionModal').modal('hide');
  $('#createPlaylistModal').modal('hide');
  $('#removeModal').modal('hide');
  $('#deleteModal').modal('hide');
  $('#editCollectionModal').modal('hide');
  $('#editPlaylistModal').modal('hide');
  $('#editAccountModal').modal('hide');
  $('#leaveOrganisationModal').modal('hide');
  $('#onboardingQuestionsModal').modal('hide');
  if (modalId) {
    $(`#${modalId}`).modal('hide');
  }
};

export const showModal = (modalId, disableClose = false) => {
  const closeOptions = disableClose
    ? {
        keyboard: false,
        backdrop: 'static',
      }
    : {};

  setTimeout(() => {
    $(`#${modalId}`).modal({
      show: true,
      ...closeOptions,
    });
  }, 500);
};

export default hideModal;
