import { useMutation } from '@apollo/client';
import { EDIT_PLAYLIST } from '../../graphql/mutations/playlist';
import {
  GET_PLAYLIST,
  GET_PLAYLISTS,
} from '../../graphql/queries/playlist/playlist';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';

const useSharePlaylist = (id, organisationRef) => {
  const [sharePlaylist, { loading }] = useMutation(EDIT_PLAYLIST, {
    refetchQueries: [
      {
        query: GET_PLAYLIST,
        variables: {
          id,
          organisation_ref: organisationRef,
        },
      },
      {
        query: GET_PLAYLISTS,
        variables: {
          createdBy: 'user',
          organisation_ref: organisationRef,
        },
      },
    ],
    notifyOnNetworkStatusChange: true,
  });

  const sharePlaylistRequest = (deleteId = null) => {
    const inputPayload = deleteId
      ? { delete: [deleteId] }
      : { upsert: [{ organisation_ref: organisationRef }] };

    sharePlaylist({
      variables: {
        id,
        input: {
          shares: {
            ...inputPayload,
          },
        },
      },
    })
      .then(() => {
        displaySuccessMessage();
        dismissLoadingMessage();
      })
      .catch(() => {
        displayErrorMessage();
        dismissLoadingMessage();
      });
  };

  loading && displayLoadingMessage();

  return { sharePlaylistRequest, loading };
};

export default useSharePlaylist;
