import React from 'react';
import { getResourceTags } from '../../../../utils/resource';
import Content from './Content';
import Tabs from './Tabs';

const AssemblyPlans = ({ assembly, selectedPlan, handleSelectedPlan }) => {
  if (!assembly.assemblyPlans || assembly.assemblyPlans?.length < 1) {
    return null;
  }

  const tags = assembly.assemblyPlans[selectedPlan].assemblyPlanTags;
  const preparationTime = getResourceTags(tags, 'Preparation time');

  return (
    <>
      <Tabs
        selectedPlan={assembly.assemblyPlans[selectedPlan]}
        handleSelectedPlan={handleSelectedPlan}
        assemblyPlans={assembly.assemblyPlans}
        preparationTime={preparationTime}
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].engage}
        title="Engage"
        type="engage"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].engagementActivity}
        title="Engagement activity"
        type="engagementActivity"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].wonder}
        title="Wonder"
        type="wonder"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].playAnimation}
        title="Play animation"
        type="playAnimation"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].readStory}
        title="Read story"
        type="readStory"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].talkAbout}
        title="Talk about"
        type="talkAbout"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].talkOrThinkAbout}
        title="Talk/think about"
        type="talkOrThinkAbout"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].bibleBit}
        title="Bible bit"
        type="bibleBit"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].bibleBitOrReflect}
        title="Bible bit/reflect"
        type="bibleBitorReflect"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].bibleBitOrRespond}
        title="Bible bit/respond"
        type="bibleBitOrRespond"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].respond}
        title="respond"
        type="respond"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].responsiveActivity}
        title="Responsive activity"
        type="responsiveActivity"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].reflect}
        title="reflect"
        type="reflect"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].prayer}
        title="prayer"
        type="prayer"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].prayerActivity}
        title="prayer activity"
        type="prayerActivity"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].resource}
        title="Resource"
        type="resource"
      />
      <Content
        text={assembly.assemblyPlans[selectedPlan].goDeeper}
        title="go deeper"
        type="goDeeper"
      />
      <Content
        title="sing"
        type="sing"
        songs={assembly.assemblyPlans[selectedPlan].songs}
      />
    </>
  );
};

export default AssemblyPlans;
