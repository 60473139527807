/* eslint-disable arrow-body-style */
import React from 'react';
import { organisationDetails } from '../../../../../utils/reactiveVars';
import CardsSection from './CardsSection/CardsSection';
import EditOrganisationButton from './EditOrganisationButton';
import LeaveOrganisationButton from './LeaveOrganisationButton';
import OrganisationDetails from './OrganisationDetails';

const Organisation = ({ organisation, isAdmin, sharedLinksCount }) => {
  return (
    <>
      <div className="row pt-5 manage-account__organisation">
        <OrganisationDetails organisation={organisation} />
        <div className="col-sm-12 col-md-auto">
          {isAdmin && (
            <EditOrganisationButton
              disabled={!isAdmin}
              onClick={() => organisationDetails(organisation)}
            />
          )}
          <LeaveOrganisationButton
            onClick={() => organisationDetails(organisation)}
          />
        </div>
      </div>

      <CardsSection
        organisation={organisation}
        sharedLinksCount={sharedLinksCount}
        isAdmin={isAdmin}
      />
    </>
  );
};

export default Organisation;
