/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-linebreak */
import React, { useCallback } from 'react';

const TagsModule = ({ title, tags }) => {
  const types = {
    Compose: 'bg__fuchsia',
    Notation: 'bg__conifer',
    Improvise: 'bg__orchid',
    Play: 'bg__cerise-dark',
    Sing: 'bg__pumpkin',
    Listen: 'bg__mantis',
    Rhythm: 'bg__fuchsia',
    Tempo: 'bg__conifer',
    Texture: 'bg__orchid',
    Dynamics: 'bg__cerise-dark',
    Timbre: 'bg__pumpkin',
    Articulation: 'bg__mantis',
    Arts: 'bg__fuchsia',
    Music: 'bg__conifer',
    Pitch: 'bg__orchid',
    Pulse: 'bg__cerise-dark',
    Structure: 'bg__pumpkin',
  };

  if (tags.length < 1) return null;

  const getRandomColor = useCallback(() => {
    const keys = Object.keys(types);
    const randomKey = keys[Math.floor(Math.random() * keys.length)];
    return types[randomKey];
  }, [tags, title]);

  return (
    <>
      <p className="summary__text-sm text__black mb-2">
        <b>{title}</b>
      </p>
      <div className="d-flex flex-wrap mb-4">
        {tags?.map(({ formattedName }) => (
          <div
            className={`tag-sm mr-2 mb-2 ${
              types[formattedName] || getRandomColor() || 'bg__pumpkin'
            }`}
            key={formattedName}
          >
            {formattedName}
          </div>
        ))}
      </div>
    </>
  );
};

export default TagsModule;
