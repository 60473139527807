import React from 'react';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';

const NotFound = () => (
  <h1 className="mx-auto mt-5" style={{ width: '300px' }}>
    Not found!
  </h1>
);

export default withPageWrapper({})(NotFound);
