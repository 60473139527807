import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_EVENTS } from '../graphql/queries/calendar/events';

const useGetEvents = () => {
  const { data, loading, error } = useQuery(GET_EVENTS, {
    onCompleted: () => toast.dismiss('loading'),
    onError: () => {
      toast.dismiss('loading');
      toast.error('Could not load events', { toastId: 'eventsErr' });
    },
  });

  return { data, loading, error };
};

export default useGetEvents;
