/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Link } from 'react-router-dom';
import { playlistList } from '../../utils/reactiveVars';
import ContentHereSquare from './ContentHereSquare';
import Playlist from './Playlist';

const PlaylistCards = ({
  playlists,
  title,
  collection,
  type,
  disableDeletePlaylist,
  orderingFunction,
}) => {
  if (!playlists || playlists?.length < 1) return null;

  return (
    <>
      <div className="card-section-header mb__lg-21 mb-2 px-3 px-xl-0">
        <p className="heading__section text-uppercase">{title}</p>
        <Link
          to="/see-all/playlists"
          className="text__white text__fs-10"
          onClick={() =>
            playlistList({
              playlists,
              type,
              disableDeletePlaylist,
              orderingFunction,
            })
          }
        >
          SEE ALL
        </Link>
      </div>

      <div className="assembly__grid__playlist__container">
        {collection ? (
          <div className="assembly__grid__playlist">
            {playlists?.slice(0, 5).map(({ id, name, songs }) => (
              <Playlist title={name} key={id} id={id} songs={songs} />
            ))}
            <ContentHereSquare />
          </div>
        ) : (
          <div className="assembly__grid__playlist">
            {playlists
              ?.slice(0, 6)
              .map(({ id, name, songs, id: playlistId }) => (
                <Playlist title={name} key={id} songs={songs} id={playlistId} />
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default PlaylistCards;
