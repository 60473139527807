/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { MediaPlayerStore, MediaPlayerStoreType } from './MediaPlayerStore';

export const StoreContext = React.createContext<{
  mediaPlayerStore: MediaPlayerStoreType;
}>(
  { mediaPlayerStore: {} as MediaPlayerStoreType }
  // Context expects a default state - ({} as Type) workaround
  // uses the type information to satisfy Typescripts "object might be null" error
);

/**
 * A wrapper to the Context.Provider to create the Mobx Local Store
 */
type Props = { children: React.ReactChild };
export const StoreProvider = ({ children }: Props) => (
  <StoreContext.Provider value={{ mediaPlayerStore: MediaPlayerStore() }}>
    {children}
  </StoreContext.Provider>
);
