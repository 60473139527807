/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useForm } from 'react-hook-form';
import useGetTag from '../../../../hooks/useGetTag';
import useRefetchTags from '../../../../hooks/useRefetchTags';
import { buildFilterParams } from '../../../../utils/helpers';
import AgeRangeSelect from '../../../Shared/CategoryPages/AgeRangeSelect';
import SelectSection from '../../../Shared/CategoryPages/SelectSection';
import SubmitSection from '../../../Shared/CategoryPages/SubmitSection';

const FilterSection = ({ handleFilter, loading }) => {
  const withContent = {
    tags: ['Concerts/Performance'],
    min_age: 5,
    max_age: 11,
  };

  const {
    data: vocalRangeDescription,
    fetchMore: fetchMoreVocalRangeDescriptions,
    loading: loadingVocalRangeDescription,
  } = useGetTag('Vocal Range Description');

  const {
    data: songType,
    fetchMore: fetchMoreSongTypes,
    loading: loadingSongTypes,
  } = useGetTag('Song Type', { withContent });

  const {
    data: musicalStyle,
    fetchMore: fetchMoreMusicalStyles,
    loading: loadingMusicalStyles,
  } = useGetTag('Musical Style', { withContent });

  const {
    data: season,
    fetchMore: fetchMoreSeasons,
    loading: loadingSeasons,
  } = useGetTag('Seasonal', {
    withContent,
  });

  const {
    data: tempoDescription,
    fetchMore: fetchMoreTempoDescriptions,
    loading: loadingTempoDescriptions,
  } = useGetTag('Tempo Description', { withContent });

  const {
    data: topicOrTheme,
    fetchMore: fetchMoreTopicOrThemes,
    loading: loadingTopicsOrThemes,
  } = useGetTag('Topic/Theme', { withContent });

  const isLoading =
    loadingMusicalStyles ||
    loadingSeasons ||
    loadingSongTypes ||
    loadingTempoDescriptions ||
    loadingTopicsOrThemes ||
    loadingVocalRangeDescription ||
    loading;

  const allTagTypesRefetchMapDefault = {
    vocal_range_description: fetchMoreVocalRangeDescriptions,
    topic_or_theme: fetchMoreTopicOrThemes,
    seasonal: fetchMoreSeasons,
    song_type: fetchMoreSongTypes,
    musical_style: fetchMoreMusicalStyles,
    tempo_description: fetchMoreTempoDescriptions,
  };

  const { register, handleSubmit, reset } = useForm();

  const handleFilterParams = (data) => handleFilter(buildFilterParams(data));

  const [handleClearFilters, handleTagChange, handleAgeRangeChange] =
    useRefetchTags(allTagTypesRefetchMapDefault, 'Concerts/Performance');

  const songTypeObjs = songType?.tags
    ?.slice()
    .sort((a, b) =>
      a.formattedName.toLowerCase().localeCompare(b.formattedName.toLowerCase())
    );

  const musicalStyleObjs = musicalStyle?.tags
    ?.slice()
    .sort((a, b) =>
      a.formattedName.toLowerCase().localeCompare(b.formattedName.toLowerCase())
    );

  const topicOrThemeObjs = topicOrTheme?.tags
    ?.slice()
    .sort((a, b) =>
      a.formattedName.toLowerCase().localeCompare(b.formattedName.toLowerCase())
    );

  return (
    <div className="main__container bg__blue-nile mt-0">
      <div className="assembly__songfinder">
        <h2 className="assembly__songfinder__title">Find songs</h2>
        <div className="assembly__filter assembly__filter__choir">
          <div className="assembly__songfinder__range">
            <label htmlFor="">Age range</label>
            <div className="assembly__songfinder__range__selectors">
              <AgeRangeSelect
                ref={register}
                name="min_age"
                handleAgeRangeChange={handleAgeRangeChange}
              />
              <p>to</p>
              <AgeRangeSelect
                ref={register}
                name="max_age"
                handleAgeRangeChange={handleAgeRangeChange}
              />
            </div>
          </div>

          <SelectSection
            label="Vocal Range"
            ref={register}
            tags={vocalRangeDescription?.tags}
            handleTagChange={handleTagChange}
            name="vocal_range_description"
          />

          <SelectSection
            label="Song Type"
            ref={register}
            tags={songTypeObjs}
            handleTagChange={handleTagChange}
            name="song_type"
          />

          <SelectSection
            label="Musical Style"
            ref={register}
            tags={musicalStyleObjs}
            handleTagChange={handleTagChange}
            name="musical_style"
          />

          <SelectSection
            label="Tempo"
            ref={register}
            tags={tempoDescription?.tags}
            handleTagChange={handleTagChange}
            name="tempo_description"
          />

          <SelectSection
            label="Topic / Theme"
            ref={register}
            tags={topicOrThemeObjs}
            handleTagChange={handleTagChange}
            name="topic_or_theme"
          />

          <SubmitSection
            label="Season"
            ref={register}
            tags={season?.tags}
            name="seasonal"
            onClick={handleSubmit(handleFilterParams)}
            disabled={isLoading}
            reset={reset}
            handleTagChange={handleTagChange}
            handleClearFilters={() => {
              handleClearFilters();
              handleSubmit(handleFilter({}));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
