import { useEffect, useState } from 'react';
import FontFaceObserver from 'fontfaceobserver';

/**
 * Returns a new key when the specified font has successfully loaded
 * @param id number | string
 * @param fontFamily string
 * @returns {[string, boolean]}
 */
const useFontLoaded = (
  id: number | string,
  fontFamily: string
): [boolean, boolean] => {
  const [fontLoaded, setFontLoaded] = useState(false);
  // NOTE changing this key will force canvas to redraw (used when font loads)
  const [error, setError] = useState(false);

  useEffect(() => {
    const font = new FontFaceObserver(fontFamily); // NOTE this will come from the page.json
    const centuryGothic = new FontFaceObserver('CenturyGothic');
    const blueMixChar = new FontFaceObserver('BlueMixChar');
    centuryGothic.load();
    blueMixChar.load();

    font.load().then(
      () => {
        setFontLoaded(true); // use this as the key to the element to
      },
      () => {
        setError(true);
      }
    );
    const timer = setTimeout(() => {
      setError(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, [id, fontFamily]);

  return [fontLoaded, error];
};

export default useFontLoaded;
