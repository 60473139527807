import React from 'react';
import Tag from '../Tag';

const Tags = ({ tags }) => (
  <div className="mt-2 mb-3">
    {tags?.map(({ type, formattedName, id }) => (
      <Tag type={type} formattedName={formattedName} key={id} />
    ))}
  </div>
);

export default Tags;
