/* eslint-disable react/no-danger */
import React from 'react';
import DownloadsAndAboutDropdown from '../../Resources/DownloadsAndAboutDropdown';
import LikeAndAddIcons from '../../Shared/LikeAddIcons';
import Dropdown from '../Dropdown/Index';

const Header = ({
  title,
  description,
  isUserCollection,
  id,
  resource,
  hasSharedWithOrganisation,
  organisationRef,
  shareId,
  wasSharedWithUser,
}) => {
  const downloadablesAttached = JSON.parse(resource.downloadables)?.length > 0;
  const stat = {
    collection_id: id,
    event_type: 'OpenedCollectionPDF',
  };

  return (
    <>
      <div className="main__container bg__arsenic mt-0">
        <div className="mt-0">
          <div className="mycollections__header">
            <div className="mycollections__header__icon">
              <img src="/img/collections_white.svg" alt="" />
            </div>
            <h3 className="mycollections__header__title">Collections</h3>{' '}
            {downloadablesAttached && (
              <div className="" style={{ height: '70px' }}>
                <DownloadsAndAboutDropdown
                  resource={resource}
                  buttonText="Downloads"
                  stat={stat}
                  eventPage
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isUserCollection && (
        <div className="main__container mt-0 bg__moderate-cyan py-3">
          <div className="px-md-4 px-xl-0">
            <div className="text__fs-14 song-calendar__header__description font-weight-normal">
              Search or browse to add songs and resources to this collection
            </div>
          </div>
        </div>
      )}
      <div className="main__container mt-4 py-3 d-flex justify-content-between">
        <div className="px-md-4 px-xl-0 w-100">
          <div className="song-calendar__header__description font-weight-normal">
            <h2 className="text__fs-24 font-weight-bold mb-20">{title}</h2>
            <p
              className="text__fs-14"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
        {isUserCollection && (
          <div className="pr-4 pr-xl-0">
            <Dropdown
              title={title}
              description={description}
              id={id}
              hasSharedWithOrganisation={hasSharedWithOrganisation}
              organisationRef={organisationRef}
              shareId={shareId}
            />
          </div>
        )}
        {wasSharedWithUser && (
          <LikeAndAddIcons
            id={id}
            headerText="Collection Options"
            modalId="CollectionActions"
            copyCollection
            hidePC="collection"
          />
        )}
      </div>
    </>
  );
};
export default Header;
