import { useLazyQuery } from '@apollo/client';
import { GET_ORGANISATION_PLAYS } from '../../graphql/queries/organisation/organisation';
import { dismissLoadingMessage } from '../../utils/helpers';

const useGetOrganisationPlays = () => {
  const [query, { data, loading, error }] = useLazyQuery(
    GET_ORGANISATION_PLAYS,
    {
      onError: () => {
        dismissLoadingMessage();
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const getOrganisationPlays = (input) => {
    query({
      variables: {
        input,
      },
    });
  };

  return { getOrganisationPlays, data, loading, error };
};

export default useGetOrganisationPlays;
