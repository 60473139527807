/* eslint-disable operator-linebreak */
import React from 'react';
import { defaultOrganisationLogo } from '../../../../../utils/objects';

const OrganisationDetails = ({ organisation }) => {
  const { detailCategory, detailName, displayName, detailNation, avatar } =
    organisation;

  return (
    <div className="col d-flex align-items-center">
      <img
        src={avatar || defaultOrganisationLogo}
        alt="user"
        className="d-inline-block"
        width={64}
      />
      <div className="manage-account__segment__name">
        <span className="manage-account__segment__name__primary">
          {displayName || detailName}
        </span>
        <span className="manage-account__segment__name__secondary">
          {detailCategory}
        </span>
        <span className="manage-account__segment__name__secondary">
          {detailNation}
        </span>
      </div>
    </div>
  );
};

export default OrganisationDetails;
