/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { getYear, format } from 'date-fns';
import { toast } from 'react-toastify';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import Header from '../../components/SongCalendar/CalendarPage/Sections/Header';
import MainSection from '../../components/SongCalendar/CalendarPage/Sections/MainSection';
import useGetEvents from '../../hooks/useGetEvents';

const SongCalendar = () => {
  const { loading, data } = useGetEvents();

  loading && toast.info('Loading...', { toastId: 'loading' });

  const eventsObj = {};
  if (data) {
    const eventsCopy = [...data.events];
    const sortedEvents = eventsCopy.sort(
      (a, b) =>
        new Date(a.startDate.replace(/\s/, 'T')) -
        new Date(b.startDate.replace(/\s/, 'T'))
    );

    const getMonthAndYear = (event) => {
      const datestring = event.startDate.split(' ')[0];
      const date = new Date(datestring);
      return getYear(date) + format(date, 'MMMM');
    };

    sortedEvents.map((event) => {
      if (!eventsObj[getMonthAndYear(event)]) {
        return (eventsObj[getMonthAndYear(event)] = sortedEvents.filter(
          (item) => getMonthAndYear(item) === getMonthAndYear(event)
        ));
      }
    });
  }

  return (
    <>
      <div className="main__container px-0 mt-0 pb-5">
        <Header />
        {data?.events && <MainSection events={eventsObj} />}
      </div>
    </>
  );
};

export default withPageWrapper({ pageTitle: 'Song Calendar | Sparkyard' })(
  SongCalendar
);
