import React from 'react';
import { sparkyardLoginUrl } from '../../utils/objects';
import { user } from '../../utils/reactiveVars';
import {
  clearLoginPreferences,
  removeUserFromLocalStorage,
} from '../../utils/user';

const Logout = () => {
  user();
  removeUserFromLocalStorage();
  clearLoginPreferences();
  window.location.href = sparkyardLoginUrl;

  return <h1>You have been logged out</h1>;
};

export default Logout;
