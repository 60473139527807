import React from 'react';
import RelatedGuidesCard from './RelatedGuidesCard';
import RelatedGuidesCardLarge from './RelatedGuidesCardLarge';

const Body = ({ resources }) => (
  <>
    {resources.length === 1 ? (
      <RelatedGuidesCardLarge
        title={resources[0].title}
        content={resources[0].thumbnailText}
        thumbnail={resources[0].thumbnail}
        slug={resources[0].slug}
      />
    ) : (
      <div className="px-xl-0 px-3 mb-30">
        <div className="grid__two-items mb-20">
          {resources.slice(0, 2).map((resource) => (
            <RelatedGuidesCard
              key={resource.id}
              title={resource.title}
              content={resource.thumbnailText}
              thumbnail={resource.thumbnail}
              slug={resource.slug}
            />
          ))}
        </div>
      </div>
    )}
  </>
);

export default Body;
