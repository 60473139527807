import React from 'react';
import { formatTags } from '../../utils/helpers';

const TagRow = ({ title, tags }) => {
  if (tags.length < 1) return null;
  return (
    <>
      <div>
        <p className="text__fs-12 text__fw-600 mb-0">{title}</p>
      </div>
      <div>
        <span className="text__fs-11">{formatTags(tags)}</span>
      </div>
    </>
  );
};

export default TagRow;
