import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import SimpleCard from '../../Shared/SimpleCard';
import classNames from 'classnames';
import LeftIcon from './LeftIcon';
import RightIcon from './RightIcon';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

const SwiperButton = ({ onPrev, onNext, hidePrev, hideNext }) => {
  return (
    <>
      {!hidePrev && (
        <div className={classNames('home-tips__btn home-tips__btn-prev')}>
          <div className="home-tips__btn-shadow"></div>
          <button onClick={onPrev}>
            <LeftIcon />
          </button>
        </div>
      )}

      {!hideNext && (
        <div className={classNames('home-tips__btn home-tips__btn-next')}>
          <div className="home-tips__btn-shadow"></div>
          <button onClick={onNext}>
            <RightIcon />
          </button>
        </div>
      )}
    </>
  );
};

const Tips = ({ datas }) => {
  const [reachBeginning, setReachBeginning] = useState(true);
  const [reachEnd, setReachEnd] = useState(false);
  const [swiperTips, setSwiperTips] = useState(null);

  return (
    <div className="px-xl-0 px-3 pb-20">
      <h3 className="mb-20 text-uppercase">Tips, Tricks and Ideas</h3>
      <div className="home-tips">
        <Swiper
          spaceBetween={20}
          slidesPerView={1.4}
          onInit={(swiper) => {
            setSwiperTips(swiper);
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            926: {
              slidesPerView: 4,
            },
          }}
          allowTouchMove={true}
          onReachBeginning={() => {
            setReachBeginning(true);
          }}
          onReachEnd={() => {
            setReachEnd(true);
          }}
          onSlidePrevTransitionStart={() => {
            setReachEnd(false);
          }}
          onSlideNextTransitionStart={() => {
            setReachBeginning(false);
          }}
          modules={[Navigation]}
        >
          {datas.map((tip, index) => (
            <SwiperSlide key={index}>
              <div className="home-tips__item">
                <SimpleCard
                  image={tip.image}
                  title={tip.title}
                  description={tip.description}
                  link={tip.link}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <SwiperButton
          onPrev={() => swiperTips.slidePrev()}
          onNext={() => swiperTips.slideNext()}
          hidePrev={reachBeginning}
          hideNext={reachEnd}
        />
      </div>
    </div>
  );
};

export default withRouter(Tips);
