/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useEffect, useMemo } from 'react';
import useGetRecommendedResources from '../../hooks/Resource/useGetRecommendedResources';
import { displayLoadingMessage } from '../../utils/helpers';
import { filterTypeMap, updateQuery } from '../../utils/objects';
import Header from '../RecommendedResources/Sections/Header';
import ResourcesListInfiniteScroll from './Resources/ResourcesListInfiniteScroll';

const ResourceList = ({ isRecommendedResourcesComponent }) => {
  const { data, loading, fetchMore } = useGetRecommendedResources(20, 1);

  loading && displayLoadingMessage('Loading recommended resources...');

  const originalResourceList = data?.recommendedResources?.data;
  const [recommendedResources, setRecommendedResources] = useState(
    originalResourceList || []
  );
  const [filterParams, setFilterParams] = useState(null);

  useEffect(() => {
    if (data) {
      setRecommendedResources((prevState) => [
        ...prevState,
        ...data?.recommendedResources?.data,
      ]);
    }
  }, [data]);

  const resourcesToDisplay = useMemo(() => {
    if (!filterParams) return recommendedResources;
    const { resource_type: resourceType } = filterParams;
    const resourceResultListCopy = [...recommendedResources];

    const filteredResources = resourceResultListCopy.filter(
      ({ category: { name } }) => {
        if (filterTypeMap[resourceType]) {
          return filterTypeMap[resourceType](name);
        }

        return name === resourceType;
      }
    );
    return filteredResources;
  }, [recommendedResources, filterParams]);

  const contentTypeTagsWithTotal = useMemo(() => {
    const contentTypeTags = [
      { formattedName: 'Assembly', id: 'assembly' },
      { formattedName: 'Blog or Guide', id: 'blog-guide' },
      {
        formattedName: 'Curriculum links and more',
        id: 'curriculum-links-and-more',
      },
      { formattedName: 'Music Activity', id: 'music-activity' },
      { formattedName: 'SEND Resource', id: 'send-resource' },
    ];

    return contentTypeTags.map((element) => ({
      ...element,
      total: originalResourceList?.filter(({ category: { name } }) => {
        if (filterTypeMap[element.formattedName]) {
          return filterTypeMap[element.formattedName](name);
        }

        return name === element.formattedName;
      }).length,
    }));
  }, [originalResourceList]);

  const loadMoreResources = () => {
    fetchMore({
      variables: {
        first: 20,
        page: data?.recommendedResources?.paginatorInfo?.currentPage + 1,
        ...filterParams,
      },
      ...updateQuery,
    });
  };

  return (
    <>
      {isRecommendedResourcesComponent && (
        <Header
          contentTypeTags={contentTypeTagsWithTotal.filter(
            (elm) => elm.total > 0
          )}
          filter={setFilterParams}
          reset={() => setFilterParams(null)}
        />
      )}
      <section className="container__small mx-auto ml-lg-40">
        {recommendedResources && (
          <ResourcesListInfiniteScroll
            loadMore={loadMoreResources}
            hasMore={data?.recommendedResources?.paginatorInfo.hasMorePages}
            resources={resourcesToDisplay}
          />
        )}
      </section>
    </>
  );
};

export default ResourceList;
