import React, { useState } from 'react';

const SelectPlaylist = ({ playlists, onChange }) => {
  const [selected, setSelected] = useState(0);

  return (
    <div className="d-flex align-items-center">
      <select
        name={'name'}
        // ref={ref}
        className="filter__select-lg"
        style={{ minHeight: '48px', width: '100%' }}
        onChange={(e) => {
          setSelected(e.target.value);
        }}
      >
        <option value="all">All songs</option>
        {playlists?.map((playlist) => (
          <option value={playlist.id} key={playlist.id}>
            {playlist.name}
          </option>
        ))}
      </select>

      <button
        className="button button__xl button__turquoise ml-4 treatbox__modal_button_go"
        onClick={() => onChange(selected)}
      >
        GO
      </button>
    </div>
  );
};

export default SelectPlaylist;
