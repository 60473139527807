import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import NotificationItem from './NotificationItem';
import useGetUserNotifications from '../../../../../../hooks/Notification/useGetUserNotifications';
import Loading from '../../../../Loading';
import MarkAsRead from '../../../../../Notifications/MarkAsRead';

const Notifications = () => {
  const { data, loading, error } = useGetUserNotifications();

  const unreadNotificationsCount = useMemo(
    () => data?.getUserNotifications.filter((item) => !item.read).length || 0,
    [data]
  );

  return (
    <div className="mr-2 mr-md-0 main__nav__info__notifications dropdown ">
      <a
        href="#"
        className="dropdown-toggle"
        role="button"
        id="dropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img src="/img/notifications.svg" alt="" />
        {unreadNotificationsCount > 0 && (
          <div className="notifications__unread-indicator" />
        )}
      </a>
      <div
        className="dropdown-menu dropdown-menu-notifications dropdown-menu-right"
        aria-labelledby="dropdownMenuLink"
      >
        {loading && <Loading />}
        {error && <span>Could not load notifications</span>}
        {data && (
          <div className="notifications">
            <div className="notifications__item text__fs-12">
              <Link to="/notifications">
                SEE ALL ({unreadNotificationsCount} unread)
              </Link>
              <MarkAsRead />
            </div>
            {data.getUserNotifications.slice(0, 5).map((notification) => (
              <NotificationItem
                notification={notification}
                key={notification.id}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
