import React from 'react';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import ResourceList from '../../components/Shared/ResourceList';

const CurriculumResources = () => (
  <>
    <div className="resource__favorite__header">
      <div className="main__container">
        <div className="resource__favorite__header__grid">
          <div className="resource__favorite__header__title">
            <i className="fa fa-file-alt" />
            <h2>
              <span className="text__white">Curriculum Resources</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <ResourceList category="Music Curriculum Activity" />
  </>
);

export default withPageWrapper({})(CurriculumResources);
