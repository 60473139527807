import React from 'react';

const Header = () => (
    <>
        <div className="main__container d-flex align-items-center p-0 px-md-5 mt-4">
            <span className="treatbox__heading">Treat box</span>
        </div>
        <p className="treatbox__subheading">
            Time for a treat! Choose an option below to get started…
        </p>
    </>
);

export default Header;
