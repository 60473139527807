import tw, { styled } from 'twin.macro';
import 'styled-components/macro';

// eslint-disable-next-line @typescript-eslint/ban-types
type ControlsProps = {};
const Controls = styled('div')<ControlsProps>(() => [
  tw`h-full w-full absolute block top-0 left-0`,
]);

export default Controls;
