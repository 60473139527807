import React from 'react';
import { toast } from 'react-toastify';
import useGetCategoryPage from '../../../../../hooks/useGetCategoryPage';
import GuidesAndResources from '../../../../Shared/GuidesAndResources';
import PlaylistCards from '../../../../Shared/PlaylistCards';
import SongCards from '../../../../Shared/SongCards/Index';
import VideoCards from '../../../../Shared/Video/VideoCards';

const MainSection = ({
  songs,
  resources,
  playlists,
  displayResources = true,
  displaySongs = true,
  displayPlaylists = true,
  filterParams,
}) => {
  const { data, loading } = useGetCategoryPage('school routines and signals');
  loading && toast.info('Loading...', { toastId: 'loading' });

  return (
    <section className="main__container">
      <div className="px__sm-3">
        {displaySongs && (
          <SongCards
            title="School Routines and Signals Songs"
            songs={songs}
            pageTitle="School Routines and Signals playlists"
            name="Routines & Signals"
            type="OOTA Singing School"
            filterParams={filterParams}
          />
        )}
        {displayResources && (
          <GuidesAndResources
            resources={resources}
            title="Routines & Signals"
            type="OOTA Singing School"
          />
        )}
        {displayPlaylists && (
          <PlaylistCards
            playlists={playlists}
            title="School Routines and Signals playlists"
            disableDeletePlaylist
          />
        )}
        <VideoCards
          videos={JSON.parse(data?.categoryPage?.videos || '[]')}
          title="School routine and signals videos"
        />
      </div>
    </section>
  );
};

export default MainSection;
