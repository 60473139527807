/* eslint-disable operator-linebreak */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import useDeleteCollection from '../../../hooks/Collection/useDeleteCollection';
import useDeletePlaylist from '../../../hooks/Playlist/useDeletePlaylist';
import { deleteModalObj } from '../../../utils/reactiveVars';

const DeleteModal = () => {
  const { itemType, id } = useReactiveVar(deleteModalObj);
  const { deletePlaylistRequest } = useDeletePlaylist(id);
  const { deleteCollectionRequest } = useDeleteCollection(id);

  const fnMap = {
    PLAYLIST: deletePlaylistRequest,
    COLLECTION: deleteCollectionRequest,
  };

  return (
    <>
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="actions"
        aria-hidden="true"
      >
        <div
          className="modal-dialog  modal-dialog-centered custom__modal__filter-new"
          role="document"
        >
          <div className="modal-content">
            <div className="custom__modal__content">
              <div className="custom__modal__header mb-5">
                <h5 className="custom__modal__title font-weight-normal">
                  Delete {itemType?.toLowerCase()}
                </h5>
                <a
                  href=""
                  className="custom__modal__close"
                  data-dismiss="modal"
                >
                  <img src="/img/plus-icon.svg" alt="" />
                </a>
              </div>
              <div className="mb-20 text__black text__fs-16">
                Are you sure you want to delete this {itemType?.toLowerCase()}?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => fnMap[itemType].send()}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
