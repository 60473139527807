import React from 'react';
import SearchPlaylistArt from './SearchPlaylistArt';
import SearchCollectionArt from './SearchCollectionArt';

const SearchResultImage = ({ type, title, id, getDisplayImage }) => (
  <>
    {type === 'Playlist' && <SearchPlaylistArt id={id} />}
    {type === 'Collection' && <SearchCollectionArt id={id} />}
    {!['Playlist', 'Collection'].includes(type) && (
      <img
        src={getDisplayImage()}
        alt={title}
        className="resources-favourites__card__thumbnail__show"
      />
    )}
  </>
);
export default SearchResultImage;
