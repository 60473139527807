import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { EDIT_PLAYLIST } from '../../graphql/mutations/playlist';
import { GET_PLAYLIST } from '../../graphql/queries/playlist/playlist';
import hideModal from '../../styles/js/modals';
import {
  dismissLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { playlistDefaultObj, toastErrorObj } from '../../utils/objects';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useEditPlaylist = (id, reset) => {
  const [editPlaylist] = useMutation(EDIT_PLAYLIST, {
    refetchQueries: [
      {
        query: GET_PLAYLIST,
        variables: {
          id,
          organisation_ref: organisationLoggedInAs()?.id || null,
        },
      },
    ],
  });

  const editPlaylistRequest = (name) => {
    editPlaylist({
      variables: {
        id,
        input: {
          name,
        },
      },
    })
      .then(() => {
        reset(playlistDefaultObj);
        displaySuccessMessage('Playlist edited successfully!');
        dismissLoadingMessage();
        hideModal();
      })
      .catch(() => {
        toast.error('Could not edit playlist.', toastErrorObj);
        toast.dismiss('loading');
      });
  };
  return { editPlaylistRequest };
};

export default useEditPlaylist;
