/* eslint-disable react/no-danger */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import LikeAndAddIcons from '../../../components/Shared/LikeAddIcons';
import { mediaPlayerSelectedSongState } from '../../../utils/reactiveVars';

const SongActions = ({ isGuest }): JSX.Element => {
  const songState = useReactiveVar(mediaPlayerSelectedSongState);

  return (
    <div className="d-flex justify-content-between">
      <div>
        {songState.loading ? (
          <span className="text__fs-m-8">Loading song...</span>
        ) : (
          <>
            <span
              className="font-weight-bold d-block text__fs-13"
              dangerouslySetInnerHTML={{
                __html: songState.data.song.infoName,
              }}
            />
            <span className="text__fs-13">
              {JSON.parse(songState.data.song.audios[0].metadata).album}
            </span>
          </>
        )}
      </div>
      <div
        className={`d-inline-flex align-items-center ${
          isGuest ? 'disable-element' : ''
        }`}
      >
        <i className="far fa-heart text-white text__fs-20 mr-20" />
        <LikeAndAddIcons
          headerText="Song options"
          modalId="songHeaderActions"
          favourite
          addToPlaylist
          addToCollection
          share
          id={songState.data.song.id}
          itemType="SONG"
        />
      </div>
    </div>
  );
};

export default SongActions;
