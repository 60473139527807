import React from 'react';
import useSendVerificationMail from '../../../../hooks/User/useSendVerificationMail';

const CheckYourMailModal = () => {
  const { sendVerificationMailRequest } = useSendVerificationMail();

  return (
    <div
      className="modal fade"
      id="checkYourMailModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="checkYourMailModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="custom__modal__content bg__cello px-sm-32 px-74">
            <div className="custom__modal__header">
              <div className="w-100 pt-2 text-center">
                <h5 className="custom__modal__title text__white text__fw-700 text__fs-25  text__center">
                  CHECK YOUR EMAIL!
                </h5>
              </div>

              <a href="" className="custom__modal__close" data-dismiss="modal">
                <img src="/img/plus-icon.svg" alt="" />
              </a>
            </div>
            <img
              src="/img/email-verification-2.png"
              alt="remove"
              width="295"
              height="133"
              className="mx-auto mt-8"
            />
            <h6 className="text__fs-18 text__fw-200 text__white mt-20 text__center">
              We’ve sent an email to you. Open it up, click the link to validate
              your email address, then put your feet up and start exploring!
            </h6>
            <h6 className="text__fs-18 text__fw-200 text__white mb-12 mt-20 text__center">
              Nothing arrived?
            </h6>
            <button
              href=""
              className="button button__md button__green-meadow mx-auto mt-6"
              onClick={() => sendVerificationMailRequest.send()}
            >
              Re-send email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckYourMailModal;
