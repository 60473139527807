import React, { useEffect, useState } from 'react';
import Loading from '../../Shared/Loading';
import Collection from '../CardCollection';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useUpdateCollectionsOrdering from '../../../hooks/Playlist/useUpdateCollectionsOrdering';

const UserCollections = ({ collections, isLoading, showDragIcon }) => {
  const { updateCollectionsOrderingRequest } = useUpdateCollectionsOrdering();

  const [localCollections, setLocalCollections] = useState([]);

  useEffect(() => {
    setLocalCollections(collections);
  }, [collections]);

  const handleOnDragEnd = (result) => {
    const items = Array.from(localCollections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setLocalCollections(items);
    updateCollectionsOrderingRequest(
      items.map((item, index) => ({ id: item.id, ordering: index + 1 }))
    );
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable-my-collections">
            {(provided) => (
              <div ref={provided.innerRef}>
                {localCollections?.map((collection, index) => (
                  <Draggable
                    draggableId={collection.id}
                    index={index}
                    key={collection.id}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        snapshot={snapshot}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Collection
                          title={collection.title}
                          id={collection.id}
                          songs={collection.songs}
                          isShowSorting={showDragIcon}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default UserCollections;
