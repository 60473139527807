/* eslint-disable react/no-danger */
import React from 'react';
import { Link } from 'react-router-dom';

const Song = ({ title, id }) => (
  <div className="col-md-3 col-6">
    <div className="assembly__song__choice">
      <div className="assembly__song__choice__thumbnail mb-md-3">
        <img
          className="assembly__song__choice__img"
          src="/img/song-img-one.jpg"
          alt={title}
        />
        <a href="" className="assembly__song__choice__icon">
          <img src="/img/play-icon.svg" alt="" />
        </a>
      </div>
      <Link to={`/song/${id}`}>
        <p
          className="summary__text-xsm text__fw-600 text__black-night"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </Link>
    </div>
  </div>
);

export default Song;
