/* eslint-disable operator-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useState, useEffect } from 'react';
import { Page, PageDefaultState } from '../types';
/**
 * Outputs the current page based on the progress into a song
 *
 * @param pages the given data for a song
 * @param milliseconds current progress into the song
 *
 * @returns {Page} A page which contains the lyrics to display on-screen
 */
const useSongPage = (pages: Array<Page>, milliseconds: number): Page => {
  const [currentPage, setCurrentPage] = useState<Page>(PageDefaultState);

  const newPage = useCallback(
    (pages) =>
      pages.find((page: Page, idx: number) => {
        if (pages.length === 1) return true;
        const pageEnd =
          idx < pages.length - 1
            ? pages[idx + 1].millisecondsStart
            : page.millisecondsStop;
        return milliseconds >= page.millisecondsStart && milliseconds < pageEnd;
      }) || PageDefaultState,
    [milliseconds]
  );

  useEffect(() => {
    setCurrentPage(newPage(pages));
  }, [pages, newPage]);

  return currentPage;
};

export default useSongPage;
