import React, { useMemo, useState } from 'react';

import { currentResource } from '../../utils/reactiveVars';
import { getResourceTags } from '../../utils/resource';
import Downloadable from '../Shared/Downloadable';
import TagRow from '../Shared/TagRow';

const DownloadsAndAboutDropdown = ({
  resource,
  buttonText,
  eventPage,
  stat,
}) => {
  const [isActive, setIsActive] = useState(false);
  const { resourceTags, downloadables } = resource || currentResource();

  const formattedDownloadables = JSON.parse(downloadables);

  const sparkyardCategory = getResourceTags(
    resourceTags,
    'OOTA Singing School'
  );

  const subtitle = getResourceTags(resourceTags, 'Curriculum Subject Links');
  const seasonal = getResourceTags(resourceTags, 'Seasonal');
  const topicTheme = getResourceTags(resourceTags, 'Topic/Theme');
  const values = getResourceTags(resourceTags, 'Values (PSHE, SMSC)');
  const curriculumConcept = getResourceTags(resourceTags, 'Curriculum Concept');
  const interrelatedDimensions = getResourceTags(
    resourceTags,
    'Music Checklist'
  );
  const ageRange = getResourceTags(resourceTags, 'Age Range');
  const musicActivitySizeAndScale = getResourceTags(
    resourceTags,
    'Music activity Size and Scale'
  );
  const musicChecklist = getResourceTags(
    resourceTags,
    'National Music Curriculum descriptor'
  );

  return (
    <div
      className={`resource__item__about ${
        eventPage ? 'eventpage__header__dropdown' : ''
      } ${isActive && 'active'}`}
    >
      <a
        href="#"
        className="resource__item__about__cta text__no-underline-hover"
        onClick={() => setIsActive(!isActive)}
        style={{ width: buttonText === 'Downloads' ? 'unset' : '215px' }}
      >
        <p>{buttonText || `Downloads & About`}</p>
        <i className="fa fa-chevron-down" />
      </a>
      <div className="resource__item__about__dropdown">
        <div className="resource__item__about__dropdown__grid">
          {formattedDownloadables?.map((downloadable) => (
            <Downloadable
              key={downloadable.key}
              text={downloadable.attributes.text}
              link={downloadable.attributes.pdf}
              pdfId={downloadable.attributes.pdfId}
              stat={stat}
            />
          ))}
          <TagRow title="Sparkyard Category" tags={sparkyardCategory} />
          <TagRow title="Subject" tags={subtitle} />
          <TagRow title="Topic / Theme" tags={topicTheme} />
          <TagRow title="Seasonal" tags={seasonal} />
          <TagRow title="Values" tags={values} />
          <TagRow
            title="Interrelated Dimensions"
            tags={interrelatedDimensions}
          />
          <TagRow title="Curriculum Concept" tags={curriculumConcept} />
          <TagRow title="N.C. coverage for Music" tags={musicChecklist} />
          <TagRow title="Age Range" tags={ageRange} />
          <TagRow
            title="Music Activity Size and Scale"
            tags={musicActivitySizeAndScale}
          />
        </div>
      </div>
    </div>
  );
};

const withWrapper = (WrappedComponent) => {
  const HOC = (props) => {
    const { resourceTags, downloadables } = props.resource || currentResource();

    const formattedDownloadables = JSON.parse(downloadables);

    const shouldShowButton = useMemo(() => {
      return resourceTags.length >= 1 || formattedDownloadables.length >= 1;
    }, [resourceTags, formattedDownloadables]);

    return <>{shouldShowButton ? <WrappedComponent {...props} /> : null}</>;
  };

  return HOC;
};

export default withWrapper(DownloadsAndAboutDropdown);
