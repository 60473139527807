/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { UPSERT_ORGANISATION_NAME } from '../../graphql/mutations/organisation';
import { GET_ORGANISATION } from '../../graphql/queries/organisation/organisation';
import {
  displayErrorMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { organisationLoggedInAs } from '../../utils/reactiveVars';
import useGetOrganisation from './useGetOrganisation';

const useUpdateOrganisationName = (organisationRef) => {
  const { getOrganisation, data: organisationData } = useGetOrganisation();

  const [updateOrganisationName, { loading, data, error }] = useMutation(
    UPSERT_ORGANISATION_NAME,
    {
      refetchQueries: [
        {
          query: GET_ORGANISATION,
          variables: {
            id: organisationRef,
          },
        },
      ],
    }
  );

  useEffect(() => {
    if (data) {
      getOrganisation(organisationRef);
    }
  }, [data]);

  useEffect(() => {
    if (organisationData?.organisation) {
      organisationLoggedInAs(organisationData?.organisation);
    }
  }, [organisationData]);

  const updateOrganisationNameRequest = (syId, input) => {
    updateOrganisationName({
      variables: { syId, input },
    })
      .then(() => {
        displaySuccessMessage('Name updated!');
      })
      .catch(() => displayErrorMessage());
  };

  return { updateOrganisationNameRequest, loading, data, error };
};

export default useUpdateOrganisationName;
