/* eslint-disable operator-linebreak */
import { useMutation } from '@apollo/client';
import { GENERATE_SHARE_LINK } from '../../graphql/mutations/share';
import {
  GET_SHARED_LINKS,
  GET_SHARE_LINK,
} from '../../graphql/queries/share/share';

const useGenerateShareLink = (songId, organisationRef) => {
  const [generateShareLink, { loading, data }] = useMutation(
    GENERATE_SHARE_LINK,
    {
      refetchQueries: [
        {
          query: GET_SHARE_LINK,
          variables: {
            where: {
              AND: [
                { column: 'SONGID', value: songId },
                { column: 'ORGANISATIONREF', value: organisationRef },
              ],
            },
          },
        },
        {
          query: GET_SHARED_LINKS,
          variables: {
            organisation_ref: organisationRef,
          },
        },
      ],
    }
  );

  const generateShareLinkRequest = () => {
    generateShareLink({
      variables: {
        input: { song_id: songId, organisation_ref: organisationRef },
      },
      fetchPolicy: 'network-only',
    });
  };

  return { generateShareLinkRequest, data, loading };
};

export default useGenerateShareLink;
