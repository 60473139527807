import React from 'react';
import TableRow from './TableRow';

const TableBody = ({
  users,
  invites,
  organisationRef,
  joinRequests,
  isAdmin,
}) => (
  <tbody className="manage-account__invite-colleagues__tbody">
    {users?.map((user) => (
      <React.Fragment key={user.memberUserRef}>
        <TableRow
          user={{ ...user, status: 'isActive' }}
          organisationRef={organisationRef}
          isAdminUser={isAdmin}
        />
      </React.Fragment>
    ))}
    {invites?.map((user) => (
      <React.Fragment key={user.inviteRef}>
        <TableRow
          user={{ userProfile: user, status: 'isInvited' }}
          organisationRef={organisationRef}
          isAdminUser={isAdmin}
        />
      </React.Fragment>
    ))}
    {joinRequests?.map((request) => (
      <React.Fragment key={request.id}>
        <TableRow
          user={{
            userProfile: {
              ...request,

              joinRequestRef: request.id,
            },
            status: 'isRequest',
          }}
          organisationRef={organisationRef}
          isAdminUser={isAdmin}
        />
      </React.Fragment>
    ))}
  </tbody>
);

export default TableBody;
