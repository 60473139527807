/* eslint-disable operator-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import useGenerateUrlHash from '../../../../../hooks/Others/useGenerateUrlHash';
import { subscriptionDetails, user } from '../../../../../utils/reactiveVars';

const SubscriptionButton = ({ href, text, shouldDisableButton = '' }) => (
  <div className="mx-auto mt-5 mt-lg-auto">
    <a
      className={`btn button__cerise-dark-bg ml-auto mt-5 uppercase w__214 ${shouldDisableButton}`}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  </div>
);

const Footer = () => {
  const { data, generateUrlHashRequest, loading } = useGenerateUrlHash();
  const redirectUrl = `${document.location.origin}/manage-account`;

  useEffect(() => {
    generateUrlHashRequest(redirectUrl);
  }, []);

  const accessTokenAndUrlReturnSignature = `${
    user().accessToken
  }&url_return=${redirectUrl}&url_return_signature=${data?.generateUrlHash}`;

  let subscriptionUrl = `${process.env.REACT_APP_SUBSCRIPTION_URL}${accessTokenAndUrlReturnSignature}`;
  let manageDiscountCodeUrl = `${process.env.REACT_APP_MANAGE_DISCOUNT_URL}${accessTokenAndUrlReturnSignature}`;
  let managePONumberUrl = `${process.env.REACT_APP_MANAGE_PURCHASE_ORDER_NUMBER_URL}${accessTokenAndUrlReturnSignature}`;

  const shouldDisableButton = loading ? 'disable-element' : '';

  const { sysubs } = useReactiveVar(subscriptionDetails);
  const firstActiveSub = sysubs?.find((sub) => sub.isActive);

  if (sysubs?.[0]) {
    const { sysubRef } = firstActiveSub || sysubs[0];
    subscriptionUrl += `&manage_sysub=${sysubRef}`;
    manageDiscountCodeUrl += `&sysub_id=${sysubRef}`;
    managePONumberUrl += `&sysub_id=${sysubRef}`;
  }

  return (
    <>
      <SubscriptionButton
        href={subscriptionUrl}
        text={firstActiveSub ? 'Manage your Subscription' : 'Buy Now'}
        shouldDisableButton={shouldDisableButton}
      />
      {firstActiveSub?.willRenew && (
        <SubscriptionButton
          href={manageDiscountCodeUrl}
          text="Apply Discount Code"
        />
      )}
      {firstActiveSub?.willRenew &&
        firstActiveSub.stripeCollectionMethod === 'send_invoice' && (
          <SubscriptionButton
            href={managePONumberUrl}
            text="Apply Purchase Order Number"
          />
        )}
    </>
  );
};

export default Footer;
