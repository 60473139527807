import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import useGetOrganisation from '../../../../../hooks/Organisation/useGetOrganisation';
import { organisationId } from '../../../../../utils/reactiveVars';
import Loading from '../../../Loading';
import ColleaguesList from './ColleaguesList/ColleaguesList';
import ModalHeader from './ModalHeader';
import SendInviteSection from './SendInviteSection';

const InviteAndManageColleaguesModal = ({ isAdmin }) => {
  const id = useReactiveVar(organisationId);
  const { data, loading, getOrganisation } = useGetOrganisation();

  useEffect(() => {
    if (id) {
      getOrganisation(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div
      className="modal fade"
      id="inviteAndManageColleaguesModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="inviteAndManageColleaguesModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered custom__modal__invite-colleagues"
        role="document"
      >
        <div className="modal-content custom__modal__leave-organization__content">
          <ModalHeader loading={loading} />
          {loading && <Loading />}
          {data && (
            <>
              {isAdmin && <SendInviteSection organisationId={id} />}
              <ColleaguesList
                organisation={data.organisation}
                isAdmin={isAdmin}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InviteAndManageColleaguesModal;
