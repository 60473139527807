/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Tappable from 'react-tappable';
import { useLocation, withRouter } from 'react-router-dom';
import { songChoiceSongs } from '../../../utils/reactiveVars';
import SongItem from '../SongItem';

const EventSongCards = ({ title, songs, pageTitle, history }) => {
  if (!songs || songs?.length < 1) return null;
  const location = useLocation();

  useEffect(() => {
    songChoiceSongs({ songs, path: location.pathname, pageTitle });
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-20 px-xl-0 px-3 pt-5">
        <p className="heading__section text-uppercase">{title}</p>
        <Tappable
          component="a"
          className="text__white text__fs-10"
          onTap={() => {
            history.push('/song-choice');
          }}
        >
          SEE ALL
        </Tappable>
      </div>
      <div className="px-xl-0 px-3">
        <div className="row mb-20 py-5">
          {songs?.slice(0, 6).map(({ title: songTitle, id, albumArt }) => (
            <div className="col-lg-2 col-md-4 col-6" key={id}>
              <SongItem
                title={songTitle}
                key={id}
                albumArt={albumArt}
                id={id}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default withRouter(EventSongCards);
