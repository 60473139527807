/* eslint-disable operator-linebreak */
import React from 'react';
import useHandleSubscriptionAndTrials from '../../../../../hooks/Organisation/useHandleSubscriptionAndTrials';
import { defaultOrganisationLogo } from '../../../../../utils/objects';
import {
  loggedInAsPrivateUser,
  organisationLoggedInAs,
  playerActive,
} from '../../../../../utils/reactiveVars';
import { persistOrganisationLoggedInAs } from '../../../../../utils/user';

const Organisation = ({
  logo,
  name,
  organisation,
  disableClick,
  privateUser,
}) => {
  const { handleOrganisationSub, handleUserSubOrTrial } =
    useHandleSubscriptionAndTrials();

  return (
    <a
      className="dropdown-item"
      href=""
      onClick={(e) => {
        e.preventDefault();
        if (!disableClick && organisation) {
          organisationLoggedInAs(organisation);
          loggedInAsPrivateUser(false);
          handleOrganisationSub(organisation);
          persistOrganisationLoggedInAs(organisation.id);
        }
        if (privateUser) {
          organisationLoggedInAs(null);
          loggedInAsPrivateUser(true);
          handleUserSubOrTrial();
          localStorage.setItem('loggedInAsPrivateUser', 'true');
        }
        if (playerActive()) window.location.reload();
      }}
    >
      <img src={logo || defaultOrganisationLogo} alt="" className="logo" />
      <p>{name}</p>
    </a>
  );
};

export default Organisation;
