import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { HIDE_SHARED_PLAYLIST } from '../../graphql/mutations/playlist';
import { GET_PLAYLISTS_SHARED_WITH_ORGANISATION } from '../../graphql/queries/playlist/playlist';
import {
  dismissLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const useHideSharedPlaylist = () => {
  const organisationRef = organisationLoggedInAs()?.id || null;
  const history = useHistory();

  const [hideSharedPlaylist, { loading }] = useMutation(HIDE_SHARED_PLAYLIST, {
    refetchQueries: [
      {
        query: GET_PLAYLISTS_SHARED_WITH_ORGANISATION,
        variables: {
          organisation_ref: organisationRef,
        },
      },
    ],
    onCompleted: () => {
      dismissLoadingMessage();
      displaySuccessMessage('Playlist unfollowed!');
      history.push('/songs/playlists');
    },
  });

  const hideSharedPlaylistRequest = (playlistId) => {
    hideSharedPlaylist({
      variables: {
        playlist_id: playlistId,
        organisation_ref: organisationRef,
      },
    });
  };

  return { hideSharedPlaylistRequest, loading };
};

export default useHideSharedPlaylist;
