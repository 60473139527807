/* eslint-disable operator-linebreak */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import useGetMemberships from '../../../../hooks/Organisation/useGetMemberships';
import useGetSharedLinks from '../../../../hooks/Share/useGetSharedLinks';
import { organisationLoggedInAs } from '../../../../utils/reactiveVars';
import Loading from '../../../Shared/Loading';
import CreateUsageReport from '../../../Shared/Modals/ManageAccount/CreateUsageReport/Index';
import EditOrganisationModal from '../../../Shared/Modals/ManageAccount/EditOrganisationModal/EditOrganisationModal';
import InviteAndManageColleaguesModal from '../../../Shared/Modals/ManageAccount/InviteAndManageColleaguesModal/InviteAndManageColleaguesModal';
import LeaveOrganisationModal from '../../../Shared/Modals/ManageAccount/LeaveOrganisation/LeaveOrganisationModal';
import ManageStudentLinksModal from '../../../Shared/Modals/ManageAccount/ManageStudentLinksModal/ManageStudentLinksModal';
import ManageSubscriptionModal from '../../../Shared/Modals/ManageAccount/ManageSubscription/ManageSubscriptionModal';
import Organisation from './Organisation/Organisation';

const OrganisationsSegment = () => {
  const { data, loading } = useGetMemberships();

  const selectedOrganisation = useReactiveVar(organisationLoggedInAs);
  const organisationRef = selectedOrganisation?.id || null;

  const { data: sharedLinks } = useGetSharedLinks(organisationRef);
  const sharedLinksCount = sharedLinks?.getSharedLinks?.length || 0;

  const getIsAdmin = () => {
    const findMembership = data?.memberships?.find(
      (membership) => membership.id === organisationRef
    );
    return findMembership?.isAdmin || false;
  };

  return (
    <>
      {selectedOrganisation && (
        <>
          <div className="manage-account__segment">
            <span className="manage-account__segment__header">
              YOUR SUBSCRIPTION
            </span>
          </div>

          {loading && <Loading />}

          <Organisation
            organisation={selectedOrganisation}
            isAdmin={getIsAdmin()}
            sharedLinksCount={sharedLinksCount}
          />

          <EditOrganisationModal />
          <LeaveOrganisationModal />
          <InviteAndManageColleaguesModal isAdmin={getIsAdmin()} />
        </>
      )}
      <ManageSubscriptionModal />
      <ManageStudentLinksModal sharedLinks={sharedLinks?.getSharedLinks} />
      <CreateUsageReport />
    </>
  );
};

export default OrganisationsSegment;
