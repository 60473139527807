/* eslint-disable operator-linebreak */
import React from 'react';
import useRemoveFromCollection from '../../../hooks/Collection/useRemoveFromCollection';
import useRemoveFromPlaylist from '../../../hooks/Playlist/useRemoveFromPlaylist';

const RemoveModal = ({ type, childId }) => {
  const { parentId, item } = type;
  const { removeFromPlaylistRequest } = useRemoveFromPlaylist(
    childId,
    type.parentId
  );

  const { removeFromCollectionRequest } = useRemoveFromCollection(
    parentId,
    item,
    childId
  );

  const fnMap = {
    playlist: removeFromPlaylistRequest,
    collection: removeFromCollectionRequest,
  };

  return (
    <>
      <div
        className="modal fade"
        id="removeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="actions"
        aria-hidden="true"
      >
        <div
          className="modal-dialog  modal-dialog-centered custom__modal__filter-new"
          role="document"
        >
          <div className="modal-content">
            <div className="custom__modal__content">
              <div className="custom__modal__header mb-5">
                <h5 className="custom__modal__title font-weight-normal">
                  Remove from {type.from}
                </h5>
                <a
                  href=""
                  className="custom__modal__close"
                  data-dismiss="modal"
                >
                  <img src="/img/plus-icon.svg" alt="" />
                </a>
              </div>
              <div className="mb-20 text__black text__fs-16">
                Are you sure you want to remove this {type.item} from this{' '}
                {type.from}?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => fnMap[type.from].send()}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoveModal;
