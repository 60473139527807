import React, { forwardRef } from 'react';
import Select from './Select';

const SelectSection = forwardRef(
  ({ label, tags, name, handleTagChange }, ref) => (
    <div className="assembly__songfinder__value">
      <label htmlFor="">{label}</label>
      <Select
        ref={ref}
        tags={tags}
        name={name}
        handleTagChange={handleTagChange}
      />
    </div>
  )
);

SelectSection.displayName = 'SelectSection';

export default SelectSection;
