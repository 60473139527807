import { useQuery } from '@apollo/client';
import { GET_SONGBOOK } from '../../graphql/queries/songbook/songbook';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
} from '../../utils/helpers';

const useGetSongbook = (id) => {
  const { data, loading } = useQuery(GET_SONGBOOK, {
    variables: { id },
    onCompleted: () => dismissLoadingMessage(),
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage();
    },
  });

  loading && displayLoadingMessage('Loading songbook...');

  return { data };
};

export default useGetSongbook;
