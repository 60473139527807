import { gql } from '@apollo/client';
import {
  CollectionFieldsFragment,
  CollectionShareLinkFieldsFragment,
} from '../../queries/collection/fragments';

export const CREATE_COLLECTION = gql`
  mutation CreateCollection($input: CreateCollectionInput!) {
    createCollection(input: $input) {
      id
      title
      description
    }
  }
`;

export const ADD_TO_COLLECTION = gql`
  mutation AddToCollection($input: AddToCollectionInput!) {
    addToCollection(input: $input) {
      id
    }
  }
`;

export const EDIT_COLLECTION = gql`
  mutation EditCollection($id: ID!, $input: EditCollectionInput!) {
    editCollection(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation DeleteCollection($id: ID!) {
    deleteCollection(id: $id) {
      id
    }
  }
`;

export const REMOVE_FROM_COLLECTION = gql`
  mutation RemoveFromCollection($id: ID!, $input: RemoveFromCollectionInput!) {
    removeFromCollection(id: $id, input: $input) {
      ...CollectionFields
    }
  }
  ${CollectionFieldsFragment}
`;

export const UPDATE_COLLECTION = gql`
  mutation UpdateCollection($id: ID!, $input: UpdateCollectionInput!) {
    updateCollection(id: $id, input: $input) {
      ...CollectionFields
    }
  }
  ${CollectionFieldsFragment}
`;

export const UPDATE_COLLECTION_SONG_ORDERING = gql`
  mutation UpdateCollectionSongOrdering(
    $id: ID!
    $input: UpdateCollectionSongsInput!
  ) {
    updateCollectionSongOrdering(id: $id, input: $input) {
      ...CollectionFields
    }
  }
  ${CollectionFieldsFragment}
`;

export const UPDATE_COLLECTION_PLAYLIST_ORDERING = gql`
  mutation UpdateCollectionPlaylistOrdering(
    $id: ID!
    $input: UpdateCollectionPlaylistInput!
  ) {
    updateCollectionPlaylistOrdering(id: $id, input: $input) {
      ...CollectionFields
    }
  }
  ${CollectionFieldsFragment}
`;

export const COPY_COLLECTION = gql`
  mutation CopyCollection($id: ID!) {
    copyCollection(id: $id)
  }
`;

export const GENERATE_COLLECTION_SHARE_LINK = gql`
  mutation GenerateCollectionShareLink($collection_id: ID!) {
    generateCollectionShareLink(collection_id: $collection_id) {
      ...CollectionShareLinkFields
    }
  }
  ${CollectionShareLinkFieldsFragment}
`;

export const VERIFY_COLLECTION_SHARE_LINK = gql`
  mutation VerifyCollectionShareLink(
    $where: MutationVerifyCollectionShareLinkWhereWhereConditions
  ) {
    verifyCollectionShareLink(where: $where) {
      id
      userId
    }
  }
`;

export const HIDE_SHARED_COLLECTION = gql`
  mutation HideSharedCollection($collection_id: ID!, $organisation_ref: ID!) {
    hideSharedCollection(
      collection_id: $collection_id
      organisation_ref: $organisation_ref
    ) {
      id
    }
  }
`;

export const UPDATE_COLLECTIONS_ORDERING = gql`
  mutation UpdateCollectionsOrdering(
    $input: [UpdateCollectionsOrderingInput]!
  ) {
    updateCollectionsOrdering(input: $input)
  }
`;
