/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/naming-convention */
import { useReactiveVar } from '@apollo/client';
import React, { useState } from 'react';
import useAcceptOrDeclineJoinRequest from '../../../../../../hooks/Organisation/useAcceptOrDeclineJoinRequest';
import useCancelInvite from '../../../../../../hooks/Organisation/useCancelInvite';
import useEditMember from '../../../../../../hooks/Organisation/useEditMember';
import useRemoveMember from '../../../../../../hooks/Organisation/useRemoveMember';
import { user as currentUserEmail } from '../../../../../../utils/reactiveVars';

const TableRow = ({ user, organisationRef, isAdminUser }) => {
  if (!user) return null;
  const {
    userProfile: { firstname, surname, email, inviteRef, joinRequestRef },
    isAdmin,
    status,
    memberUserRef,
    sendEmails,
  } = user;

  const checkStatus = (userStatus) => userStatus === status;

  const [checkboxesState, setCheckboxesState] = useState({ isAdmin });

  const { sendCancelInviteRequest, loading } = useCancelInvite(
    inviteRef,
    organisationRef
  );

  const { sendAcceptOrDeclineJoinRequest, loading: loadingJoinRequest } =
    useAcceptOrDeclineJoinRequest(organisationRef, email);

  const { sendEditMemberRequest, loading: loadingEditMember } =
    useEditMember(organisationRef);

  const { email: authedUserEmail } = useReactiveVar(currentUserEmail);

  const { sendRemoveMemberRequest, loading: loadingRemoveMember } =
    useRemoveMember(organisationRef);

  const organisation_ref = parseInt(organisationRef, 10);
  const member_user_ref = parseInt(memberUserRef, 10);

  const handleCheckboxChange = (e) => {
    setCheckboxesState({
      ...checkboxesState,
      [e.target.name]: e.target.checked,
    });

    const input = {
      organisation_ref,
      member_user_ref,
      is_admin: e.target.checked,
      send_emails: sendEmails,
    };

    sendEditMemberRequest(input);
  };

  const handleRemoveModel = (e) => {
    e.preventDefault();
    if (status === 'isInvited') {
      return sendCancelInviteRequest();
    }
    if (status === 'isActive') {
      const input = { organisation_ref, member_user_ref };
      return sendRemoveMemberRequest(input);
    }
    if (status === 'isRequest') {
      const input = { action: 'DECLINE', join_request_ref: joinRequestRef };
      sendAcceptOrDeclineJoinRequest(input);
    }
  };

  const isLoading =
    loadingEditMember || loading || loadingRemoveMember || loadingJoinRequest;
  const rowClassName = isLoading ? 'disable-element' : '';
  const removeElementClassName =
    authedUserEmail === email || !isAdminUser ? 'disable-element' : '';

  const className = 'd-none d-md-table-cell';

  const checkboxClassName = !isAdminUser ? 'disable-element' : '';

  return (
    <tr className={rowClassName}>
      <th scope="row">
        {firstname} {surname || ''}
      </th>
      <td className={className}>{email}</td>

      {checkStatus('isInvited') && (
        <td className="manage-account__invited">Invited</td>
      )}
      {checkStatus('isActive') && (
        <td className="manage-account__active">Active</td>
      )}
      {checkStatus('isRequest') && (
        <td className="manage-account__invited">Activate?</td>
      )}

      <td>
        <input
          type="checkbox"
          checked={checkStatus('isActive')}
          disabled={status === 'isInvited'}
          className={checkboxClassName}
          onChange={() => {
            if (checkStatus('isRequest')) {
              sendAcceptOrDeclineJoinRequest({
                action: 'ACCEPT',
                join_request_ref: joinRequestRef,
              });
            }
          }}
        />
      </td>
      <td className={className}>
        <input
          type="checkbox"
          checked={checkboxesState.isAdmin}
          disabled={checkStatus('isInvited') || checkStatus('isRequest')}
          name="isAdmin"
          onChange={(e) => handleCheckboxChange(e)}
          className={checkboxClassName}
        />
      </td>
      <td className={className}>
        <a
          href=""
          onClick={handleRemoveModel}
          disabled={loading}
          className={removeElementClassName}
        >
          <img src="/img/bin.png" alt="remove" width="11" height="13" />
        </a>
      </td>
    </tr>
  );
};

export default TableRow;
