import React, { useEffect, useState } from 'react';
import useShareCollection from '../../../../../../hooks/Collection/useShareCollection';
import { organisationLoggedInAs } from '../../../../../../utils/reactiveVars';

const ShareCollectionWithOrganisation = ({ id, shareCollection }) => {
  const organisationRef = organisationLoggedInAs()?.id || null;
  const { shareCollectionRequest, loading } = useShareCollection(
    id,
    organisationRef
  );

  const [state, setState] = useState(
    shareCollection?.hasSharedWithOrganisation
  );
  const text = state ? 'Stop sharing' : 'Share with my Organization';

  useEffect(() => {
    setState(shareCollection?.hasSharedWithOrganisation);
  }, [id, shareCollection]);

  const handleShareCollection = () => {
    const deleteId = state ? shareCollection.shareId : null;
    shareCollectionRequest(deleteId);
  };

  return (
    <>
      <p className="text-dark text__fs-12 mb-0">
        Share this Collection with everyone within your organization. You will
        still be able to edit it, but other users can also create their own copy
      </p>
      <button
        className="btn bg__cello text-white mt-2"
        onClick={() => handleShareCollection()}
        disabled={loading}
        data-dismiss="modal"
      >
        {text}
      </button>
    </>
  );
};

export default ShareCollectionWithOrganisation;
