import React, { useState, useEffect } from 'react';
import SelectSection from '../../Shared/CategoryPages/SelectSection';
import SubmitSection from '../../Shared/CategoryPages/SubmitSection';

const Header = ({ filter, reset, contentTypeTags }) => {
  const [filterData, setFilterData] = useState({});

  useEffect(() => {}, [filterData]);

  return (
    <>
      <div className="resource__favorite__header">
        <div className="main__container">
          <div className="resource__favorite__header__grid">
            <div className="resource__favorite__header__title">
              <i className="fa fa-file-alt" />
              <h2>
                Resources &nbsp;
                <span className="text__white"> Recommended</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="main__container bg__blue-nile mt-0">
          <div className="assembly__songfinder">
            <h2 className="assembly__songfinder__title">Find resources</h2>

            <div
              className="assembly__filter"
              style={{ gridTemplateColumns: 'auto 10%' }}
            >
              <SelectSection
                label="Content Type"
                tags={contentTypeTags}
                name="content_type"
                handleTagChange={(value) => {
                  setFilterData({ resource_type: value });
                }}
              />

              <SubmitSection
                onClick={() => {
                  filter(filterData);
                }}
                hideClearFilters
                reset={reset}
                handleClearFilters={reset}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
