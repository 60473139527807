import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import Loading from '../../components/Shared/Loading';
import SongsList from '../../components/Songs/Sections/Songslist';
import useGetRecommendedSongs from '../../hooks/Song/useGetRecommendedSongs';
import { displayLoadingMessage } from '../../utils/helpers';
import { updateQuery } from '../../utils/objects';

const RecommendedSongs = () => {
  const { data, loading, fetchMore } = useGetRecommendedSongs(50);

  loading && displayLoadingMessage('Loading recommended songs');

  const loadMoreSongs = () => {
    fetchMore({
      variables: {
        first: data?.recommendedSongs?.paginatorInfo?.count + 50,
      },
      ...updateQuery,
    });
  };

  return (
    <>
      <div className="resource__favorite__header">
        <div className="main__container">
          <div className="resource__favorite__header__grid">
            <div className="resource__favorite__header__title">
              <img
                src="img/audio-icon-grey.svg"
                className="songslist__header__icon"
                alt=""
              />
              <h2>
                Songs &nbsp;
                <span className="text__white"> Recommended</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="main__container">
        {data?.recommendedSongs?.data && (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMoreSongs}
            hasMore={data.recommendedSongs.paginatorInfo.hasMorePages}
            loader={<Loading />}
          >
            <SongsList songs={data.recommendedSongs.data} loading={loading} />
          </InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default withPageWrapper({})(RecommendedSongs);
