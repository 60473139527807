/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const Row = ({ title, disabled, checked, setChecked }) => {
  let className = 'manage-account__subscription-details-row checkbox__white';
  if (disabled) className += ' disable-element';

  return (
    <div className={className}>
      <span className="text__fw-600 pr-3">{title}</span>
      <input
        type="checkbox"
        name="roles"
        className=""
        id={title}
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
      />
      <label htmlFor={title} className="pl-4" />
    </div>
  );
};

export default Row;
