import React from 'react';

const Header = () => (
  <div className="main__container bg__jellybean mt-0">
    <div className="assembly__header wellbeing__edition">
      <h2 className="assembly__header__title">Wellbeing</h2>
      <p className="assembly__header__text">
        Songs and resources to promote self-confidence, resilience, teamwork and
        more.
      </p>
      <div className="assembly__header__buttons">
        <a href="" className="button__md button__white w-100">
          Assembly plans
        </a>
      </div>
    </div>
  </div>
);

export default Header;
