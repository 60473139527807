import React from 'react';
import GenerateCollectionShareLink from './GenerateCollectionShareLink/Index';
import ShareCollectionWithOrganisation from './ShareCollectionWithOrganisation';

const ModalBody = ({ id, shareCollection }) => (
  <>
    <ShareCollectionWithOrganisation id={id} shareCollection={shareCollection} />
    <GenerateCollectionShareLink collectionId={id} />
  </>
);

export default ModalBody;
