import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { shortenLongDescription } from '../../../../utils/helpers';

const RelatedGuidesCardLarge = ({
  title,
  content,
  thumbnail,
  slug,
  history,
}) => (
  <div className="resources__carousel">
    <div className="carousel__container">
      <div className="carousel__container__img resource-card-large">
        <img src={thumbnail} alt={title} />
      </div>
      <Tappable
        component="a"
        className="carousel__container__title"
        onTap={() => {
          history.push(`/resources/${slug}`);
        }}
      >
        {title}
      </Tappable>
      <p className="carousel__container__desc pr-4">
        {shortenLongDescription(content, 300)}
      </p>
    </div>
  </div>
);

export default withRouter(RelatedGuidesCardLarge);
