import { useMutation } from '@apollo/client';
import { SEND_VERIFICATION_MAIL } from '../../graphql/mutations/user';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';

const useSendVerificationMail = () => {
  const [sendVerificationMail, { loading }] = useMutation(
    SEND_VERIFICATION_MAIL
  );

  const sendVerificationMailRequest = {
    send: () => {
      sendVerificationMail()
        .then(() => {
          dismissLoadingMessage();
          displaySuccessMessage();
        })
        .catch(() => displayErrorMessage());
    },
  };

  loading && displayLoadingMessage();

  return { sendVerificationMailRequest };
};

export default useSendVerificationMail;
