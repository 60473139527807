/* eslint-disable react/no-danger */
import React from 'react';
import {
  addGlossaries,
  formatList,
  removeHtmlClassFromString,
} from '../../../../../utils/helpers';
import { glossaries } from '../../../../../utils/reactiveVars';

const TextModule = ({ text, title, list, whiteHeader, noMargin }) => {
  const glossariesArr = glossaries();
  let formattedText = formatList(removeHtmlClassFromString(text));
  formattedText = addGlossaries(formattedText, glossariesArr);

  return (
    <>
      {title && (
        <p
          className={`card__plain__heading ${list ? 'mb-3' : 'mb-20'} ${
            !whiteHeader && 'text__elephant'
          }`}
        >
          {title}
        </p>
      )}
      <p
        className={`summary__text-sm ${!whiteHeader && 'text__black'} ${
          noMargin ? 'mb-0' : 'mb-5'
        }`}
        dangerouslySetInnerHTML={{ __html: formattedText }}
      />
    </>
  );
};

export default TextModule;
