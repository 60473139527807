/* eslint-disable operator-linebreak */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import useGetMemberships from '../../../../../hooks/Organisation/useGetMemberships';
import { organisationLoggedInAs } from '../../../../../utils/reactiveVars';

const OrganisationName = () => {
  const { data } = useGetMemberships();

  const organisationCount = data?.memberships?.length || 0;

  const selectedOrganisation = useReactiveVar(organisationLoggedInAs);
  const organisationName =
    selectedOrganisation?.displayName ||
    selectedOrganisation?.detailName ||
    'Private license user';

  const hideName = organisationCount < 1 ? 'd-none' : '';

  return (
    <p className={`main__nav__info__school ${hideName}`}>{organisationName}</p>
  );
};

export default OrganisationName;
