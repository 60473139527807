import React from 'react';

const RightIcon = () => {
  return (
    <svg
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18px"
      height="28px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1 0 0 1 -1001 -27 )">
        <path
          d="M 18 14  C 18 14.301075268817204  17.88996138996139 14.561621174524399  17.66988416988417 14.781637717121587  L 4.777992277992278 27.669975186104217  C 4.557915057915059 27.889991728701407  4.297297297297298 28  3.9961389961389964 28  C 3.6949806949806954 28  3.4343629343629347 27.889991728701407  3.2142857142857144 27.669975186104217  L 0.3301158301158301 24.786600496277917  C 0.11003861003861021 24.566583953680727  0 24.30603804797353  0 24.00496277915633  C 0 23.703887510339126  0.11003861003861021 23.443341604631925  0.3301158301158301 23.22332506203474  L 9.555984555984557 14  L 0.3301158301158301 4.776674937965261  C 0.11003861003861021 4.5566583953680695  0 4.296112489660875  0 3.9950372208436713  C 0 3.693961952026464  0.11003861003861021 3.4334160463192696  0.3301158301158301 3.2133995037220844  L 3.2142857142857144 0.33002481389578087  C 3.4343629343629347 0.11000827129859259  3.6949806949806954 0  3.9961389961389964 0  C 4.297297297297298 0  4.557915057915059 0.11000827129859259  4.777992277992278 0.33002481389578087  L 17.66988416988417 13.218362282878413  C 17.88996138996139 13.4383788254756  18 13.698924731182796  18 14  Z "
          fillRule="nonzero"
          fill="#ffffff"
          stroke="none"
          transform="matrix(1 0 0 1 1001 27 )"
        />
      </g>
    </svg>
  );
};

export default RightIcon;
