/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import LikeAndAddIcons from '../LikeAddIcons';
import useGetPurchasedSongs from '../../../hooks/Song/useGetPurchasedSongbooks';

const SongbookList = ({ songbooks, type, history }) => {
  if (songbooks?.length < 1) return null;
  let songbookType;

  if (type !== undefined && typeof type !== 'object' && type !== null) {
    songbookType = type;
  }

  const [songbooksData, setSongbooksData] = useState(null);
  const { data, getPurchasedItemsRequest } = useGetPurchasedSongs(songbookType);

  const itemsDataMap = {
    Purchased: 'purchasedSongbooks',
    'Purchased Nativities': 'purchasedNativities',
    'Purchased Musicals': 'purchasedMusicals',
  };

  useEffect(() => {
    if (type !== undefined && typeof type !== 'object' && type !== null) {
      return getPurchasedItemsRequest.send();
    }
    setSongbooksData(songbooks);
  }, [type, songbooks]);

  useEffect(() => {
    if (data) {
      setSongbooksData(data[itemsDataMap[type]]);
    }
  }, [data]);

  const SongBook = ({ title, image, id }) => (
    <div className="songslist__item">
      <div className="songslist__item__thumbnail">
        <img
          src={image}
          className="songslist__item__thumbnail__bg"
          alt={title}
        />
      </div>
      <Tappable
        component="a"
        className="songslist__item__title"
        onTap={() => {
          history.push(`/songbook/${id}`);
        }}
      >
        {title}
      </Tappable>
      <LikeAndAddIcons
        headerText="Options"
        modalId="songbookId"
        addToCollection
        id={id}
        itemType="ESONGBOOK"
        type={songbookType}
      />
    </div>
  );

  return (
    <div className="songslist__container" style={{ maxWidth: '698px' }}>
      {songbooksData?.map(({ id, albumArt, imageFileMedium, infoName }) => (
        <SongBook
          key={id}
          id={id}
          title={infoName}
          image={albumArt || imageFileMedium}
        />
      ))}
    </div>
  );
};

export default withRouter(SongbookList);
