/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useGetCollection from '../../../hooks/Collection/useGetCollection';
import { displayLoadingMessage } from '../../../utils/helpers';
import CollectionComponent from '../../../components/Collection/Sections/Collection';
import { showModal } from '../../../styles/js/modals';
import CopyModelModal from '../../../components/Shared/Modals/CopyModelModal';

const Collection = ({ id }) => {
  const { data, loading } = useGetCollection(id);
  loading && displayLoadingMessage('Loading collection...');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (data?.collection && !copied) {
      showModal('copyModelModal');
    }
  }, [data]);

  return (
    <>
      {data?.collection && <CollectionComponent collection={data.collection} />}
      <CopyModelModal type="collection" id={id} setCopied={setCopied} />
    </>
  );
};

export default Collection;
