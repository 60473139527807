import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_FAVOURITE_RESOURCES } from '../../graphql/queries/resource/resource';
import { userFavouriteResources } from '../../utils/reactiveVars';

const useGetFavouriteResources = () => {
  const { data, loading, error } = useQuery(GET_FAVOURITE_RESOURCES, {
    onCompleted: (res) => {
      toast.dismiss('loading');
      userFavouriteResources(res?.userFavouriteResources);
    },
    onError: () => {},
  });

  return { data, loading, error };
};

export default useGetFavouriteResources;
