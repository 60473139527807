/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { resourceList } from '../../../utils/reactiveVars';
import {
  getResourceDescription,
  getResourceImage,
} from '../../../utils/resource';
import AssemblyPlanCard from '../../Shared/Card';
import ContentBanner from '../ContentBanner';

const Resources = ({ resources, isUserCollection, parentId, pageTitle }) => {
  const resourcesCopy = [...resources];
  const sortedResources = resourcesCopy.sort((a, b) => a.ordering - b.ordering);

  const resourceCount = 2;
  const location = useLocation();
  const actions = isUserCollection
    ? {
        item: 'resource',
        from: 'collection',
        parentId,
      }
    : null;

  const showContentHereImg = isUserCollection && sortedResources?.length < 2;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-26 px-3 px-lg-0">
        <p className="text__fs-18 mb-0">RESOURCES</p>
        <Link
          to="/collection/resources/see-all"
          className="text__white text__fs-10"
          onClick={() => {
            resourceList({
              path: location.pathname,
              title: pageTitle,
              resources: sortedResources,
              actions,
              isUserCollection,
              collectionId: parentId,
            });
          }}
        >
          SEE ALL
        </Link>
      </div>
      <div className="assembly__grid theme-page__grid">
        {sortedResources?.slice(0, resourceCount).map((resource) => (
          <AssemblyPlanCard
            id={resource.id}
            slug={resource.slug}
            title={resource.title}
            description={getResourceDescription(resource)}
            key={resource.id}
            thumbnail={getResourceImage(resource.category.name)}
            parentId={parentId}
            themePage
            resource
            type={actions}
          />
        ))}
        {showContentHereImg && <ContentBanner />}
      </div>
    </>
  );
};

export default Resources;
