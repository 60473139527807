import React from 'react';
import useCopyCollection from '../../../../hooks/Collection/useCopyCollection';

const CopyCollection = ({ show, id }) => {
  if (!show) return null;

  const { copyCollectionRequest } = useCopyCollection();

  return (
    <div className="mb-20 text__black text__fs-16">
      <a
        href=""
        className="text__black"
        id="copyCollection"
        data-dismiss="modal"
        onClick={(e) => {
          e.preventDefault();
          copyCollectionRequest(id);
        }}
      >
        <i className="fa fa-copy text__turquoise-medium text__fs-20 mr-4" />{' '}
        Copy collection
      </a>
    </div>
  );
};

export default CopyCollection;
