import { useReactiveVar } from '@apollo/client';
import React from 'react';
import useGetSharedLinks from '../../../../hooks/Share/useGetSharedLinks';
import {
  calculateDaysLeftInSubscription,
  getSubscriptionCardTexts,
  redirectToOota,
} from '../../../../utils/helpers';
import {
  organisationLoggedInAs,
  subscriptionDetails,
  user,
} from '../../../../utils/reactiveVars';
import Card from '../OrganisationsSegment/Organisation/CardsSection/Card';
import LastCard from '../OrganisationsSegment/Organisation/CardsSection/LastCard';

const PrivateUserCardsSection = ({
  sysubs,
  activeSysub,
  activeTrial,
  trialDateUntil,
}) => {
  const { data: sharedLinks } = useGetSharedLinks(null);

  if (useReactiveVar(organisationLoggedInAs)) return null;
  const sharedLinksCount = sharedLinks?.getSharedLinks?.length || 0;

  // const subscriptionCardBottomText = activeTrial
  //   ? 'remaining in your free trial subscription'
  //   : 'remaining in your subscription';

  let daysLeftOnSubscription = '0 days';

  const firstActiveSub = sysubs?.find((sub) => sub.isActive);
  const selectedSub = firstActiveSub || sysubs[0];

  if (activeTrial || activeSysub) {
    const dateRenewal = activeSysub ? selectedSub?.dateRenewal : trialDateUntil;
    const isActive = activeSysub ? selectedSub?.isActive : activeTrial;
    daysLeftOnSubscription = calculateDaysLeftInSubscription(
      dateRenewal,
      isActive
    );
  }

  // const isManualSub = activeSysub && !selectedSub?.dateRenewal;
  // const topText = isManualSub ? '&nbsp;' : 'You have';
  // const bottomText = isManualSub ? '' : subscriptionCardBottomText;

  const { firstname, surname } = user();

  const {
    topText,
    middleText,
    bottomText,
    hasManualSubs,
    hasMultiplePaidSubs,
  } = getSubscriptionCardTexts(sysubs, daysLeftOnSubscription, activeTrial);

  // creating an addresses array on the fly here since there's no provision for
  // user addresses yet on sparkyard
  const addresses = [
    {
      detailCompany: `${firstname} ${surname}`,
      detailFirstName: firstname,
      detailSurname: surname,
      detailLine1: '',
      detailTown: '',
      detailPostcode: '',
      detailCountry: '',
    },
  ];

  return (
    <>
      <div className="manage-account__card-section mx-0">
        <Card
          topText={topText || 'You have'}
          middleText={middleText}
          bottomText={bottomText || 'remaining in your subscription'}
          cardColour="blue-navy"
          footerText="Manage your subscription"
          modalTarget="manageSubscriptionModal"
          onClick={() => {
            subscriptionDetails({
              sysubs,
              addresses,
              hasManualSubs,
              hasMultiplePaidSubs,
            });
          }}
        />
        <Card
          topText="You have"
          middleText={`${sharedLinksCount} songs`}
          bottomText="shared with students"
          cardColour="cerise-dark"
          footerText="Manage student links"
          modalTarget="manageStudentLinksModal"
        />
        <LastCard onClick={redirectToOota} />
      </div>
    </>
  );
};

export default PrivateUserCardsSection;
