import React from 'react';

const LeaveOrganisationButton = ({ onClick }) => (
  <button
    className="btn button__cerise-dark w-sm-100"
    data-target="#leaveOrganisationModal"
    data-toggle="modal"
    onClick={onClick}
  >
    Leave organization
  </button>
);

export default LeaveOrganisationButton;
