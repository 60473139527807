import React from 'react';
import { albumArtDefault } from '../../../utils/helpers';
import Song from '../Song';

const SongsList = ({ songs, type, loading, esongbookId }) => (
  <div className="songslist__container" style={{ maxWidth: '698px' }}>
    {songs?.length < 1 && !loading && <div>No songs available</div>}
    {[...songs]
      ?.sort((a, b) => a.pivot?.ordering - b.pivot?.ordering)
      .map(({ id, title, duration, albumArt }) => (
        <Song
          key={id}
          id={id}
          title={title}
          // duration={song.audios[0].duration}
          // song id 1956 (and possibly others) is missing audios
          // check if audios array isn't empty before displaying duration
          duration={duration || 0}
          albumArt={albumArt || albumArtDefault}
          type={type}
          songs={songs}
          esongbookId={esongbookId}
        />
      ))}
  </div>
);

export default SongsList;
