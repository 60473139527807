/* eslint-disable react/no-danger */
import React from 'react';

const BannerQuote = ({ title, text, imageUrl, backgroundColor }) => (
  <div className="banner__quote__grid mb-20">
    <img src={imageUrl} alt={title} className="img-fluid" />
    <div
      className="banner__quote__grid__text"
      style={{ background: backgroundColor || '#15a881' }}
    >
      <p className="card__plain__heading mb-4 lh-140">{title}</p>
      <p
        className="summary__text-sm mb-0"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  </div>
);

export default BannerQuote;
