import React from 'react';

const Header = () => (
  <>
    <div className="main__container d-flex align-items-center py-4 px-md-5 mt-0">
      <img
        src="/img/calendar_white.svg"
        alt="calendar icon"
        className="song-calendar__header__icon"
      />
      <span className="song-calendar__header__text">Song Calendar</span>
    </div>

    <div className="main__container bg__cerise-dark px-md-5 song-calendar__header__container song-calendar__banner">
      <span className="song-calendar__header__description">
        Use Sparkyard songs and resources to mark special days and events
        throughout the school year.
      </span>
      <img
        src="/img/song-calendar-banner.png"
        className="img-fluid song-calendar__header__banner"
        alt="banner"
      />
    </div>
  </>
);

export default Header;
