import React from 'react';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import Header from '../../components/ManageAccount/Sections/Header';
import MainSection from '../../components/ManageAccount/Sections/MainSection';

const ManageAccount = () => (
  <section className="main__container">
    <Header />
    <MainSection />
  </section>
);

export default withPageWrapper({ pageTitle: 'Manage Account | Sparkyard' })(
  ManageAccount
);
