/* eslint-disable no-use-before-define */
import { gql } from '@apollo/client';
import {
  ResourceFieldsFragments,
  SongFieldsFragments,
  SparklesFieldsFragment,
} from '../song/fragments';
import { TagFieldsFragment } from '../tag/fragments';

export const MusicCurriculumActivityFieldsFragment = gql`
  fragment MusicCurriculumActivityFields on MusicCurriculumActivity {
    id
    slug
    title
    privateTitle
    content
    active
    activityTags {
      ...TagFields
    }
    sparkles {
      ...SparklesFields
    }
    songs {
      ...SongFields
    }
  }
  ${TagFieldsFragment}
  ${SparklesFieldsFragment}
  ${SongFieldsFragments}
`;

export const CurriculumLessonPlanFieldsFragment = gql`
  fragment CurriculumLessonPlanFields on CurriculumLessonPlan {
    id
    title
    lessonPlanColor
    downloadables
    pickAndMix
    headerImage
    thumbnail
    active
    content
    callout {
      id
      lessonPlanColor
      calloutTitle
      calloutImage
      copy
      buttonText
    }
    lessonPlanTags {
      ...TagFields
    }
    musicCurriculumActivities {
      ...ResourceFields
    }
    step {
      id
      step
      title
      term {
        id
        term
        year {
          id
          year
        }
      }
    }
  }
  ${TagFieldsFragment}
  ${ResourceFieldsFragments}
`;

export const MinCurriculumLessonPlanFieldsFragment = gql`
  fragment MinCurriculumLessonPlanFields on CurriculumLessonPlan {
    id
    title
    lessonPlanColor
    downloadables
    pickAndMix
    headerImage
    thumbnail
    active
    content
    callout {
      id
      lessonPlanColor
      calloutTitle
      calloutImage
      copy
      buttonText
    }
    hasBeenBookmarked
    bookmarkId
    step {
      id
      step
      title
      term {
        id
        term
        year {
          id
          year
        }
      }
    }
  }
`;

export const CurriculumTermStepFieldsFragment = gql`
  fragment CurriculumTermStepFields on CurriculumTermStep {
    id
    step
    title
    lessonPlans {
      ...CurriculumLessonPlanFields
    }
  }
  ${CurriculumLessonPlanFieldsFragment}
`;

export const MinCurriculumTermStepFieldsFragment = gql`
  fragment MinCurriculumTermStepFields on CurriculumTermStep {
    id
    step
    title
    lessonPlans {
      ...MinCurriculumLessonPlanFields
    }
  }
  ${MinCurriculumLessonPlanFieldsFragment}
`;

export const CurriculumTermFieldsFragment = gql`
  fragment CurriculumTermFields on CurriculumTerm {
    id
    term
    title
    description
    steps {
      ...CurriculumTermStepFields
    }
  }
  ${CurriculumTermStepFieldsFragment}
`;

export const MinCurriculumTermFieldsFragment = gql`
  fragment MinCurriculumTermFields on CurriculumTerm {
    id
    term
    title
    description
    steps {
      ...MinCurriculumTermStepFields
    }
  }
  ${MinCurriculumTermStepFieldsFragment}
`;

export const CurriculumFieldsFragment = gql`
  fragment CurriculumFields on Curriculum {
    id
    year
    pdf
    pdfCopy
    adminOnly
    terms {
      ...CurriculumTermFields
    }
  }
  ${CurriculumTermFieldsFragment}
`;

export const MinCurriculumFieldsFragment = gql`
  fragment MinCurriculumFields on Curriculum {
    id
    year
    pdf
    pdfCopy
    adminOnly
    terms {
      ...MinCurriculumTermFields
    }
  }
  ${MinCurriculumTermFieldsFragment}
`;

export const MusicCurriculumOverviewFieldsFragment = gql`
  fragment MusicCurriculumOverviewFields on MusicCurriculumOverview {
    id
    title
    subtitle
    media
    pdf
    pdf2
    howTo
  }
`;
