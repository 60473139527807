/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useHistory } from 'react-router-dom';
import Categories from '../components/Home/Categories/Index';
import SongCards from '../components/Shared/SongCards/Index';
import withPageWrapper from '../components/Shared/HOCs/withPageWrapper';
import useGetRecentlyPlayed from '../hooks/Player/useGetSongs';
import UpcomingEvents from '../components/Home/UpcomingEvents';
import Features from '../components/Home/Features';
import Tips from '../components/Home/Tips';
import SimpleCard from '../components/Shared/SimpleCard';
import Quote from '../components/Shared/Quote';
import useGetHomePage from '../hooks/useGetHomePage';
import Video from '../components/Shared/Video/Video';

const Index = () => {
  const { data: dataRecentlyPlayed } = useGetRecentlyPlayed(2, {
    randomise: true,
  });

  const history = useHistory();

  const {
    data: dataHomePage,
    loading: loadingHomePage,
    features,
    content,
    events,
  } = useGetHomePage();

  if (loadingHomePage) {
    return <p>Loading...</p>;
  }

  const shouldDisplayNewSparks =
    dataHomePage &&
    dataHomePage.getHomepage.newSparks.length >= 1 &&
    dataHomePage.getHomepage.newSparks[0].resources;

  return (
    <>
      <section className="section-home">
        <div className="main__container">
          <div className="mb-40">
            <Categories />
          </div>

          <div className="mb-40">
            <Features features={features} />
          </div>

          <div className="px-xl-0 px-3">
            <div className="row mb-40">
              <div className="col-sm-12 col-md-8">
                <h3 className="mb-20 text-uppercase">Be Inspired</h3>
                {content.map(({ layout, attributes }, index) => {
                  if (!attributes.active) return null;

                  return (
                    <div className="mb-30" key={`content-${index}`}>
                      {layout === 'Blog' ? (
                        <SimpleCard
                          image={attributes.blog_thumbnail}
                          title={attributes.blog_title}
                          description={attributes.blog_description}
                          link={attributes.blog_url}
                        />
                      ) : layout === 'Quote' ? (
                        <Quote
                          songId={attributes.song_id}
                          songs={[attributes.song_id]}
                          image={
                            (attributes && attributes?.image !== 1
                              ? attributes.image
                              : null) || attributes.song_album_art
                          }
                          text={attributes.quote}
                          description={attributes.author}
                          buttonText={attributes.button_copy || ''}
                          buttonLink={
                            attributes.callout_button && attributes.button_url
                              ? attributes.button_url
                              : ''
                          }
                          buttonBgColor={attributes.button_color || ''}
                        />
                      ) : layout === 'video' ? (
                        <Video url={attributes.url} title={attributes.title} />
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="mb-40">
                  <SongCards
                    songs={(dataRecentlyPlayed?.recentlyPlayed?.data || []).map(
                      (elm) => elm.song
                    )}
                    title="Recently Played"
                    name="Recently"
                    type="Recently"
                    limit={2}
                    onTap={() => history.push('/recently-played')}
                  />
                </div>
                <UpcomingEvents events={events} />
              </div>
            </div>
          </div>

          {shouldDisplayNewSparks && (
            <Tips
              datas={(
                dataHomePage.getHomepage.newSparks[0].resources || []
              ).map((resource) => ({
                image: resource.thumbnail,
                title: resource.title,
                description: resource.summary,
                link: `/resources/${resource.slug}`,
              }))}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default withPageWrapper({ pageTitle: 'Home | Sparkyard' })(Index);
