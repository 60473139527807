import React from 'react';
import { Link } from 'react-router-dom';

const MusicCurriculumBanner = () => (
  <Link to="/music-curriculum">
    <div className="curriculum__banner bg__pumpkin text-white">
      <h5 className="curriculum__banner__heading">Music Curriculum</h5>
      <p className="curriculum__banner__text">
        A complete music curriculum with songs, playlists and resources for your
        whole school.
      </p>
      <img
        src="img/music-curriculum.svg"
        className="curriculum__banner__img"
        alt=""
      />
    </div>
  </Link>
);

export default MusicCurriculumBanner;
