/* eslint-disable operator-linebreak */
/* eslint-disable array-callback-return */
import React from 'react';
import TableBody from './TableBody';
import TableHead from './TableHead';

const ColleaguesList = ({ organisation, isAdmin }) => {
  const { members, users, invites, organisationRef, joinRequests } =
    organisation;

  const membersWithSYProfiles = members
    ?.map((member) => {
      const findUser = users.find(
        (user) => user.ootaId === parseInt(member.memberUserRef, 10)
      );

      if (findUser) {
        return {
          ...member,
          userProfile: findUser,
        };
      }
    })
    .filter((item) => item !== undefined);

  const tableClass = 'table table-borderless manage-account__invite-colleagues';

  return (
    <>
      <h5 className="text__fs-18 my-5">Colleague list</h5>
      <table className={tableClass}>
        <TableHead />
        <TableBody
          users={membersWithSYProfiles}
          invites={invites}
          organisationRef={organisationRef}
          joinRequests={joinRequests}
          isAdmin={isAdmin}
        />
      </table>
    </>
  );
};

export default ColleaguesList;
