import { useMutation } from '@apollo/client';
import { GENERATE_PLAYLIST_SHARE_LINK } from '../../graphql/mutations/playlist';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';

const useGeneratePlaylistShareLink = (setGeneratedShareLink) => {
  const [generatePlaylistShareLink, { loading }] = useMutation(
    GENERATE_PLAYLIST_SHARE_LINK,
    {
      onError: () => {
        dismissLoadingMessage();
        displayErrorMessage('Could not generate playlist share link.');
      },
      onCompleted: (res) => {
        setGeneratedShareLink(res.generatePlaylistShareLink);
        dismissLoadingMessage();
        displaySuccessMessage('Playlist share link generated!');
      },
    }
  );

  const generatePlaylistShareLinkRequest = (id) => {
    generatePlaylistShareLink({
      variables: { playlist_id: id },
    });
  };

  loading && displayLoadingMessage();

  return { generatePlaylistShareLinkRequest };
};

export default useGeneratePlaylistShareLink;
