import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { albumArtDefault } from '../../../utils/helpers';

const SongbookItem = ({
  title,
  id,
  src,
  nativitiesAndMusicals,
  large,
  history,
}) => {
  const songbookClassName = `${
    large ? 'col-lg-3 col-md-3' : 'col-lg-2 col-md-4'
  } col-6`;

  const nativitiesClassName = `${
    nativitiesAndMusicals ? 'nativities' : 'song'
  }`;

  return (
    <div className={songbookClassName}>
      <div className={`${nativitiesClassName}__details`}>
        <div className={`${nativitiesClassName}__thumbnail`}>
          <Tappable
            component="a"
            onTap={() => {
              history.push(`/songbook/${id}`);
            }}
          >
            <img
              className={`${nativitiesClassName}__thumbnail__img`}
              src={src || albumArtDefault}
              alt={title}
            />
          </Tappable>
        </div>
        <div className="song__title">&nbsp;</div>
      </div>
    </div>
  );
};

export default withRouter(SongbookItem);
