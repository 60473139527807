/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/naming-convention */
import { useReactiveVar } from '@apollo/client';
import { pick } from 'ramda';
import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import useUploadImage from '../../../../../hooks/Others/useUploadmage';
import useUpdateUser from '../../../../../hooks/User/useUpdateUser';
import { returnNumberRange } from '../../../../../utils/helpers';
import { user } from '../../../../../utils/reactiveVars';
import FormError from '../../../FormError';
import Avatar from './Avatar';
import ModalHeader from './ModalHeader';
import ResetPasswordButton from './ResetPasswordButton';
import SelectedTags from './SelectedTags';
import TagSuggestions from './TagSuggestions';

const EditAccountModal = () => {
  const {
    firstname,
    surname,
    email,
    roles,
    subjects,
    minAge,
    maxAge,
    avatar,
    categories,
    marketingConsent,
    questions,
  } = useReactiveVar(user);

  const [questionsState, setQuestionsState] = useState({});

  const { register, handleSubmit, reset, errors } = useForm();
  const { updateUserRequest } = useUpdateUser();
  const questionsParsed = useMemo(() => JSON.parse(questions), [questions]);

  const defaultState = useMemo(
    () =>
      questionsParsed?.reduce(
        (acc, val) => ({
          ...acc,
          [val.questionRef]: val,
        }),
        {}
      ),
    [questionsParsed]
  );

  useEffect(() => {
    setQuestionsState(defaultState);
  }, [questionsParsed, defaultState]);

  const defaultImage = avatar || '/img/big-avatar.png';
  const { onChange, img } = useUploadImage(defaultImage);

  const handleUpdateUser = (data) => {
    const { min_age, max_age } = data;

    const avatarHasChanged =
      defaultImage !== img.url && img.url !== '/img/big-avatar.png';

    let questionsInput = Object.values(questionsState);

    questionsInput = questionsInput.map((q) => ({
      ...pick(
        ['questionRef', 'type', 'aarAgeFrom', 'aarAgeTo', 'amcAnswers'],
        q
      ),
      questionRef: q.questionRef,
      type: q.type,
      ...(q.amcAnswers && {
        amcAnswers: JSON.stringify(q.amcAnswers),
      }),
    }));

    const input = {
      min_age: parseInt(min_age, 10),
      max_age: parseInt(max_age, 10),
      firstname: data.firstname,
      surname: data.surname,
      email: data.email,
      marketing_consent: data.marketing_consent ? 1 : 0,
      ...(avatarHasChanged && { avatar: img.file }),
      questions: questionsInput,
    };

    updateUserRequest.send(input);
  };

  const handleTagChange = (e, questionRef, answer, action) => {
    e.preventDefault();
    const actionMap = {
      ADD: true,
      REMOVE: false,
    };
    const questionsStateCopy = { ...questionsState };
    questionsStateCopy[questionRef].amcAnswers[answer] = actionMap[action];
    setQuestionsState(questionsStateCopy);
  };

  const handleAgeRangeChange = (e, questionRef, type) => {
    const questionsStateCopy = { ...questionsState };
    questionsStateCopy[questionRef][type] = +e.target.value;
    setQuestionsState(questionsStateCopy);
  };

  return (
    <div
      className="modal fade"
      id="editAccountModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="editAccountModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered custom__modal__edit-account"
        role="document"
      >
        <div className="modal-content custom__modal__edit-account__content">
          <ModalHeader />
          <div className="row">
            <Avatar onChange={onChange} img={img} />
            <div className="col mt-56 mt-md-0">
              <form className="manage-account text__fs-13">
                <div className="row">
                  <div className="form-group col-md-4">
                    <label htmlFor="inputCity">First name</label>
                    <input
                      type="text"
                      className="form-control input__h-54"
                      defaultValue={firstname}
                      name="firstname"
                      ref={register({ required: true })}
                    />
                  </div>
                  {errors.firstname && <FormError text="required" />}

                  <div className="form-group col-md-5">
                    <label htmlFor="inputCity">Surname</label>
                    <input
                      type="text"
                      className="form-control input__h-54"
                      defaultValue={surname}
                      name="surname"
                      ref={register({ required: true })}
                    />
                  </div>
                  {errors.surname && <FormError text="required" />}

                  <ResetPasswordButton />
                </div>

                <div className="form-row mt-20 mt-md-0">
                  <div className="form-group col-md-8">
                    <label htmlFor="inputAddress">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      defaultValue={email}
                      ref={register({ required: true })}
                    />
                  </div>
                </div>
                {errors.email && <FormError text="required" />}
                {questions &&
                  questionsParsed?.map((question) => (
                    <div className="form-group" key={question.questionRef}>
                      <label htmlFor="inputAddress2">
                        {question.reference}
                      </label>
                      {question.type === 'multiple_choice' && (
                        <>
                          <SelectedTags
                            selectedTags={Object.entries(
                              questionsState?.[question.questionRef]
                                ?.amcAnswers || {}
                            )?.filter(([key, value]) => !!value)}
                            handleChange={handleTagChange}
                            questionRef={question.questionRef}
                          />
                          <TagSuggestions
                            tags={Object.keys(
                              questionsState?.[question.questionRef]
                                ?.amcAnswers || {}
                            )}
                            handleChange={handleTagChange}
                            questionRef={question.questionRef}
                          />
                        </>
                      )}
                      {question.type === 'age_range' && (
                        <div
                          className="form-row"
                          style={{ marginBottom: '26px' }}
                        >
                          <div className="form-group col-5 col-md-3">
                            <select
                              className="form-control form-control-lg"
                              name="min_age"
                              ref={register({ required: true })}
                              value={question.aarAgeFrom}
                              onChange={(e) =>
                                handleAgeRangeChange(
                                  e,
                                  question.questionRef,
                                  'aarAgeFrom'
                                )
                              }
                            >
                              {returnNumberRange(
                                question.aarAgeMin,
                                question.aarAgeMax
                              ).map((num) => (
                                <option value={num} key={num}>
                                  {num}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group col-5 col-md-3">
                            <select
                              className="form-control form-control-lg"
                              name="max_age"
                              ref={register({ required: true })}
                              value={question.aarAgeTo}
                              onChange={(e) =>
                                handleAgeRangeChange(
                                  e,
                                  question.questionRef,
                                  'aarAgeTo'
                                )
                              }
                            >
                              {returnNumberRange(
                                question.aarAgeMin,
                                question.aarAgeMax
                              ).map((num) => (
                                <option value={num} key={num}>
                                  {num}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                <div className="form-group checkbox__white">
                  <input
                    type="checkbox"
                    id="marketing_consent"
                    name="marketing_consent"
                    ref={register({ required: false })}
                    defaultChecked={marketingConsent}
                  />
                  <label htmlFor="marketing_consent" className="text-white">
                    Would you like to sign up to our Sparkyard email mailing
                    list to receive monthly tips, ideas and suggestions for
                    making the most out of your subscription? You can change
                    your settings here at any time.{' '}
                    <a href="https://www.sparkyard.com/privacy-notice">
                      Visit our Privacy Notice.
                    </a>
                  </label>
                </div>
              </form>
            </div>
          </div>

          <div className="mt-auto d-flex justify-content-between">
            <button
              onClick={() => {
                reset(user);
                setQuestionsState(defaultState);
              }}
              data-dismiss="modal"
              className="btn button__green-meadow button__w-144 button__transparent__green border__green"
            >
              Cancel
            </button>
            <button
              className="btn button__green-meadow button__w-144"
              onClick={handleSubmit(handleUpdateUser)}
            >
              Update details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAccountModal;
