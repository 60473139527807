import { gql } from '@apollo/client';

export const EsongbookResourceFieldsFragments = gql`
  fragment EsongbookResourceFields on EsbResource {
    id
    ordering
    type
    title
    linkUrl
    pdfFile
    pdfFileMd5
    pdfFileSize
    sfxFile
    sfxFileMd5
    sfxFileSize
  }
`;

export const EsongbookFieldsFragments = gql`
  fragment EsongbookFields on Esongbook {
    id
    infoName
    infoAuthor
    seriesId
    imageFilename
    imageFileOriginal
    imageFileLarge
    imageFileMedium
    albumArt
    infoCategory
    linkToOota
    esongbookId
    excludeResources
    resources {
      ...EsongbookResourceFields
    }
    songs {
      id
      title
      author
      age
      albumArt
      duration
      pivot {
        ordering
      }
    }
  }
  ${EsongbookResourceFieldsFragments}
`;

export const MinEsongbookFieldsFragments = gql`
  fragment MinEsongbookFields on Esongbook {
    id
    infoName
    infoAuthor
    seriesId
    seriesName
    imageFilename
    imageFileOriginal
    imageFileLarge
    imageFileMedium
    albumArt
    infoCategory
    linkToOota
    resources {
      ...EsongbookResourceFields
    }
  }
  ${EsongbookResourceFieldsFragments}
`;
