import { gql } from '@apollo/client';
import { MinSongFieldsFragment } from '../../queries/song/fragments';
import { MinResourceFragmentFields } from '../../queries/resource/fragments';
import { MinAssemblyFieldsFragment } from '../../queries/assembly/fragments';

export const ADD_SONG_TO_FAVOURITES = gql`
  mutation AddSongToFavourite($input: AddToFavouriteInput!) {
    addSongToFavourite(input: $input) {
      ...MinSongFields
    }
  }
  ${MinSongFieldsFragment}
`;

export const ADD_RESOURCE_TO_FAVOURITES = gql`
  mutation AddResourceToFavourite($input: AddToFavouriteInput!) {
    addResourceToFavourite(input: $input) {
      ...MinResourceFields
    }
  }
  ${MinResourceFragmentFields}
`;

export const ADD_ASSEMBLY_TO_FAVOURITES = gql`
  mutation AddAssemblyToFavourites($input: AddToFavouriteInput!) {
    addAssemblyToFavourites(input: $input) {
      ...MinAssemblyFields
    }
  }
  ${MinAssemblyFieldsFragment}
`;

export const REMOVE_SONG_FROM_FAVOURITES = gql`
  mutation RemoveSongFromFavourite($input: RemoveFavouriteInput!) {
    removeSongFromFavourite(input: $input) {
      ...MinSongFields
    }
  }
  ${MinSongFieldsFragment}
`;

export const REMOVE_RESOURCE_FROM_FAVOURITES = gql`
  mutation RemoveResourceFromFavourite($input: RemoveFavouriteInput!) {
    removeResourceFromFavourite(input: $input) {
      ...MinResourceFields
    }
  }
  ${MinResourceFragmentFields}
`;

export const REMOVE_ASSEMBLY_FROM_FAVOURITES = gql`
  mutation RemoveAssemblyFromFavourites($input: RemoveFavouriteInput!) {
    removeAssemblyFromFavourites(input: $input) {
      ...MinAssemblyFields
    }
  }
  ${MinAssemblyFieldsFragment}
`;

export const UPDATE_FAVOURITES = gql`
  mutation UpdateFavourites($input: UpdateFavouritesInput!) {
    updateFavourites(input: $input)
  }
`;

export const UPDATE_FAVOURITE_SONGS_ORDERING = gql`
  mutation UpdateFavouriteSongsOrdering(
    $id: ID!
    $input: UpdateFavouriteSongsOrderingInput!
  ) {
    updateFavouriteSongsOrdering(id: $id, input: $input) {
      id
    }
  }
`;
