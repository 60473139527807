/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import useRenderResource from '../../../../hooks/Resource/useRenderResource';
import useRenderSparkles from '../../../../hooks/useRenderSparkles';
import { currentResource } from '../../../../utils/reactiveVars';
import { getResourceTags } from '../../../../utils/resource';
import TagsModule from '../../../Song/Sections/SongsPageContent/Sections/MusicActivity/TagsModule';

const MainSection = () => {
  const { content, resourceTags, sparkles, category } = currentResource();
  const parsedContent = JSON.parse(content);
  const { render } = useRenderResource();

  const isBlogOrGuide = () =>
    category.name === 'Blog' || category.name === 'Guide';

  const shouldDisplayTag = ({ type, formattedName }) =>
    type === 'Curriculum Subject Links' &&
    !(category?.name === 'SEND Resource' && formattedName === 'SEND');

  return (
    <div className="resource__container">
      {!isBlogOrGuide && (
        <div
          className="card__plain__header resource-header"
          style={{ background: category?.color || '#8dc261' }}
        >
          <p className="card__plain__heading mb-0">{category?.name}</p>
        </div>
      )}

      {resourceTags?.find(
        (item) => item.type === 'Curriculum Subject Links'
      ) && (
        <div className="card__plain mb-0">
          <div className="card__plain__body plus-padding bg__white pt-4 pb-0">
            {resourceTags?.map(
              (tag) =>
                shouldDisplayTag(tag) && (
                  <div className="tag mb-23 mr-2 d-inline-block" key={tag.id}>
                    {tag.formattedName}
                  </div>
                )
            )}
          </div>
        </div>
      )}
      <div className="card__plain mb-0">
        <div
          className={`card__plain__body plus-padding bg__white ${
            isBlogOrGuide() ? 'pt-0' : 'pt-4'
          }`}
          style={{ padding: '40px 32px' }}
        >
          {parsedContent.map((resource) => render(resource))}
        </div>
      </div>

      <div className="card__plain mb-20">
        <div className="card__plain__body plus-padding bg__white pt-4 pb-1">
          <TagsModule
            title="Inter-related Dimensions of Music"
            tags={getResourceTags(resourceTags, 'Music Checklist')}
          />
          <TagsModule
            title="National Curriculum coverage for Music"
            tags={getResourceTags(
              resourceTags,
              'National Music Curriculum descriptor'
            )}
          />
        </div>
      </div>

      {sparkles?.map((sparkle) => (
        <React.Fragment key={sparkle.id}>
          {useRenderSparkles(sparkle)}
        </React.Fragment>
      ))}
    </div>
  );
};

export default MainSection;
