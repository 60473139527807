/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { openTabType } from '../../../../../utils/reactiveVars';
import LinkGeneric from './Lists/LinkGeneric';
import LinkGenericMobile from './Lists/LinkGenericMobile';
import MyFavourites from './Lists/MyFavourites';
// import Resources from './Lists/Resources';
import SongsAndPlaylist from './Lists/SongsAndPlaylist';
import TreatBox from './Lists/TreatBox';

const SidebarList = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('favourite')) {
      openTabType('favourite');
    } else if (location.pathname.includes('/songs')) {
      openTabType('songs');
    } else openTabType('');
  }, []);

  return (
    <ul className="sidebar__list">
      <LinkGeneric iconUrl="home-icon.svg" text="Home" route="/" />
      <SongsAndPlaylist />
      {/* <Resources /> */}
      <MyFavourites />
      <LinkGeneric
        iconUrl="assemblies.svg"
        text="Assembly Plans"
        route="/assembly-plans"
      />
      <LinkGeneric
        iconUrl="book-icon.svg"
        text="Music Curriculum"
        route="/music-curriculum"
      />
      <LinkGeneric
        iconUrl="collection-icon.svg"
        text="Collections"
        route="/collections"
      />
      <LinkGeneric
        iconUrl="calendar-icon.svg"
        text="Song Calendar"
        route="/song-calendar"
      />
      <TreatBox />
      {/* <LinkGenericMobile iconUrl="help-icon.svg" text="Help centre" route="/" /> */}
      <li className="sidebar__list__item">
        <a
          href="https://sparkyard.zendesk.com/hc/en-gb"
          target="_blank"
          rel="noreferrer"
          className="sidebar__list__item__link"
        >
          <img src="/img/help-icon.svg" alt="Help centre" />
          <p>Help centre</p>
        </a>
      </li>
      <LinkGenericMobile
        iconUrl="cart-icon.svg"
        text="Buy songs and songbooks"
        route="https://outoftheark.co.uk"
      />
      <LinkGenericMobile
        iconUrl="settings-icon.svg"
        text="Manage account"
        route="/manage-account"
      />
      <LinkGenericMobile
        iconUrl="signout-icon.svg"
        text="Sign out"
        route="/signout"
      />
    </ul>
  );
};
export default SidebarList;
