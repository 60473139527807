import React from 'react';
import ModalHeader from './ModalHeader';
import TableBody from './TableBody';
import TableHead from './TableHead';

const ManageStudentLinksModal = ({ sharedLinks }) => {
  const tableClass = 'table table-borderless manage-account__invite-colleagues';

  return (
    <div
      className="modal fade"
      id="manageStudentLinksModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="manageStudentLinksModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered custom__modal__invite-colleagues"
        role="document"
      >
        <div className="modal-content custom__modal__leave-organization__content">
          {sharedLinks && (
            <>
              <ModalHeader sharedLinksCount={sharedLinks?.length || 0} />
              <table className={tableClass}>
                <TableHead />
                <TableBody sharedLinks={sharedLinks} />
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageStudentLinksModal;
