/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';
import { albumArtDefault } from '../../utils/helpers';
import Playlist from './Playlist';

const PlaylistListContent = ({
  playlists,
  type,
  disableDeletePlaylist,
  sharedWithUser,
  orderingFunction,
}) => {
  const [localPlaylists, setLocalPlaylists] = useState([]);

  useEffect(() => {
    setLocalPlaylists(
      [...(playlists || [])]?.sort((a, b) => a.ordering - b.ordering)
    );
  }, [playlists]);

  const handleOnDragEnd = (result) => {
    const items = Array.from(localPlaylists);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setLocalPlaylists(items);
    orderingFunction(
      items.map((item, index) => ({ id: item.id, ordering: index + 1 }))
    );
  };

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable-playlists">
          {(provided) => (
            <div ref={provided.innerRef}>
              {localPlaylists?.map(
                (
                  {
                    id,
                    songs,
                    name,
                    isUserPlaylist,
                    hasSharedWithOrganisation,
                    shareId,
                  },
                  index
                ) => (
                  <Draggable
                    draggableId={id}
                    index={index}
                    key={id}
                    isDragDisabled={!orderingFunction}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        snapshot={snapshot}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Playlist
                          key={id}
                          albumArtDefault={albumArtDefault}
                          songs={songs}
                          id={id}
                          disableDeletePlaylist={disableDeletePlaylist}
                          isUserPlaylist={isUserPlaylist}
                          sharePlaylist={{ hasSharedWithOrganisation, shareId }}
                          name={name}
                          type={type}
                          sharedWithUser={sharedWithUser}
                          showDragIcon={!!orderingFunction}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const PlaylistList = (props) => {
  if (props.playlists?.length < 1) return null;

  return <PlaylistListContent {...props} />;
};

export default PlaylistList;
