import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_FEATURED_ASSEMBLIES } from '../../graphql/queries/assembly/assembly';

const useGetFeaturedAssemblies = () => {
  const { data, loading, error } = useQuery(GET_FEATURED_ASSEMBLIES, {
    onError: () => {
      toast.error('Could not load featured assemblies', { toastId: 'error' });
    },
    onCompleted: () => {
      toast.dismiss('loading');
    },
  });

  return { data, loading, error };
};

export default useGetFeaturedAssemblies;
