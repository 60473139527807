/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import LinkedAssemblies from '../../components/Song/Sections/SongsPageContent/Sections/LinkedAssemblies/Index';
import useGetEvent from '../../hooks/useGetEvent.js';
import ResourceHeader from '../../components/Shared/ResourceHeader';
import useRenderResource from '../../hooks/Resource/useRenderResource';
import TagsModule from '../../components/Song/Sections/SongsPageContent/Sections/MusicActivity/TagsModule';
import CategoryHeader from '../../components/Shared/CategoryHeader';
import useRenderSparkles from '../../hooks/useRenderSparkles';
import EventSongCards from '../../components/Shared/EventSongCards/Index';
import Header from '../../components/SongCalendar/EventPage/Sections/Header';
import { formatResourceContent, getResourceTags } from '../../utils/resource';
import { displayLoadingMessage } from '../../utils/helpers';
import LoadJquery from '../../components/Shared/LoadJquery';

const Event = () => {
  const { slug } = useParams();
  const { loading, data } = useGetEvent(slug);
  const { render } = useRenderResource();

  useEffect(() => {
    document.title = `${data?.event?.title || ''} | Song Calendar | Sparkyard`;
  }, [data]);

  loading && displayLoadingMessage('Loading event...');

  if (data?.event === null) return <Redirect to="/404" />;
  const downloadablesArr = [];

  data?.event?.resources?.map((resource) =>
    JSON.parse(resource.downloadables)?.map((downloadable) =>
      downloadablesArr.push(downloadable)
    )
  );

  return (
    <>
      {data?.event?.id && (
        <>
          <LoadJquery />
          <Header
            title={data.event.title}
            description={data.event.description}
            startDate={data.event.startDate}
            endDate={data.event.endDate}
            resource={{
              resourceTags: [],
              downloadables: JSON.stringify([
                ...JSON.parse(data.event.downloadables),
                ...downloadablesArr,
              ]),
            }}
            id={data.event.id}
          />
          <div className="bg__dark-grayish-blue main__container mt-0">
            <div className="mt-5 p-3 mt-md-0 p-md-0" />
            <EventSongCards
              title="Song choice"
              songs={data.event.songs}
              pageTitle={data.event.title}
            />
          </div>

          <div className="main__container bg__dark-desaturated-blue mt-0 py-1">
            <div className="cp__container">
              <LinkedAssemblies assemblyPlans={data.event.assemblyPlans} />
            </div>
          </div>

          <div className="mt-n24" />
          <div className="main__container bg__dark-desaturated-blue mt-0 py-5">
            <div className="cp__container">
              {data?.event.resources.length > 0 && (
                <>
                  <CategoryHeader
                    title={data.event.title}
                    backgroundColor="#3d82ba"
                  />
                  <div
                    className="card__plain__body plus-padding bg__white"
                    style={{ padding: '40px 32px' }}
                  >
                    {data?.event.resources?.map((resource, index) => (
                      <React.Fragment key={resource.id}>
                        <ResourceHeader
                          title={resource.title}
                          tags={resource.resourceTags}
                          id={resource.id}
                        />
                        {formatResourceContent(resource.content)?.map(
                          (item) => (
                            <React.Fragment key={item.key}>
                              {render(item)}
                            </React.Fragment>
                          )
                        )}
                        <TagsModule
                          title="Inter-related Dimensions of Music"
                          tags={getResourceTags(
                            resource.resourceTags,
                            'Music Checklist'
                          )}
                        />
                        <TagsModule
                          title="National Curriculum coverage for Music"
                          tags={getResourceTags(
                            resource.resourceTags,
                            'National Music Curriculum descriptor'
                          )}
                        />
                        {index + 1 !== data.event.resources.length && (
                          <hr className="hr__lime my-4" />
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </>
              )}

              <div className="mb-20" />
              {data.event.sparkles?.map((sparkle) => (
                <React.Fragment key={sparkle.id}>
                  {useRenderSparkles(sparkle)}
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withPageWrapper({})(Event);
