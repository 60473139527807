import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { songbookList } from '../../../utils/reactiveVars';
import ContentHereSquare from '../ContentHereSquare';
import SongbookItem from './SongbookItem';

const Songbooks = ({
  songbooks,
  title,
  collection,
  type,
  nativitiesMusicals,
  history,
}) => {
  if (!songbooks || songbooks?.length < 1) return null;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-20 px-xl-0">
        <p className="heading__section text-uppercase">
          {title || 'Songbooks'}
        </p>
        <Tappable
          component="a"
          className="text__white text__fs-10"
          onClick={() => {
            songbookList({ songbooks, type });
            history.push('/see-all/songbooks');
          }}
        >
          SEE ALL
        </Tappable>
      </div>
      <div className="px-xl-0">
        {collection ? (
          <div className="row mb-20">
            {songbooks
              ?.slice(0, 5)
              .map(({ id, albumArt, infoName, imageFileMedium }) => (
                <SongbookItem
                  key={id}
                  id={id}
                  title={infoName}
                  src={albumArt}
                  imgMedium={imageFileMedium}
                />
              ))}
            <ContentHereSquare />
          </div>
        ) : (
          <div className="row mb-20">
            {songbooks
              ?.slice(0, 6)
              .map(({ id, albumArt, infoName, imageFileMedium }) => (
                <SongbookItem
                  key={id}
                  id={id}
                  title={infoName}
                  src={albumArt}
                  imgMedium={imageFileMedium}
                  nativitiesAndMusicals={nativitiesMusicals}
                  large={nativitiesMusicals}
                />
              ))}
          </div>
        )}
      </div>
    </>
  );
};
export default withRouter(Songbooks);
