import React from 'react';
import { toast } from 'react-toastify';
import useGetCategoryPage from '../../../../../hooks/useGetCategoryPage';
import GuidesAndResources from '../../../../Shared/GuidesAndResources';
import PlaylistCards from '../../../../Shared/PlaylistCards';
import SongCards from '../../../../Shared/SongCards/Index';
import VideoCards from '../../../../Shared/Video/VideoCards';
// import Treatbox from './TreatBox';

const MainSection = ({
  songs,
  resources,
  displayResources = true,
  displaySongs = true,
  displayPlaylists = true,
  playlists,
  filterParams,
}) => {
  const { data, loading } = useGetCategoryPage('sing for fun');
  loading && toast.info('Loading...', { toastId: 'loading' });

  return (
    <section className="main__container mt-40">
      <div className="px__sm-3">
        {/* <Treatbox /> */}
        {displaySongs && (
          <SongCards
            title="Sing for Fun Songs"
            songs={songs}
            pageTitle="Sing for Fun Songs"
            name="Just for Fun"
            type="OOTA Singing School"
            filterParams={filterParams}
          />
        )}
        {displayResources && (
          <GuidesAndResources
            title="Just for Fun"
            resources={resources}
            type="OOTA Singing School"
          />
        )}
        {displayPlaylists && (
          <PlaylistCards
            playlists={playlists}
            title="sing for fun playlists"
            disableDeletePlaylist
          />
        )}
        <VideoCards
          videos={JSON.parse(data?.categoryPage?.videos || '[]')}
          title="sing for fun videos"
        />
      </div>
    </section>
  );
};

export default MainSection;
