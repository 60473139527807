/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from '@apollo/client';
import { LOGIN_USER } from '../../graphql/mutations/auth';
import {
  dismissLoadingMessage,
  displayErrorMessage,
} from '../../utils/helpers';
import { sparkyardLoginUrl } from '../../utils/objects';
import { user } from '../../utils/reactiveVars';

const useLoginUser = (code) => {
  const [loginUser, { loading, data }] = useMutation(LOGIN_USER);

  const loginRequest = {
    send: async () => {
      await loginUser({
        variables: {
          input: { code },
        },
        context: {
          headers: {
            // forceRefresh header is needed so the server knows not to use redis but instead
            // make request to OOTA to get fresh token
            forceRefresh: true,
          },
        },
      })
        .then((res) => {
          dismissLoadingMessage();
          user(res.data?.loginUser);
          localStorage.setItem('user', JSON.stringify(res.data?.loginUser));
        })
        // eslint-disable-next-line no-unused-vars
        .catch((e) => {
          dismissLoadingMessage();
          displayErrorMessage('We are unable to log you in at this time.');
          // window.location.href = sparkyardLoginUrl;
        });
    },
  };

  return { loginRequest, loading, data };
};

export default useLoginUser;
