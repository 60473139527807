/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable operator-linebreak */
import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import useGetCollection from '../../hooks/Collection/useGetCollection';
import { organisationLoggedInAs } from '../../utils/reactiveVars';
import CollectionComponent from '../../components/Collection/Sections/Collection';

const Collection = () => {
  const { id } = useParams();
  const organisationRef = useReactiveVar(organisationLoggedInAs)?.id;

  const { data } = useGetCollection(id, organisationRef);

  useEffect(() => {
    document.title = `${data?.collection?.title || ''} | Resource | Sparkyard`;
  }, [data]);

  if (data?.collection === null) return <Redirect to="/404" />;

  return (
    <>
      {data?.collection && (
        <>
          <CollectionComponent collection={data.collection} />
        </>
      )}
    </>
  );
};

export default withPageWrapper({})(Collection);
