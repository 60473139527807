import React from 'react';

const Tag = ({ type, formattedName }) => (
  <>
    {type === 'Curriculum Subject Links' ? (
      <div className="tag d-inline-block mr-2 mb-1 text-white">{formattedName}</div>
    ) : null}
  </>
);

export default Tag;
