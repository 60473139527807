import { gql } from '@apollo/client';
import { RecentlyPlayedFragment } from './fragments';

export const GET_RECENNTLY_PLAYED = gql`
  query RecentlyPlayed($first: Int!, $page: Int) {
    recentlyPlayed(first: $first, page: $page) {
      data {
        ...RecentlyPlayedFields
      }
      paginatorInfo {
        currentPage
        lastPage
        count
        hasMorePages
      }
    }
  }
  ${RecentlyPlayedFragment}
`;
