/* eslint-disable arrow-body-style */
import React from 'react';
import SongbookItem from './SongbookItem';

const SongbookSeries = ({ title, songbooks, onClick, showModalContent }) => (
  <>
    <div>
      <span className="text__fs-18 pt-4" id={title}>
        {title}
      </span>
      {showModalContent && (
        <a
          href="#"
          data-target="#songbookSeriesModal"
          data-toggle="modal"
          onClick={() => onClick()}
        >
          <img
            src="/img/help_icon.svg"
            alt="info"
            className="ml-3 d-inline-block"
          />
        </a>
      )}
    </div>
    <div className="row mb-10 mt-4">
      {songbooks?.map(({ id, albumArt, infoName }) => (
        <SongbookItem key={id} id={id} title={infoName} src={albumArt} />
      ))}
    </div>
  </>
);

export default SongbookSeries;
