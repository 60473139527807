/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState } from 'react';
import useFileStats from '../../hooks/Others/useFileStats';
import { currentSong } from '../../utils/reactiveVars';
import { getResourceTags } from '../../utils/resource';
import Downloadable from './Downloadable';
import TagRow from './TagRow';

const DownloadsAndAboutDropdown = () => {
  const [isActive, setIsActive] = useState(false);
  const { lyrics, instrumentalResource, age, songTags, resources, score, id } =
    currentSong();

  const downloadablesArr = [];
  resources?.map((resource) =>
    JSON.parse(resource.downloadables)?.map((downloadable) =>
      downloadablesArr.push(downloadable)
    )
  );

  const { createFileStatRequest } = useFileStats();

  const stat = {
    song_id: id,
    event_type: 'OpenedSyResourcePDF',
  };

  return (
    <div className={`songpage__item__about ${isActive && 'active'}`}>
      <a
        href="#"
        className="songpage__item__about__cta"
        onClick={() => setIsActive(!isActive)}
      >
        <p>Downloads &amp; About</p>
        <i className="fa fa-chevron-down" />
      </a>
      <div className="songpage__item__about__dropdown">
        <div className="songpage__item__about__dropdown__grid">
          <div>
            <p className="text__fs-12 text__fw-600 mb-0">Lyrics</p>
          </div>
          <div>
            {lyrics ? (
              <a
                href={lyrics}
                className="font-weight-light text__white d-flex align-items-center"
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  createFileStatRequest({
                    ...stat,
                    event_type: 'OpenedSongLyricsPDF',
                  })
                }
              >
                <i className="fas fa-cloud-download-alt mr-3" />
                <u className="text__fs-11 mt-1">Download PDF</u>
              </a>
            ) : (
              <div className="text__fs-11">N/A</div>
            )}
          </div>
          <div>
            <p className="text__fs-12 text__fw-600 mb-0">Score</p>
          </div>
          <div>
            {score ? (
              <a
                href={score}
                className="font-weight-light text__white d-flex align-items-center"
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  createFileStatRequest({
                    ...stat,
                    event_type: 'OpenedSongScorePDF',
                  })
                }
              >
                <i className="fas fa-cloud-download-alt mr-3" />
                <u className="text__fs-11 mt-1">Download PDF</u>
              </a>
            ) : (
              <div className="text__fs-11">N/A</div>
            )}
          </div>
          <div>
            <p className="text__fs-12 text__fw-600 mb-0">
              Instrumental Resource
            </p>
          </div>
          <div>
            {instrumentalResource ? (
              <a
                href={instrumentalResource}
                className="font-weight-light text__white d-flex align-items-center"
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  createFileStatRequest({
                    ...stat,
                    event_type: 'OpenedSongInstrumentalResourcePDF',
                  })
                }
              >
                <i className="fas fa-cloud-download-alt mr-3" />
                <u className="text__fs-11 mt-1">Download PDF</u>
              </a>
            ) : (
              <div className="text__fs-11">N/A</div>
            )}
          </div>
        </div>
        <hr className="hr__white my-3" />
        <div className="songpage__item__about__dropdown__grid">
          <div>
            <p className="text__fs-12 text__fw-600 mb-0">Age</p>
          </div>
          <div>
            <a
              href=""
              className="font-weight-light text__white d-flex align-items-center"
            >
              <u className="text__fs-11">{age || 'N/A'}</u>
            </a>
          </div>
          <TagRow
            title="Sparkyard Category"
            tags={getResourceTags(songTags, 'OOTA Singing School')}
          />
          <TagRow
            title="Song Type"
            tags={getResourceTags(songTags, 'Song Type')}
          />
          <TagRow
            title="Musical Style"
            tags={getResourceTags(songTags, 'Musical Style')}
          />
          <TagRow
            title="Vocal Range/Description"
            tags={[
              ...getResourceTags(songTags, 'Vocal Range'),
              ...getResourceTags(songTags, 'Vocal Range Description'),
            ]}
          />
          <TagRow
            title="Starting Key"
            tags={getResourceTags(songTags, 'Starting Key')}
          />
          <TagRow
            title="Time Signature"
            tags={getResourceTags(songTags, 'Time Signature')}
          />
          <TagRow
            title="Subject"
            tags={getResourceTags(songTags, 'Curriculum Subject Links')}
          />
          <TagRow
            title="Topic / Theme"
            tags={getResourceTags(songTags, 'Topic/Theme')}
          />
          <TagRow
            title="Seasonal"
            tags={getResourceTags(songTags, 'Seasonal')}
          />
          <TagRow
            title="Values"
            tags={getResourceTags(songTags, 'Values (PSHE, SMSC)')}
          />
        </div>
        <hr className="hr__white my-3" />
        {downloadablesArr.length > 0 && (
          <>
            <div className="text__fs-14 my-5">Resources</div>
            <div className="songpage__item__about__dropdown__grid">
              {downloadablesArr?.map((downloadable, i) => (
                <Downloadable
                  key={`${downloadable.key}-${i}`}
                  text={downloadable.attributes.text}
                  link={downloadable.attributes.pdf}
                  pdfId={downloadable.attributes.pdfId}
                  stat={stat}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DownloadsAndAboutDropdown;
