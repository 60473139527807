/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable react/no-array-index-key */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-return-assign */
/* eslint-disable operator-linebreak */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { decode } from 'html-entities';
import { Text } from 'react-konva';
import html from 'html-parse-stringify';
import Konva from 'konva';
import { Line as LineType, Phrase } from '../types';

type Props = LineType & {
  position: number;
  yPosition: number;
  highlightedPhrases: Phrase[];
  onClick: (phrase: Phrase) => void;
  onDblClick: (phrase: Phrase) => void;
  isPlaying: boolean;
  infoName: string;
  newPhraseID: number;
  plainTextView: boolean;
  fontFamily: string;
  fontSize: number;
  textPrimaryColor: string;
  textHighlightColor: string;
  totalLineCount: number;
  highlightInfoName: boolean;
};

const Line = ({
  yPosition,
  phrases,
  highlightedPhrases,
  onClick,
  onDblClick,
  isPlaying,
  infoName,
  position,
  newPhraseID,
  fontFamily,
  fontSize,
  textPrimaryColor,
  textHighlightColor,
  plainTextView,
  totalLineCount,
  highlightInfoName,
}: Props): JSX.Element => {
  const doubleClick = useRef<NodeJS.Timeout | null>(null);
  const infoNameRef = useRef<Konva.Text>(null);
  const itemEls = useRef<{ [phraseId: number]: Konva.Text[] }>({});

  const getClickHandler = (phrase: Phrase) => {
    // doubleClick.current is null
    // if user hasn't done a first click or if user completes a doubleclick
    if (!doubleClick.current) {
      // global.setTimeout tells typescript *not* node.setTimeout (different return types)
      doubleClick.current = global.setTimeout(() => {
        // Cancellable timeout allows user to initiate double click
        onClick(phrase);
        doubleClick.current = null;
      }, 250);
    } else {
      // if user double clicks this will enter
      clearTimeout(doubleClick.current);
      doubleClick.current = null;
      onDblClick(phrase);
    }
  };

  const handleFill = (phrase: Phrase) => {
    const isHighlitablePhrase = highlightedPhrases.some(
      (phraseItem: Phrase) => phraseItem.id === phrase.id
    );

    const fillColour =
      isHighlitablePhrase && phrase.isHighlightable && isPlaying
        ? textHighlightColor
        : textPrimaryColor;

    // if (newPhraseID > 0) {
    //   console.log(newPhraseID,  phrase.id)
    //   // if double clicking prevents highlight flash
    //   fillColour =
    //     newPhraseID === phrase.id ? textHighlightColor : textPrimaryColor;
    // }
    return fillColour;
  };

  type SectionType = {
    type: string;
    children: {
      name: string | undefined;
      children: { name: string | number; content: string }[];
      content: string;
      type: string;
    }[];
    name: keyof {
      small: number | string;
      large: number | string;
      italic: string;
      bold: string;
    };
    content: string;
  };

  type Child = {
    type: string;
    name: string | undefined;
    content: string | undefined;
    children: { content: string | undefined; name: string }[];
  };

  const parseHTML = (text: string) => html.parse(decode(text));

  const isLarge = (section: SectionType) => section.name === 'large';

  const isInstructionalText = (section: SectionType, isClickable: boolean) => {
    if (isClickable) return false;
    if (section.name === 'small') {
      return true;
    }

    return section?.children?.[0]?.name === 'small';
  };

  const getFontStyle = (section: SectionType | Child) => {
    if (section.name === 'italic' || section.name === 'bold') {
      return section.name;
    }
    if (
      section?.children?.[0]?.name === 'italic' ||
      section?.children?.[0]?.name === 'bold'
    ) {
      return section?.children?.[0]?.name;
    }
    return '';
  };

  const getFontSize = (section: SectionType): number => {
    if (section.type !== 'tag' || (plainTextView && section.name !== 'italic'))
      return fontSize;

    const sizeObj: {
      small: number;
      large: number;
      italic?: number;
    } = {
      small: fontSize / 1.6,
      large: fontSize + 8,
      italic: fontSize,
    };

    if (section.name === 'small' || section.name === 'large') {
      return sizeObj[section.name] || fontSize;
    }

    if (section?.children?.[0]?.name) {
      return sizeObj[section?.children?.[0]?.name];
    }

    return fontSize;
  };

  let currentElementPositionInRef = 0;

  const getXPosition = (
    phraseId: number,
    phraseIdx: number,
    sectionIdx: number
  ) => {
    const xOffset = totalLineCount > 10 ? 162 : 190;
    const isNonFirstPhrase = phraseIdx !== 0 && phrases.length > 1;
    let xPosition = infoName.length > 3 ? xOffset : 60.54;

    const sumWidths = (a: number) => {
      xPosition += a;
    };

    let lastPhraseWidth = 0;

    if (isNonFirstPhrase && itemEls.current?.[phraseId]) {
      // flatten the array of arrays (gotten through object.values()) of sections
      // look for the section index in the flatten array and calculate text width
      // up to that point
      const flattenedRef = Object.values(itemEls.current).flat();

      let charLength = 0;

      const elId = itemEls.current?.[phraseId]?.[sectionIdx]?._id;
      const elIdxInFlattenedRef = flattenedRef.findIndex(
        (item) => item?._id === elId
      );

      for (let i = 0; i < elIdxInFlattenedRef; i++) {
        charLength += flattenedRef?.[i]?.size().width;
      }
      lastPhraseWidth = charLength + xPosition;
    }
    if (itemEls.current?.[phraseId]) {
      const arrCopy = [...itemEls.current[phraseId]];

      arrCopy
        .splice(0, currentElementPositionInRef)
        .forEach((el: Konva.Text) => sumWidths(el?.size().width));
      currentElementPositionInRef += 1;
    }

    return isNonFirstPhrase
      ? lastPhraseWidth + phraseIdx * 20
      : phraseIdx * 33 + xPosition;
  };

  const getYposition = (section: SectionType, isClickable: boolean) => {
    if (isLarge(section) && !plainTextView) return yPosition - 5;
    if (isInstructionalText(section, isClickable)) return yPosition + 10;
    return yPosition;
  };

  const setCursorType = (isClickable: boolean) => {
    document.body.style.cursor = isClickable ? 'pointer' : 'default';
  };

  const renderSection = (
    section: SectionType,
    phrase: Phrase,
    phraseIdx: number,
    sectionIdx: number
  ) => {
    const handleElementPush = (element: Konva.Text) => {
      const elementId = element?._id;
      const phraseInRef = itemEls?.current?.[phrase.id];

      if (elementId) {
        const elementExistsInRef = phraseInRef?.find(
          (item) => item?._id === elementId
        );
        if (!elementExistsInRef) {
          phraseInRef?.push(element);
        }
      }
    };

    const handleRef = (element: Konva.Text) => {
      // create a new key in ref with phrase id as key with a value of an array
      // of elements if key does not already exist
      // check if element exists in array before pushing
      const phraseInRef = itemEls?.current?.[phrase.id];
      if (phraseInRef) {
        handleElementPush(element);
      } else {
        itemEls.current[phrase.id] = [element];
      }
    };

    if (section.type !== 'tag') {
      return (
        <Text
          key={sectionIdx}
          ref={(element: Konva.Text) => {
            if (sectionIdx !== 0) {
              handleElementPush(element);
            } else {
              itemEls.current[phrase.id] = [element];
            }
          }}
          y={isLarge(section) && !plainTextView ? yPosition - 5 : yPosition}
          x={getXPosition(phrase.id, phraseIdx, sectionIdx)}
          wrap="word"
          text={section.content}
          fill={handleFill(phrase)}
          fontFamily={fontFamily}
          fontSize={getFontSize(section)}
          fontStyle={getFontStyle(section)}
          onMouseEnter={() => setCursorType(phrase.isClickable)}
          onMouseLeave={() => (document.body.style.cursor = 'default')}
          onMouseDown={(e) => {
            // only register mouse-left-click on clickable elements
            if (e.evt.button !== 0 || !phrase.isClickable) return;
            getClickHandler(phrase);
          }} // NOTE onTap for mobile
        />
      );
    }

    return section.children.map((child: Child) => {
      if (child.type === 'text') {
        return (
          <Text
            key={sectionIdx}
            ref={(element: Konva.Text) => handleRef(element)}
            y={getYposition(section, phrase.isClickable)}
            x={getXPosition(phrase.id, phraseIdx, sectionIdx)}
            wrap="word"
            text={child.content}
            fill={handleFill(phrase)}
            fontFamily={fontFamily}
            fontSize={getFontSize(section)}
            fontStyle={getFontStyle(section)}
            onMouseEnter={() => setCursorType(phrase.isClickable)}
            onMouseLeave={() => (document.body.style.cursor = 'default')}
            onMouseDown={(e) => {
              // only register mouse-left-click on clickable elements
              if (e.evt.button !== 0 || !phrase.isClickable) return;
              getClickHandler(phrase);
            }} // NOTE onTap for mobile
          />
        );
      }

      return child.children.map(
        (grandChild: { content: string | undefined }) => (
          <Text
            key={sectionIdx}
            ref={(element: Konva.Text) => handleRef(element)}
            y={getYposition(section, phrase.isClickable)}
            x={getXPosition(phrase.id, phraseIdx, sectionIdx)}
            wrap="word"
            text={grandChild.content}
            fill={handleFill(phrase)}
            fontFamily={fontFamily}
            fontSize={getFontSize(section)}
            fontStyle={getFontStyle(child)}
            onMouseEnter={() => setCursorType(phrase.isClickable)}
            onMouseLeave={() => (document.body.style.cursor = 'default')}
            onMouseDown={(e) => {
              // only register mouse-left-click on clickable elements
              if (e.evt.button !== 0 || !phrase.isClickable) return;
              getClickHandler(phrase);
            }} // NOTE onTap for mobile
          />
        )
      );
    });
  };

  // store phrases in a state and unset ref when component unmounts
  const [phrasesState, setPhrasesState] = useState<Phrase[] | []>([]);
  useEffect(() => {
    setPhrasesState(phrases);

    return () => {
      itemEls.current = {};
    };
  }, [phrases]);

  return (
    <>
      {phrasesState.map((phrase: Phrase, phraseIdx: number) => (
        <React.Fragment key={phrase.id}>
          {!!infoName && position === 0 && (
            <Text
              x={16}
              y={yPosition}
              text={infoName}
              wrap="word"
              fill={highlightInfoName ? textHighlightColor : textPrimaryColor}
              fontFamily={fontFamily}
              fontSize={fontSize}
              ref={infoNameRef}
            />
          )}
          {parseHTML(phrase.content).map(
            (section: SectionType, sectionIdx: number) =>
              renderSection(section, phrase, phraseIdx, sectionIdx)
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default Line;
