import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_SONGBOOKS } from '../../graphql/queries/songbook/songbook';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
} from '../../utils/helpers';

const useGetSongbooks = (variables = {}, config = {}) => {
  const { data, loading, fetchMore } = useQuery(GET_SONGBOOKS, {
    variables: { ...variables },
    ...config,
    onCompleted: () => dismissLoadingMessage(),
    onError: () => {
      dismissLoadingMessage();
      displayErrorMessage('Could not load songbooks');
    },
  });

  loading && displayLoadingMessage('Loading songbooks...');

  const songbooksGrouped = useMemo(() => {
    const songbooks = {};

    data?.esongbooks?.forEach((songbook) => {
      const seriesTitle = songbook.seriesName || 'Other';

      if (songbooks[seriesTitle]) {
        songbooks[seriesTitle].push(songbook);
      } else {
        songbooks[seriesTitle] = [songbook];
      }
    });

    return songbooks;
  }, [data]);

  return { data, songbooksGrouped, fetchMore };
};

export default useGetSongbooks;
