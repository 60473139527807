/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useForm } from 'react-hook-form';
import useUpdateOrganisationName from '../../../../../hooks/Organisation/useUpdateOrganisationName';

const EditOrganisationNameSection = ({
  name,
  organisationRef,
  syId,
  displayName,
}) => {
  const { register, handleSubmit } = useForm();
  const { updateOrganisationNameRequest, loading } =
    useUpdateOrganisationName(organisationRef);

  const handleEditOrganisationName = (data) => {
    updateOrganisationNameRequest(syId, {
      organisation_ref: parseInt(organisationRef, 10),
      display_name: data.name,
    });
  };

  return (
    <div className="row mb-5">
      <div className="col-md-8">
        <label htmlFor="">Name to display in Sparkyard</label>
        <input
          type="text"
          className="form-control"
          name="name"
          ref={register({ required: true })}
          defaultValue={displayName || name}
          key={name}
        />
      </div>
      <div className="col-4">
        <button
          className="btn button__green-meadow button__w-200 mt-26"
          onClick={handleSubmit(handleEditOrganisationName)}
          disabled={loading}
        >
          Edit Display Name
        </button>
      </div>
    </div>
  );
};

export default EditOrganisationNameSection;
