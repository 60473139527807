import React from 'react';

const EditOrganisationButton = ({ disabled, onClick }) => (
  <button
    className="btn button__green-meadow mb-5 w-sm-100 manage-account__segment__button-organization"
    data-target="#editOrganisationModal"
    data-toggle="modal"
    disabled={disabled}
    onClick={onClick}
  >
    Edit your organization details
  </button>
);

export default EditOrganisationButton;
