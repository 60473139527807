/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect } from 'react';
import { countries } from 'countries-list';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import FormRow from './FormRow';
import { organisationDetails, user } from '../../../../../utils/reactiveVars';
import useAddOrEditAddress from '../../../../../hooks/Organisation/useAddOrEditAddress';
import Logo from './Logo';
import EditOrganisationNameSection from './EditOrganisationNameSection';
import ModalHeader from './ModalHeader';
import useGetOrganisation from '../../../../../hooks/Organisation/useGetOrganisation';

const EditOrganisationModal = () => {
  const { register, handleSubmit } = useForm();
  const { data, getOrganisation } = useGetOrganisation();
  const orgDetails = useReactiveVar(organisationDetails);

  useEffect(() => {
    if (orgDetails.id) {
      getOrganisation(orgDetails.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgDetails.id]);

  const {
    organisationRef,
    addresses,
    readOnly,
    avatar,
    detailName,
    detailBillingEmail,
    syId,
    displayName,
  } = data?.organisation || orgDetails;

  const { firstname, surname } = user();

  const { sendAddAddressRequest, sendEditAddressRequest, loading } =
    useAddOrEditAddress(organisationRef);

  const hasExistingAddress = addresses?.length > 0;

  const getCountries = () => {
    const countriesFormatted = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(countries)) {
      countriesFormatted.push({ key, name: value.name });
    }
    return countriesFormatted;
  };

  const handleAddOrEditAddress = (inputData) => {
    const { company, line1, town, country, phone, postcode, billing_email } =
    inputData;
    let input = {
      organisation_ref: parseInt(organisationRef, 10),
      company,
      line1,
      town,
      country,
      phone,
      postcode,
      firstname,
      surname,
      default_billing: false,
      default_delivery: false,
      billing_email,
    };

    if (!hasExistingAddress) {
      return sendAddAddressRequest(input);
    }

    input = { ...input, address_ref: parseInt(addresses[0].addressRef, 10) };
    delete input.default_billing;
    delete input.default_delivery;

    sendEditAddressRequest(input);
  };

  if (!organisationRef) return null;

  const zendeskFormLink = `https://sparkyard.zendesk.com/hc/en-gb/requests/new?ticket_form_id=4479907246481&tf_subject=Organisation Details Change Request&tf_4479874138129=${detailName}&tf_360018048137=${addresses?.[0]?.detailPostcode}`;

  return (
    <div
      className="modal fade"
      id="editOrganisationModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="editAccountModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered custom__modal__edit-account"
        role="document"
      >
        <div className="modal-content custom__modal__edit-account__content">
          <ModalHeader />

          <div className="row">
            <Logo avatar={avatar} organisationRef={organisationRef} />

            <div className="col mt-56 mt-md-0">
              <form className="manage-account text__fs-13">
                <EditOrganisationNameSection
                  name={detailName}
                  organisationRef={organisationRef}
                  syId={syId}
                  displayName={displayName}
                />
                {readOnly && (
                  <div className="mb-20">
                    Details out of date?{' '}
                    <a
                      href={zendeskFormLink}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white text__underline"
                    >
                      Update them here
                    </a>
                  </div>
                )}
                <div className={`${readOnly ? 'disable-element' : ''}`}>
                  <h4 className="mb-4">Address details</h4>
                  <FormRow
                    labelText="School or Organization Name"
                    name="company"
                    ref={register({ required: true })}
                    value={addresses?.[0]?.detailCompany}
                  />
                  <FormRow
                    labelText="Street Address"
                    name="line1"
                    ref={register({ required: true })}
                    value={addresses?.[0]?.detailLine1}
                  />
                  <FormRow
                    labelText="City"
                    name="town"
                    ref={register({ required: true })}
                    value={addresses?.[0]?.detailTown}
                  />
                  <div className="form-row">
                    <div className="form-group col-md-8">
                      <label htmlFor="country">Country / Region</label>
                      <select
                        id="country"
                        name="country"
                        className="form-control"
                        ref={register({ required: true })}
                        defaultValue={addresses?.[0]?.detailCountry}
                      >
                        {getCountries()?.map((country) => (
                          <option value={country.key} key={country.key}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <FormRow
                    labelText="Phone"
                    name="phone"
                    ref={register({ required: true })}
                    value={addresses?.[0]?.detailPhone}
                  />
                  <FormRow
                    labelText="Postal code"
                    name="postcode"
                    mini
                    ref={register({ required: true })}
                    value={addresses?.[0]?.detailPostcode}
                  />
                  <h4 className="mb-4">Billing details</h4>
                  <FormRow
                    labelText="Billing Email"
                    name="billing_email"
                    ref={register({ required: true })}
                    value={detailBillingEmail}
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="mt-auto d-flex justify-content-between">
            <button
              className="btn button__green-meadow button__w-144 button__transparent__green border__green"
              disabled={loading}
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              className="btn button__green-meadow button__w-144 "
              disabled={loading || readOnly}
              onClick={handleSubmit(handleAddOrEditAddress)}
            >
              Update details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrganisationModal;
