import React from 'react';
import { useForm } from 'react-hook-form';
import useEditPlaylist from '../../../../hooks/Playlist/useEditPlaylist';
import FormError from '../../FormError';

const EditPlaylistModal = ({ name, id }) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const { editPlaylistRequest } = useEditPlaylist(id, reset);

  const handleEdit = (data) => {
    editPlaylistRequest(data.name);
  };

  return (
    <div
      className="modal fade"
      id="editPlaylistModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="editPlaylistModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered custom__modal__filter"
        role="document"
      >
        <div className="modal-content">
          <div className="custom__modal__content">
            <div className="custom__modal__header">
              <h5 className="custom__modal__title">Edit playlist</h5>
              <a href="" className="custom__modal__close" data-dismiss="modal">
                <img src="/img/plus-icon.svg" alt="" />
              </a>
            </div>

            <div className="input__control mb-20">
              <input
                name="name"
                type="text"
                placeholder="Playlist name"
                className="text-dark"
                defaultValue={name}
                ref={register({ required: true })}
              />
              {errors.name && <FormError text="required" />}
            </div>

            <button
              href=""
              className="button button__turquoise"
              onClick={handleSubmit(handleEdit)}
              disabled={!!errors.title || !!errors.description}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPlaylistModal;
