import { gql } from '@apollo/client';
import { PlayerHitFieldsFragment } from '../../queries/player/fragments';

export const CREATE_HIT = gql`
  mutation CreateHit($input: CreateHitInput!) {
    createHit(input: $input) {
      ...PlayerHitFields
    }
  }
  ${PlayerHitFieldsFragment}
`;

export const UPDATE_HIT = gql`
  mutation UpdateHit($input: UpdateHitInput!) {
    updateHit(input: $input) {
      ...PlayerHitFields
    }
  }
  ${PlayerHitFieldsFragment}
`;
