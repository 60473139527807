/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import ResourceList from '../../components/Shared/ResourceList';

const RecommendedResources = () => (
  <>
    <ResourceList isRecommendedResourcesComponent />
  </>
);

export default withPageWrapper({})(RecommendedResources);
