/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState } from 'react';
import Video from '../../../Shared/Video/Video';
import AssemblyPlans from './AssemblyPlans';
import Description from './Description';

const MainSection = ({ assembly, slug }) => {
  const getIndexFromSlug = () => {
    const index = assembly.assemblyPlans?.findIndex(
      (item) => item?.slug === slug
    );
    return index !== -1 ? index : null;
  };

  const [selectedPlan, setSelectedPlan] = useState(getIndexFromSlug() || 0);

  const handleSelectedPlan = (index) => setSelectedPlan(index);

  const allSongs = [];
  assembly.assemblyPlans?.map((plan) =>
    plan.songs?.map((song) => allSongs.push(song))
  );

  return (
    <section className="main__container">
      <div className="assembly__song__container">
        <Video url={assembly.videoUrl} title={assembly.videoTitle} />
        <Description text={assembly.description} />
        <AssemblyPlans
          assembly={assembly}
          selectedPlan={selectedPlan}
          handleSelectedPlan={handleSelectedPlan}
        />
      </div>
    </section>
  );
};

export default MainSection;
