/* eslint-disable operator-linebreak */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Step from './Step';

const Term = ({ isOpen, term, handlePanelIds }) => {
  const sortedSteps = (steps) => {
    const stepsCopy = [...steps];
    return stepsCopy.sort((a, b) => a.step - b.step);
  };

  return (
    <div
      className={`mc__tabs__collapse ${isOpen(term.id) ? 'show' : ''}`}
      key={term.id}
    >
      <div
        className="mc__tabs__collapse__header"
        onClick={() => handlePanelIds(term.id)}
      >
        <p>
          <span className="text__fw-600">Term {term.term} </span> {term.title}
        </p>
        <a href="#" className="mc__tabs__collapse__cta">
          <i className="fa fa-chevron-down" />
        </a>
      </div>
      <div className="mc__tabs__collapse__body">
        <p
          className="text__md text__black mb__lg-32 mb-4"
          dangerouslySetInnerHTML={{ __html: term.description }}
        />

        {term.steps &&
          sortedSteps(term.steps).map((step) => (
            <Step step={step} key={step.id} />
          ))}
      </div>
    </div>
  );
};

export default Term;
