/* eslint-disable @typescript-eslint/naming-convention */
import { useReactiveVar } from '@apollo/client';
import { useObserver } from 'mobx-react-lite';
import React from 'react';
import Tappable from 'react-tappable';
import useGetSongForPlayer from '../../hooks/Song/useGetSongForPlayer';
import useStores from '../../player/hooks/useStores';

import {
  organisationLoggedInAs,
  playerActive,
  selectedSongId,
} from '../../utils/reactiveVars';

const PlaySong = ({ mini, id, nativities, esongbookId }) => {
  const organisation_ref = useReactiveVar(organisationLoggedInAs)?.id || null;
  const { mediaPlayerStore } = useStores();
  const { getSongRequest } = useGetSongForPlayer(
    false,
    esongbookId,
    organisation_ref
  );
  // const dimensions = mini ? 18 : 50;

  let className = 'song__thumbnail__icon';

  if (mini) {
    className = 'songslist__item__thumbnail__play';
  }

  if (nativities) className += ' nativities__play-icon';

  const songStateClassName = mediaPlayerStore.playing
    ? 'song__thumbnail__icon__playing'
    : 'song__thumbnail__icon__ready';

  const selectedSong = useReactiveVar(selectedSongId);
  const onClick = (e) => {
    e.preventDefault();
    if (mediaPlayerStore.ready && mediaPlayerStore.song.id === id) {
      mediaPlayerStore.setPlaying(!mediaPlayerStore.playing);
    } else {
      mediaPlayerStore.setPlaying(false);
      getSongRequest(id);
    }
    playerActive(true);
  };

  return useObserver(() => (
    <Tappable onTap={(e) => onClick(e)}>
      <a
        href=""
        className="w-100 h-100 d-block songslist__overlay"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <img
          src="/img/play-button-idle.svg"
          alt="play song"
          className={className}
        />
        {mediaPlayerStore.song.id === id && id === selectedSong && (
          <img
            src={
              mediaPlayerStore.playing
                ? '/img/pause-button.svg'
                : '/img/play-button-ready.svg'
            }
            alt="play song"
            className={`${className} ${songStateClassName}`}
          />
        )}
      </a>
    </Tappable>
  ));
};

export default PlaySong;
