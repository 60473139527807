/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { useState, useEffect, useCallback } from 'react';
import * as mobx from 'mobx';
import { Phrase, Group } from '../types';

/**
 * Outputs the current highlighted phrase based on the position of milliseconds
 * in the provided Groups and the time(ms) that the phrase stops playing.
 *
 * @param groups Groups of lines/phrases that make up the slide
 * @param milliseconds current value in ms that the song has progressed
 *
 * @returns {[phrase: Phrase[], phraseEndsAt: number]} the current phrase to highlight and a number when it ends
 */

const useHighlightedPhrase = (
  groups: Array<Group>,
  milliseconds: number
): [Phrase[], Phrase[]] => {
  const filterGroups = useCallback((groups: Array<Group>) => {
    const phrasesArr: Array<Phrase[]> = [];
    mobx.toJS(groups).forEach((group) => {
      phrasesArr.push(
        group.lines
          .slice()
          .flatMap((line) => line.phrases)
          .filter(
            (phrase) =>
              phrase.id <= 0 ||
              (phrase.id >= 0 &&
                phrase.milliseconds > 0 &&
                phrase.isHighlightable)
          )
      );
    });
    return phrasesArr;
  }, []);

  const flattenGroups = useCallback(
    (groups: Array<Group>) =>
      mobx.toJS(groups).flatMap((group) =>
        group.lines
          .slice()
          .flatMap((line) => line.phrases)
          .filter(
            (phrase) =>
              phrase.id <= 0 ||
              (phrase.id >= 0 &&
                phrase.milliseconds > 0 &&
                phrase.isHighlightable)
          )
      ),
    []
  );

  const [phrases, setPhrases] = useState(filterGroups(groups));
  const [currentPhrases, setCurrentPhrases] = useState<Phrase[]>([
    phrases[0]?.[0],
  ]);

  const nextPhrase = useCallback(
    (phrases: Array<Phrase>) =>
      phrases.find((phrase: Phrase, idx: number) => {
        const passedThisPhrase = phrase.milliseconds <= milliseconds;
        let beforeNextPhrase = true;
        if (idx < phrases.length - 1) {
          beforeNextPhrase = phrases[idx + 1].milliseconds > milliseconds;
        }

        if (
          idx === phrases.length - 1 &&
          milliseconds > phrases[idx].hide &&
          phrases[idx].hide !== 0
        ) {
          return false;
        }
        // Finds the first phrase where milliseconds (into the song) is higher than phrase.milliseconds but lower than the next phrase
        return passedThisPhrase && beforeNextPhrase;
      }),
    [milliseconds]
  );

  useEffect(() => {
    setPhrases(filterGroups(groups));
  }, [groups, filterGroups]);

  useEffect(() => {
    const allPhrases: Phrase[] = [];

    phrases.forEach((phraseArr) => {
      if (milliseconds > nextPhrase(phraseArr)?.milliseconds) {
        allPhrases.push(nextPhrase(phraseArr));
        const currentIdx = phraseArr.findIndex(
          (phrase) => phrase.id === nextPhrase(phraseArr)?.id
        );

        // eslint-disable-next-line no-plusplus
        for (let i = currentIdx - 1; i >= 0; i--) {
          if (
            phraseArr[i].milliseconds === phraseArr[currentIdx].milliseconds
          ) {
            allPhrases.push(phraseArr[i]);
          }
        }
      }
    });
    setCurrentPhrases(() => allPhrases);
  }, [milliseconds, groups, phrases, nextPhrase]);

  return [currentPhrases, flattenGroups(groups)];
};

export default useHighlightedPhrase;
