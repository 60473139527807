import { useMutation } from '@apollo/client';
import { DELETE_SHARED_LINK } from '../../graphql/mutations/share';
import { GET_SHARED_LINKS } from '../../graphql/queries/share/share';
import {
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';

const useDeleteSharedLink = (organisationRef) => {
  const [deleteSharedLink, { loading }] = useMutation(DELETE_SHARED_LINK, {
    refetchQueries: [
      {
        query: GET_SHARED_LINKS,
        variables: {
          organisation_ref: organisationRef,
        },
      },
    ],
  });

  const deleteSharedLinkRequest = (id) => {
    deleteSharedLink({
      variables: { id },
    })
      .then(() => {
        dismissLoadingMessage();
        displaySuccessMessage();
      })
      .catch(() => {
        dismissLoadingMessage();
        displayErrorMessage();
      });
  };

  loading && displayLoadingMessage();

  return { deleteSharedLinkRequest };
};

export default useDeleteSharedLink;
