import { gql } from '@apollo/client';

export const SongShareFieldsFragments = gql`
  fragment SongShareFields on SongShare {
    id
    userId
    songId
    uuid
    expiresIn
    status
    organisationRef
    ootaUUID
    song {
      id
      infoName
    }
  }
`;
