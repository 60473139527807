import React, { useEffect } from 'react';
import useGetOrganisation from '../../../../../hooks/Organisation/useGetOrganisation';
import { organisationLoggedInAs } from '../../../../../utils/reactiveVars';

const UserSelection = ({
  selectedUser,
  setSelectedUser,
  isForSingleUser,
  handleIsForSingleUser,
}) => {
  const organisationRef = organisationLoggedInAs()?.id;

  const { data, getOrganisation } = useGetOrganisation();

  useEffect(() => {
    if (organisationRef) {
      getOrganisation(organisationRef);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationRef]);

  return (
    <div className="manage-account__subscription-details-row">
      <span className="text__fw-600 pr-3">Users</span>
      <div>
        <div className="d-inline mr-5">
          <input
            type="radio"
            id="all"
            name="selectUser"
            value="az"
            className="mr-3"
            onClick={() => {
              handleIsForSingleUser(false);
              setSelectedUser('');
            }}
          />
          <label htmlFor="all"> All Users</label>
        </div>
        <div className="d-inline mr-5">
          <input
            type="radio"
            id="selectUser"
            name="selectUser"
            value="az"
            className="mr-3"
            onClick={() => handleIsForSingleUser(true)}
          />
          <label htmlFor="selectUser"> Select User</label>
        </div>
        <div className="d-inline-block">
          <select
            className="form-control form-control-lg input__age-range d-inline"
            name="min_age"
            disabled={!isForSingleUser}
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
          >
            <option selected disabled hidden value="">
              Select user...
            </option>
            {data?.organisation?.users?.map(
              ({ ootaId, firstname, surname }) => (
                <option value={ootaId} key={ootaId}>
                  {firstname} {surname}
                </option>
              )
            )}
          </select>
        </div>
      </div>
    </div>
  );
};

export default UserSelection;
