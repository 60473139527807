import React from 'react';

const Footer = ({ link, howTo, pdf2 }) => (
  <div className="bg__green-meadow p-3 pb-13 text__white mb-20">
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="d-flex justify-content-between text__white mb-3"
    >
      <p className="text__fs-14 mb-0 mr-2">
        Complete curriculum overview and skills progression (Reception)
      </p>
      <i className="fa fa-chevron-right text__fs-19" />
    </a>
    <a
      href={howTo}
      target="_blank"
      rel="noreferrer"
      className="d-flex justify-content-between text__white mb-3"
    >
      <p className="text__fs-14 mb-0 mr-2">
        Reception Music Curriculum and the 7 Areas of Learning
      </p>
      <i className="fa fa-chevron-right text__fs-19" />
    </a>
    <a
      href={pdf2}
      target="_blank"
      rel="noreferrer"
      className="d-flex justify-content-between text__white"
    >
      <p className="text__fs-14 mb-0 mr-2">
        Complete curriculum overview and skills progression (Y1-6)
      </p>
      <i className="fa fa-chevron-right text__fs-19" />
    </a>
  </div>
);

export default Footer;
