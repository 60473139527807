/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroller';
import { useReactiveVar } from '@apollo/client';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import SonglistHeader from '../../components/Songs/Sections/SonglistHeader';
import FilterSection from '../../components/Songs/Sections/FilterSection';
import SongsList from '../../components/Songs/Sections/Songslist';
import useGetSongs from '../../hooks/Song/useGetSongs';
import { updateQuery } from '../../utils/objects';
import { displayLoadingMessage, equals } from '../../utils/helpers';
import Loading from '../../components/Shared/Loading';
import { allSongsPageSongs } from '../../utils/reactiveVars';

const Songs = () => {
  const { data, loading, fetchMore } = useGetSongs(50, {
    primary: true,
    available: true,
  });

  const [filterParams, setFilterParams] = useState(null);
  // const [songs, setSongs] = useState([]);
  const [tagsChanged, setTagsChanged] = useState(false);
  const persistedSongs = useReactiveVar(allSongsPageSongs);

  useEffect(() => {
    if (data?.songs?.data) {
      if (!equals(data.songs.data, persistedSongs)) {
        setTagsChanged(false);
      }

      if (tagsChanged) {
        // setSongs(data.songs.data);
        allSongsPageSongs(data.songs.data);
      } else {
        allSongsPageSongs([...persistedSongs, ...data.songs.data]);
        // setSongs((prevState) => {
        //   const newState = [...prevState, ...data.songs.data];
        //   return newState;
        // });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, data]);

  loading && displayLoadingMessage();

  const loadMoreSongs = () => {
    fetchMore({
      variables: {
        first: 50,
        page: data?.songs?.paginatorInfo?.currentPage + 1,
        ...filterParams,
      },
      ...updateQuery,
    });
  };

  const filterSongs = async (params) => {
    toast.info('Loading...', {
      toastId: 'loading',
      autoClose: 2000,
    });
    if (Object.keys(params).length !== 0) {
      setTagsChanged(true);
    }
    setFilterParams(params);
    allSongsPageSongs([]);
    fetchMore({
      variables: {
        ...params,
      },
      ...updateQuery,
    });
  };

  // const sortSongs = async (order) => {
  //   setFilterParams({
  //     ...filterParams,
  //     orderBy: [{ column: 'INFO_NAME', order }],
  //   });
  //   fetchMore({
  //     variables: {
  //       ...(filterParams && { filterParams }),
  //       orderBy: [{ column: 'INFO_NAME', order }],
  //     },
  //     ...updateQuery,
  //   });
  // };

  return (
    <>
      <SonglistHeader />
      <FilterSection handleFilterSongs={filterSongs} />
      <section className="main__container">
        {/* <SortSongs sortSongs={sortSongs} /> */}
        {persistedSongs.length > 1 && (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMoreSongs}
            hasMore={data?.songs?.paginatorInfo.hasMorePages}
            loader={<Loading key={0} />}
          >
            <SongsList songs={persistedSongs} loading={loading} />
          </InfiniteScroll>
        )}
      </section>
    </>
  );
};

export default withPageWrapper({ pageTitle: 'All Songs | Sparkyard' })(Songs);
