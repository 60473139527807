import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

const ShareGuest = () => {
  const { type } = useParams();

  const validTypes = ['playlist', 'collection'];
  if (!validTypes.includes(type)) return <Redirect to="/404" />;

  return (
    <>
      <div style={{ maxWidth: '178px' }} className="mx-auto my-5">
        <a href="/">
          <img src="/img/logo.png" alt="" />
        </a>
      </div>
      <div className="d-flex pt-5">
        <div className="mt-auto m-auto text__fs-16 p-5 lh-140 text-center">
          <img
            src="/img/shared-mock-up-character.png"
            alt=""
            style={{ maxWidth: '300px' }}
            className="my-5 d-inline"
          />
          <p className="pt-5">
            Someone has shared a Sparkyard {type} with you.
          </p>
          <p>
            Log in to your Sparkyard account at{' '}
            <a
              href="https://www.sparkyard.com"
              className="text-light text__underline"
            >
              www.sparkyard.com
            </a>{' '}
            to access it.
          </p>
          <p>
            If you don't have a Sparkyard account, you'll be able to create a
            free trial or{' '}
            <a
              href="https://www.outoftheark.co.uk/sparkyard/auth?client_id=sparkyard_liveclient01&redirect_uri=https://app.sparkyard.com/login-oota&response_type=code&state=xyz&purchasenow=true"
              className="text-light text__underline"
            >
              purchase a subscription here.
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default ShareGuest;
