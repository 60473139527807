/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import { useObserver } from 'mobx-react-lite';
import React from 'react';
import tw, { styled } from 'twin.macro';
import 'styled-components/macro';
import useStores from '../hooks/useStores';
import { MediaButton } from './Icon';

const VolumeContainer = styled.div<{
  gridPosition: number;
  hideOnMobile?: boolean;
}>`
  ${tw`w-48 flex justify-around`}
  ${({ gridPosition }) => gridPosition && `-ms-grid-column:  ${gridPosition}`};
  ${({ gridPosition }) => gridPosition && `grid-column:  ${gridPosition}`};
  ${({ hideOnMobile }) =>
    hideOnMobile &&
    `
    @media (max-width: 767px),screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
      display: none;
    }`};
`;

const VolumeSlider = ({
  gridPosition,
}: {
  gridPosition: number;
}): JSX.Element => {
  const { mediaPlayerStore } = useStores();

  return useObserver(() => (
    <VolumeContainer gridPosition={gridPosition} hideOnMobile>
      <MediaButton icon="Volume" />
      {/* TODO: Replace this input with the ProgressBar.tsx */}
      <input
        tw="w-36"
        name="volume"
        type="range"
        min={0}
        max={1}
        step="any"
        value={mediaPlayerStore.volume}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          mediaPlayerStore.setVolume(parseFloat(e.target.value))
        }
        className="d-none d-md-block"
      />
    </VolumeContainer>
  ));
};

export default VolumeSlider;
