/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Decimal from 'decimal.js';
import React, { useRef } from 'react';
import tw, { styled } from 'twin.macro';
import 'styled-components/macro';
import ReactPlayer from 'react-player';
import { useObserver } from 'mobx-react-lite';
import numeral from 'numeral';
import { Line } from 'rc-progress';
import useStores from '../hooks/useStores';
import useMediaQuery from '../../hooks/Others/useMediaQuery';

type Props = {
  player: React.RefObject<ReactPlayer>;
};

const Container = styled('div')<{ height: number }>`
  ${tw`absolute w-full`}
  top: 14px;
  max-width: 1360px;
  @media (max-width: 1600px) {
    max-width: 90%;
  }
  @media (max-width: 767px),
    screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    max-width: 100vw;
    margin-left: -5%;
    top: 0;
  }
`;

const RelativeRootElement = styled('div')<{ height: number }>`
  ${tw`relative`};
  height: ${({ height }) => height};
`;

const Slider = styled.input.attrs({ type: 'range' })`
  ${tw`absolute top-0 w-full m-0 z-20 bg-transparent`}
  height: 5px;
  -webkit-appearance: none;

  :focus {
    outline: none;
  }
  ::-webkit-slider-runnable-track {
    ${tw`w-full h-2 cursor-pointer bg-transparent`}
    border-radius: 1.3px;
    border: 0.2px solid transparent;
  }
  ::-webkit-slider-thumb {
    ${tw`w-4 h-8 border border-solid border-transparent rounded bg-transparent cursor-pointer mt-3`}
    -webkit-appearance: none;
  }
  :focus::-webkit-slider-runnable-track {
    ${tw`bg-transparent`}
  }
  ::-moz-range-track {
    ${tw`w-full h-2 cursor-pointer bg-transparent border border-solid border-transparent`}
  }
  ::-moz-range-thumb {
    ${tw`border border-solid border-transparent h-0 w-0 bg-transparent hidden cursor-pointer`}
  }
  ::-ms-track {
    ${tw`w-full h-2 cursor-pointer bg-transparent border border-solid border-transparent text-transparent`}
  }
  ::-ms-thumb {
    ${tw`border border-solid border-transparent h-8 w-4 bg-transparent cursor-pointer`}
  }
  max-width: 1360px;
  left: 0px;
  @media (min-width: 429px) and (max-width: 1024px) {
    width: 94.5%;
  }
  @media (min-width: 1025px) and (max-width: 1366px) {
    max-width: 1266px;
  }
  @media (max-width: 767px),
    screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    width: 100%;
  }
  @media screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    width: 414px !important;
  }
`;

export const formatTime = (value: number) =>
  numeral(value).format('0:00').replace('0:0', '');

export const ProgressBar = ({ player }: Props) => {
  const { mediaPlayerStore } = useStores();
  const sliderRef = useRef<HTMLInputElement>(null!);

  const isMobile = useMediaQuery(
    '(max-width: 767px), screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) '
  );

  const LineStyle = {
    height: `${sliderRef.current?.clientHeight || 2}px`,
    width: '100%',
    position: 'absolute' as 'absolute',
    left: isMobile ? '10px' : 0,
    zIndex: 5,
  };

  const onMouseUp = (
    e: React.TouchEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>
  ) => {
    mediaPlayerStore.setSeeking(false);
    if (player.current) {
      player.current.seekTo(parseFloat(e.currentTarget.value));
    }
  };

  return useObserver(() => (
    <Container height={sliderRef.current?.clientHeight || 2}>
      {/* <div
        className="d-flex justify-content-between"
        style={{
          width: isTablet ? '94%' : '80.5%',
          ...(isMobile && {
            width: '99%',
            marginLeft: '3px',
            marginRight: '5px',
          }),
        }}
      >
        <ProgressLeft />
        <ProgressRight />
      </div> */}
      <RelativeRootElement height={sliderRef.current?.clientHeight || 2}>
        <Line
          strokeColor="#9ca3aa"
          percent={
            (mediaPlayerStore.progress.loadedSeconds /
              mediaPlayerStore.duration) *
            100
          }
          style={LineStyle}
          strokeLinecap="square"
        />
        <Line
          strokeColor="#40c0c0"
          percent={
            +new Decimal(
              mediaPlayerStore.progress.playedSeconds /
                mediaPlayerStore.duration
            ).times(100) // using decimal.js here cos js cannot do floating point operations well
          }
          style={{ ...LineStyle, zIndex: 10 }}
          strokeLinecap="square"
        />
        <Slider
          ref={sliderRef}
          name="progress"
          type="range"
          min={0}
          max={0.999999}
          step="any"
          value={mediaPlayerStore.progress.played}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            mediaPlayerStore.setLastPlayheadPositionMs(
              Math.round(mediaPlayerStore.progress.playedSeconds * 1000)
            );
            mediaPlayerStore.setCurrentPlayheadPositionMs(
              Math.round(
                Number(e.target.value) * mediaPlayerStore.duration * 1000
              )
            );
            mediaPlayerStore.setProgress({
              ...mediaPlayerStore.progress,
              played: Number(e.target.value),
            });
          }}
          onMouseUp={(e) => onMouseUp(e)}
          onMouseDown={() => mediaPlayerStore.setSeeking(true)}
          onTouchStart={() => mediaPlayerStore.setSeeking(true)}
          onTouchEnd={(e) => onMouseUp(e)}
        />
      </RelativeRootElement>
    </Container>
  ));
};
