import React from 'react';
import { useReactiveVar } from '@apollo/client';
import useAddLessonPlanBookmark from '../../hooks/Curriculum/useAddLessonPlanBookmark';
import useRemoveLessonPlanBookmark from '../../hooks/Curriculum/useRemoveLessonPlanBookmark';
import { organisationLoggedInAs } from '../../utils/reactiveVars';

const Bookmark = ({ hasBeenBookmarked, bookmarkId, lessonPlanId }) => {
  const organisationRef = useReactiveVar(organisationLoggedInAs)?.id;
  const { addLessonPlanBookmark, loading: loadingAddBookmark } =
    useAddLessonPlanBookmark(organisationRef);
  const { removeLessonPlanBookmark, loading: loadingRemoveBookmark } =
    useRemoveLessonPlanBookmark(organisationRef);

  const loading = loadingAddBookmark || loadingRemoveBookmark;

  const handleAddBookmark = (e, id) => {
    e.preventDefault();
    addLessonPlanBookmark({
      variables: {
        lessonPlanId: id,
        organisationRef,
      },
    });
  };

  const handleRemoveBookmark = (e, id) => {
    e.preventDefault();
    removeLessonPlanBookmark({
      variables: {
        id,
      },
    });
  };

  const className = `text-white position-absolute top-0 right-0 pr-4 ${
    loading ? 'disable-element' : ''
  }`;

  return (
    <a
      href=""
      onClick={(e) => {
        hasBeenBookmarked
          ? handleRemoveBookmark(e, bookmarkId)
          : handleAddBookmark(e, lessonPlanId);
      }}
      className={className}
    >
      <i
        className={`${
          hasBeenBookmarked ? 'fa-solid' : 'fa-regular'
        } fa-bookmark text__fs-25 pt-4`}
        style={{ transform: 'scale(1, 1.25)' }}
      />
    </a>
  );
};

export default Bookmark;
