/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useGenerateUrlHash from '../../../../../hooks/Others/useGenerateUrlHash';
import { resetPasswordUrl } from '../../../../../utils/objects';
import { user } from '../../../../../utils/reactiveVars';

const ResetPasswordButton = () => {
  const { accessToken } = user();
  const { data, generateUrlHashRequest, loading } = useGenerateUrlHash();

  const redirectUrl = `${document.location.origin}/manage-account`;
  useEffect(() => {
    generateUrlHashRequest(redirectUrl);
  }, []);

  const redirectToUrl = `${resetPasswordUrl}?access_token=${accessToken}&url_return_success=${redirectUrl}&url_return_success_signature=${data?.generateUrlHash}`;

  const shouldDisableButton = loading ? 'disable-element' : '';

  return (
    <a
      href={redirectToUrl}
      target="_blank"
      rel="noreferrer"
      className={`btn button__cerise-dark button__transparent w-unset mt-26 ml-3 ml-md-auto ${shouldDisableButton}`}
    >
      Reset password
    </a>
  );
};

export default ResetPasswordButton;
