/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import queryString from 'query-string';
import AgeRangeSelect from '../../Shared/CategoryPages/AgeRangeSelect';
//import SubmitSection from '../../Shared/CategoryPages/SubmitSection';
import MultiFilterSection from '../../Shared/CategoryPages/MultiFilterSection';
import { useLocation } from 'react-router-dom';

function getResourceTypes(queryStrings) {
  if (queryStrings === undefined) return [];

  return queryStrings['types[]'] && Array.isArray(queryStrings['types[]'])
    ? queryStrings['types[]']
    : queryStrings['types[]']
    ? [queryStrings['types[]']]
    : [];
}

const FilterSection = ({ handleFilter, searchText, tags }) => {
  const location = useLocation();
  const queryStrings = queryString.parse(location.search);

  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      min_age: queryStrings.minAge || 5,
      max_age: queryStrings.maxAge || 11,
      resource_types: getResourceTypes(queryStrings),
    },
  });

  useEffect(() => {
    setValue('resource_types', getResourceTypes(queryStrings));
  }, [queryStrings]);

  const [text, setText] = useState('');

  useEffect(() => {
    if (text !== searchText) {
      setText(searchText);
    }
  }, [searchText, text]);

  const handleFilterParams = (data) => handleFilter(data);

  const formRef = useRef();

  return (
    <div className="main__container bg__blue-nile mt-0">
      <div className="assembly__songfinder">
        {/*<h2 className="assembly__songfinder__title">Find items</h2>*/}
        <form onSubmit={handleSubmit(handleFilterParams)} ref={formRef}>
          <div className="assembly__filter assembly__filter__choir">
            <div className="assembly__songfinder__range">
              <label htmlFor="">Age range</label>
              <div className="assembly__songfinder__range__selectors">
                <AgeRangeSelect
                  ref={register}
                  name="min_age"
                  handleAgeRangeChange={() => {
                    formRef.current.dispatchEvent(
                      new Event('submit', { cancelable: true, bubbles: true })
                    );
                  }}
                />
                <p>to</p>
                <AgeRangeSelect
                  ref={register}
                  name="max_age"
                  handleAgeRangeChange={() => {
                    formRef.current.dispatchEvent(
                      new Event('submit', { cancelable: true, bubbles: true })
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <Controller
            control={control}
            name="resource_types"
            render={({ onChange, value }) => {
              return (
                <MultiFilterSection
                  tags={tags}
                  value={value}
                  onChange={(value) => {
                    onChange(value);

                    formRef.current.dispatchEvent(
                      new Event('submit', { cancelable: true, bubbles: true })
                    );
                  }}
                />
              );
            }}
          ></Controller>
        </form>
      </div>
    </div>
  );
};

export default FilterSection;
