export const searchTypeTexts = {
  SONG: 'Songs',
  ESONGBOOK: 'Songbooks',
  PLAYLIST: 'Playlists',
  COLLECTION: 'Collections',
  MUSIC_ACTIVITY: 'Music Activities',
  BLOG_OR_GUIDE: 'Blogs or Guides',
  CURRICULUM_LINKS_AND_MORE: 'Curriculum Links and more',
  SEND_RESOURCE: 'SEND Resource',
};
