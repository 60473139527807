import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/AssemblyPlans/Sections/Header';
import FilterSection from '../../components/AssemblyPlans/Sections/FilterSection';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import MainSection from '../../components/AssembliesTheme/Sections/MainSection/Index';
import AnimatedAssemblyMedia from '../../components/AssembliesTheme/Sections/MainSection/AnimatedAssemblyMedia';

const AssembliesTheme = () => {
  const { theme, ageRange } = useParams();

  return (
    <>
      <Header />
      {theme === 'animated' && <AnimatedAssemblyMedia />}
      <FilterSection />
      <MainSection theme={theme} ageRange={ageRange} />
    </>
  );
};

export default withPageWrapper({})(AssembliesTheme);
