import React from 'react';
import PackageDetails from './PackageDetails';
import SubscriptionDetails from './SubscriptionDetails';

const ModalBody = () => (
  <div className="row mt-4">
    <SubscriptionDetails />
    <PackageDetails />
  </div>
);

export default ModalBody;
