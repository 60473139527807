import { useMutation } from '@apollo/client';
import { REMOVE_MEMBER } from '../../graphql/mutations/organisation';
import { GET_ORGANISATION } from '../../graphql/queries/organisation/organisation';
import {
  dismissErrorMessage,
  dismissLoadingMessage,
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessMessage,
} from '../../utils/helpers';

const useRemoveMember = (organisationRef) => {
  const [removeMember, { loading }] = useMutation(REMOVE_MEMBER, {
    refetchQueries: [
      {
        query: GET_ORGANISATION,
        variables: {
          id: organisationRef,
        },
      },
    ],
  });

  const sendRemoveMemberRequest = (input) => {
    dismissErrorMessage();

    removeMember({
      variables: {
        input,
      },
    })
      .then(() => {
        dismissLoadingMessage();
        displaySuccessMessage();
      })
      .catch(() => {
        dismissLoadingMessage();
        displayErrorMessage();
      });
  };

  loading && displayLoadingMessage('Please wait...');

  return { sendRemoveMemberRequest, loading };
};

export default useRemoveMember;
