import React from 'react';

const Header = () => (
  <div className="songslist__header">
    <img
      src="/img/audio-icon-grey.svg"
      className="songslist__header__icon"
      alt=""
    />
    <h2 className="songslist__header__title d-md-block d-none">
      Songs and Playlists &nbsp; <span className="text__white">Songbooks</span>
    </h2>
    <h2 className="songslist__header__title d-md-none">
      Music &nbsp;<span className="text__white">Songbook</span>
    </h2>
  </div>
);

export default Header;
