import React from 'react';

const Header = () => (
  <>
    <div className="main__container bg__arsenic mt-0">
      <div className="mt-0">
        <div className="mycollections__header">
          <div className="mycollections__header__icon d-none d-lg-block">
            <img src="/img/song-icon-white.svg" alt="" />
          </div>
          <h3 className="mycollections__header__title">
            Purchased Nativities & Musicals
          </h3>
          <a
            target="_blank"
            href="https://outoftheark.co.uk"
            rel="noreferrer"
            className="button mt-5 mt-md-0 d-none d-lg-flex button__nativities-cta"
          >
            BUY MORE NATIVITIES AND MUSICALS
          </a>
        </div>
      </div>
    </div>
    <div className="main__container mt-0 bg__moderate-cyan py-3">
      <div className="text__fs-14 font-weight-normal px-4 p-xl-0">
        Nativities, Musicals, or eSongbooks you have previously purchased from
        Out of the Ark will appear here.
      </div>
    </div>

    <a
      target="_blank"
      href="https://outoftheark.co.uk"
      rel="noreferrer"
      className="button button__nativities-cta mr-auto m-4 mb-5 d-flex d-lg-none"
    >
      BUY MORE NATIVITIES AND MUSICALS
    </a>
  </>
);

export default Header;
