import React, { useState, useMemo } from 'react';
import ReactPlayer from 'react-player';
import withPageWrapper from '../../components/Shared/HOCs/withPageWrapper';
import SelectPlaylist from '../../components/TreatBox/SelectPlaylist';
import useGetPlaylists from '../../hooks/Playlist/useGetPlaylists';
import { TreatboxSurpriseAnimation } from '../../components/LottieAnimation/index';
import MainButton from '../../components/TreatBox/MainButton';
import Header from '../../components/TreatBox/Sections/Header';
import PlaySong from '../../components/Shared/PlaySong';
import { albumArtDefault } from '../../utils/helpers';
import useGetSongs from '../../hooks/Song/useGetSongs';

const TreatBox = () => {
  const { data: userPlaylists, loading: loadingUserPlaylists } =
    useGetPlaylists('user');
  const [modal, setModal] = useState('');
  const [surprise, setSurprise] = useState(false);
  const [surpriseButtonAndIcon, setSurpriseButtonAndIcon] = useState(false);
  const [surpriseAnimation, setSurpriseAnimation] = useState(false);
  const [randomSong, setRandomSong] = useState([]);
  const [selectPlaylistID, setSelectPlaylistID] = useState(0);

  const { data: randomFromAllSongs, loading: randomFromAllSongsLoading } =
    useGetSongs(100, {
      primary: true,
      available: true,
      randomise: true,
      excludeFromTreatbox: false,
    });

  const sortedUserPlaylists = useMemo(() => {
    const userPlaylistsLocal = userPlaylists
      ? [...userPlaylists.playlists]
      : [];
    return userPlaylistsLocal.sort((a, b) => a.name.localeCompare(b.name));
  }, [userPlaylists]);

  const setRandomSongId = (playlistId) => {
    let songs = [];
    if (playlistId) {
      songs = sortedUserPlaylists.find((p) => p.id === playlistId).songs;
    } else {
      songs = randomFromAllSongs?.songs?.data ?? [];
      songs = randomFromAllSongs?.songs?.data ?? [];
    }

    const randomSongId = Math.floor(Math.random() * songs.length);
    setRandomSong(songs[randomSongId]);
  };

  return (
    <>
      <Header />
      <section className="main__container mt-1">
        <div className="px__sm-3">
          <div className="grid__banner mb__lg-80 mb-40">
            <MainButton
              label="Choose from my playlists"
              onClick={() => {
                setModal('selectPlaylist');
                setSurpriseButtonAndIcon(false);
              }}
              color="green-meadow"
            />
            <MainButton
              label={
                randomFromAllSongsLoading
                  ? 'loading..'
                  : 'Surprise me with something new'
              }
              onClick={() => {
                if (!randomFromAllSongsLoading) {
                  setSurprise(true);
                  setModal('surprise');
                  setRandomSongId(0);
                  setSelectPlaylistID(0);
                  setSurpriseAnimation(false);
                  setSurpriseButtonAndIcon(false);
                }
              }}
              color="cerise-dark"
            />
          </div>
        </div>
      </section>

      {modal && (
        <div
          className={
            'treatbox__modal_overlay' +
            (modal === 'surprise' ? ' treatbox__modal_overlay_surprise' : '')
          }
        >
          <div
            className={
              'treatbox__modal' +
              (modal === 'selectPlaylist'
                ? ' treatbox__modal_dark'
                : modal === 'surprise'
                ? ' treatbox__modal_surprise'
                : '')
            }
          >
            <button
              onClick={() => {
                setModal('');
              }}
              className="treatbox__modal_close"
            >
              {modal === 'surprise' ? (
                <img src="/img/close-white.svg" width="100%" alt="close" />
              ) : (
                <img src="/img/close-black.svg" width="100%" alt="close" />
              )}
            </button>
            <div className="treatbox__modal_content">
              {loadingUserPlaylists && <div>loading...</div>}
              <div className="treatbox__modal_content_inner">
                {modal === 'selectPlaylist' && (
                  <>
                    <div className="treatbox__modal_select_title">
                      SELECT PLAYLIST
                    </div>
                    <div className="treatbox__modal_select_wrapper">
                      <SelectPlaylist
                        playlists={sortedUserPlaylists}
                        onChange={(val) => {
                          setRandomSongId(val);
                          setSelectPlaylistID(val);
                          setSurprise(true);
                          setModal('surprise');
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {modal === 'surprise' && surprise === true && (
              <div className="treatbox__surprised_animation_wrapper">
                {!surpriseAnimation && (
                  <div className="treatbox__surprise_audio_loading">
                    loading..
                  </div>
                )}
                <div className="treatbox__surprise_audio">
                  <ReactPlayer
                    onReady={() => {
                      setSurpriseAnimation(true);
                      setTimeout(() => {
                        setSurpriseButtonAndIcon(true);
                      }, 1500);
                    }}
                    url="https://assets.sparkyard.com/treatbox-surprise-audio.m4a"
                    playing
                  />
                </div>

                {surpriseAnimation && <TreatboxSurpriseAnimation />}

                {surpriseButtonAndIcon && (
                  <div className="treatbox__surprised_animation_play">
                    <img
                      className="assembly__song__card__img"
                      src={randomSong?.albumArt || albumArtDefault}
                      alt=""
                    />
                    <div>
                      <PlaySong
                        id={randomSong.id}
                        esongbookId={null}
                        mini
                        playing
                      />
                    </div>
                    <span>
                      <a href={`/song/${randomSong.id}`}>
                        {randomSong.title}
                        <br />
                        (Age {randomSong.age})
                      </a>
                    </span>
                  </div>
                )}

                {surpriseButtonAndIcon && (
                  <div className="treatbox__surprised_animation_button_wrapper">
                    <button
                      onClick={() => {
                        setRandomSongId(selectPlaylistID);
                        setSurpriseAnimation(false);
                        setSurpriseButtonAndIcon(false);
                        setSurprise(false);
                        setTimeout(() => {
                          setSurprise(true);
                        }, 100);
                      }}
                      className="treatbox__surprised_animation_button"
                    >
                      Another one?
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default withPageWrapper({})(TreatBox);
