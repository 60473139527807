import React from 'react';
import DownloadsAndAboutDropdown from '../../../Shared/DownloadsAndAboutDropdown';
import LikeAndAddIcons from '../../../Shared/LikeAddIcons';
import SongsHeaderInfo from './SongsHeaderInfo';

const SongsHeader = ({ id }) => (
  <div className="main__container bg__cello mt-0">
    <div className="songpage__header">
      <div className="songpage__header__grid">
        <SongsHeaderInfo />
        <LikeAndAddIcons
          headerText="Song options"
          modalId="songHeaderActions"
          favourite
          addToPlaylist
          addToCollection
          share
          itemType="SONG"
          id={id}
        />
        <DownloadsAndAboutDropdown />
      </div>
    </div>
  </div>
);

export default SongsHeader;
