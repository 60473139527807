/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import tw, { styled } from 'twin.macro';
import 'styled-components/macro';
import Fullscreen from '../../images/full-screen.svg';
import ChangeSong from '../../images/change-song.svg';
import Favourite from '../../images/favourite.svg';
import Favourited from '../../images/favourited.svg';
import Hotspot from '../../images/hotspot.svg';
import Pause from '../../images/pause.svg';
import Play from '../../images/play.svg';
import ChangeSlide from '../../images/change-slide.svg';
import Volume from '../../images/volume.png';
import Minimize from '../../images/minimize.png';
import Instrumental from '../../images/instrumental.png';
import Vocal from '../../images/vocal.png';
import Plaintext from '../../images/plaintext-white.png';
import Fancytext from '../../images/fancytext-white.png';
import MusicalNote from '../../images/musical-note.png';
import { getMapValue } from '../../utils';
import { isMobileFullscreen } from '../../../utils/reactiveVars';

interface IIcon {
  rotate?: number;
  variant?: string;
  mobileDimensions?: number;
  tabletDimensions?: number;
  mobileFullscreenDimensions?: number;
}

const iconImageMap = {
  Fullscreen,
  ChangeSong,
  Favourite,
  Favourited,
  Hotspot,
  Pause,
  Play,
  ChangeSlide,
  Volume,
  Minimize,
  Instrumental,
  Vocal,
  Plaintext,
  Fancytext,
  MusicalNote,
};

const iconStyleMap = {
  Fullscreen: tw`w-14 h-14`,
  Minimize: tw`w-14 h-14`,
  ChangeSong: tw`w-8 h-8`,
  Favourite: tw`w-8 h-8`,
  Favourited: tw`w-8 h-8`,
  Hotspot: tw`w-8 h-8`,
  Pause: tw`w-18 h-18 fill-current text-black`,
  Play: tw`w-18 h-18 fill-current text-teal-400`,
  ChangeSlide: tw`w-8 h-8`,
  Volume: tw`w-6 h-8`,
  Instrumental: tw`w-14 h-14`,
  Vocal: tw`w-14 h-14`,
  Plaintext: tw`w-14 h-14`,
  Fancytext: tw`w-14 h-14`,
  MusicalNote: tw`w-14 h-14`,
};

export const Icon = styled.img.attrs(({ variant = 'Play' }: IIcon) => ({
  src: getMapValue(variant, iconImageMap),
}))<IIcon>`
  ${({ rotate }) => rotate && `transform: rotate(${rotate}deg);`},
  ${tw`h-full w-full disabled:opacity-25`}
  ${({ variant = 'Play' }) => getMapValue(variant, iconStyleMap)}
    ${({ mobileDimensions }) =>
    mobileDimensions &&
    `
    @media (max-width: 767px),screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
      width: ${mobileDimensions}px;
        height: ${mobileDimensions}px;
      }`};
  ${({ tabletDimensions }) =>
    tabletDimensions &&
    `@media (min-width: 768px) and (max-width: 1024px) {
        width: ${tabletDimensions}px;
        height: ${tabletDimensions}px;
      }`};
  ${({ mobileFullscreenDimensions }) =>
    mobileFullscreenDimensions &&
    isMobileFullscreen() &&
    `
    @media (max-width: 767px),screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
      width: ${mobileFullscreenDimensions}px;
        height: ${mobileFullscreenDimensions}px;
      }`};
  display: inherit;
  ${({ pulsate }) =>
    pulsate &&
    `
  animation-duration: 3s;
  animation-name: pulsate;
  animation-iteration-count: infinite;
  `}
`;

export default Icon;
