/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

const Mp4 = ({ url }) => (
  <div className="mb-64">
    <video width="100%" height="240" controls>
      <source src={url} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
);

export default Mp4;
