import React from 'react';
import { addToPlaylistSongId } from '../../utils/reactiveVars';

const CreatePlaylistButton = () => (
  <a
    href=""
    data-toggle="modal"
    data-target="#createPlaylistModal"
    className="button__rounded__sm button__turquoise text-nowrap"
    onClick={() => addToPlaylistSongId(null)}
  >
    Create Playlist
  </a>
);

export default CreatePlaylistButton;
