import React from 'react';
import Video from '../../Shared/Video/Video';

const Animation = ({ media }) => {
  if (!media) return null;

  const parsedMedia = JSON.parse(media)[0];

  return (
    <div className="mc__animation main__container p-0 mt-0">
      {parsedMedia.layout === 'image' && (
        <img src={parsedMedia.attributes.image} alt="" className="w-100" />
      )}
      {parsedMedia.layout === 'video' && (
        <Video url={parsedMedia.attributes.video} />
      )}
    </div>
  );
};

export default Animation;
