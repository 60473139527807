import { useMutation } from '@apollo/client';
import { UPDATE_FAVOURITES } from '../../graphql/mutations/favourite';
import { GET_FAVOURITE_ASSEMBLIES } from '../../graphql/queries/assembly/assembly';
import { GET_FAVOURITE_RESOURCES } from '../../graphql/queries/resource/resource';
import { displayErrorMessage } from '../../utils/helpers';

const useUpdateFavourites = () => {
  const [updateFavourites] = useMutation(UPDATE_FAVOURITES, {
    refetchQueries: [
      { query: GET_FAVOURITE_ASSEMBLIES },
      { query: GET_FAVOURITE_RESOURCES },
    ],
  });

  const updateFavouritesRequest = {
    send: (input) => {
      updateFavourites({
        variables: { input },
      }).catch(() => displayErrorMessage());
    },
  };

  return { updateFavouritesRequest };
};

export default useUpdateFavourites;
