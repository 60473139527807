import React from 'react';
import { toast } from 'react-toastify';
import useGetCategoryPage from '../../../../hooks/useGetCategoryPage';
import AssemblyPlans from '../../../Shared/AssemblyPlans';
import GuidesAndResources from '../../../Shared/GuidesAndResources';
import PlaylistCards from '../../../Shared/PlaylistCards';
import Songbooks from '../../../Shared/Songbook/Index';
import SongCards from '../../../Shared/SongCards/Index';

const MainSection = ({
  songs,
  resources,
  displayResources = true,
  displaySongs = true,
  displayPlaylists = true,
  displaySongbooks = true,
  filterParams,
}) => {
  const { data, loading } = useGetCategoryPage('collective worship');
  loading && toast.info('Loading...', { toastId: 'loading' });

  return (
    <section className="main__container mt__lg-50 mt-40">
      <div className="px__sm-3">
        {displaySongs && (
          <SongCards
            title="Collective Worship songs"
            songs={songs}
            pageTitle="Collective Worship"
            name="Collective Worship"
            type="OOTA Singing School"
            filterParams={filterParams}
          />
        )}
        {displayResources && (
          <GuidesAndResources
            resources={resources}
            title="Collective Worship"
            type="OOTA Singing School"
          />
        )}
        {displayPlaylists && (
          <PlaylistCards
            playlists={data?.categoryPage?.playlists}
            title="Collective worship playlists"
            disableDeletePlaylist
          />
        )}
        {displaySongbooks && (
          <Songbooks
            songbooks={data?.categoryPage?.songbooks}
            title="Collective Worship songbooks"
          />
        )}
      </div>
      <AssemblyPlans />
    </section>
  );
};

export default MainSection;
