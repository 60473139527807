import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_NATIVITIES_MUSICALS } from '../../graphql/queries/nativitiesAndMusicals/nativitiesAndMusicals';
import { toastErrorObj } from '../../utils/objects';

const useGetNativitiesAndMusicals = () => {
  const { data, loading, error } = useQuery(GET_NATIVITIES_MUSICALS, {
    onCompleted: () => toast.dismiss('loading'),
    onError: () => {
      toast.dismiss('loading');
      toast.error('Could not load nativities and musicals', toastErrorObj);
    },
  });

  return { data, loading, error };
};

export default useGetNativitiesAndMusicals;
