import { useMutation } from '@apollo/client';
import { UPDATE_PLAYLISTS_ORDERING } from '../../graphql/mutations/playlist';
import { displayErrorMessage } from '../../utils/helpers';

const useUpdatePlaylistsOrdering = () => {
  const [updatePlaylistsOrdering] = useMutation(UPDATE_PLAYLISTS_ORDERING);

  const updatePlaylistsOrderingRequest = (input) => {
    updatePlaylistsOrdering({
      variables: {
        input,
      },
    }).catch(() => displayErrorMessage());
  };

  return { updatePlaylistsOrderingRequest };
};

export default useUpdatePlaylistsOrdering;
