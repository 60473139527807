/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { resourceList } from '../../../../../utils/reactiveVars';

const CurriculumResources = ({ resources, title, type, query = null }) => {
  if (!resources || resources?.length < 1) return null;
  const location = useLocation();

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3 px-xl-0">
        <p className="heading__section text-uppercase">Curriculum resources</p>
        <Link
          to="see-all/resources"
          className="text__white text__fs-10"
          onClick={() => {
            resourceList({
              path: location.pathname,
              title,
              type,
              query,
              showTags: true,
            });
          }}
        >
          SEE ALL
        </Link>
      </div>
      <div className="curriculum__card__grid">
        {resources.map((resource) => (
          <div className="curriculum__card" key={resource.id}>
            <Link
              to={`resources/${resource.slug}`}
              className="text__fs-14 text__blue-sky mb-3 mb-sm-0 text__fw-600"
            >
              {resource.title}
            </Link>
            <div className="tags-wrapper">
              {resource.resourceTags?.find(
                (item) => item.type === 'Curriculum Subject Links'
              ) &&
                resource.resourceTags?.map(
                  (tag) =>
                    tag.type === 'Curriculum Subject Links' && (
                      <div className="tag" key={tag.id}>
                        {tag.formattedName}
                      </div>
                    )
                )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CurriculumResources;
