import React from 'react';

export const DeleteCollection = ({ onClick }) => (
  <div className="d-flex mb-26">
    <a
      href=""
      data-target="#deleteModal"
      data-toggle="modal"
      className="text__black d-flex align-items-center"
      onClick={onClick}
    >
      <img src="/img/bin.svg" alt="bin" className="mr-3 d-inline" />{' '}
      <div className="pt-2">Delete collection</div>
    </a>
  </div>
);
