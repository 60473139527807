import { useMutation } from '@apollo/client';
import { UPDATE_COLLECTIONS_ORDERING } from '../../graphql/mutations/collection';
import { displayErrorMessage } from '../../utils/helpers';

const useUpdateCollectionsOrdering = () => {
  const [updateCollectionsOrdering] = useMutation(UPDATE_COLLECTIONS_ORDERING);

  const updateCollectionsOrderingRequest = (input) => {
    updateCollectionsOrdering({
      variables: {
        input,
      },
    }).catch(() => displayErrorMessage());
  };

  return { updateCollectionsOrderingRequest };
};

export default useUpdateCollectionsOrdering;
