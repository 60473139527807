/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import useGetCollectionShareLink from '../../../../../../../hooks/Collection/useGetCollectionShareLink';
import CopyShareLink from './CopyShareLink';
import GenerateShareLink from './GenerateShareLink';

const GenerateCollectionShareLink = ({ collectionId }) => {
  const { data, loading } = useGetCollectionShareLink(collectionId);

  const [generatedShareLink, setGeneratedShareLink] = useState(null);

  const shareLink = data?.getCollectionShareLink || generatedShareLink;

  return (
    <>
      <p className="text-dark text__fs-12 mt-5 mb-0">
        Create a link to share a copy of this collection with anyone who has a
        Sparkyard subscription.
      </p>
      {loading && <span>Please wait...</span>}

      <CopyShareLink shareLink={shareLink} />
      <GenerateShareLink
        setGeneratedShareLink={setGeneratedShareLink}
        hide={shareLink}
        collectionId={collectionId}
      />
    </>
  );
};

export default GenerateCollectionShareLink;
