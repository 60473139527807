/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

const SoundcloudAudio = ({ url, noMargin }) => {
  if (!url) return null;
  return (
    <div className={`${noMargin ? 'mb-0' : 'mb-3'}`}>
      <iframe
        width="100%"
        height="166"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src={`https://w.soundcloud.com/player/?url=${url}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
      />
    </div>
  );
};

export default SoundcloudAudio;
