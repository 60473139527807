import { gql } from '@apollo/client';
import { EventFieldsFragment, MinEventFieldsFragment } from './fragments';

export const GET_EVENTS = gql`
  query Events {
    events {
      ...MinEventFields
    }
  }
  ${MinEventFieldsFragment}
`;

export const GET_EVENT = gql`
  query Event($slug: String!) {
    event(slug: $slug) {
      ...EventFields
    }
  }
  ${EventFieldsFragment}
`;
