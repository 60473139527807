/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from 'react';
import Decimal from 'decimal.js';
import 'twin.macro';
import 'styled-components/macro';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useObserver } from 'mobx-react-lite';
import { useReactiveVar } from '@apollo/client';

import MediaPlayer from './MediaPlayer';
import Slide from './Slide';
import useStores from '../hooks/useStores';
import useSongPage from '../hooks/useSongPage';
import {
  isMobileFullscreen,
  isPlayerFullscreen,
  playerActive,
} from '../../utils/reactiveVars';
import SongTypes from './SongTypes';
import useMediaQuery from '../../hooks/Others/useMediaQuery';
import GuestHeader from './MobileFullscreen/GuestHeader';
import ActionsModal from '../../components/Shared/Modals/ActionsModal/Index';

const App = (): JSX.Element => {
  const { mediaPlayerStore } = useStores();

  const isFullScreen = useReactiveVar(isPlayerFullscreen);
  const isPlayerActive = useReactiveVar(playerActive);

  let currentPage = useSongPage(
    mediaPlayerStore.song?.pages,
    Math.round(
      +new Decimal(mediaPlayerStore.progress.playedSeconds).times(1000)
    ) // using decimal.js here cos js cannot do floating point operations well
  );

  const handle = useFullScreenHandle();

  const slidesInvisible = () => isPlayerActive && !isFullScreen;
  const isMobile = useMediaQuery(
    '(max-width: 767px), screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape)'
  );
  const isMobiileLandscape = useMediaQuery(
    'screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape)'
  );
  const mobileHeight = isMobiileLandscape
    ? 'calc(42vw - 50px)'
    : 'calc(42% - 50px)';
  const height = isMobile ? mobileHeight : 'calc(100%)';

  let currentPageIndex = mediaPlayerStore.song.pages.indexOf(currentPage);
  const toggleFullScreen = () => {
    if (!isFullScreen) return handle.enter();
    return handle.exit();
  };

  if (mediaPlayerStore.progress.playedSeconds === mediaPlayerStore.duration) {
    mediaPlayerStore.switchSong(true);
  }

  if (currentPageIndex === -1 && mediaPlayerStore.song?.pages.length > 0) {
    currentPageIndex = mediaPlayerStore.song?.pages.length - 1;
    currentPage = mediaPlayerStore.song?.pages[currentPageIndex];
  }

  useEffect(() => {
    if (mediaPlayerStore.song?.pages.length < 1) {
      isPlayerFullscreen(false);
      mediaPlayerStore.setShowSlide(false);
      handle.exit();
    }
  }, [mediaPlayerStore.song]);

  const mobileFullscreen = useReactiveVar(isMobileFullscreen);
  let mediaPlayerClassName = 'd-block w-100 media-player z-100';
  let slidesClassName = '';
  if (slidesInvisible()) {
    mediaPlayerClassName += ' media-player-hide-slides';
    slidesClassName = 'media-player-hide-slides';
  }
  if (mobileFullscreen) {
    mediaPlayerClassName += ' flip-element';
  }

  return useObserver(() => (
    <FullScreen handle={handle}>
      {isFullScreen && <ActionsModal fullscreenView />}
      <div className={mediaPlayerClassName} style={{ height: '100%' }}>
        <div
          tw="h-screen"
          className={slidesClassName}
          style={{ height: '100%' }}
        >
          <GuestHeader isGuest />
          <Slide
            id={currentPage.id}
            title={mediaPlayerStore.song.infoName}
            page={currentPage}
            milliseconds={
              +new Decimal(mediaPlayerStore.progress.playedSeconds).times(1000)
            } // using decimal.js here cos js cannot do floating point operations well
            width="100%"
            height={height}
            currentSlide={currentPageIndex}
            cloud={currentPage.cloudPosX !== -1 && currentPage.cloudPosY !== -1}
            background={mediaPlayerStore.song.fileLarge}
            totalSlides={mediaPlayerStore.song.pages.length}
          />
          <SongTypes />
          <MediaPlayer toggleFullScreen={toggleFullScreen} />
        </div>
      </div>
    </FullScreen>
  ));
};

export default App;
