import { gql } from '@apollo/client';
import {
  PlaylistFieldsFragment,
  PlaylistShareLinkFieldsFragment,
} from './fragments';

export const GET_PLAYLISTS = gql`
  query Playlists(
    $createdBy: String!
    $hasCategoryPages: QueryPlaylistsHasCategoryPagesWhereHasConditions
    $hasPlaylistTags: QueryPlaylistsHasPlaylistTagsWhereHasConditions
    $filtered: [TagInput]
    $organisation_ref: ID
  ) {
    playlists(
      createdBy: $createdBy
      hasCategoryPages: $hasCategoryPages
      hasPlaylistTags: $hasPlaylistTags
      filtered: $filtered
    ) {
      ...PlaylistFields
      hasSharedWithOrganisation(organisation_ref: $organisation_ref)
      shareId(organisation_ref: $organisation_ref)
    }
  }
  ${PlaylistFieldsFragment}
`;

export const GET_PLAYLIST = gql`
  query Playlist($id: ID!, $organisation_ref: ID) {
    playlist(id: $id) {
      ...PlaylistFields
      hasSharedWithOrganisation(organisation_ref: $organisation_ref)
      shareId(organisation_ref: $organisation_ref)
      isSharedWithUser(organisation_ref: $organisation_ref)
    }
  }
  ${PlaylistFieldsFragment}
`;

export const GET_PLAYLISTS_SHARED_WITH_ORGANISATION = gql`
  query PlaylistsSharedWithOrganisation($organisation_ref: ID!) {
    playlistsSharedWithOrganisation(organisation_ref: $organisation_ref) {
      ...PlaylistFields
    }
  }
  ${PlaylistFieldsFragment}
`;

export const GET_RECOMMENDED_PLAYLISTS = gql`
  query RecommendedPlaylists {
    recommendedPlaylists {
      id
      playlists {
        ...PlaylistFields
      }
    }
  }
  ${PlaylistFieldsFragment}
`;

export const GET_PLAYLIST_SHARE_LINK = gql`
  query GetPlaylistShareLink($playlist_id: ID!) {
    getPlaylistShareLink(playlist_id: $playlist_id) {
      ...PlaylistShareLinkFields
    }
  }
  ${PlaylistShareLinkFieldsFragment}
`;
