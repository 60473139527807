import { useHistory } from 'react-router-dom';
import { showModal } from '../../styles/js/modals';
import { dismissWarningMessage } from '../../utils/helpers';
import {
  currentlySelectedSubscription,
  currentOrganisationHasActiveSub,
  user,
  userHasActiveSubOrTrial,
} from '../../utils/reactiveVars';

const useHandleSubscriptionAndTrials = () => {
  const history = useHistory();

  const hasActiveSubscription = (subs) => {
    const firstActiveSub = subs?.find((sub) => sub.isActive);
    // fallback empty object is needed here so sub details get reset when
    // organizations are switched
    currentlySelectedSubscription(firstActiveSub || {});
    return !!firstActiveSub;
  };

  const handleOrganisationSub = (organisation) => {
    const hasActiveSub = hasActiveSubscription(organisation?.sysubs);
    currentOrganisationHasActiveSub(hasActiveSub);

    if (!hasActiveSub) {
      history.push('/manage-account');
      showModal('noSubscriptionModal');
    } else {
      dismissWarningMessage();
    }
  };

  const handleUserSubOrTrial = () => {
    const { activeTrial, sysubs } = user();
    const hasActiveSubOrTrial = hasActiveSubscription(sysubs) || activeTrial;
    const trialRecentlyEnded = sysubs?.length === 0 && !activeTrial;
    userHasActiveSubOrTrial(hasActiveSubOrTrial);

    if (!hasActiveSubOrTrial) {
      history.push('/manage-account');

      if (trialRecentlyEnded) {
        showModal('trialEndedModal');
      } else {
        showModal('noSubscriptionModal');
      }
    } else {
      dismissWarningMessage();
    }
  };

  return { handleOrganisationSub, handleUserSubOrTrial };
};

export default useHandleSubscriptionAndTrials;
