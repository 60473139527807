/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable operator-linebreak */
/* eslint-disable no-extra-boolean-cast */
import React, { useState, useRef, useEffect } from 'react';
import { format } from 'date-fns';
import sub from 'date-fns/sub';
import useGetUsageReport from '../../../../../hooks/Report/useGetUsageReport';
import { organisationLoggedInAs } from '../../../../../utils/reactiveVars';
import DateRange from './DateRange';
import Row from './Row';
import UserSelection from './UserSelection';
import hideModal from '../../../../../styles/js/modals';
import FormatSelection from './FormatSelection';

const Table = () => {
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedFormat, setSelectedFormat] = useState('xlsx');
  const [dayInclusiveFrom, setDayInclusiveFrom] = useState(null);
  const [dayInclusiveTo, setDayInclusiveTo] = useState(null);
  const [groupByUser, setGroupByUser] = useState(true);
  const [includePlays, setIncludePlays] = useState(true);
  const [includeLyrics, setIncludeLyrics] = useState(true);
  const [isForSingleUser, setIsForSingleUser] = useState(false);

  const organisationRef = organisationLoggedInAs()?.id;

  const { data, loading, getUsageReportRequest } =
    useGetUsageReport('organisation');

  const downloadFileRef = useRef(null);
  const mimeTypes = {
    csv: 'data:text/csv',
    xlsx: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64',
  };

  useEffect(() => {
    if (data?.organisationUsage.file) {
      downloadFileRef.current.href = `${mimeTypes[selectedFormat]},${encodeURI(
        data.organisationUsage.file
      )}`;
      downloadFileRef.current.download = `usage-report-sparkyard.${selectedFormat}`;
      downloadFileRef.current.click();
      hideModal('createUsageReportModal');
    }
  }, [data]);

  const date = new Date();
  const yesterday = format(sub(date, { days: 1 }), 'yyyy-MM-dd');

  const handleIsForSingleUser = (selection) => {
    if (selection) {
      setIsForSingleUser(true);
      setGroupByUser(false);
      return;
    }
    setIsForSingleUser(false);
  };

  const sendGetUsageReportRequest = () => {
    // exludeScores and excludeLyrics are separate in the api so
    // data for excludeLyrics is being used for both here
    const input = {
      organisationRef,
      dataVersion: false,
      groupByUser,
      dayInclusiveFrom,
      dayInclusiveTo,
      memberUserRef: !!selectedUser ? selectedUser : '',
      fileFormat: selectedFormat,
      excludePlays: !includePlays,
      excludeLyrics: !includeLyrics,
      excludeScores: !includeLyrics,
    };

    getUsageReportRequest(input);
  };

  const disableButton =
    !dayInclusiveFrom ||
    !dayInclusiveTo ||
    dayInclusiveFrom > dayInclusiveTo ||
    dayInclusiveTo > yesterday ||
    loading;

  return (
    <>
      <h5 className="text__fs-18 my-4">Filter report</h5>
      <UserSelection
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        isForSingleUser={isForSingleUser}
        handleIsForSingleUser={handleIsForSingleUser}
      />
      <DateRange
        setDayInclusiveFrom={setDayInclusiveFrom}
        setDayInclusiveTo={setDayInclusiveTo}
        yesterday={yesterday}
      />
      <Row
        title="Song Plays"
        checked={includePlays}
        setChecked={setIncludePlays}
      />
      <Row
        title="Scores and Lyrics Opened"
        checked={includeLyrics}
        setChecked={setIncludeLyrics}
      />
      <Row
        title="Sub-Total by User"
        checked={groupByUser && !isForSingleUser}
        setChecked={setGroupByUser}
        disabled={isForSingleUser}
      />
      <FormatSelection
        selectedFormat={selectedFormat}
        setSelectedFormat={setSelectedFormat}
      />
      <div className="mt-40 d-flex justify-content-between">
        <button
          className="btn button__green-meadow button__w-144 button__transparent__green border__green"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <a ref={downloadFileRef} className="d-none" />
        <button
          className="btn button__green-meadow"
          onClick={sendGetUsageReportRequest}
          disabled={disableButton}
        >
          {`Generate report (${selectedFormat.toUpperCase()})`}
        </button>
      </div>
    </>
  );
};

export default Table;
